import { IAction } from '@Root/types'
import filter from 'lodash/filter'

import {
  IExportationState,
  TExportationActionTypes,
  ExportationActionTypes,
} from './types'

import {
  IGetExportationsForDownloadSuccessPayload,
  IRemoveFromExportationsForDownloadPayload,
} from '@EditorModules/Exportation/types'

export const initialState: IExportationState = {
  exportationsForDownload: [],
}

export const exportationsReducer = (
  state: IExportationState = initialState,
  action: TExportationActionTypes,
): IExportationState => {
  switch (action.type) {
    case ExportationActionTypes.GET_EXPORTATIONS_FOR_DOWNLOAD_SUCCESS: return getExportationsForDownloadSuccess(state, action)
    case ExportationActionTypes.REMOVE_FROM_EXPORTATIONS_FOR_DOWNLOAD: return removeFromExportationsForDownload(state, action)

    default: return state
  }
}

function isGetExportationsForDownloadSuccessAction(arg: any): arg is Required<IAction<IGetExportationsForDownloadSuccessPayload>> {
  return arg.payload && arg.payload.data.exportationUrls
}

const getExportationsForDownloadSuccess =  (state: IExportationState, action: TExportationActionTypes): IExportationState => {
  let updatedExportationsForDownload = state.exportationsForDownload

  if (isGetExportationsForDownloadSuccessAction(action) && action.payload.data.exportationUrls.length > 0) {
    updatedExportationsForDownload = updatedExportationsForDownload.concat([{ id: action.payload.data.id as number, urls: action.payload.data.exportationUrls }])
  }

  return { ...state, exportationsForDownload: updatedExportationsForDownload }
}

function isRemoveFromExportationForDownloadAction(arg: any): arg is Required<IAction<IRemoveFromExportationsForDownloadPayload>> {
  return arg.payload && arg.payload.id
}

const removeFromExportationsForDownload =  (state: IExportationState, action: TExportationActionTypes): IExportationState => {
  let updatedExportationsForDownload = state.exportationsForDownload

  if (isRemoveFromExportationForDownloadAction(action)) {
    updatedExportationsForDownload = filter(updatedExportationsForDownload, exportationForDownload => exportationForDownload.id !== action.payload.id)
  }

  return { ...state, exportationsForDownload: updatedExportationsForDownload }
}
