export const ExternalTokenActionTypes = {
  CREATE_OR_UPDATE_EXTERNAL_TOKEN: '@@externalToken/CREATE_OR_UPDATE_EXTERNAL_TOKEN',
  CREATE_OR_UPDATE_EXTERNAL_TOKEN_SUCCESS: '@@externalTopup/CREATE_OR_UPDATE_EXTERNAL_TOKEN_SUCCESS',
  CREATE_OR_UPDATE_EXTERNAL_TOKEN_FAILURE: '@@externalTopup/CREATE_OR_UPDATE_EXTERNAL_TOKEN_FAILURE',
}

export interface IExternalToken {
  readonly id: string
  readonly type: 'externalToken'
  readonly attributes: {
    readonly externalTokenUrl: string
  }
}

export interface IExternalTokenData {
  readonly data: IExternalToken
}

export interface IExternalTokenState {
  readonly externalTokenData: IExternalToken
  readonly loading: boolean
}

export interface ICreateOrUpdateExternalTokenAction {
  readonly type: string
  readonly payload: ICreateOrUpdateExternalTokenPayload
}

export interface ICreateOrUpdateExternalTokenPayload {
  readonly subtitleId: number
  readonly validityInDays: number
}

export interface IFetchExternalTokenFailureAction {
  readonly type: string
}

export interface ICreateOrUpdateExternalTokenFailureAction {
  readonly type: string
}

export interface ICreateOrUpdateExternalTokenSuccessAction {
  readonly type: string
  readonly externalTokenData: IExternalToken
}

export interface ICreateOrUpdateExternalTokenSuccessResponse {
  readonly data: IExternalTokenData
  readonly config: object
  readonly headers: object
  readonly request: object
  readonly status: number
  readonly statusText: string
}

export type IExternalTokenActionTypes =
  ICreateOrUpdateExternalTokenAction |
  ICreateOrUpdateExternalTokenSuccessAction |
  IFetchExternalTokenFailureAction |
  ICreateOrUpdateExternalTokenFailureAction
