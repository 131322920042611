import React from 'react'

import './Loader.scss'

interface LoaderProps {
  readonly color?: string
}

const Loader: React.FC<LoaderProps> = props => {
  return (
    <div className={`Loader Loader-${props.color}`}>
      <div className="Loader__ball Loader__ball-first" />
      <div className="Loader__ball Loader__ball-second" />
      <div className="Loader__ball Loader__ball-third" />
    </div>
  )
}

export default Loader
