export default function convertFormality(formality: string | undefined): string | undefined {
  let formalityOption: string | undefined
  switch(formality) {
    case 'Auto':
      formalityOption = 'default'
      break
    case 'Formal':
      formalityOption = 'more'
      break
    case 'Informal':
      formalityOption = 'less'
      break
  }

  return formalityOption
}
