import axios, { AxiosRequestConfig } from 'axios'
import convertToSnakeCase from '@Utils/ConvertToSnakeCase'

import {
  IConnectExternalChannel,
  IOpenSsoAdminPortal,
  ISetLastViewedAlert,
} from './types'

export function logout() {
  const config: AxiosRequestConfig = {
    headers: {
      'X-CSRF-Token': window.Checksub.token,
      'X-Requested-With': 'XMLHttpRequest',
    },
    responseType: 'json'
  }

  return axios.delete('/users/sign_out', config)
}

export function getUserInfo() {
  const config: AxiosRequestConfig = {
    headers: {
      'X-CSRF-Token': window.Checksub.token
    },
    responseType: 'json'
  }

  return axios.get('/api/v1/users/', config)
}

export function getUserStripeInfo() {
  const config: AxiosRequestConfig = {
    headers: {
      'X-CSRF-Token': window.Checksub.token
    },
    responseType: 'json'
  }

  return axios.get('/api/v1/users/show_stripe_info', config)
}

export function connectExternalChannel(data: IConnectExternalChannel) {
  const config: AxiosRequestConfig = {
    headers: {
      'X-CSRF-Token': window.Checksub.token,
      'X-Requested-With': 'XMLHttpRequest',
    },
    responseType: 'json'
  }

  return axios.post('/api/v1/external_channels/connect', data, config)
}

export function createCustomerPortalSession() {
  const config: AxiosRequestConfig = {
    headers: {
      'X-CSRF-Token': window.Checksub.token
    }
  }

  return axios.get('/api/v1/users/create_customer_portal_session', config)
}

export function openSsoAdminPortal(data: IOpenSsoAdminPortal) {
  const config: AxiosRequestConfig = {
    headers: {
      'X-CSRF-Token': window.Checksub.token,
      'X-Requested-With': 'XMLHttpRequest',
    },
    responseType: 'json'
  }

  return axios.post('/api/v1/sso/open_admin_portal', convertToSnakeCase(data), config)
}

export function setLastViewedAlert(data: ISetLastViewedAlert) {
  const config: AxiosRequestConfig = {
    headers: {
      'X-CSRF-Token': window.Checksub.token,
      'X-Requested-With': 'XMLHttpRequest',
    },
    responseType: 'json'
  }

  return axios.put('/api/v1/users/set_last_viewed_alert', convertToSnakeCase(data), config)
}

export function getExportingSubtitleIds() {
  const config: AxiosRequestConfig = {
    headers: {
      'X-CSRF-Token': window.Checksub.token
    },
    responseType: 'json'
  }

  return axios.get('/api/v1/users/show_exporting_subtitle_ids', config)
}
