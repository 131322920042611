import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { IApplicationState } from '../../rootReducer'
import SignInForm from '@SiteComponents/SignInForm'
import OnboardingSidebar from '@SiteComponents/OnboardingSidebar'

import {
  IUserSignInPayload,
  ISsoSignInPayload,
} from './types'

import {
  signIn,
  ssoSignIn,
} from './actions'

import './UsersSignIn.scss'

interface UsersSignInProps {
  readonly loading: boolean
  signIn(payload: IUserSignInPayload): void
  ssoSignIn(payload: ISsoSignInPayload): void
}

interface UsersSignInState {
}

class UsersSignIn extends React.Component<UsersSignInProps, UsersSignInState> {
  signIn = (payload: IUserSignInPayload | ISsoSignInPayload, useSso: boolean) => {
    if (useSso) {
      this.props.ssoSignIn(payload as ISsoSignInPayload)
    } else {
      this.props.signIn(payload as IUserSignInPayload)
    }
  }

  render() {
    const { loading } = this.props

    return (
      <div className="UsersSignIn">
        <OnboardingSidebar
          type="sign-in"
          caption1={<span>Grow your audience with<br />subtitles, dubbing and translation.</span>}
          caption2={<span />}
        />
        <div className="UsersSignIn__form-wrapper">
          <SignInForm
            submit={this.signIn}
            showSpinner={loading}
            disableButton={loading}
          />
        </div>
      </div>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { userSignIn } = state

  return {
    loading: userSignIn.loading,
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    signIn,
    ssoSignIn,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersSignIn)
