import {
  IActionSuccess,
} from '@Root/types'

import {
  ICredit,
  IUpdateTimeAction,
  ICreditsTopupState,
  ICreditsTopupActionTypes,
  CreditsTopupActionTypes,
  ISubmitTopupRequestSuccessAction,
} from './types'

import {
  IPrice,
  PaymentActionTypes,
  IPaymentActionTypes,
} from '@SitePages/CheckoutsPaymentDetails/types'

const initialCredit: ICredit = {
  id: '-1',
  type: 'creditPaymentInfo',
  attributes: {
    amount: 0,
    vat: 0,
    shouldApplyVat: false,
    availableTimeInHours: 0,
    paymentIntentClientSecret: '',
  }
}

export const initialState: ICreditsTopupState = {
  time: 1,
  errors: [],
  loading: false,
  credit: initialCredit,
}

export const creditsTopupReducer = (
  state: ICreditsTopupState = initialState,
  action: ICreditsTopupActionTypes
): ICreditsTopupState => {
  switch (action.type) {
    case CreditsTopupActionTypes.UPDATE_TIME: return updateTime(state, action)

    case CreditsTopupActionTypes.SUBMIT_TOPUP_REQUEST: return submitTopupRequest(state)
    case CreditsTopupActionTypes.SUBMIT_TOPUP_REQUEST_SUCCESS: return submitTopupRequestSuccess(state, action)
    case CreditsTopupActionTypes.SUBMIT_TOPUP_REQUEST_FAILURE: return submitTopupRequestFailure(state)
    case PaymentActionTypes.PAYMENT_SUCCESS: return resetCredit(state)
    case PaymentActionTypes.FETCH_CREDIT_SUCCESS: return fetchCreditSuccess(state, action)
    default: return state
  }
}

const submitTopupRequest = (state: ICreditsTopupState): ICreditsTopupState => {
  return { ...state, loading: true }
}

function isIActionSuccess(arg: any): arg is ISubmitTopupRequestSuccessAction {
  return arg.payload && arg.payload.type === 'creditPaymentInfo'
}

const submitTopupRequestSuccess = (state: ICreditsTopupState,  action: ICreditsTopupActionTypes): ICreditsTopupState => {
  let { credit } = state

  if (isIActionSuccess(action)) {
    credit = action.payload
  }

  return { ...state, credit, loading: false }
}

const submitTopupRequestFailure = (state: ICreditsTopupState): ICreditsTopupState => {
  return { ...state, loading: false }
}

function isIUpdateTimeAction(arg: any): arg is IUpdateTimeAction {
  return arg.time != null
}

const updateTime = (state: ICreditsTopupState, action: ICreditsTopupActionTypes): ICreditsTopupState => {
  let time = 0

  if (isIUpdateTimeAction(action)){
    time = action.time
  }

  return { ...state, time}
}

const resetCredit = (state: ICreditsTopupState): ICreditsTopupState => {
  return {...state, credit: initialCredit }
}

const fetchCreditSuccess = (state: ICreditsTopupState, action: IPaymentActionTypes): ICreditsTopupState => {
  let credit = state.credit

  if (isIFetchCreditSuccessAction(action)) {
    const creditUpdate = action.payload

    credit = {
      id: state.credit.id,
      type: 'creditPaymentInfo',
      attributes: {
        amount: creditUpdate.attributes.amount,
        vat: creditUpdate.attributes.vat,
        shouldApplyVat: creditUpdate.attributes.shouldApplyVat,
        availableTimeInHours: state.credit.attributes.availableTimeInHours,
        paymentIntentClientSecret: state.credit.attributes.paymentIntentClientSecret
      }
    }
  }

  return { ...state, credit }
}

function isIFetchCreditSuccessAction(arg: any): arg is Required<IActionSuccess<IPrice>> {
  return arg.payload != null
}
