import {
  PayloadData,
  PasswordNewFormActionTypes,
  TPasswordNewActionTypes
} from './types'

export const passwordNew = (payload: PayloadData): TPasswordNewActionTypes => ({
  type: PasswordNewFormActionTypes.PASSWORD_NEW,
  payload
})

export const passwordNewSuccess = (): TPasswordNewActionTypes => ({
  type: PasswordNewFormActionTypes.PASSWORD_NEW_SUCCESS,
})

export const passwordNewFailure = (): TPasswordNewActionTypes => ({
  type: PasswordNewFormActionTypes.PASSWORD_NEW_FAILURE,
})
