import padNumber from '../PadNumber'

export default function formatDateAndTime(dateString: string): string {
  const date = new Date(dateString)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hours = date.getHours()
  const minutes = date.getMinutes()

  return `${padNumber(day, 2)}/${padNumber(month, 2)}/${year} ${padNumber(hours, 2)}h${padNumber(minutes, 2)}`
}
