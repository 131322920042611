import {
  IFetchProjectVersionSettingsSuccessPayload,
  TProjectVersionSettingsAction,
  PayloadSubmitProjectVersionUpdateBody,
  ProjectVersionSettingsActionTypes,
  IProjectVersionSettingsActionTypes,
  IFetchProjectVersionSettingsMultiplePayload,
  IFetchProjectVersionSettingsMultipleSuccessPayload,
  IFetchProjectVersionSettingsPayload,
  IFetchProjectVersionSettingsDemoSuccessPayload,
} from './types'

export const submitProjectVersionUpdate = (payload: PayloadSubmitProjectVersionUpdateBody) => ({
  type: ProjectVersionSettingsActionTypes.SUBMIT_PROJECT_VERSION_UPDATE,
  payload,
})

export const submitProjectVersionUpdateSuccess = (): TProjectVersionSettingsAction => ({
  type: ProjectVersionSettingsActionTypes.SUBMIT_PROJECT_VERSION_UPDATE_SUCCESS
})

export const submitProjectVersionUpdateFailure = (): TProjectVersionSettingsAction => ({
  type: ProjectVersionSettingsActionTypes.SUBMIT_PROJECT_VERSION_UPDATE_FAILURE,
})

export const fetchProjectVersionSettings = (payload: IFetchProjectVersionSettingsPayload): IProjectVersionSettingsActionTypes => ({
  type: ProjectVersionSettingsActionTypes.FETCH_PROJECT_VERSION_SETTINGS,
  payload,
})

export const fetchProjectVersionSettingsSuccess = (payload: IFetchProjectVersionSettingsSuccessPayload): IProjectVersionSettingsActionTypes => ({
  type: ProjectVersionSettingsActionTypes.FETCH_PROJECT_VERSION_SETTINGS_SUCCESS,
  payload,
})

export const projectVersionSettingsFailure = (): IProjectVersionSettingsActionTypes => ({
  type: ProjectVersionSettingsActionTypes.PROJECT_VERSION_SETTINGS_FAILURE,
})

export const fetchProjectVersionSettingsMultiple = (payload: IFetchProjectVersionSettingsMultiplePayload): IProjectVersionSettingsActionTypes => ({
  type: ProjectVersionSettingsActionTypes.FETCH_PROJECT_VERSION_SETTINGS_MULTIPLE,
  payload,
})

export const fetchProjectVersionSettingsMultipleSuccess = (payload: IFetchProjectVersionSettingsMultipleSuccessPayload): IProjectVersionSettingsActionTypes => ({
  type: ProjectVersionSettingsActionTypes.FETCH_PROJECT_VERSION_SETTINGS_MULTIPLE_SUCCESS,
  payload,
})

export const fetchProjectVersionSettingsDemo = (): IProjectVersionSettingsActionTypes => ({
  type: ProjectVersionSettingsActionTypes.FETCH_PROJECT_VERSION_SETTINGS_DEMO,
})

export const fetchProjectVersionSettingsDemoSuccess = (payload: IFetchProjectVersionSettingsDemoSuccessPayload): IProjectVersionSettingsActionTypes => ({
  type: ProjectVersionSettingsActionTypes.FETCH_PROJECT_VERSION_SETTINGS_DEMO_SUCCESS,
  payload,
})
