import axios, { AxiosRequestConfig }  from 'axios'
import convertToSnakeCase from '@Utils/ConvertToSnakeCase'

import {
  ISetupTtsPayload,
} from './types'

export function fetchProjectVersionSettingsTts() {
  return axios.get('/api/v1/project_versions/show_settings_tts')
}

export function setupTts(payload: ISetupTtsPayload) {
  const config: AxiosRequestConfig = {
    headers: {
      'X-CSRF-Token': window.Checksub.token
    },
    responseType: 'json'
  }

  return axios.put('/api/v1/project_versions/setup_tts', convertToSnakeCase(payload), config)
}
