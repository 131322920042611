import React, { useState } from 'react'
import Button from '@SiteComponents/Button'
import toastr from 'toastr'
import Logger from '@Utils/Logger'
import * as API from '@SitePages/CheckoutsPaymentDetails/api'

import {
  useStripe,
  useElements,
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js'

import {
  StripeCardNumberElement
} from '@stripe/stripe-js'

import Visa from '@Images/visa.svg'
import Amex from '@Images/amex.svg'
import Discover from '@Images/discover.svg'
import Maestro from '@Images/maestro.svg'
import Mastercard from '@Images/mastercard.svg'

import './PayWithCardForm.scss'

interface PayWithCardFormProps {
  readonly currency: string
  readonly clientSecret: string
  readonly formattedAmount: string
  paymentSuccess(): void
  paymentFailure(message: string): void
}

const DEFAULT_ELEMENT_OPTIONS_CONFIGURATION = {
  style: {
    base: {
      fontSize: '16px',
      color: '#FFFFFF',
      '::placeholder': {
        color: '#8A8F98',
      },
    },
    invalid: {
      color: '#EC1B25',
    },
  }
}

const PayWithCardForm: React.FC<PayWithCardFormProps> = props => {
  const [loading, setLoading] = useState<boolean>(false)

  const stripe = useStripe()
  const elements = useElements()

  const { formattedAmount, currency } = props

  const pay = async (cardNumberElement: StripeCardNumberElement) => {
    if (!stripe) { return }

    setLoading(true)

    toastr.info('Payment step 1/2', 'Verification')

    // { error, token }
    const { token } = await stripe.createToken(cardNumberElement)

    if (token) {
      try {
        await API.updateStripeToken({ token: token.id })
      } catch(e) {
        props.paymentFailure('Check your credit card data')
        setLoading(false)
        return
      }
    }

    const { clientSecret } = props

    toastr.info('Payment step 2/2', 'Charging')

    try {
      // { error, token }
      const { paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardNumberElement
        }
      })

      if (paymentIntent) {
        props.paymentSuccess()
      } else {
        props.paymentFailure('Check your credit card data')
      }
    } catch(e) {
      Logger.error(e)
    }

    setLoading(false)
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!elements) { return }

    const cardNumberElement = elements.getElement(CardNumberElement)!

    pay(cardNumberElement)
  }

  return(
    <form onSubmit={handleSubmit}>
      <div className="PayWithCardForm">
        <div className="PayWithCardForm__title">
          Pay with card
          <div className="PayWithCardForm__title__imagewrapper">
            <Visa />
          </div>
          <div className="PayWithCardForm__title__imagewrapper">
            <Mastercard />
          </div>
          <div className="PayWithCardForm__title__imagewrapper">
            <Amex />
          </div>
          <div className="PayWithCardForm__title__imagewrapper">
            <Discover />
          </div>
          <div className="PayWithCardForm__title__imagewrapper PayWithCardForm__title__imagewrapper__last">
            <Maestro />
          </div>
        </div>
        <div className="PayWithCardForm__body">
          <div className="PayWithCardForm__body__cardelementwrapper PayWithCardForm__body__cardNumber">
            Card Number
            <CardNumberElement
              options={DEFAULT_ELEMENT_OPTIONS_CONFIGURATION}
            />
          </div>
          <div className="PayWithCardForm__body__cardelementwrapper PayWithCardForm__body__expirationDate">
            Expiration Date
            <CardExpiryElement
              options={DEFAULT_ELEMENT_OPTIONS_CONFIGURATION}
            />
          </div>
          <div className="PayWithCardForm__body__cardelementwrapper PayWithCardForm__body__CVC">
            CVC
            <CardCvcElement
              options={DEFAULT_ELEMENT_OPTIONS_CONFIGURATION}
            />
          </div>
        </div>
      </div>

      <Button
        type="submit"
        color="primary-dark"
        caption={<span>
            Pay <span translate="no"> {currency}{formattedAmount}</span>
          </span>
        }
        showSpinner={loading}
        disabled={!stripe || loading}
      />
    </form>
  )
}

export default PayWithCardForm
