import { TActionTypes, IAction } from '@Root/types'

export const VocabularyActionTypes = {
  FETCH_VOCABULARIES: '@@vocabulary/FETCH_VOCABULARY',
  FETCH_VOCABULARIES_FAILURE: '@@vocabulary/FETCH_VOCABULARIES_FAILURE',
  FETCH_VOCABULARIES_SUCCESS: '@@vocabulary/FETCH_VOCABULARIES_SUCCESS',
  CREATE_VOCABULARY: '@@vocabulary/CREATE_VOCABULARY',
  CREATE_VOCABULARY_FAILURE: '@@vocabulary/CREATE_VOCABULARY_FAILURE',
  CREATE_VOCABULARY_SUCCESS: '@@vocabulary/CREATE_VOCABULARY_SUCCESS',
  UPDATE_VOCABULARY: '@@vocabulary/UPDATE_VOCABULARY',
  UPDATE_VOCABULARY_FAILURE: '@@vocabulary/UPDATE_VOCABULARY_FAILURE',
  UPDATE_VOCABULARY_SUCCESS: '@@vocabulary/UPDATE_VOCABULARY_SUCCESS',
  DELETE_VOCABULARY: '@@vocabulary/DELETE_VOCABULARY',
  DELETE_VOCABULARY_FAILURE: '@@vocabulary/DELETE_VOCABULARY_FAILURE',
  DELETE_VOCABULARY_SUCCESS: '@@vocabulary/DELETE_VOCABULARY_SUCCESS',
}

export interface IVocabularyState {
  readonly errors: string[]
  readonly vocabularies: IVocabulary[]
  readonly createVocabulary: {
    readonly loading: boolean
  },
  readonly updateVocabulary: {
    readonly loading: boolean
  },
  readonly deleteVocabulary: {
    readonly loading: boolean
  },
  readonly fetchVocabularies: {
    readonly loading: boolean
  },
}

export interface IVocabulary {
  readonly id: string
  readonly type: 'vocabulary'
  readonly attributes: IVocabularyAttributes
}

export interface IVocabularyAttributes {
  readonly id: number
  readonly name: string
  readonly utterances: string[]
}

export interface IFetchVocabulariesSuccessResponseBody {
  readonly data: IVocabulary[]
}

export interface IFetchVocabulariesSuccessAction extends IAction {
  readonly payload: IFetchVocabulariesSuccessResponseBody
}

export interface ICreateVocabularyPayload {
  readonly name: string
}

export interface IDeleteVocabularyPayload {
  readonly id: number
}

export interface IUpdateVocabularyPayload {
  readonly id: number
  readonly utterances: string[]
}

export type IVocabularyActionTypes =
  TActionTypes |
  IAction<ICreateVocabularyPayload> |
  IAction<IUpdateVocabularyPayload> |
  IAction<IDeleteVocabularyPayload> |
  IFetchVocabulariesSuccessAction
