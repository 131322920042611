import {
  DeleteProjectVersionActionTypes,
  IDeleteProjectVersionActionTypes,
  IDeleteProjectVersionPayload,
} from './types'

export const deleteProjectVersion = (payload: IDeleteProjectVersionPayload): IDeleteProjectVersionActionTypes => ({
  type: DeleteProjectVersionActionTypes.DELETE_PROJECT_VERSION,
  payload,
})

export const deleteProjectVersionFailure = (): IDeleteProjectVersionActionTypes => ({
  type: DeleteProjectVersionActionTypes.DELETE_PROJECT_VERSION_FAILURE,
})

export const deleteProjectVersionSuccess = (): IDeleteProjectVersionActionTypes => ({
  type: DeleteProjectVersionActionTypes.DELETE_PROJECT_VERSION_SUCCESS
})
