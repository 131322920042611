import {
  SsoActionTypes,
  ISsoActionTypes,
  IDeleteMemberPayload,
  IDeleteMemberSuccessResponseBody,
  IAddUsersPayload,
  IAddUsersSuccessResponseBody,
  IChangeRolePayload,
  IChangeRoleSuccessResponseBody,
  IChangeSubscriptionPayload,
  IChangeSubscriptionSuccessResponseBody,
  IFetchSsoDataPayload,
  IFetchSsoDataSuccessResponseBody,
} from './types'

export const addUsers = (payload: IAddUsersPayload): ISsoActionTypes => ({
  type: SsoActionTypes.ADD_USERS,
  payload
})

export const addUsersSuccess = (payload: IAddUsersSuccessResponseBody): ISsoActionTypes => ({
  type: SsoActionTypes.ADD_USERS_SUCCESS,
  payload
})

export const addUsersFailure = (): ISsoActionTypes => ({
  type: SsoActionTypes.ADD_USERS_FAILURE,
})

export const openAddUsersToSsoPopup = (): ISsoActionTypes => ({
  type: SsoActionTypes.OPEN_ADD_USERS_TO_SSO_POPUP,
})

export const closeAddUsersToSsoPopup = (): ISsoActionTypes => ({
  type: SsoActionTypes.CLOSE_ADD_USERS_TO_SSO_POPUP,
})

export const fetchSsoData = (payload: IFetchSsoDataPayload): ISsoActionTypes => ({
  type: SsoActionTypes.FETCH_SSO_DATA,
  payload
})

export const fetchSsoDataSuccess = (payload: IFetchSsoDataSuccessResponseBody): ISsoActionTypes => ({
  type: SsoActionTypes.FETCH_SSO_DATA_SUCCESS,
  payload
})

export const fetchSsoDataFailure = (): ISsoActionTypes => ({
  type: SsoActionTypes.FETCH_SSO_DATA_FAILURE,
})

export const deleteMember = (payload: IDeleteMemberPayload): ISsoActionTypes => ({
  type: SsoActionTypes.DELETE_MEMBER,
  payload
})

export const deleteMemberSuccess = (payload: IDeleteMemberSuccessResponseBody): ISsoActionTypes => ({
  type: SsoActionTypes.DELETE_MEMBER_SUCCESS,
  payload
})

export const deleteMemberFailure = (): ISsoActionTypes => ({
  type: SsoActionTypes.DELETE_MEMBER_FAILURE,
})

export const changeRole = (payload: IChangeRolePayload): ISsoActionTypes => ({
  type: SsoActionTypes.CHANGE_ROLE,
  payload
})

export const changeRoleSuccess = (payload: IChangeRoleSuccessResponseBody): ISsoActionTypes => ({
  type: SsoActionTypes.CHANGE_ROLE_SUCCESS,
  payload
})

export const changeRoleFailure = (): ISsoActionTypes => ({
  type: SsoActionTypes.CHANGE_ROLE_FAILURE,
})

export const changeSubscription = (payload: IChangeSubscriptionPayload): ISsoActionTypes => ({
  type: SsoActionTypes.CHANGE_SUBSCRIPTION,
  payload
})

export const changeSubscriptionSuccess = (payload: IChangeSubscriptionSuccessResponseBody): ISsoActionTypes => ({
  type: SsoActionTypes.CHANGE_SUBSCRIPTION_SUCCESS,
  payload
})

export const changeSubscriptionFailure = (): ISsoActionTypes => ({
  type: SsoActionTypes.CHANGE_SUBSCRIPTION_FAILURE,
})
