import {
  IExternalToken,
  ExternalTokenActionTypes,
  IExternalTokenActionTypes,
  ICreateOrUpdateExternalTokenPayload,
} from './types'

export const createOrUpdateExternalToken = (payload: ICreateOrUpdateExternalTokenPayload): IExternalTokenActionTypes => ({
  type: ExternalTokenActionTypes.CREATE_OR_UPDATE_EXTERNAL_TOKEN,
  payload
})

export const createOrUpdateExternalTokenSuccess = (externalTokenData: IExternalToken): IExternalTokenActionTypes => ({
  type: ExternalTokenActionTypes.CREATE_OR_UPDATE_EXTERNAL_TOKEN_SUCCESS,
  externalTokenData
})

export const createOrUpdateExternalTokenFailure = (): IExternalTokenActionTypes => ({
  type: ExternalTokenActionTypes.CREATE_OR_UPDATE_EXTERNAL_TOKEN_FAILURE,
})
