import React from 'react'
import Loader from '@SiteComponents/Loader'

import './EditorButton.scss'

interface EditorButtonProps {
  readonly color: string
  readonly caption: string
  readonly disabled?: boolean
  readonly showSpinner?: boolean
  readonly type?: 'button' | 'submit' | 'reset' | undefined
  readonly icon?: JSX.Element
  onMouseEnter?(): void
  onMouseLeave?(): void
  onClick?(): void
}

const EditorButton: React.FC<EditorButtonProps> = props => {
  const onClick = () => {
    if (!props.onClick) { return }

    props.onClick()
  }

  return (
    <button
      type={props.type || 'button'}
      className={`EditorButton EditorButton-${props.color}`}
      onClick={onClick}
      disabled={props.disabled}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      {props.showSpinner ? (
        <Loader />
      ) : (
        <div>
          {props.caption}
          {props.icon}
        </div>
      )}
    </button>
  )
}

export default EditorButton
