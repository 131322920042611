import {
  ISetupTtsPayload,
  ProjectVersionSettingsTtsActionTypes,
  IFetchProjectVersionSettingsTtsSuccessResponsePayload
} from './types'

export const fetchProjectVersionSettingsTts = () => ({
  type: ProjectVersionSettingsTtsActionTypes.FETCH_PROJECT_VERSION_SETTINGS_TTS,
})

export const fetchProjectVersionSettingsTtsSuccess = (payload: IFetchProjectVersionSettingsTtsSuccessResponsePayload) => {
  return {
    type: ProjectVersionSettingsTtsActionTypes.FETCH_PROJECT_VERSION_SETTINGS_TTS_SUCCESS,
    payload
  }
}

export const fetchProjectVersionSettingsTtsFailure = () => ({
  type: ProjectVersionSettingsTtsActionTypes.FETCH_PROJECT_VERSION_SETTINGS_TTS_FAILURE,
})

export const setupTts = (payload: ISetupTtsPayload) => ({
  type: ProjectVersionSettingsTtsActionTypes.SETUP_TTS,
  payload
})

export const setupTtsFailure = () => ({
  type: ProjectVersionSettingsTtsActionTypes.SETUP_TTS_FAILURE,
})
