import React from 'react'
import capitalize from 'lodash/capitalize'
import DropdownV2 from '@EditorComponents/DropdownV2'

const GENDERS = [
  'Male',
  'Female'
]

interface GenderProps {
  readonly gender: string
  setGender(newGender: string): void
}

const Gender: React.FC<GenderProps> = props => {
  const { gender } = props

  const handleChangeGender = (newGender: string) => {
    props.setGender(newGender.toLowerCase())
  }

  return (
    <div className="VoiceSettings__value-wrapper">
      <span className="VoiceSettings__value">Gender</span>
      <DropdownV2
        items={GENDERS}
        currentItem={capitalize(gender)}
        onChange={handleChangeGender}
        noPortal
      />
    </div>
  )
}

export default Gender
