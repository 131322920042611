import React, { useState } from 'react'
import PayWithCardForm from '@SiteComponents/PayWithCardForm'
import { IUser } from '@Root/types'
import { Elements} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Link } from 'react-router-dom'

import './PaymentDetails.scss'

// TODO: firstName, lastName, amount...
// TODO: use omit instead...
interface PaymentDetailsProps extends Pick<IUser, 'company' | 'address' | 'zipcode' | 'country' | 'city'> {
  readonly company: string
  readonly firstName: string
  readonly lastName: string
  readonly amount: number
  readonly currency: string
  readonly clientSecret: string
  paymentSuccess(): void
  paymentFailure(message: string): void
}

const PaymentDetails: React.FC<PaymentDetailsProps> = props => {
  const [stripePromise] = useState(() => loadStripe(window.Checksub.stripePublishableKey))

  const {
    city,
    amount,
    address,
    zipcode,
    country,
    company,
    lastName,
    firstName,
    clientSecret,
    currency,
  } = props

  const formattedAmount = amount.toFixed(2)
  const redirectPath = '/checkouts/billing_address'

  return (
    <Elements stripe={stripePromise}>
      <div className="PaymentDetails">
        <div className="PaymentDetails__title">
          Payment Details
        </div>
        <div className="PaymentDetails__address"  translate="no">
          <div>
            {company}
          </div>
          <div className="PaymentDetails__address__name" translate="no">
            {firstName} {' '} {lastName}{' '}
            <Link to={redirectPath}>
              <span className="PaymentDetails__address__name__pen">✎</span>
            </Link>
          </div>

          {address} <br />
          {zipcode} {' '} {city} <br />
          {country}
        </div>

        <div className="PaymentDetails__subtitle">
          Enter Payment Details
        </div>

        <PayWithCardForm
          currency={currency}
          clientSecret={clientSecret}
          formattedAmount={formattedAmount}
          paymentSuccess={props.paymentSuccess}
          paymentFailure={props.paymentFailure}
        />
      </div>
    </Elements>
  )
}

export default PaymentDetails
