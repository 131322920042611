import { put, takeEvery } from 'redux-saga/effects'
import * as Eff from 'redux-saga/effects' // tslint:disable-line:no-duplicate-imports
import toastr from 'toastr'
import 'toastr/build/toastr.css'

import Logger from '@Utils/Logger'
import { ISuccessResponse, IAction } from '@Root/types'

import {
  fetchVocabulariesFailure,
  fetchVocabulariesSuccess,
  createVocabularyFailure,
  createVocabularySuccess,
  updateVocabularyFailure,
  updateVocabularySuccess,
  deleteVocabularyFailure,
  deleteVocabularySuccess,
} from './actions'

import {
  VocabularyActionTypes,
  IFetchVocabulariesSuccessResponseBody,
  ICreateVocabularyPayload,
  IUpdateVocabularyPayload,
  IDeleteVocabularyPayload,
} from './types'

import * as API from './api'

const call: any = Eff.call

function* fetchVocabulariesSaga() {
  try {
    const response: ISuccessResponse<IFetchVocabulariesSuccessResponseBody> = yield call(API.fetchVocabularies)
    const payloadResponse = response.data
    yield put(fetchVocabulariesSuccess(payloadResponse))
  } catch (e) {
    Logger.error(e, 'Vocabulary lists could not be loaded')
    yield put(fetchVocabulariesFailure())
  }
}

function* createVocabularySaga(action: IAction<ICreateVocabularyPayload>) {
  try {
    const { payload } = action
    yield call(API.createVocabulary, payload)
    yield put(createVocabularySuccess())
    toastr.success('', 'Vocabulary list created')
  } catch (e) {
    Logger.error(e, 'Vocabulary list could not be created')
    yield put(createVocabularyFailure())
  }
}

function* updateVocabularySaga(action: IAction<IUpdateVocabularyPayload>) {
  try {
    const { payload } = action
    yield call(API.updateVocabulary, payload)
    yield put(updateVocabularySuccess())
    toastr.success('', 'Changes saved')
  } catch (e) {
    Logger.error(e, 'Changed could not be saved')
    yield put(updateVocabularyFailure())
  }
}

function* deleteVocabularySaga(action: IAction<IDeleteVocabularyPayload>) {
  try {
    const { payload } = action
    yield call(API.deleteVocabulary, payload)
    yield put(deleteVocabularySuccess())
    toastr.success('', 'Vocabulary list deleted')
  } catch (e) {
    Logger.error(e, 'Vocabulary list could not be deleted')
    yield put(deleteVocabularyFailure())
  }
}

function* vocabularySagas() {
  yield takeEvery(VocabularyActionTypes.FETCH_VOCABULARIES, fetchVocabulariesSaga)
  yield takeEvery(VocabularyActionTypes.CREATE_VOCABULARY, createVocabularySaga)
  yield takeEvery(VocabularyActionTypes.CREATE_VOCABULARY_SUCCESS, fetchVocabulariesSaga)
  yield takeEvery(VocabularyActionTypes.UPDATE_VOCABULARY, updateVocabularySaga)
  yield takeEvery(VocabularyActionTypes.UPDATE_VOCABULARY_SUCCESS, fetchVocabulariesSaga)
  yield takeEvery(VocabularyActionTypes.DELETE_VOCABULARY, deleteVocabularySaga)
  yield takeEvery(VocabularyActionTypes.DELETE_VOCABULARY_SUCCESS, fetchVocabulariesSaga)
}

export default vocabularySagas
