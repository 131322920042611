import React, { useEffect, useRef } from 'react'
import uniq from 'lodash/uniq'
import Multiselect from '@checksub_team/react-widgets/lib/Multiselect'
import DropdownList from '@checksub_team/react-widgets/lib/DropdownList'
import ProjectType from './ProjectType'
import classnames from 'classnames'

import './MainSettings.scss'

interface MainSettingsProps {
  readonly errors: any
  readonly register: any
  readonly projectVersionLanguage: string
  readonly subtitleLanguages: string[]
  readonly isTranslatable: boolean
  readonly initialProjectVersionLanguage: string
  readonly languagesForVideos: string[]
  readonly translationLanguages: string[]
  readonly disabledSubtitleLanguages: string[]
  readonly hasLoaded: boolean
  readonly projectType: string
  readonly projectTypeOptions: string[]
  changeSubtitleLanguages(newSubtitleLanguages: string[]): void
  changeProjectVersionLanguage(newProjectVersionLanguage: string): void
  changeProjectType(newProjectType: string): void
}

const MainSettings: React.FC<MainSettingsProps> = props => {
  const {
    errors,
    register,
    projectVersionLanguage,
    changeSubtitleLanguages,
    subtitleLanguages,
    isTranslatable,
    languagesForVideos,
    changeProjectVersionLanguage,
    translationLanguages,
    initialProjectVersionLanguage,
    disabledSubtitleLanguages,
    hasLoaded,
    projectType,
    changeProjectType,
    projectTypeOptions,
  } = props

  const subtitleLanguagesRef = useRef(subtitleLanguages)

  const TOP_LANGUAGES = [
    'Arabic',
    'Chinese, Mandarin (Simplified, China)',
    'English',
    'French',
    'French (Canada)',
    'German',
    'Hindi',
    'Italian',
    'Japanese',
    'Portuguese',
    'Russian',
    'Spanish'
  ]

  let originalLanguages
  let subtitlingLanguages
  let topLanguagesForSubtitling = TOP_LANGUAGES

  const setToManual = (languages: string[]) => languages.map((language) => {
    if (language.endsWith('(manual)')) { return language }

    return language + ' (manual)'
  })

  if (isTranslatable) {
    originalLanguages = languagesForVideos
    subtitlingLanguages = translationLanguages
  } else {
    originalLanguages = languagesForVideos
    subtitlingLanguages = setToManual(translationLanguages)
    topLanguagesForSubtitling = setToManual(topLanguagesForSubtitling)
  }

  const originalLanguagesWithTopLanguagesFirst = uniq(TOP_LANGUAGES.concat(originalLanguages))
  const subtitlingLanguagesWithTopLanguagesFirst = uniq(topLanguagesForSubtitling.concat(subtitlingLanguages))

  const originalLanguageGrouping = (language: string) => TOP_LANGUAGES.includes(language)
  const subtitlingLanguageGrouping = (language: string) => topLanguagesForSubtitling.includes(language)

  const languageGroupHeading = ({ item }: {item: boolean}) => (
    item ? <span>Top Languages</span> : <span>All Languages</span>
  )

  const shouldDisableSelectingProjectVersionLanguage = !!initialProjectVersionLanguage

  const fillInPlaceholderProjectVersionLanguage = () => {
    const searchProjectVersionLanguage = document.querySelectorAll('.MainSettings__formfield-projectVersionLanguage .rw-input-reset')[0] as HTMLInputElement

    if (searchProjectVersionLanguage) {
      searchProjectVersionLanguage.placeholder = 'Set language...'
    }
  }

  useEffect(() => {
    // Add button behaviour, i.e. opening and closing dropdown when clicking on the input field
    const inputContainerSubtitleLanguages = document.querySelectorAll('.MainSettings__formfield-subtitleLanguages .rw-widget-input.rw-widget-picker.rw-widget-container')[0] as HTMLElement
    inputContainerSubtitleLanguages.addEventListener('click', onClick)
  }, [])

  const onClick = () => {
    if (subtitleLanguagesRef.current.length > 0) { return }

    const closedDropdownSubtitleLanguages = document.querySelectorAll('.MainSettings__formfield-subtitleLanguages .rw-popup-transition-exited')[0] as HTMLElement
    const dropdownClosed = !!closedDropdownSubtitleLanguages
    if (dropdownClosed) {
      closedDropdownSubtitleLanguages.classList.remove('rw-popup-transition-exited')
    } else {
      const dropdownSubtitleLanguages = document.querySelectorAll('.MainSettings__formfield-subtitleLanguages .rw-popup-container')[0] as HTMLElement
      if (!!dropdownSubtitleLanguages) {
        dropdownSubtitleLanguages.classList.add('rw-popup-transition-exited')
      }
    }
  }

  useEffect(() => {
    const inputFieldProjectVersionLanguage = document.querySelectorAll('.MainSettings__formfield-projectVersionLanguage .rw-widget-input.rw-widget-picker.rw-widget-container')[0] as HTMLElement
    if (!!projectVersionLanguage && !initialProjectVersionLanguage) {
      inputFieldProjectVersionLanguage.style.backgroundColor = '#7318FD'
      inputFieldProjectVersionLanguage.style.border = 'none'
    }
  }, [projectVersionLanguage])

  useEffect(() => {
    const inputFieldSubtitleLanguages = document.querySelectorAll('.MainSettings__formfield-subtitleLanguages .rw-multiselect .rw-input-reset')[0] as HTMLInputElement
    const inputContainerSubtitleLanguages = document.querySelectorAll('.MainSettings__formfield-subtitleLanguages .rw-widget-input.rw-widget-picker.rw-widget-container')[0] as HTMLElement
    const patch = document.querySelectorAll('.MainSettings__formfield-subtitleLanguages-hideBorderPatch')[0] as HTMLElement
    if (subtitleLanguages.length > 0) {
      inputFieldSubtitleLanguages.style.width = '400px'
      inputFieldSubtitleLanguages.style.paddingLeft = '10px'
      inputFieldSubtitleLanguages.style.paddingTop = '5px'
      inputFieldSubtitleLanguages.style.height = '20px'
      inputFieldSubtitleLanguages.placeholder = '+ Add language'
      inputContainerSubtitleLanguages.style.backgroundColor = '#2D2F36'
      inputContainerSubtitleLanguages.style.border = 'none'
      inputContainerSubtitleLanguages.style.paddingLeft = '0px'
      patch.style.visibility = 'visible'
    } else {
      inputFieldSubtitleLanguages.style.width = '220px'
      inputFieldSubtitleLanguages.style.paddingLeft = '0'
      inputFieldSubtitleLanguages.style.paddingTop = '0'
      inputFieldSubtitleLanguages.style.height = '17px'
      inputContainerSubtitleLanguages.style.backgroundColor = '#37373C'
      inputContainerSubtitleLanguages.style.border = '1px solid #8A8F98'
      inputContainerSubtitleLanguages.style.padding = '10px 12px'
      patch.style.visibility = 'hidden'
    }

    subtitleLanguagesRef.current = subtitleLanguages

  }, [subtitleLanguages])

  const hasNoDropdown = !!initialProjectVersionLanguage || !hasLoaded

  const onSearch = () => {
    setTimeout(() => {
      const emptyList = document.getElementsByClassName('rw-list-empty')

      if (emptyList && emptyList.length > 0) {
        emptyList[0].innerHTML = 'Not available'
      }
    }, 0)
  }

  const renderProjectVersionLanguageDropDown = () => {
    return(
      <div className={classnames('ProjectVersionSettingsForm__formfield MainSettings__formfield-projectVersionLanguage', {'noDropdown': hasNoDropdown})} onClick={fillInPlaceholderProjectVersionLanguage}>
        <label className="ProjectVersionSettingsForm__label" htmlFor="projectVersionLanguage">
          Original language of the video(s):
        </label>

        <DropdownList
          data={originalLanguagesWithTopLanguagesFirst}
          id="projectVersionLanguage"
          name="projectVersionLanguage"
          value={projectVersionLanguage}
          onChange={changeProjectVersionLanguage}
          customref={register({ required: true })}
          disabled={shouldDisableSelectingProjectVersionLanguage}
          filter="startsWith"
          groupComponent={languageGroupHeading}
          groupBy={originalLanguageGrouping}
          placeholder="Select the video language..."
          onSearch={onSearch}
        />
        {errors.projectVersionLanguage && (
          <span className="errorMessage">This field is required</span>
        )}
      </div>
    )
  }

  const renderSubtitleLanguagesDropDown = () => {
    return(
      <div className={classnames('ProjectVersionSettingsForm__formfield MainSettings__formfield-subtitleLanguages', {'MainSettings__formfield-subtitleLanguages-filledOut': subtitleLanguages.length > 0})}>
        <label className="ProjectVersionSettingsForm__label MainSettings__formfield-subtitleLanguages-label" htmlFor="subtitleLanguages">
          Desired languages:
        </label>
        <Multiselect
          data={subtitlingLanguagesWithTopLanguagesFirst}
          id="subtitleLanguages"
          name="subtitleLanguages"
          value={subtitleLanguages}
          onChange={changeSubtitleLanguages}
          disabled={hasLoaded ? disabledSubtitleLanguages : true}
          customref={register({ required: true })}
          groupComponent={languageGroupHeading}
          groupBy={subtitlingLanguageGrouping}
          placeholder="Choose subtitle languages..."
          onSearch={onSearch}
        />
        {errors.subtitleLanguages && (
          <span className="errorMessage">This field is required</span>
        )}
      <span className="MainSettings__formfield-subtitleLanguages-hideBorderPatch"/>
      </div>
    )
  }

  const renderProjectType = () => {
    return(
      <ProjectType
        projectType={projectType}
        changeProjectType={changeProjectType}
        projectTypeOptions={projectTypeOptions}
      />
    )
  }

  return (
    <React.Fragment>
      {renderProjectVersionLanguageDropDown()}
      {renderSubtitleLanguagesDropDown()}
      {renderProjectType()}
    </React.Fragment>
  )
}

export default MainSettings
