import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { IApplicationState } from '../../rootReducer'

import Header from '@SiteContainers/Header'
import Arrow from '@Images/project_type_selection_arrow.svg'

import './ProjectTypeSelection.scss'

interface ProjectTypeSelectionProps {
  readonly isNewUser: boolean
}

interface ProjectTypeSelectionState {
}

class ProjectTypeSelection extends React.Component<ProjectTypeSelectionProps, ProjectTypeSelectionState> {
  forwardToUpload = () => {
    window.location.href = '/upload'
  }

  forwardToSetupTts = () => {
    window.location.href = '/setup_tts'
  }

  subtitleDubbing = () => {
    return (
      <div
        className="ProjectTypeSelection__option  ProjectTypeSelection__option-subtitle-dubbing"
        onClick={this.forwardToUpload}
      >
        <div className="ProjectTypeSelection__option-captions">
          <div className="ProjectTypeSelection__option-caption">
            Generate subtitles and/or dubbings
          </div>
          <div className="ProjectTypeSelection__option-subcaption">
            Import a video or an audio to add subtitles to it, dub it or translate it.
          </div>
        </div>
        <Arrow />
      </div>
    )
  }

  voiceOverFromText = () => {
    return (
      <div
        className="ProjectTypeSelection__option  ProjectTypeSelection__option-voice"
        onClick={this.forwardToSetupTts}
      >
        <div className="ProjectTypeSelection__option-captions">
          <div className="ProjectTypeSelection__option-caption">
            Create voices from a text
          </div>
          <div className="ProjectTypeSelection__option-subcaption">
            Import a text or type it and select voices to generate an audio.
          </div>
        </div>
        <Arrow />
      </div>
    )
  }

  render() {
    const shouldNotLinkToDashboard = this.props.isNewUser

    return (
      <div className="ProjectTypeSelection">
        <Header
          shouldNotLinkToDashboard={shouldNotLinkToDashboard}
        />
        <div className="ProjectTypeSelection__header">What do you want to do?</div>
        {this.subtitleDubbing()}
        {this.voiceOverFromText()}
      </div>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { user } = state

  return {
    isNewUser: user.user.attributes.isNewUser,
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectTypeSelection)
