import React from 'react'
import { connect } from 'react-redux'
import { Action, Dispatch, bindActionCreators } from 'redux'
import { IApplicationState } from '@Site/rootReducer'
import 'toastr/build/toastr.css'
import Loader from '@SiteComponents/Loader'
import EmptyDashboard from '@Images/empty_dashboard.jpg'
import Asset from './Asset'
import Pagination from '@SiteComponents/Pagination'

import './AssetsTable.scss'

import {
  increasePageCount,
  decreasePageCount,
  getAffectedProjectVersions,
  deleteElement,
} from './actions'

import {
  IElement,
  IGetAffectedProjectVersionsPayload,
  IDeleteElementPayload,
} from './types'

const ELEMENTS_PER_PAGE = 10

interface AssetsTableState {
}

interface AssetsTableProps {
  readonly elements: IElement[]
  readonly totalCount: number
  readonly totalPages: number
  readonly currentPage: number
  readonly loading: boolean
  increasePageCount(): void
  decreasePageCount(): void
  getAffectedProjectVersions(payload: IGetAffectedProjectVersionsPayload): void
  deleteElement(payload: IDeleteElementPayload): void
}

class AssetsTable extends React.Component<AssetsTableProps, AssetsTableState> {

  renderLoader() {
    const { loading } = this.props

    if (!loading) { return }

    return(
      <span className="AssetsList__loader loader" />
    )
  }

  renderAssets() {
    const {
      elements,
      totalCount,
      totalPages,
      currentPage,
    } = this.props

    return (
      <div className="Assets">
        <div className="Assets__pagination">
          <Pagination
            totalCount={totalCount}
            totalPages={totalPages}
            currentPage={currentPage}
            itemsPerPage={ELEMENTS_PER_PAGE}
            increasePageCount={this.props.increasePageCount}
            decreasePageCount={this.props.decreasePageCount}
          />
        </div>
        <table className="Assets__table">
          <thead className="Assets__table-header">
            <tr>
              <th className="Assets__table-cell" />
              <th className="Assets__table-cell Assets__header-title">Title</th>
              <th className="Assets__table-cell">Length</th>
              <th className="Assets__table-cell">Uploaded</th>
              <th className="Assets__table-cell">Type</th>
              <th className="Assets__table-cell">Size</th>
              <th className="Assets__table-cell" />
            </tr>
          </thead>
          <tbody className="Assets__table-body">
            {elements.map((element, index) => {
              return (
                <Asset
                  key={index}
                  element={element}
                  getAffectedProjectVersions={this.props.getAffectedProjectVersions}
                  deleteElement={this.props.deleteElement}
                />
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }

  renderBlankPage() {
    return(
      <div className="Assets-blank-page">
        <p className="Assets-blank-page__title">
          👋 Blank Page Syndrome?
          <br />
          Start by uploading your first video
          or connecting a new channel.
        </p>

        <img
          src={EmptyDashboard}
          className="Assets-blank-page__image"
        />
      </div>
    )
  }

  render() {
    const { elements, loading } = this.props
    const shouldRenderAssets = !!elements.length
    const statusOfAssets = loading ? 'loading' : 'loaded'
    const shouldRenderLoader = loading
    const shouldRenderBlankPage = !elements.length

    return (
      <div className="AssetsList">
        {this.renderLoader()}

        <div className={`AssetsList-${statusOfAssets}`}>
          {shouldRenderLoader ? (
            <Loader color="dark" />
          ) : (
            <React.Fragment>
              {shouldRenderAssets && this.renderAssets()}
              {shouldRenderBlankPage && this.renderBlankPage()}
            </React.Fragment>
          )}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { assetsTable} = state

  return {
    elements: assetsTable.elements,
    loading: assetsTable.loading,
    totalCount: assetsTable.totalCount,
    totalPages: assetsTable.totalPages,
    currentPage: assetsTable.currentPage,
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    increasePageCount,
    decreasePageCount,
    getAffectedProjectVersions,
    deleteElement,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetsTable)
