export const ChannelsActionTypes = {
  GET_EXTERNAL_VIDEOS: '@@channelsTable/GET_EXTERNAL_VIDEOS',
  GET_EXTERNAL_VIDEOS_SUCCESS: '@@channelsTable/GET_EXTERNAL_VIDEOS_SUCCESS',
  GET_EXTERNAL_VIDEOS_FAILURE: '@@channelsTable/GET_EXTERNAL_VIDEOS_FAILURE',
  INCREASE_PAGE_COUNT: '@@channelsTable/INCREASE_PAGE_COUNT',
  DECREASE_PAGE_COUNT: '@@channelsTable/DECREASE_PAGE_COUNT',
  GET_EXTERNAL_CHANNELS: '@@channelsManage/GET_EXTERNAL_CHANNELS',
  GET_EXTERNAL_CHANNELS_SUCCESS: '@@channelsManage/GET_EXTERNAL_CHANNELS_SUCCESS',
  GET_EXTERNAL_CHANNELS_FAILURE: '@@channelsManage/GET_EXTERNAL_CHANNELS_FAILURE',
  OPEN_ACTIONS_DROPDOWN: '@@channelsManage/OPEN_ACTIONS_DROPDOWN',
  CLOSE_DROPDOWNS: '@@channelsManage/CLOSE_DROPDOWNS',
  REMOVE_EXTERNAL_CHANNEL: '@@channelsManage/REMOVE_EXTERNAL_CHANNEL'
}

export interface IChannelsTableState {
  readonly externalChannels: IExternalChannel[]
  readonly externalVideos: IExternalVideo[]
  readonly loadingVideos: boolean
  readonly loadingChannels: boolean
  readonly totalCount: number
  readonly totalPages: number
  readonly currentPage: number
  readonly videosPerPage: number
  readonly actionsDropdownOpen: boolean
  readonly hasLoadedVideosOnce: boolean
}

export interface IExternalChannel {
  readonly id: string
  readonly service: string
  readonly name: string
  readonly thumbnailUrl: string
  readonly valid: boolean
  readonly verified: boolean
}

export interface IExternalVideo {
  readonly externalId: string
  readonly title: string
  readonly length: number
  readonly createdAt: string
  readonly thumbnailUrl: string
  readonly service: string
  readonly externalChannelId: string
  readonly externalChannelName: string
  readonly downloadUrl: string
  readonly canBeDownloaded: boolean
  readonly isReady: boolean
}

export interface IGetExternalVideosAction {
  readonly type: string
}

export interface IGetExternalVideosSuccessAction {
  readonly type: string
  readonly payload: IGetExternalVideosSuccessPayload
}

export interface IGetExternalVideosSuccessPayload {
  readonly videos: IExternalVideo[]
  readonly unprocessedChannelsIds: number[]
}

export interface IGetExternalChannelsAction {
  readonly type: string
}

export interface IGetExternalChannelsSuccessAction {
  readonly type: string
  readonly payload: IGetExternalChannelsSuccessPayload
}

export interface IGetExternalChannelsSuccessPayload {
  readonly externalChannels: IExternalChannel[]
}

export interface IRemoveExternalChannelPayload {
  readonly externalChannelId: string
}

export type IChannelsActionTypes = IGetExternalVideosAction | IGetExternalVideosSuccessAction | IGetExternalChannelsAction | IGetExternalChannelsSuccessAction
