import { IAction } from '@Root/types'

import {
  IProcessingState,
  TProcessingActionTypes,
  ProcessingActionTypes,
  IFetchProcessingInfoSuccessResponseBody,
  IFetchCurrentProcessingStepSuccessResponseBody,
} from './types'

export const initialState: IProcessingState = {
  loading: false,
  attributes: {
    allProcessingSteps: [],
    currentProcessingStep: '',
    projectVersionDuration: 0,
    projectTitle: '',
    projectId: '',
    subtitleId: -1,
    hasVoice: false,
  }
}

export const processingReducer = (
  state: IProcessingState = initialState,
  action: TProcessingActionTypes
): IProcessingState => {
  switch (action.type) {
    case ProcessingActionTypes.FETCH_PROCESSING_INFO: return fetchProcessingInfo(state)
    case ProcessingActionTypes.FETCH_PROCESSING_INFO_SUCCESS: return fetchProcessingInfoSuccess(state, action)
    case ProcessingActionTypes.FETCH_PROCESSING_INFO_SUCCESS_FOR_DOWNLOADING: return fetchProcessingInfoSuccessForDownloading(state)
    case ProcessingActionTypes.FETCH_CURRENT_PROCESSING_STEP_SUCCESS: return fetchCurrentProcessingStepSuccess(state, action)

    default: return state
  }
}

const fetchProcessingInfo = (state: IProcessingState): IProcessingState => {
  return { ...state, loading: true }
}

function isIFetchProcessingInfoSuccessAction(arg: any): arg is IAction<IFetchProcessingInfoSuccessResponseBody> {
  return arg.payload && arg.payload.data !== null && arg.payload.data.atributes !== null
}

const fetchProcessingInfoSuccess = (state: IProcessingState, action: TProcessingActionTypes): IProcessingState => {
  let processingAttributes = state.attributes

  if (isIFetchProcessingInfoSuccessAction(action)) {
    processingAttributes = action.payload.data.attributes
  }

  return { ...state, loading: false, attributes: processingAttributes }
}

const fetchProcessingInfoSuccessForDownloading = (state: IProcessingState): IProcessingState => {
  const processingAttributes = { ...state.attributes, currentProcessingStep: 'downloading' }

  return { ...state, loading: false, attributes: processingAttributes }
}

function isIFetchCurrentProcessingStepSuccessAction(arg: any): arg is IAction<IFetchCurrentProcessingStepSuccessResponseBody> {
  return arg.payload && arg.payload.data !== null
}

const fetchCurrentProcessingStepSuccess = (state: IProcessingState, action: TProcessingActionTypes): IProcessingState => {
  let currentProcessingStep = state.attributes.currentProcessingStep

  if (isIFetchCurrentProcessingStepSuccessAction(action)) {
    currentProcessingStep = action.payload.data.currentProcessingStep
  }

  return { ...state, attributes: { ...state.attributes, currentProcessingStep } }
}
