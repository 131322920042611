export function getWidth() {
  return Math.max(
    // document.body.scrollWidth, // does not work with overflow: hidden for body
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  )
}

export function getHeight() {
  return Math.max(
    // document.body.scrollHeight, // does not work with overflow: hidden for body
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.documentElement.clientHeight
  )
}
