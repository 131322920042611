import React, { useState, useRef } from 'react'
import AddVideosImage from '@Images/add_videos_true_scale.svg'

interface ScriptImportProps {
  setScriptText(newScriptText: string): void
}

const MAX_FILE_SIZE = 2097152 // Bytes = 2MB

const ScriptImport: React.FC<ScriptImportProps> = props => {
  const [dragEnterCounter, setDragEnterCounter] = useState(0)

  const fileRef = useRef<HTMLInputElement>(null)

  const changeScriptText = (newText: string) => {
    props.setScriptText(newText.replace(/\s+/g,' ').trim())
  }

  const handleScriptChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    changeScriptText(e.target.value)
  }

  const handleImportClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (!files) { return }

    handleFileImport(Array.from(files))
  }

  const openFileManager = () => {
    fileRef.current?.click()
  }

  const onFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.currentTarget.classList.remove('ProjectVersionSettingsFormTts__upload-dragOver')

    const files = e.dataTransfer.files
    if (!files) { return }

    handleFileImport(Array.from(files))
  }

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    if (dragEnterCounter === 0) {
      e.currentTarget.classList.add('ProjectVersionSettingsFormTts__upload-dragOver')
    }
    setDragEnterCounter(dragEnterCounter + 1)
  }

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    const nextDragEnterCounter = dragEnterCounter - 1
    setDragEnterCounter(nextDragEnterCounter)
    if (nextDragEnterCounter === 0) {
      e.currentTarget.classList.remove('ProjectVersionSettingsFormTts__upload-dragOver')
    }
  }

  const handleFileImport = (files: File[]) => {
    if (files.length === 0) { return }

    if (files[0].size > MAX_FILE_SIZE) {
      toastr.error('Your file is too large (> 2MB).', 'Error')
      return
    }

    const fileReader = new FileReader()
    fileReader.onload = () => {
      const newScriptText = fileReader.result?.toString()

      if (!newScriptText) { return }

      const textArea = document.getElementsByClassName('ProjectVersionSettingsFormTts__script').item(0) as HTMLTextAreaElement
      textArea.value = newScriptText
      changeScriptText(newScriptText)
    }

    fileReader.readAsText(files[0])
  }

  const renderUploadArea = () => {
    return (
      <div
        className="ProjectVersionSettingsFormTts__upload"
        onClick={openFileManager}
        onDrop={onFileDrop}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
      >
        <div
          className="ProjectVersionSettingsFormTts__image"
        >
          <AddVideosImage />
        </div>

        <div
          className="ProjectVersionSettingsFormTts__text"
        >
          Click or Drag-and-Drop
        </div>
        to import your file

        <input
          ref={fileRef}
          type="file"
          className="ProjectVersionSettingsFormTts__file"
          onChange={handleImportClick}
        />
      </div>
    )
  }

  const renderTextAreaForScriptImport = () => {
    return (
      <div className="ProjectVersionSettingsFormTts__script-wrapper">
        <textarea
          onChange={handleScriptChange}
          placeholder="Or paste your script here..."
          className="ProjectVersionSettingsFormTts__script"
        />
      </div>
    )
  }

  return (
    <div className="ProjectVersionSettingsFormTts__formfield">
      <label className="ProjectVersionSettingsFormTts__label">
        Import your script below:
      </label>
      {renderUploadArea()}
      {renderTextAreaForScriptImport()}
    </div>
  )
}

export default ScriptImport
