import React  from 'react'
import ButtonNewV2 from '@SiteComponents/ButtonNewV2'
import { Circle } from 'rc-progress'

import './UploadingProgress.scss'

interface UploadingProgressProps {
  readonly alreadyUploaded: number
  readonly numberOfFilesToUpload: number
  readonly uploadingFileName: string
  readonly progress: number
  readonly strikeColor: string
  cancelUploading(): void
}

const UploadingProgress: React.FC<UploadingProgressProps> = props => {
  return (
    <div className="UploadingProgress">
      <div className="UploadingProgress__file-name">
        {props.uploadingFileName}
      </div>
      {props.numberOfFilesToUpload > 1 && (
        <div className="UploadingProgress__counter">
          Uploading {props.alreadyUploaded + 1} of {props.numberOfFilesToUpload} videos
        </div>
      )}
      <div className="UploadingProgress__progress-wrapper">
        <Circle percent={props.progress} strokeWidth={4} strokeColor={props.strikeColor} />
        <div className="UploadingProgress__progress">{Math.round(props.progress)}%</div>
      </div>
      <ButtonNewV2
        styleType="brand-primary"
        size="large"
        caption="Cancel"
        onClick={props.cancelUploading}
      />
    </div>
  )
}

export default UploadingProgress
