import axios, { AxiosRequestConfig } from 'axios'
import convertToSnakeCase from '@Utils/ConvertToSnakeCase'

import {
  PayloadData
} from './types'

export function submitTopupRequest(data: PayloadData) {
  const config: AxiosRequestConfig = {
    headers: {
      'X-CSRF-Token': window.Checksub.token
    },
    responseType: 'json'
  }

  return axios.post('/api/v1/credits/buy', convertToSnakeCase(data), config)
}
