import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { bindActionCreators, Dispatch, Action } from 'redux'
import Header from '@SiteContainers/Header'
import { IApplicationState } from '@Site/rootReducer'
import { ICredit } from '@SitePages/CreditsTopup/types'
import PaymentDetails from '@SiteComponents/PaymentDetails'
import PaymentOverview from '@SiteComponents/PaymentOverview'
import { IBillingAddressAttributes } from '@SitePages/CheckoutsBillingAddress/types'
import { IProjectVersionSettings } from '@SitePages/ProjectVersionSettings/types'

import {
  paymentSuccess,
  paymentFailure,
  fetchCreditPrice,
} from './actions'

import {
  isCreditPresent,
  isThereAnythingInCart,
} from '@SitePages/CheckoutsBillingAddress/selectors'

import {
  IPaymentSuccessPayload
} from './types'

import './CheckoutsPaymentDetails.scss'

interface CheckoutsPaymentDetailsProps {
  readonly credit: ICredit
  readonly creditPresent: boolean
  readonly projectVersionSettings: IProjectVersionSettings
  readonly isThereAnythingToPayFor: boolean
  readonly billingAddress: IBillingAddressAttributes
  paymentSuccess(payload: IPaymentSuccessPayload): void
  paymentFailure(message: string): void
  fetchCreditPrice(creditId: number): void
}

interface CheckoutsPaymentDetailsState {
  readonly itemToPayFor: string
}

class CheckoutsPaymentDetails extends React.Component<CheckoutsPaymentDetailsProps, CheckoutsPaymentDetailsState> {
  constructor(props: CheckoutsPaymentDetailsProps) {
    super(props)

    this.state = {
      itemToPayFor: '',
    }
  }

  componentDidMount() {
    const { creditPresent } = this.props

    if (creditPresent) {
      const { credit } = this.props
      this.props.fetchCreditPrice(parseInt(credit.id, 10))
      this.setState({ itemToPayFor: 'credit' })
      return
    }
  }

  paymentSuccess = () => {
    const { creditPresent } = this.props

    let paidItemId = -1
    let amount = 0
    let vat = 0
    const currency = 'EUR'

    if (creditPresent) {
      const { credit } = this.props
      paidItemId = parseInt(credit.id, 10)
      amount = credit.attributes.amount
      vat = credit.attributes.vat
    }

    const payload = {
      paidItem: this.state.itemToPayFor,
      paidItemId,
      amount,
      vat,
      currency,
    }

    this.props.paymentSuccess(payload)
  }

  renderCreditOverview = () => {
    const { credit } = this.props

    const {
      amount,
      shouldApplyVat,
      availableTimeInHours,
    } = credit.attributes

    const description = `${availableTimeInHours} Essential hour(s)`

    return(
      <PaymentOverview
        amount={amount}
        description={description}
        title={'Credits'}
        shouldApplyVat={shouldApplyVat}
        currency="€"
      />
    )
  }

  render() {
    const {
      credit,
      billingAddress,
      creditPresent,
      isThereAnythingToPayFor,
    } = this.props

    const {
      company,
      name,
      last_name,
      address,
      city,
      country,
      zipcode,
    } = billingAddress

    // TODO: move to selectors
    let amount = 0
    let clientSecret = ''

    if (creditPresent) {
      amount = credit.attributes.amount
      clientSecret = credit.attributes.paymentIntentClientSecret
    }

    return (
      <React.Fragment>
        {isThereAnythingToPayFor ? (
          <div className="CheckoutsPaymentDetails">
            <Header />

            <div className="CheckoutsPaymentDetails_body">
              {creditPresent && this.renderCreditOverview()}

              <PaymentDetails
                company={company}
                firstName={name}
                lastName={last_name}
                address={address}
                zipcode={zipcode}
                city={city}
                country={country}
                amount={amount}
                currency="€"
                clientSecret={clientSecret}
                paymentSuccess={this.paymentSuccess}
                paymentFailure={this.props.paymentFailure}
              />
            </div>
          </div>
        ) : (
          <Redirect to="/" />
        )}
      </React.Fragment>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  // TODO: refactor it later...
  const { credit } = state.creditsTopup
  const { projectVersionSettings } = state.projectVersionSettings

  return {
    credit,
    projectVersionSettings,
    creditPresent: isCreditPresent(state),
    isThereAnythingToPayFor: isThereAnythingInCart(state),
    billingAddress: state.checkoutsBillingAddress.billingAddress.attributes,
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    paymentSuccess,
    paymentFailure,
    fetchCreditPrice,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutsPaymentDetails)
