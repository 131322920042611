import React  from 'react'
import map from 'lodash/map'
import SsoMember from './SsoMember'

import {
  ISsoUser,
  ISsoSubscription
} from '@SiteModules/Sso/types'

import './SsoMembers.scss'

interface SsoMembersProps {
  readonly members: ISsoUser[]
  readonly ssoSubscriptions: ISsoSubscription[]
  deleteMember(userId: number): void
  changeRole(userId: number, isAdmin: boolean): void
  changeSubscription(userId: number, subscriptionId: number): void
}

const SsoMembers: React.FC<SsoMembersProps> = props => {
  const {
    members,
    deleteMember,
    changeRole,
    changeSubscription,
    ssoSubscriptions,
  } = props

  return (
    <table className="SsoMembers">
      <tbody>
        {map(members, (member, index) => {
          return (
            <SsoMember
              key={index}
              member={member}
              ssoSubscriptions={ssoSubscriptions}
              deleteMember={deleteMember}
              changeRole={changeRole}
              changeSubscription={changeSubscription}
            />
          )}
        )}
      </tbody>
    </table>
    )
  }

export default SsoMembers
