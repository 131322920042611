import React, { useState } from 'react'
import map from 'lodash/map'

import Rainbow from '@Images/rainbow.svg'
import ButtonNew from '@SiteComponents/ButtonNew'

import {
  ITheme,
} from '@SiteContainers/Themes/types'

import './ThemesForm.scss'

interface ThemesFormProps {
  readonly themes: ITheme[]
  readonly isReadyForTheme: boolean
  readonly isRenderedInEditor: boolean
  onSubmit(theme: string | undefined): void
}

const ThemesForm: React.FC<ThemesFormProps> = props => {
  const [currentTheme, setCurrentTheme] = useState<string | undefined>(undefined)
  const [hiddenImageIndex, setHiddenImageIndex] = useState<number | undefined>(undefined)

  const {
    themes,
    isReadyForTheme,
    isRenderedInEditor,
    onSubmit,
  } = props

  const noThemeSelected = !currentTheme

  const onClick = () => {
    onSubmit(currentTheme?.toLowerCase())
  }

  const renderVideoThemeList = () => {
    return (
      <ul
        className={`Themes__list${isRenderedInEditor ? '-editor' : ''}`}
      >
        {map(themes, (theme, index) => {
          const { name, sampleUrl, sampleImageUrl } = theme
          const isSelected = name === currentTheme
          const videoId = `theme-preview-${index}`

          const handleSelectTheme = () => {
            setCurrentTheme(name)
          }

          const onMouseOver = () => {
            setHiddenImageIndex(index)
            const video = document.getElementById(videoId) as HTMLVideoElement
            video.play()
          }

          const onMouseOut = () => {
            setHiddenImageIndex(undefined)
            const video = document.getElementById(videoId) as HTMLVideoElement
            video.pause()
            video.currentTime = 0
          }

          const shouldShowImage = hiddenImageIndex !== index

          return (
            <li
              className="Themes__theme"
              onClick={handleSelectTheme}
              key={index}
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
            >
              <video
                id={videoId}
                className={`Themes__theme-${isSelected ? 'selected' : 'not-selected'}`}
                loop
              >
                <source src={sampleUrl} type="video/mp4" />
              </video>
              {shouldShowImage && (
                <img
                  src={sampleImageUrl}
                  className={`Themes__theme-image-${isSelected ? 'selected' : 'not-selected'}`}
                />
              )}
              <div
                className={`Themes__theme-title${isRenderedInEditor ? '-editor' : ''}`}
              >
                {name}
              </div>
            </li>
          )
        })}
      </ul>
    )
  }

  return (
    <React.Fragment>
      <div className="Themes__header">
        <div className="Themes__icon-wrapper">
          <div className="Themes__icon">
            <Rainbow />
          </div>
        </div>
        <h2 className="Themes__title">
          Choose your subtitles style
        </h2>
      </div>
      <label
        className={`Themes__label${isRenderedInEditor ? '-editor' : ''}`}
      >
        Use one of our themes to give your video more impact.<br />
        You can always customize the theme later in the settings of the project.
      </label>
      {renderVideoThemeList()}
      <ButtonNew
        type="button"
        color="primary-small"
        caption="Apply this theme"
        showSpinner={!isReadyForTheme}
        disabled={!isReadyForTheme || noThemeSelected}
        onClick={onClick}
      />
    </React.Fragment>
  )
}

export default ThemesForm
