import map from 'lodash/map'

import {
  IVocabularyState
} from './types'

export const getVocabularyNames = (state: IVocabularyState): string[] => {
  return map(state.vocabularies, vocabulary => vocabulary.attributes.name)
}

export const getVocabularyIds = (state: IVocabularyState): number[] => {
  return map(state.vocabularies, vocabulary => vocabulary.attributes.id)
}
