import React, { useState } from 'react'
import classnames from 'classnames'
import Arrow from '@Images/arrow_pv_settings.svg'
import Formality from './Formality'

const DELAY_TO_SHOW_OVERFLOW = 200 // ms, The same time as for the opening/closing animations in ProjectVersionSettingsForm__options-body

interface TranslationOptionsProps {
  readonly formality?: string
  changeFormality(newFormality: string | undefined): void
}

const TranslationOptions: React.FC<TranslationOptionsProps> = props => {
  const {
    formality,
    changeFormality,
  } = props

  const [bodyOpen, setBodyOpen] = useState(false)
  const [bodyOpenDelayed, setBodyOpenDelayed] = useState(false)

  const toggleBody = () => {
    // https://stackoverflow.com/questions/13938460/css-transition-auto-height-not-working
    const bodyDiv = document.getElementById('ProjectVersionSettingsForm__options-body-translation')
    if (!bodyDiv) { return }

    bodyDiv.clientHeight ? closeBody(bodyDiv) : openBody(bodyDiv)
  }

  const openBody = (bodyDiv: HTMLElement) => {
    const wrapper = document.querySelector('.ProjectVersionSettingsForm__options-body-inner-wrapper-translation')
    if (wrapper) {
      bodyDiv.style.height = wrapper.clientHeight + 'px'
      setBodyOpen(true)
      setTimeout(() => {
        setBodyOpenDelayed(true)
      }, DELAY_TO_SHOW_OVERFLOW)
    }
  }

  const closeBody = (bodyDiv: HTMLElement) => {
    bodyDiv.style.height = '0'
    setBodyOpen(false)
    setBodyOpenDelayed(false)
  }

  const renderFormality = () => {
    return(
      <Formality
        formality={formality}
        changeFormality={changeFormality}
      />
    )
  }

  const renderBody = () => {
    return (
      <div
        className={classnames(
          'ProjectVersionSettingsForm__options-body',
          {'ProjectVersionSettingsForm__options-body-open': bodyOpenDelayed})
        }
        id="ProjectVersionSettingsForm__options-body-translation"
      >
        <div className="ProjectVersionSettingsForm__options-body-inner-wrapper-translation">
          <div className="ProjectVersionSettingsForm__options-items">
            {renderFormality()}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="ProjectVersionSettingsForm__options">
      <div
        className="ProjectVersionSettingsForm__options-header"
        onClick={toggleBody}
      >
        <span>
          Translation
        </span>
        <Arrow
          className={classnames(
            'ProjectVersionSettingsForm__options-arrow',
            {'ProjectVersionSettingsForm__options-arrow-open': bodyOpen})
          }
        />
      </div>
      {renderBody()}
    </div>
  )
}

export default TranslationOptions
