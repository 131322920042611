import React, { useState } from 'react'
import DropdownList from '@checksub_team/react-widgets/lib/DropdownList'
import formattedCreditsWithHours from '@Utils/FormattedCreditsWithHours'
import classnames from 'classnames'

import './DebitOptions.scss'

interface DebitOptionsProps {
  readonly debitOptions: string[]
  readonly currentDebitOption: string
  readonly shouldDisableDebitDropdown: boolean
  readonly availableCredits: number
  readonly availableSubtitlingTime: number
  changeDebitOption(newDebitOption: string): void
}

const DebitOptions: React.FC<DebitOptionsProps> = props => {
  const {
    debitOptions,
    changeDebitOption,
    currentDebitOption,
    shouldDisableDebitDropdown,
    availableCredits,
    availableSubtitlingTime,
  } = props

  const formattedAvailableCredits: string = formattedCreditsWithHours(availableCredits)
  const formattedAvailableSubtitlingTime: string = formattedCreditsWithHours(availableSubtitlingTime)

  const isDebitedFromCredits = currentDebitOption === 'Use my credits'
  const isDebitedFromSubscription = currentDebitOption === 'Use my subscription'
  const isDebitedFromSubscriptionAndCredits = currentDebitOption === 'Use my subscription and credits in addition'

  const [showInfoForAvailableCredits, setShowInfoForAvailableCredits] = useState(false)
  const [infoForAvailableCreditsTimeout, setInfoForAvailableCreditsTimeout] = useState<ReturnType<typeof setTimeout> | null>(null)

  const toggleInfoForAvailableCredits = () => {
    if (infoForAvailableCreditsTimeout) {
      setShowInfoForAvailableCredits(false)
      clearTimeout(infoForAvailableCreditsTimeout)
      setInfoForAvailableCreditsTimeout(null)
    } else {
      const newInfoForAvailableCreditsTimeout = setTimeout(() => {
        setShowInfoForAvailableCredits(true)
      }, window.Checksub.tooltipDelay)
      setInfoForAvailableCreditsTimeout(newInfoForAvailableCreditsTimeout)
    }
  }

  const renderAvailableCredits = () => {
    return (
      <span>You have {formattedAvailableCredits} of credits left</span>
    )
  }

  const renderAvailableSubtitlingTime = () => {
    return (
      <span>You have {formattedAvailableSubtitlingTime} on your subscription left</span>
    )
  }

  const renderAvailableSubtitlingTimeAndCredits = () => {
    return (
      <span>
        You have {formattedAvailableSubtitlingTime} on your subscription left<br />
        You have {formattedAvailableCredits} of credits left
      </span>
    )
  }

  const hasNoDropdown = debitOptions.length === 1
  const debitDropdown = document.querySelectorAll('.DebitOptions__formfield .rw-open')[0] as HTMLElement
  const shouldShowInfoForAvailableCredits = !debitDropdown && showInfoForAvailableCredits

  return (
    <div
      className={classnames(
        'ProjectVersionSettingsForm__formfield DebitOptions__formfield',
        {'noDropdown': hasNoDropdown})
      }
    >
      <span className="DebitOptions__dropdownlist-wrapper"
        onMouseEnter={toggleInfoForAvailableCredits}
        onMouseLeave={toggleInfoForAvailableCredits}
        onClick={toggleInfoForAvailableCredits}
      >
        <DropdownList
          data={debitOptions}
          id="debitOption"
          name="debitOption"
          value={currentDebitOption}
          onChange={changeDebitOption}
          disabled={shouldDisableDebitDropdown || hasNoDropdown}
        />
        {shouldShowInfoForAvailableCredits && (
          <div className="ProjectVersionSettingsForm__tooltip DebitOptions__tooltip">
            {isDebitedFromCredits && renderAvailableCredits()}
            {isDebitedFromSubscription && renderAvailableSubtitlingTime()}
            {isDebitedFromSubscriptionAndCredits && renderAvailableSubtitlingTimeAndCredits()}
          </div>
        )}
      </span>
  </div>
)}

export default DebitOptions
