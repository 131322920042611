import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { IApplicationState } from '@Site/rootReducer'
import ConfirmationNewForm from '@SiteComponents/ConfirmationNewForm'
import OnboardingSidebar from '@SiteComponents/OnboardingSidebar'

import {
  PayloadData
} from './types'

import {
  confirmationNew
} from './actions'

import './UsersConfirmationNew.scss'

interface UsersConfirmationNewProps {
  readonly loading: boolean
  confirmationNew(payload: PayloadData): void
}

interface UsersConfirmationNewState {
}

class UsersConfirmationNew extends React.Component<UsersConfirmationNewProps, UsersConfirmationNewState> {
  render() {
    const { loading } = this.props

    return (
      <div className="UsersConfirmationNew">
        <OnboardingSidebar
          type="sign-in"
          caption1={<span>Didn’t receive confirmation instructions?<br />We will resend them to you.</span>}
          caption2={<span />}
        />
        <div className="UsersConfirmationNew__form-wrapper">
          <ConfirmationNewForm
            submit={this.props.confirmationNew}
            showSpinner={loading}
            disableButton={loading}
          />
        </div>
      </div>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { userConfirmationNew } = state

  return {
    loading: userConfirmationNew.loading
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    confirmationNew
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersConfirmationNew)
