import axios, { AxiosRequestConfig } from 'axios'
import convertToSnakeCase from '@Utils/ConvertToSnakeCase'

import {
  ICreateOrUpdateExternalTokenPayload
} from './types'

export function createOrUpdateExternalToken(payload: ICreateOrUpdateExternalTokenPayload) {
  const config: AxiosRequestConfig = {
    headers: {
      'X-CSRF-Token': window.Checksub.token
    },
    responseType: 'json'
  }

  return axios.post(`/api/v1/subtitles/${payload.subtitleId}/external_tokens`, convertToSnakeCase(payload), config)
}
