export default function getBrowserLanguages(): string[] {
  const locales = [
    ...(window.navigator.languages || []),
    window.navigator.language,
    (window.navigator.browserLanguage || ''),
    (window.navigator.userLanguage || ''),
    (window.navigator.systemLanguage || '')
  ].filter(language => language.length === 2)

  return locales
}
