import filter from 'lodash/filter'

import {
  IVoiceAttributes,
} from '@EditorContainers/Settings/types'

export default function getDefaultCountry(voices: IVoiceAttributes[], gender: string, languageCode: string): string {
  let defaultCountry = 'All'

  const voicesForGenderAndLanguageCode = filter(voices, voice => {
    return voice.gender === gender && voice.languages.includes(languageCode)
  })

  const languageCodeIsDialectLanguageCode = languageCode.split('_').length > 1

  if (voicesForGenderAndLanguageCode.length > 0 && languageCodeIsDialectLanguageCode) {
    defaultCountry = voicesForGenderAndLanguageCode[0].country
  } else if (voicesForGenderAndLanguageCode.length > 0 ) {
    const dialectLanguageCode = `${languageCode}_${languageCode}` // Avoids that for example Canada is found as the default region for language code fr, by searching for voices that also have fr_fr in their languages
    const voicesForGenderAndDialectLanguageCode = filter(voicesForGenderAndLanguageCode, voice => {
      return voice.languages.includes(dialectLanguageCode)
    })

    defaultCountry = voicesForGenderAndDialectLanguageCode.length > 0 ? voicesForGenderAndDialectLanguageCode[0].country : voicesForGenderAndLanguageCode[0].country
  }

  return defaultCountry
}
