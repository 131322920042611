import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { IApplicationState } from '../../rootReducer'

import Header from '@SiteContainers/Header'
import CancelSubscriptionPopup from '@SiteComponents/CancelSubscriptionPopup'
import UpdateSubscriptionPopup from '@SiteComponents/UpdateSubscriptionPopup'
import subscriptionPriceCalculatorPerPeriod from '@Utils/SubscriptionPriceCalculatorPerPeriod'
import Faq from './Faq'
import PeriodSwitch from './PeriodSwitch'
import CurrencySwitch from './CurrencySwitch'

import Checkmark from '@Images/checkmark.svg'

import Offer from './Offer'
import EnterpriseOffer from './EnterpriseOffer'

import {
  ICancelSubscriptionPayload,
  IStartCheckoutSessionPayload,
  IUpdateSubscriptionPayload,
} from '@SiteModules/Subscription/types'

import {
  cancelSubscription,
  startCheckoutSession,
  updateSubscription,
} from '@SiteModules/Subscription/actions'

import {
  getUserStripeInfo
} from '@SiteModules/User/actions'

import './Subscription.scss'

const CURRENT_PLAN_VERSION = '005'
const DURATION_TRIAL = 14 // days
const CURRENT_PLANS = ['junior', 'starter']

interface SubscriptionProps {
  readonly plan: string
  readonly hasLoadedSubscriptionSuccessfully: boolean
  readonly hasLoadedStripeInfoSuccessfully: boolean
  readonly subscriptionIsCurrent: boolean
  readonly subscriptionIsTrialing: boolean
  readonly subscriptionId: string
  readonly secondsAvailable: number
  readonly subscriptionPlan: string
  readonly cancelAtPeriodEnd: boolean
  readonly canGetTrial: boolean
  readonly minutesProvidedByPeriod: number
  readonly maxLanguagesLimit: number
  readonly renewalDate: string
  readonly stripeCustomerCurrency?: string
  readonly taxPercent?: number
  cancelSubscription(payload: ICancelSubscriptionPayload): void
  startCheckoutSession(payload: IStartCheckoutSessionPayload): void
  updateSubscription(payload: IUpdateSubscriptionPayload): void
  getUserStripeInfo(): void
}

interface SubscriptionState {
  readonly selectedPlanName: string
  readonly monthlyPeriod: boolean
  readonly priceInUsd: boolean
  readonly cancelSubscriptionPopupOpen: boolean
  readonly updateSubscriptionPopupOpen: boolean
}

class Subscription extends React.Component<SubscriptionProps, SubscriptionState> {
  constructor(props: SubscriptionProps) {
    super(props)

    this.state = {
      cancelSubscriptionPopupOpen: false,
      updateSubscriptionPopupOpen: false,
      monthlyPeriod: false,
      priceInUsd: false,
      selectedPlanName: 'junior',
    }
  }

  componentDidMount() {
    this.props.getUserStripeInfo()

    const currentPlanIsMonthly = this.props.subscriptionIsCurrent && this.props.plan.includes('monthly')
    if (currentPlanIsMonthly) {
      this.setState({ monthlyPeriod: true })
    }
  }

  componentDidUpdate(prevProps: SubscriptionProps) {
    if ((prevProps.stripeCustomerCurrency !== this.props.stripeCustomerCurrency) && this.props.stripeCustomerCurrency === 'USD') {
      this.setState({ priceInUsd: true })
    }

    if ((!prevProps.hasLoadedSubscriptionSuccessfully && this.props.hasLoadedSubscriptionSuccessfully)) {
      const currentPlanIsMonthly = this.props.subscriptionIsCurrent && this.props.plan.includes('monthly')
      if (currentPlanIsMonthly) {
        this.setState({ monthlyPeriod: true })
      }
    }
  }

  handleChangePeriod = () => {
    this.setState((state) => ({ monthlyPeriod: !state.monthlyPeriod }))
  }

  handleChangeCurrency = () => {
    this.setState((state) => ({ priceInUsd: !state.priceInUsd }))
  }

  handleCancel = () => {
    this.setState({ cancelSubscriptionPopupOpen: true })
  }

  handleBuyOrUpdate = (newPlanName: string) => {
    const { subscriptionIsCurrent, cancelAtPeriodEnd } = this.props
    const hasCurrentUncanceledSubscription = subscriptionIsCurrent && !cancelAtPeriodEnd

    if (hasCurrentUncanceledSubscription) {
      this.setState({ selectedPlanName: newPlanName, updateSubscriptionPopupOpen: true })
    } else {
      this.handleBuy(newPlanName)
    }
  }

  handleBuy = (newPlanName: string) => {
    const { monthlyPeriod, priceInUsd } = this.state
    const fullNewPlanName = `${newPlanName}_${monthlyPeriod ? 'monthly' : 'yearly'}_${CURRENT_PLAN_VERSION}`
    const currency = priceInUsd ? 'USD' : 'EUR'
    this.props.startCheckoutSession({ planName: fullNewPlanName, currency })
  }

  closeCancelSubscriptionPopup = () => {
    this.setState({ cancelSubscriptionPopupOpen: false })
  }

  closeUpdateSubscriptionPopup = () => {
    this.setState({ updateSubscriptionPopupOpen: false })
  }

  scheduleDemo = () => {
    location.href = 'https://meetings-eu1.hubspot.com/kane-lam/lead-from-website-english'
  }

  renderCancelSubscriptionPopup = () => {
    return (
      <CancelSubscriptionPopup
        closePopup={this.closeCancelSubscriptionPopup}
        cancelSubscription={this.cancelSubscription}
        secondsAvailable={this.props.secondsAvailable}
        plan={this.props.plan}
        subscriptionIsTrialing={this.props.subscriptionIsTrialing}
      />
    )
  }

  renderUpdateSubscriptionPopup = () => {
    const { selectedPlanName, monthlyPeriod, priceInUsd } = this.state
    const fullNewPlanName = `${selectedPlanName}_${monthlyPeriod ? 'monthly' : 'yearly'}_${CURRENT_PLAN_VERSION}`
    const pricePerPeriodWithoutVat = subscriptionPriceCalculatorPerPeriod(selectedPlanName, monthlyPeriod, priceInUsd)

    return (
      <UpdateSubscriptionPopup
        closePopup={this.closeUpdateSubscriptionPopup}
        updateSubscription={this.updateSubscription}
        priceInUsd={priceInUsd}
        fullNewPlanName={fullNewPlanName}
        pricePerPeriodWithoutVat={pricePerPeriodWithoutVat}
        taxPercent={this.props.taxPercent}
      />
    )
  }

  cancelSubscription = (payload: ICancelSubscriptionPayload) => {
    this.props.cancelSubscription(payload)
  }

  updateSubscription = () => {
    const { selectedPlanName, monthlyPeriod } = this.state
    const updatedPlan = `${selectedPlanName}_${monthlyPeriod ? 'monthly' : 'yearly'}_${CURRENT_PLAN_VERSION}`

    this.props.updateSubscription({ planName: updatedPlan, subscriptionId: this.props.subscriptionId })
  }

  renderTitle = () => {
    return (
      <React.Fragment>
        <div className="Subscription__body-title">
          Expand the audience <br/>
          of your videos.
        </div>
        <div className="Subscription__body-subtitle">
          {this.props.canGetTrial && (
            <div>
              <Checkmark />Free 14-day trial
            </div>
          )}
          <div>
            <Checkmark />200 languages supported
          </div>
          <div>
            <Checkmark />Cancel anytime
          </div>
        </div>
      </React.Fragment>
    )
  }

  getPlanSettings = (plan: string, monthlyPeriod: boolean) => {
    const {
      minutesProvidedByPeriod,
      maxLanguagesLimit,
    } = this.props

    switch(plan){
      case 'junior':
        return {
          description: 'For professionals who need to generate subtitles for their videos.',
          features: [
            'Subtitles & translations',
            `${monthlyPeriod ? '2 hours of credits / month' : '24 hours of credits / year'}`,
            'Up to 1 translation per project',
            'SRT exportation',
            'Video export with watermark',
            'E-mail support'
          ]
        }
      case 'starter':
        return {
          description: 'For companies that want to translate their content.',
          features: [
            'Subtitles, translations & dubbing',
            `${monthlyPeriod ? '5 hours of credits / month' : '60 hours of credits / year'}`,
            'Up to 1 translation per project',
            'SRT exportation',
            'AI-dubbing (60 languages available)',
            'Voice-cloning (28 languages available)',
            'Connect 1 YouTube channel',
            'No watermark',
            'E-mail support'
          ]
        }
      default:
        return {
          description: 'You currently have a legacy plan',
          features: [
            `Auto-subtitles: ${monthlyPeriod ? `${minutesProvidedByPeriod} minutes of credits / month` : `${Math.round(minutesProvidedByPeriod / 60)} hours of credits / year`}`,
            `Up to ${maxLanguagesLimit - 1} translation(s) per project`,
            'SRT exportation',
            'E-mail support'
          ]
        }
    }
  }

  renderPeriodSwitch = () => {
    return (
      <PeriodSwitch
        isMonthlyPeriod={this.state.monthlyPeriod}
        changePeriod={this.handleChangePeriod}
      />
    )
  }

  renderCurrencySwitch = () => {
    return (
      <CurrencySwitch
        isPriceInUsd={this.state.priceInUsd}
        changeCurrency={this.handleChangeCurrency}
      />
    )
  }

  renderOffers = () => {
    const {
      plan,
      hasLoadedSubscriptionSuccessfully,
      hasLoadedStripeInfoSuccessfully,
      subscriptionIsCurrent,
      subscriptionIsTrialing,
      cancelAtPeriodEnd,
      canGetTrial,
      renewalDate,
    } = this.props

    const {
      monthlyPeriod,
      priceInUsd,
    } = this.state

    const [ planName, planPeriod, planVersion ] = plan.split('_')
    const isPlanMonthly = planPeriod === 'monthly'

    const plansToRender = [...CURRENT_PLANS]
    if (plan && subscriptionIsCurrent && monthlyPeriod === isPlanMonthly && (!CURRENT_PLANS.includes(planName) || planVersion !== CURRENT_PLAN_VERSION)) {
      plansToRender.unshift('legacy plan')
    }

    return (
      <React.Fragment>
        <div className="Subscription__offers">
          {plansToRender.map((planToRender) => {
            const planSettings = this.getPlanSettings(planToRender, monthlyPeriod)

            return (
              <Offer
                key={planToRender}
                description={planSettings.description}
                planName={planToRender}
                isPriceInUsd={priceInUsd}
                isMonthlyPeriod={monthlyPeriod}
                currentPlan={plan}
                currentPlanVersion={CURRENT_PLAN_VERSION}
                subscriptionIsCurrent={subscriptionIsCurrent}
                subscriptionIsTrialing={subscriptionIsTrialing}
                cancelAtPeriodEnd={cancelAtPeriodEnd}
                renewalDate={renewalDate}
                canGetTrial={canGetTrial}
                hasLoadedSuccessfully={hasLoadedSubscriptionSuccessfully && hasLoadedStripeInfoSuccessfully}
                handleCancel={this.handleCancel}
                handleBuyOrUpdate={this.handleBuyOrUpdate}
                handleBuy={this.handleBuy}
                features={planSettings.features}
              />
            )
          })}

          <EnterpriseOffer
            description={
              <React.Fragment>
                For businesses and organisations that want to spread their videos at scale.
              </React.Fragment>
            }
            planName={'enterprise'}
            isPriceInUsd={priceInUsd}
            isMonthlyPeriod={monthlyPeriod}
            currentPlan={plan}
            currentPlanVersion={CURRENT_PLAN_VERSION}
            subscriptionIsCurrent={subscriptionIsCurrent}
            hasLoadedSuccessfully={hasLoadedSubscriptionSuccessfully && hasLoadedStripeInfoSuccessfully}
            scheduleDemo={this.scheduleDemo}
            features={[
              'Subtitles, translations & dubbing',
              'Custom credits',
              'Up to 187 languages per project',
              'SRT exportation',
              'AI-dubbing (60 languages available)',
              'Voice-cloning (28 languages available)',
              'Connect your YouTube channels',
              'Account manager'
            ]}
          />
        </div>
      </React.Fragment>
    )
  }

  renderFaq = () => {
    return (
      <Faq
        durationTrialInDays={DURATION_TRIAL}
      />
    )
  }

  render() {
    const shouldRenderFaq = this.props.canGetTrial
    const shouldRenderCurrencySwitch = this.props.hasLoadedStripeInfoSuccessfully && !this.props.stripeCustomerCurrency

    return (
      <div className="Subscription">
        <Header />
        {this.state.cancelSubscriptionPopupOpen && this.renderCancelSubscriptionPopup()}
        {this.state.updateSubscriptionPopupOpen && this.renderUpdateSubscriptionPopup()}
        <div className="Subscription__body">
          {this.renderTitle()}
          <div className="Subscription__switches">
            {this.renderPeriodSwitch()}
            {shouldRenderCurrencySwitch && this.renderCurrencySwitch()}
          </div>
          {this.renderOffers()}
          {shouldRenderFaq && this.renderFaq()}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { user, subscription } = state

  let subscriptionPlan = ''
  let subscriptionId = ''

  if (user.subscription && user.subscription.attributes) {
    subscriptionId = user.subscription.id
    subscriptionPlan = user.subscription.attributes.plan.split('_')[0]
  }

  return {
    hasLoadedSubscriptionSuccessfully: subscription.hasLoadedSuccessfully,
    hasLoadedStripeInfoSuccessfully: user.hasLoadedStripeInfoSuccessfully,
    plan: user.subscription.attributes.plan,
    secondsAvailable: user.subscription.attributes.secondsAvailable,
    subscriptionIsCurrent: user.subscription.attributes.isCurrent,
    subscriptionIsTrialing: user.subscription.attributes.isTrialing,
    cancelAtPeriodEnd: user.subscription.attributes.cancelAtPeriodEnd,
    canGetTrial: user.user.attributes.canGetTrial,
    subscriptionPlan,
    subscriptionId,
    minutesProvidedByPeriod: user.subscription.attributes.minutesProvidedByPeriod,
    maxLanguagesLimit: user.subscription.attributes.maxLanguagesLimit,
    renewalDate: user.subscription.attributes.renewalDate,
    stripeCustomerCurrency: user.stripe?.attributes.customerCurrency,
    taxPercent: user.stripe?.attributes.taxPercent,
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    cancelSubscription,
    startCheckoutSession,
    updateSubscription,
    getUserStripeInfo,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Subscription)
