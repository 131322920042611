import toastr from 'toastr'
import axios from 'axios'

import hasInternetConnection from '@Utils/HasInternetConnection'

const DEFAULT_MESSAGE = 'An error occurred'
const INTERNET_CONNECTION_IS_BROKEN = 'An error occurred. Check your internet connection and try again.'
const REGENERATING_BLOCK_ERROR_MESSAGE = 'This voice block is already being regenerated.'
const REGENERATING_BLOCK_ERROR_TITLE = 'Already in process'

export default class Logger {
  static error = (e: unknown, title = 'Error') => {
    let message = ''

    if (axios.isAxiosError(e)) {
      if (e.response) {
        message = e.response.data.message
      } else {
        message = hasInternetConnection()
          ? e.message
          : INTERNET_CONNECTION_IS_BROKEN
      }
    } else {
      message = e instanceof Error
        ? e.message
        : DEFAULT_MESSAGE
    }

    if (message === REGENERATING_BLOCK_ERROR_MESSAGE) {
      title = REGENERATING_BLOCK_ERROR_TITLE
    }

    toastr.error(message || DEFAULT_MESSAGE, title)

    if (window.Rollbar) {
      window.Rollbar.error(message, e)
    }
  }

  static info = (info: string) => {
    if (window.Rollbar) {
      window.Rollbar.info(info)
    }
  }
}
