import { TActions } from '@Root/types'

export const SurveyActionTypes = {
  SUBMIT_SURVEY: '@@surveyForm/SUBMIT_SURVEY',
  SUBMIT_SURVEY_FAILURE: '@@surveyForm/SUBMIT_SURVEY_FAILURE',
  SUBMIT_SURVEY_SUCCESS: '@@surveyForm/SUBMIT_SURVEY_SUCCESS',
}

export interface ISurveyData {
  readonly role: string
  readonly videoQuantityPerMonth: string
  readonly organizationSize: string
}

export type TSurveyActionTypes = TActions<ISurveyData>
