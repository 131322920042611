import React from 'react'
import Logo from '@Images/checksub_white.png'
import classnames from 'classnames'

import './OnboardingSidebar.scss'

interface OnboardingSidebarProps {
  readonly type: string
  readonly caption1: JSX.Element
  readonly caption2: JSX.Element
}

const OnboardingSidebar: React.FC<OnboardingSidebarProps> = props => {
  return(
    <div className={classnames(
      'OnboardingSidebar',
      `OnboardingSidebar-${props.type}`)}
      >
      <img src={Logo} />
      <div className="OnboardingSidebar__caption1">
        {props.caption1}
      </div>
      <div className="OnboardingSidebar__caption2">
        {props.caption2}
      </div>
    </div>
  )
}

export default OnboardingSidebar
