export default function hasElementOrSomeParentTheClass(element: HTMLElement, classname: string): boolean {
  if (element.className !== 'SVGAnimatedString' && typeof element.className === 'string') {
    if (element.className.split(' ').indexOf(classname) >= 0) { return true }
  }

  if (element.parentElement) {
    return element.parentElement && hasElementOrSomeParentTheClass(element.parentElement, classname)
  }

  return false
}
