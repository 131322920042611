import React from 'react'
import { connect } from 'react-redux'
import { Action, Dispatch, bindActionCreators } from 'redux'
import { IApplicationState } from '@Site/rootReducer'
import toastr from 'toastr'
import 'toastr/build/toastr.css'
import Loader from '@SiteComponents/Loader'
import EmptyDashboard from '@Images/empty_dashboard.jpg'
import ChannelsItem from './ChannelsItem'
import Pagination from '@SiteComponents/Pagination'

import './ChannelsTable.scss'

import {
  IExternalVideo,
  IExternalChannel
} from './types'

import {
  ISubmitUrlsPayload
} from '@SiteModules/Element/types'

import {
  submitUrls
} from '@SiteModules/Element/actions'

import {
  increasePageCount,
  decreasePageCount
} from './actions'

interface ChannelsTableProps {
  readonly externalChannels: IExternalChannel[]
  readonly externalVideos: IExternalVideo[]
  readonly loading: boolean
  readonly uploading: boolean
  readonly totalCount: number
  readonly totalPages: number
  readonly videosPerPage: number
  readonly currentPage: number
  submitUrls(payload: ISubmitUrlsPayload): void
  increasePageCount(): void
  decreasePageCount(): void
}

interface ChannelsTableState {
}

class ChannelsTable extends React.Component<ChannelsTableProps, ChannelsTableState> {

  renderLoader() {
    const { loading } = this.props

    if (!loading) { return }

    return(
      <span className="ExternalVideosList__loader loader" />
    )
  }

  renderExternalVideos() {
    const {
      externalVideos,
      uploading,
      totalCount,
      totalPages,
      currentPage,
      videosPerPage
    } = this.props

    const startIndex = (currentPage - 1) * videosPerPage
    const endIndex = startIndex + videosPerPage

    return (
      <div className="ExternalVideos">
        <div className="ExternalVideos__pagination">
          <Pagination
            totalCount={totalCount}
            totalPages={totalPages}
            currentPage={currentPage}
            itemsPerPage={videosPerPage}
            increasePageCount={this.props.increasePageCount}
            decreasePageCount={this.props.decreasePageCount}
          />
        </div>
        <table className="ExternalVideos__table ExternalVideos__table-externalVideos">
          <thead className="ExternalVideos__table-header">
            <tr>
              <th className="ExternalVideos__table-cell" />
              <th className="ExternalVideos__table-cell ExternalVideos__header-title">Title</th>
              <th className="ExternalVideos__table-cell">Length</th>
              <th className="ExternalVideos__table-cell">Published</th>
              <th className="ExternalVideos__table-cell">Platform</th>
              <th className="ExternalVideos__table-cell">Channel</th>
              <th className="ExternalVideos__table-cell" />
            </tr>
          </thead>
          <tbody className="ExternalVideos__table-body">
            {externalVideos.slice(startIndex, endIndex).map((externalVideo, index) => {
              return(
                <ChannelsItem
                  key={index}
                  externalVideo={externalVideo}
                  submitUrls={this.props.submitUrls}
                  loading={uploading}
                />
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }

  renderBlankPage() {
    return(
      <div className="ExternalVideos-blank-page">
        <p className="ExternalVideos-blank-page__title">
          👋 Blank Page Syndrome?
          <br />
          Start by uploading your first video
          or connecting a new channel.
        </p>

        <img
          src={EmptyDashboard}
          className="ExternalVideos-blank-page__image"
        />
      </div>
    )
  }

  render() {
    const { externalChannels, externalVideos, loading } = this.props
    const shouldRenderExternalVideos = !!externalVideos.length
    const statusOfExternalVideos = loading ? 'loading' : 'loaded'
    const shouldRenderLoader = loading
    const shouldRenderBlankPage = !externalVideos.length

    if (externalChannels.some((channel) => { return !channel.valid })) {
      toastr.error('Some of your channels are disconnected. You can refresh the access in the channels settings in your profile.', 'Error', { closeButton: true, timeOut: 0, extendedTimeOut: 0, preventDuplicates: true })
    }

    return (
      <div className="ExternalVideosList">
        {this.renderLoader()}

        <div className={`ExternalVideosList-${statusOfExternalVideos}`}>
          {shouldRenderLoader ? (
            <Loader color="dark" />
          ) : (
            <React.Fragment>
              {shouldRenderExternalVideos && this.renderExternalVideos()}
              {shouldRenderBlankPage && this.renderBlankPage()}
            </React.Fragment>
          )}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { channelsTable, elements } = state

  return {
    externalChannels: channelsTable.externalChannels,
    externalVideos: channelsTable.externalVideos,
    loading: channelsTable.loadingVideos,
    totalCount: channelsTable.totalCount,
    totalPages: channelsTable.totalPages,
    currentPage: channelsTable.currentPage,
    videosPerPage: channelsTable.videosPerPage,
    uploading: elements.uploading
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    submitUrls,
    increasePageCount,
    decreasePageCount
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ChannelsTable)
