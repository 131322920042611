import { takeEvery, put, select  } from 'redux-saga/effects'
import * as Eff from 'redux-saga/effects' // tslint:disable-line:no-duplicate-imports
import { IAction, ISuccessResponse } from '@Root/types'

import toastr from 'toastr'
import Logger from '@Utils/Logger'

import { IUploadedElement } from '@Utils/ElementUploader'

import {
  ISubmitUrlsPayload,
  ElementActionTypes,
  ISubmitUrlsSuccessResponseBody,
  ICreateElementSuccessResponseBody,
} from './types'

import {
  closePopupDashboardSidebar
} from '@SiteContainers/DashboardSidebar/actions'

import {
  resetUpload,
  submitUrlsSuccess,
  submitUrlsFailure,
  createElementSuccess,
  createElementFailure,
} from './actions'

import * as API from './api'

const call: any = Eff.call

function* createElementSaga(action: IAction<IUploadedElement>): Generator<any, any, any> {
  try {
    const response: ISuccessResponse<ICreateElementSuccessResponseBody> = yield call(API.createElement, action.payload)
    yield put(createElementSuccess(response.data))

    const state = yield select()
    const numberOfFilesToUpload = state.elements.numberOfFilesToUpload
    const alreadyUploaded = state.elements.alreadyUploaded
    const projectVersionIds = state.elements.uploadedProjectVersionIds

    if (alreadyUploaded === numberOfFilesToUpload) {
      window.location.href = `/setup?projectVersionIds=${projectVersionIds.join(',')}`
    }

    toastr.success('', 'Element created!')
  } catch (error) {
    Logger.error(error, 'Element(s) could not be created!')
    yield put(createElementFailure())
    yield put(resetUpload())
    yield put(closePopupDashboardSidebar())
  }
}

function* submitUrlsSaga(action: IAction<ISubmitUrlsPayload>) {
  try {
    const { payload } = action
    const response: ISuccessResponse<ISubmitUrlsSuccessResponseBody> = yield call(API.submitUrls, payload)
    yield put(submitUrlsSuccess(response.data))
    yield put(closePopupDashboardSidebar())

    if (payload.urls.length > 1) {
      window.location.href = '/'
    } else {
      const projectVersionId = response.data.data[0].id
      window.location.href = response.data.data[0].attributes.duration === '00:00' ?
        `/processing?projectVersionId=${projectVersionId}` : `/setup?projectVersionIds=${projectVersionId}`
    }

    toastr.success('', 'URL(s) submitted')
  } catch (e) {
    Logger.error(e, 'URL(s) could not be submitted')
    yield put(submitUrlsFailure())
  }
}

function* elementSagas() {
  yield takeEvery(ElementActionTypes.CREATE_ELEMENT, createElementSaga)
  yield takeEvery(ElementActionTypes.SUBMIT_URLS, submitUrlsSaga)
}

export default elementSagas
