import {
  ISubscriptionState,
  SubscriptionsActionTypes,
  TSubscriptionsActionTypes,
} from './types'

import {
  UserActionTypes,
} from '@SiteModules/User/types'

export const initialState: ISubscriptionState = {
  hasLoadedSuccessfully: false,
}

export const subscriptionReducer = (
  state: ISubscriptionState = initialState,
  action: TSubscriptionsActionTypes
): ISubscriptionState => {
  switch (action.type) {
    case UserActionTypes.GET_INFO: return startLoading(state)
    case UserActionTypes.GET_INFO_SUCCESS: return stopLoadingSuccessfully(state)

    case SubscriptionsActionTypes.CANCEL_SUBSCRIPTION: return startLoading(state)
    case SubscriptionsActionTypes.CANCEL_SUBSCRIPTION_SUCCESS: return stopLoadingSuccessfully(state)

    case SubscriptionsActionTypes.START_CHECKOUT_SESSION: return startLoading(state)

    case SubscriptionsActionTypes.UPDATE_SUBSCRIPTION: return startLoading(state)
    case SubscriptionsActionTypes.UPDATE_SUBSCRIPTION_SUCCESS: return stopLoadingSuccessfully(state)

    default: return state
  }
}

function startLoading(state: ISubscriptionState): ISubscriptionState {
  return { ...state, hasLoadedSuccessfully: false }
}

function stopLoadingSuccessfully(state: ISubscriptionState): ISubscriptionState {
  return { ...state, hasLoadedSuccessfully: true }
}
