import React, { ReactElement } from 'react'
import Loader from '@SiteComponents/Loader'

import './Button.scss'

interface ButtonProps {
  readonly color: string
  readonly caption: ReactElement<any> | string
  readonly disabled?: boolean
  readonly showSpinner?: boolean
  readonly type?: 'button' | 'submit' | 'reset' | undefined
  onClick?(): void
}

const Button: React.FC<ButtonProps> = props => {
  const onClick = () => {
    if (!props.onClick) { return }

    props.onClick()
  }

  return (
    <button
      type={props.type || 'button'}
      className={`Button Button-${props.color}`}
      onClick={onClick}
      disabled={props.disabled}
    >
      {props.showSpinner ? (
        <Loader />
      ) : (
        props.caption
      )}
    </button>
  )
}

export default Button
