import Analytics from '@Utils/Analytics'
import Authorization from '@Utils/Authorization'

export function notifyGTMAboutExternalLogin() {
  if (Authorization.isLoggedInThroughGoogle()) {
    Analytics.trackGTMSignUpLogin('login', 'google')
    window.Checksub.cookies.set('google_login', 'false', { path: '/' })
  }
}

export function notifyGTMAboutExternalSignUp() {
  if (Authorization.isSignedUpThroughGoogle()) {
    Analytics.trackGTMSignUpLogin('sign_up', 'google')
    window.Checksub.cookies.set('google_signup', 'false', { path: '/' })
  }
}
