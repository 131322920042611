import { takeEvery  } from 'redux-saga/effects'
import * as Eff from 'redux-saga/effects' // tslint:disable-line:no-duplicate-imports
import { IAction } from '@Root/types'

import Logger from '@Utils/Logger'

import {
  DemoActionTypes,
  ISubmitDemoProjectVersionSettingsPayload
} from './types'

import * as API from './api'

const call: any = Eff.call

function* submitDemoProjectVersionSettingsSaga(action: IAction<ISubmitDemoProjectVersionSettingsPayload>): Generator<any, any, any> {
  try {
    yield call(API.submitDemoProjectVersionSettings, action.payload)
  } catch (error) {
    Logger.error(error, 'Processing cannot be started')
  }
}

function* demoSagas() {
  yield takeEvery(DemoActionTypes.SUBMIT_DEMO_PROJECT_VERSION_SETTINGS, submitDemoProjectVersionSettingsSaga)
}

export default demoSagas
