import React, { useEffect } from 'react'
import uniq from 'lodash/uniq'
import DropdownV2 from '@EditorComponents/DropdownV2'

import {
  IVoiceAttributes,
} from '@EditorContainers/Settings/types'

interface CountryProps {
  readonly country: string
  readonly voices: IVoiceAttributes[]
  setCountry(newCountry: string): void
}

const Country: React.FC<CountryProps> = props => {
  const { voices, country } = props

  const availableCountries = uniq(voices.map(voice => voice.country)).sort()
  const hasOnlyOneCountry = availableCountries.length <= 1

  availableCountries.unshift('All')

  useEffect(() => {
    if (country === 'All') { return }

    props.setCountry('All')
  }, [voices])

  return (
    <div className="VoiceSettings__value-wrapper">
      <span className="VoiceSettings__value">Region</span>
      <DropdownV2
        items={availableCountries}
        currentItem={country}
        onChange={props.setCountry}
        shouldBlockDropdown={hasOnlyOneCountry}
        noPortal
      />
    </div>
  )
}

export default Country
