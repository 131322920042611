import React from 'react'
import classnames from 'classnames'

import './CurrencySwitch.scss'

interface CurrencySwitchProps {
  readonly isPriceInUsd: boolean
  changeCurrency(): void
}

const CurrencySwitch: React.FC<CurrencySwitchProps> = props => {
  const changeToUsd = () => {
    if (props.isPriceInUsd) { return }

    props.changeCurrency()
  }

  const changeToEuro = () => {
    if (!props.isPriceInUsd) { return }

    props.changeCurrency()
  }

  return (
    <ul className="CurrencySwitch">
      <li
        className={classnames(
          'CurrencySwitch__panel',
          'CurrencySwitch__panel-left',
          { 'CurrencySwitch__panel-active': props.isPriceInUsd },
          { 'CurrencySwitch__panel-active-left': props.isPriceInUsd },
        )}
        onClick={changeToUsd}
      >
        $ USD
      </li>
      <li
        className={classnames(
          'CurrencySwitch__panel',
          'CurrencySwitch__panel-right',
          { 'CurrencySwitch__panel-active': !props.isPriceInUsd },
          { 'CurrencySwitch__panel-active-right': !props.isPriceInUsd },
        )}
        onClick={changeToEuro}
      >
        € EUR
      </li>
    </ul>
  )
}

export default CurrencySwitch
