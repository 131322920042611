import { Action } from 'redux'
import { put, takeEvery } from 'redux-saga/effects'
import * as Eff from 'redux-saga/effects' // tslint:disable-line:no-duplicate-imports
import toastr from 'toastr'

import Logger from '@Utils/Logger'
import Analytics from '@Utils/Analytics'

import { IActionFailure, ISuccessResponse } from '@Root/types'

import {
  fetchCreditPriceSuccess,
  fetchCreditPriceFailure,
} from './actions'

import {
  IPriceData,
  PaymentActionTypes,
  IPaymentSuccessPayload
} from './types'

import getParamValueFromUrl from '@Utils/GetParamValueFromUrl'

import * as API from './api'

const call: any = Eff.call

interface ICreditAction extends Action {
  readonly creditId: number
}

interface IPaymentSuccessAction extends Action {
  readonly payload: IPaymentSuccessPayload
}

const DELAY_UNTIL_STATUS_IS_UPDATED_IN_DATABASE = 2000 // msec

function* paymentSuccessSaga(action: IPaymentSuccessAction): Generator<any, any, any> {
  const {
    vat,
    amount,
    paidItem,
    currency,
    paidItemId,
  } = action.payload

  Analytics.trackGTMPurchase(paidItem, paidItemId, amount, vat, currency)

  toastr.success('', 'Payment successful')

  setTimeout(() => {
    const url = window.location.href
    const subtitleId = getParamValueFromUrl(url, 'id')

    if (subtitleId) {
      const projectId = getParamValueFromUrl(url, 'projectId')
      const projectVersionId = getParamValueFromUrl(url, 'projectVersionId')
      const subtitleLink = `/projects/${projectId}/project_versions/${projectVersionId}/subtitles/${subtitleId}/edit?showUpgradeProject=true`

      window.location.replace(subtitleLink)
    } else {
      window.location.replace('/?paid=true')
    }
  }, DELAY_UNTIL_STATUS_IS_UPDATED_IN_DATABASE)
}

function paymentFailureSaga(action: IActionFailure) {
  toastr.error(action.error || 'Something went wrong', 'Payment failed')
  if (window.Rollbar) { window.Rollbar.error(action.error) }
}

function* fetchCreditSaga(action: ICreditAction): Generator<any, any, any> {
  try {
    const response: ISuccessResponse<IPriceData> = yield call(API.fetchCreditPrice, action.creditId)
    yield put(fetchCreditPriceSuccess(response.data.data))
  } catch (e) {
    Logger.error(e, 'Credit data could not be loaded')
    yield put(fetchCreditPriceFailure())
  }
}

function* paymentSagas() {
  yield takeEvery(PaymentActionTypes.PAYMENT_SUCCESS, paymentSuccessSaga)
  yield takeEvery(PaymentActionTypes.PAYMENT_FAILURE, paymentFailureSaga)
  yield takeEvery(PaymentActionTypes.FETCH_CREDIT, fetchCreditSaga)
}

export default paymentSagas
