import { useEffect } from 'react'
import hasElementOrSomeParentTheClass from '@Utils/HasElementOrSomeParentTheClass'

export default function useClickOutsideListener(elementClass: string, setOpen: (newOpen: boolean) => void, open: boolean): void {
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (!open) { return }
      if (e.target) {
        const element = e.target as HTMLElement
        if (hasElementOrSomeParentTheClass(element, elementClass)) {
          return
        }
        setOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })
}
