import React from 'react'

import LabelIcon from '@Images/label.svg'
import PlusBig from '@Images/plus_big.svg'

import {
  IDeleteLabelPayload,
} from '@SiteContainers/DeleteLabelPopup/types'

import {
  IRenameLabelPayload,
} from '@SiteContainers/RenameLabelPopup/types'

import Label from './Label'

import './LabelsPanel.scss'

interface LabelsPanelProps {
  readonly labelIds: number[]
  resetLabelSelection(): void
  openCreateLabelPopup(): void
  readonly labelCaptions: string[]
  readonly selectedLabels: number[]
  changeSelectedLabels(id: number): void
  openRenameLabelPopup(payload: IRenameLabelPayload): void
  openDeleteLabelPopup(payload: IDeleteLabelPayload): void
}

const LabelsPanel: React.FC<LabelsPanelProps> = props => {
  const { labelCaptions, labelIds } = props

  const labelsList = labelCaptions.map((caption, index) => {
    const labelId = labelIds[index]
    const labelStatus = props.selectedLabels.includes(labelId) ? 'selected' : ''

    return (
      <Label
        key={labelId}
        labelId={labelId}
        caption={caption}
        labelStatus={labelStatus}
        openCreateLabelPopup={props.openCreateLabelPopup}
        changeSelectedLabels={props.changeSelectedLabels}
        openRenameLabelPopup={props.openRenameLabelPopup}
        openDeleteLabelPopup={props.openDeleteLabelPopup}
      />
    )
  })

  const allProjectVersionsStatus = props.selectedLabels.length ? '' : 'selected'

  return (
    <div className="LabelsPanel">
      <ul className="LabelsPanel__list">
        <li
          className="LabelsPanel__item"
          onClick={props.resetLabelSelection}
        >
          <div className="LabelsPanel__item-content">
            <span className={`LabelsPanel__list-item LabelsPanel__list-item-${allProjectVersionsStatus}`}>
              <span className="LabelsPanel__caption">
                <LabelIcon className="LabelsPanel__arrow" />
                <span className="LabelsPanel__caption-text">All projects</span>
              </span>
            </span>
          </div>
        </li>
        {labelsList}
      </ul>
      <span
        className="LabelsPanel__createNew"
        onClick={props.openCreateLabelPopup}
      >
        <span className="LabelsPanel__caption">
          <PlusBig />
          Create new label
        </span>
      </span>
    </div>
  )
}

export default LabelsPanel
