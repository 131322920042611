import React from 'react'
import { ConnectedComponent } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

interface PrivateRouteProps {
  readonly path: string
  readonly exact: boolean
  readonly component: ConnectedComponent<any, any>
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }) => {
  const { path, exact } = rest

  const isAuthorized = window.Checksub.cookies.get('authorized')

  // TODO: make a service...
  const onboarding = window.Checksub.cookies.get('onboarding')

  const onboardingPage = window.location.pathname === '/onboarding'
  const shouldRenderOnboarding = (onboarding === 'survey' || onboarding == null) && onboardingPage
  const canRenderNonOnboardingPages = onboarding === 'processed'

  return(
    // tslint:disable-next-line:jsx-no-lambda
    <Route path={path} exact={exact} render={(props) => {
      return(
        <React.Fragment>
          {isAuthorized && (
            <React.Fragment>
              {shouldRenderOnboarding && <Component {...props} />}
              {onboardingPage && canRenderNonOnboardingPages && <Redirect to={'/onboarding/check_email'} />}
              {!onboardingPage && canRenderNonOnboardingPages && <Component {...props} />}
            </React.Fragment>
          )}

          {!isAuthorized && <Redirect to={'/users/sign_in'} />}
        </React.Fragment>
      )
    }}/>
  )
}

export default PrivateRoute
