import {
  IBillingAddress,
  CheckoutsBillingAddressActionTypes,
  ICheckoutsBillingAddressState,
  ICheckoutsBillingAddressActionTypes,
  IFetchOrSubmitBillingAddressFailureAction,
  IFetchOrSubmitBillingAddressSuccessAction,
} from './types'

const initialBillingAddress: IBillingAddress = {
  id: '',
  type: 'billingAddress',
  attributes: {
    company: '',
    name: '',
    last_name: '',
    address: '',
    city: '',
    country: '',
    zipcode: '',
    vat_id: '',
    countries: [],
    eu_countries: [],
  }
}

export const initialState: ICheckoutsBillingAddressState = {
  billingAddress: initialBillingAddress,
  errors: [],
  loading: false,
}

export const checkoutsBillingAddressReducer = (
  state: ICheckoutsBillingAddressState = initialState,
  action: ICheckoutsBillingAddressActionTypes
): ICheckoutsBillingAddressState => {
  switch (action.type) {
    case CheckoutsBillingAddressActionTypes.SUBMIT_BILLING_ADDRESS: return submitBillingAddress(state)
    case CheckoutsBillingAddressActionTypes.FETCH_BILLING_ADDRESS: return fetchBillingAddress(state)
    case CheckoutsBillingAddressActionTypes.FETCH_OR_SUBMIT_BILLING_ADDRESS_SUCCESS: return fetchOrSubmitBillingAddressSuccess(state, action)
    case CheckoutsBillingAddressActionTypes.FETCH_OR_SUBMIT_BILLING_ADDRESS_FAILURE: return fetchOrSubmitBillingAddressFailure(state, action)
    default: return state
  }
}

const submitBillingAddress = (state: ICheckoutsBillingAddressState): ICheckoutsBillingAddressState => {
  return { ...state, loading: true }
}

const fetchBillingAddress = (state: ICheckoutsBillingAddressState): ICheckoutsBillingAddressState => {
  return { ...state, loading: true }
}

const fetchOrSubmitBillingAddressSuccess = (state: ICheckoutsBillingAddressState, action: ICheckoutsBillingAddressActionTypes): ICheckoutsBillingAddressState => {
  let billingAddress = initialBillingAddress

  if (isIFetchBillingAddressSuccessAction(action)) {
    billingAddress = action.billingAddress
  }

  return { ...state, billingAddress, loading: false }
}

function isIFetchBillingAddressSuccessAction(arg: any): arg is IFetchOrSubmitBillingAddressSuccessAction {
  return arg.billingAddress != null
}

const fetchOrSubmitBillingAddressFailure = (state: ICheckoutsBillingAddressState, action: ICheckoutsBillingAddressActionTypes): ICheckoutsBillingAddressState => {
  let errors: string[] = []

  if (isIFetchOrSubmitBillingAddressFailureAction(action)) {
    errors = [action.error]
  }

  return { ...state, errors, loading: false }
}

function isIFetchOrSubmitBillingAddressFailureAction(arg: any): arg is IFetchOrSubmitBillingAddressFailureAction {
  return arg.error != null
}
