import React, { useState } from 'react'
import Button from '@SiteComponents/Button'
import DropdownList from '@checksub_team/react-widgets/lib/DropdownList'
import { useForm } from 'react-hook-form'

import '@checksub_team/react-widgets/dist/css/react-widgets.css'

import '@Root/config/dropdowns.scss'
import './BillingAddressForm.scss'

interface BillingAddressFormStep2Props {
  readonly countries: string[]
  readonly address: string
  readonly city: string
  readonly country: string
  readonly company: string
  readonly zipcode: string
  readonly vatId: string
  readonly showSpinner: boolean
  readonly disableButton: boolean
  readonly euCountries: string[]
  submit(address: string, city: string, country: string, zipcode: string, vatId: string): void
}

type FormData = Omit<BillingAddressFormStep2Props, 'submit' | 'showSpinner' | 'disableButton' | 'countries'>

const BillingAddressFormStep2: React.FC<BillingAddressFormStep2Props> = props => {
  const { register, handleSubmit, errors } = useForm<FormData>()
  const [shouldShowWarningMissingVat, setShouldShowWarningMissingVat] = useState(false)

  const onSubmit = handleSubmit(({ address, city, country, zipcode, vatId }) => {
    const isEuCompanyWithoutVat = props.company.trim() !== '' && props.euCountries.includes(country) && vatId.trim() === ''

    if (isEuCompanyWithoutVat) {
      setShouldShowWarningMissingVat(true)
    } else {
      setShouldShowWarningMissingVat(false)
      props.submit(address, city, country, zipcode, vatId)
    }
  })

  const onChangeVatId = (e: React.ChangeEvent<HTMLInputElement>) => {
    const vatId = e.target.value
    if (!shouldShowWarningMissingVat || vatId.trim() === '') { return }

    setShouldShowWarningMissingVat(false)
  }

  const onChangeCountry = (newCountry: string) => {
    if (!shouldShowWarningMissingVat) { return }

    setShouldShowWarningMissingVat(props.euCountries.includes(newCountry))
  }

  const renderBillingAddress = () => {
    return(
      <div className="BillingAddressForm__formfield">
        <label htmlFor="address">Billing Address (optional)</label>
        <input
          id="address"
          name="address"
          type="text"
          defaultValue={props.address}
          ref={register()}
        />
      </div>
    )
  }

  const renderCity = () => {
    return(
      <div className="BillingAddressForm__formfield">
        <label htmlFor="city">City</label>
        <input
          id="city"
          name="city"
          type="text"
          defaultValue={props.city}
          ref={register({ required: true, maxLength: 100 })}
        />
        {
          errors.city &&
          errors.city.type === 'required' &&
          <span className="errorMessage">This field is required</span>
        }
        {
          errors.city &&
          errors.city.type === 'maxLength' &&
          <span  className="errorMessage">This field cannot exceed 100 characters</span>
        }
      </div>
    )
  }

  const renderCountry = () => {
    return(
      <div className="BillingAddressForm__formfield-dropdown">
        <label htmlFor="country">Country</label>

        <DropdownList
          id="country"
          name="country"
          customref={register({ required: true, maxLength: 100 })}
          data={props.countries}
          defaultValue={props.country}
          onChange={onChangeCountry}
          filter="startsWith"
          translate="no"
        />
        {
          errors.country &&
          errors.country.type === 'required' &&
          <span className="errorMessage">This field is required</span>
        }
        {
          errors.country &&
          errors.country.type === 'maxLength' &&
          <span  className="errorMessage">This field cannot exceed 100 characters</span>
        }
      </div>
    )
  }

  const renderZipcode = () => {
    return(
      <div className="BillingAddressForm__formfield">
        <label htmlFor="zipcode">Zipcode</label>
        <input
          id="zipcode"
          name="zipcode"
          type="text"
          defaultValue={props.zipcode}
          ref={register({ required: true, maxLength: 100 })}
        />
        {
          errors.zipcode &&
          errors.zipcode.type === 'required' &&
          <span className="errorMessage">This field is required</span>
        }
        {
          errors.zipcode &&
          errors.zipcode.type === 'maxLength' &&
          <span  className="errorMessage">This field cannot exceed 100 characters</span>
        }
      </div>
    )
  }

  const renderVatId = () => {
    return(
      <div className="BillingAddressForm__formfield">
        <label htmlFor="vatId">VAT ID (required for EU companies)</label>
        <input
          id="vatId"
          name="vatId"
          type="text"
          defaultValue={props.vatId}
          onChange={onChangeVatId}
          ref={register()}
        />
        {shouldShowWarningMissingVat && <span className="errorMessage">Please enter your VAT ID</span>}
      </div>
    )
  }

  const renderButtons = () => {
    return(
      <React.Fragment>
        <Button
          type="submit"
          caption={
            <React.Fragment>
              <span>
                Go to payment <span className="BillingAddressForm_arrow">→</span>
              </span>
            </React.Fragment>
          }
          color="primary-dark"
          showSpinner={props.showSpinner}
          disabled={props.disableButton || shouldShowWarningMissingVat }
        />
      </React.Fragment>
    )
  }

  return(
    <form className="BillingAddressForm BillingAddressForm__step2" onSubmit={onSubmit} >
      <div className="BillingAddressForm__counter">Step 2 of 2</div>
      {renderBillingAddress()}
      {renderCity()}
      {props.countries.length > 0 && renderCountry()}
      {renderZipcode()}
      {renderVatId()}
      {renderButtons()}
    </form>
  )
}

export default BillingAddressFormStep2
