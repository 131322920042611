import React from 'react'
import ButtonNewV2 from '@SiteComponents/ButtonNewV2'
import { IUser, Submittable } from '@Root/types'
import { useForm } from 'react-hook-form'
import getBrowserLanguages from '@Utils/GetBrowserLanguages'

import './SignUpForm.scss'

interface SignUpFormStep2Props extends Submittable {
  readonly email: string
  readonly password: string
}

type FormData = Pick<IUser, 'name'> & {
  readonly lastName: string
}

const SignUpFormStep2: React.FC<SignUpFormStep2Props> = props => {
  const { register, handleSubmit, errors } = useForm<FormData>()

  // tslint:disable-next-line:no-shadowed-variable
  const onSubmit = handleSubmit(({ name, lastName }) => {
    const languages = getBrowserLanguages()
    const email = props.email
    const password = props.password
    const passwordConfirmation = password

    props.submit({ name, lastName, email, password, passwordConfirmation, languages })
  })

  const renderHeader = () => {
    return(
      <div className="SignUpForm__header">
        We personalise your experience
      </div>
    )
  }

  const renderName = () => {
    return(
      <div className="SignUpForm__formfield">
        <label htmlFor="name">First Name</label>
        <input id="name" name="name" type="text" ref={register({ required: true, maxLength: 100 })} />
        {
          errors.name &&
          errors.name.type === 'required' &&
          <span className="errorMessage">&#x26A0; This field is required</span>
        }
        {
          errors.name &&
          errors.name.type === 'maxLength' &&
          <span  className="errorMessage">This field cannot exceed 100 characters</span>
        }
      </div>
    )
  }

  const renderLastName = () => {
    return(
      <div className="SignUpForm__formfield">
        <label htmlFor="Name">Last Name</label>
        <input
          id="lastName"
          name="lastName"
          type="text"
          ref={register({ required: true, maxLength: 100 })}
        />
        {
          errors.lastName &&
          errors.lastName.type === 'required' &&
          <span className="errorMessage">&#x26A0; This field is required</span>
        }
        {
          errors.lastName &&
          errors.lastName.type === 'maxLength' &&
          <span  className="errorMessage">This field cannot exceed 100 characters</span>
        }
      </div>
    )
  }

  const renderSubmitButton = () => {
    return(
      <div className="SignUpForm__submit-button-wrapper">
        <ButtonNewV2
          type="submit"
          caption="Next"
          styleType="brand-primary"
          size="large"
          disabled={props.disableButton}
          showSpinner={props.showSpinner}
        />
      </div>
    )
  }

  const renderStep = () => {
    return(
      <div className="SignUpForm__step">
        Step 2 of 2
      </div>
    )
  }

  return(
    <div className="SignUpForm" >
      {renderStep()}
      <form className="SignUpForm__main" onSubmit={onSubmit} >
        {renderHeader()}
        <div className="SignUpForm__main-formField-wrapper">
          {renderName()}
          {renderLastName()}
          {renderSubmitButton()}
        </div>
      </form>
    </div>
  )
}

export default SignUpFormStep2
