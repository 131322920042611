import React, { useState, useEffect } from 'react'

import Gender from './Gender'
import Country from './Country'
import VoiceName from './VoiceName'
import getDefaultCountry from '@Utils/GetDefaultCountry'

import {
  IVoiceAttributes,
} from '@EditorContainers/Settings/types'

import './VoiceSettings.scss'

interface VoiceSettingsProps {
  readonly voiceName: string
  readonly gender: string
  readonly voices: IVoiceAttributes[]
  readonly languageCode: string
  setVoiceName(newVoiceName: string): void
  setGender(newGender: string): void
}

const VoiceSettings: React.FC<VoiceSettingsProps> = props => {
  const [country, setCountry] = useState('All')

  const {
    gender,
    voices,
    voiceName,
    languageCode,
  } = props

  useEffect(() => {
    setCountry(getDefaultCountry(voices, gender, languageCode))
  }, [languageCode])

  const renderGender = () => {
    return (
      <Gender
        gender={gender}
        setGender={props.setGender}
      />
    )
  }

  const renderCountry = () => {
    return (
      <Country
        voices={voices}
        country={country}
        setCountry={setCountry}
      />
    )
  }

  const renderVoiceName = () => {
    return (
      <VoiceName
        gender={gender}
        country={country}
        voiceName={voiceName}
        voices={voices}
        setVoiceName={props.setVoiceName}
        languageCode={languageCode}
      />
    )
  }

  return (
    <div className="VoiceSettings__voice-settings">
      {renderCountry()}
      {renderGender()}
      {renderVoiceName()}
    </div>
  )
}

export default VoiceSettings
