import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { IApplicationState } from '@Site/rootReducer'
import UsersPasswordEditForm from '@SiteComponents/UsersPasswordEditForm'
import OnboardingSidebar from '@SiteComponents/OnboardingSidebar'

import {
  PayloadData,
} from './types'

import {
  updatePassword,
} from './actions'

import './UsersPasswordEdit.scss'

interface UsersPasswordEditProps {
  readonly loading: boolean
  updatePassword(payload: PayloadData): void
}

interface UsersPasswordEditState {
}

class UsersPasswordEdit extends React.Component<UsersPasswordEditProps, UsersPasswordEditState> {
  render() {
    const { loading } = this.props

    return (
      <div className="UsersPasswordEdit">
        <OnboardingSidebar
          type="sign-in"
          caption1={<span>Grow your audience with<br />subtitles, dubbing and translation.</span>}
          caption2={<span />}
        />
        <div className="UsersPasswordEdit__form-wrapper">
          <UsersPasswordEditForm
            submit={this.props.updatePassword}
            showSpinner={loading}
            disableButton={loading}
          />
        </div>
      </div>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { userPasswordEdit } = state

  return {
    loading: userPasswordEdit.loading
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    updatePassword
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersPasswordEdit)
