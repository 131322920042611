import axios, { AxiosRequestConfig } from 'axios'
import convertToSnakeCase from '@Utils/ConvertToSnakeCase'

import {
  PayloadData
} from './types'

export function updatePassword(data: PayloadData) {
  const config: AxiosRequestConfig = {
    headers: {
      'X-CSRF-Token': window.Checksub.token,
      'X-Requested-With': 'XMLHttpRequest',
    },
    responseType: 'json'
  }

  const updatedPayload = { ...data.payload, utf8: '✓', commit: 'Update my password' }

  return axios.put('/users/password', convertToSnakeCase(updatedPayload), config)
}
