import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { IApplicationState } from '../../rootReducer'
import filter from 'lodash/filter'
import Header from '@SiteContainers/Header'
import ButtonNewV2 from '@SiteComponents/ButtonNewV2'
import AddUsersToSsoPopup from '@SiteContainers/AddUsersToSsoPopup'
import SsoMembers from '@SiteComponents/SsoMembers'
import Add from '@Images/add_sso.svg'
import Configuration from '@Images/configuration_sso.svg'

import {
  fetchSsoData,
  openAddUsersToSsoPopup,
  deleteMember,
  changeRole,
  changeSubscription,
} from '@SiteModules/Sso/actions'

import {
  openSsoAdminPortal
} from '@SiteModules/User/actions'

import {
  ISsoUser,
  ISsoSubscription,
  IFetchSsoDataPayload,
  IDeleteMemberPayload,
  IChangeRolePayload,
  IChangeSubscriptionPayload,
} from '@SiteModules/Sso/types'

import {
  IOpenSsoAdminPortal
} from '@SiteModules/User/types'

import './SsoManagement.scss'

interface SsoManagementProps {
  readonly ssoLoading: boolean
  readonly userLoading: boolean
  readonly currentUserId: number
  readonly addUsersToSsoPopupOpen: boolean
  readonly ssoOrganizationId: number | null
  readonly ssoUsers: ISsoUser[]
  readonly ssoSubscriptions: ISsoSubscription[]
  fetchSsoData(payload: IFetchSsoDataPayload): void
  openAddUsersToSsoPopup(): void
  changeSubscription(payload: IChangeSubscriptionPayload): void
  deleteMember(payload: IDeleteMemberPayload): void
  changeRole(payload: IChangeRolePayload): void
  openSsoAdminPortal(payload: IOpenSsoAdminPortal): void
}

interface SsoManagementState {
}

const NO_SUBSCRIPTION: ISsoSubscription = {
  id: '-1',
  type: 'ssoSubscription',
  attributes: {
    label: 'None',
    subscriptionId: -1
  }
}

class SsoManagement extends React.Component<SsoManagementProps, SsoManagementState> {
  componentDidMount() {
    if (this.props.ssoOrganizationId) {
      this.props.fetchSsoData({ ssoOrganizationId: this.props.ssoOrganizationId })
    }
  }

  componentDidUpdate(prevProps: SsoManagementProps) {
    if (prevProps.userLoading && !this.props.userLoading && this.props.ssoOrganizationId) {
      this.props.fetchSsoData({ ssoOrganizationId: this.props.ssoOrganizationId })
    }
  }

  openSsoAdminPortal = () => {
    const { ssoOrganizationId } = this.props
    if (!ssoOrganizationId) { return }

    this.props.openSsoAdminPortal({ ssoOrganizationId })
  }

  deleteMember = (userId: number) => {
    const { ssoOrganizationId } = this.props
    if (!ssoOrganizationId) { return }

    this.props.deleteMember({ userId, ssoOrganizationId })
  }

  changeRole = (userId: number, isAdmin: boolean) => {
    const { ssoOrganizationId } = this.props
    if (!ssoOrganizationId) { return }

    this.props.changeRole({ userId, isAdmin, ssoOrganizationId })
  }

  changeSubscription = (userId: number, subscriptionId: number) => {
    const { ssoOrganizationId } = this.props
    if (!ssoOrganizationId) { return }

    this.props.changeSubscription({ userId, subscriptionId, ssoOrganizationId })
  }

  renderAddUsersToSsoPopup = () => {
    return (
      <AddUsersToSsoPopup/>
    )
  }

  renderManageAccess = () => {
    return (
      <div  className="SsoManagement__manage-access">
        <div className="SsoManagement__titles">
          <div className="SsoManagement__title">
            SSO access settings
          </div>
          <div className="SsoManagement__subtitle">
            Configure your Checksub access via SSO
          </div>
        </div>
        {this.renderConfigurationButton()}
      </div>
    )
  }

  renderConfigurationButton = () => {
    return (
      <ButtonNewV2
        type="button"
        styleType="brand-tertiary"
        size="large"
        caption="SSO configuration"
        icon={<Configuration/>}
        onClick={this.openSsoAdminPortal}
        disabled={this.props.userLoading || !this.props.ssoOrganizationId}
        showSpinner={this.props.userLoading}
      />
    )
  }

  renderManageMembers = () => {
    return (
      <div  className="SsoManagement__manage-members">
        <div className="SsoManagement__titles">
          <div className="SsoManagement__title">
            Members
          </div>
          <div className="SsoManagement__subtitle">
            Manage workspace members and their permission {/* correct would be 'SSO members' */}
          </div>
        </div>
        {this.renderAddButton()}
      </div>
    )
  }

  renderAddButton = () => {
    return (
      <ButtonNewV2
        type="button"
        styleType="brand-primary"
        size="large"
        caption="Add users"
        icon={<Add/>}
        onClick={this.props.openAddUsersToSsoPopup}
        disabled={this.props.ssoLoading || this.props.userLoading || !this.props.ssoOrganizationId}
        showSpinner={this.props.ssoLoading || this.props.userLoading}
      />
    )
  }

  renderSsoMembersTable = () => {
    const ssoSubscriptions = this.props.ssoSubscriptions.concat(NO_SUBSCRIPTION)
    const ssoMembers = filter(this.props.ssoUsers, ssoUser => ssoUser.attributes.userId !== this.props.currentUserId)

    return (
      <SsoMembers
        members={ssoMembers}
        ssoSubscriptions={ssoSubscriptions}
        changeSubscription={this.changeSubscription}
        deleteMember={this.deleteMember}
        changeRole={this.changeRole}
      />
    )
  }

  render() {
    const { addUsersToSsoPopupOpen } = this.props

    return (
      <div className="SsoManagement">
        <Header />
        <div className="SsoManagement__body">
          {this.renderManageAccess()}
          {this.renderManageMembers()}
          {this.renderSsoMembersTable()}
        </div>
        {addUsersToSsoPopupOpen && (
          <AddUsersToSsoPopup/>
        )}
      </div>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { sso, user } = state

  return {
    ssoLoading: sso.loading,
    userLoading: user.loading,
    ssoOrganizationId: user.user.attributes.managedSsoOrganizationId,
    ssoUsers: sso.ssoUsers,
    ssoSubscriptions: sso.ssoSubscriptions,
    addUsersToSsoPopupOpen: sso.addUsersToSsoPopupOpen,
    currentUserId: parseInt(user.user.id, 10)
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    fetchSsoData,
    openAddUsersToSsoPopup,
    changeSubscription,
    deleteMember,
    changeRole,
    openSsoAdminPortal,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SsoManagement)
