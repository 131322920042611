import React from 'react'
import IndeterminateCheckbox from '@SiteComponents/IndeterminateCheckbox'

import './LabelsDropdown.scss'

interface LabelsDropdownProps {
  readonly labelIds: number[]
  readonly labelCaptions: string[]
  readonly labelsInAllSelectedProjectVersions: number[]
  readonly labelsInNoSelectedProjectVersion: number[]
  createProjectVersionLabels(labelId: number): void
  deleteProjectVersionLabels(labelId: number): void
  openCreateLabelPopup(): void
}

const LabelsDropdown: React.FC<LabelsDropdownProps> = props => {
  const {
    labelIds,
    labelCaptions,
    labelsInAllSelectedProjectVersions,
    labelsInNoSelectedProjectVersion
  } = props

  const labelsList = labelCaptions.map((caption, index) => {
    const labelId = labelIds[index]

    let checkboxValue = 'indeterminate'
    if (labelsInAllSelectedProjectVersions.includes(labelId)) {
      checkboxValue = 'checked'
    } else if (labelsInNoSelectedProjectVersion.includes(labelId)) {
      checkboxValue = 'unchecked'
    }

    const handleChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.target.checked ? props.createProjectVersionLabels(labelId) : props.deleteProjectVersionLabels(labelId)
    }

    return (
      <li key={labelId} className="LabelsDropdown__list__item">
        <label className="LabelsDropdown__label" htmlFor={`LabelsDropdown__checkbox-${labelId}`}>
          <span className="LabelsDropdown__caption">
            <IndeterminateCheckbox
              id={`LabelsDropdown__checkbox-${labelId}`}
              name="labelsCheckbox"
              value={checkboxValue}
              onChange={handleChangeCheckbox}
            />
            {caption}
          </span>
        </label>
      </li>
    )
  })

  return (
    <div className="LabelsDropdown">
      <div className="LabelsDropdown__list-wrapper">
        <ul className="LabelsDropdown__list">
          {labelsList}
        </ul>
        <div className="LabelsDropdown__createNew" onClick={props.openCreateLabelPopup}> Create new </div>
      </div>
    </div>
  )
}

export default LabelsDropdown
