import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import InfoBadge from '@Images/info_badge.svg'
import formattedCredits from '@Utils/FormattedCredits'
import Button from '@SiteComponents/Button'
import DropdownList from '@checksub_team/react-widgets/lib/DropdownList'

import {
  ICancelSubscriptionPayload,
} from '@SiteModules/Subscription/types'

import '@Root/config/dropdowns.scss'
import './CancelSubscriptionPopup.scss'

interface CancelSubscriptionFormProps {
  readonly secondsAvailable: number
  readonly plan: string
  closePopup(): void
  cancelSubscription(payload: ICancelSubscriptionPayload): void
}

type TFormData = {
  cancellationReason: string
}

const REASONS = [
  'I\'m done with my subtitles project(s)',
  'The platform is not adapted to my needs',
  'I don\'t have any money left in my bank account',
  'Other'
]

const CancelSubscriptionForm: React.FC<CancelSubscriptionFormProps> = props => {
  const [step, setStep] = useState(1)
  const [cancellationReason, setCancellationReason] = useState('')
  const { register, handleSubmit, errors } = useForm<TFormData>()
  const isYearlySubscription = props.plan.includes('yearly')
  const timeToKeepCredits = isYearlySubscription ? '2 years' : '6 months'

  const onCancel = () => {
    const payload = { cancellationReason }
    props.cancelSubscription(payload)
    props.closePopup()
  }

  const onContinue = handleSubmit((formData: TFormData) => {
    setCancellationReason(formData.cancellationReason)
    setStep(2)
  })

  const renderStep1 = () => {
    return (
      <form className="CancelSubscriptionPopup-form" onSubmit={onContinue}>
        <div className="CancelSubscriptionPopup__header">
          <div className="CancelSubscriptionPopup__header-info-patch"/>
          <InfoBadge className="CancelSubscriptionPopup__header-info-badge" />
          Why would you like to cancel your plan?
        </div>
        <div className="CancelSubscriptionPopup__formfield">
          <DropdownList
            data={REASONS}
            id="cancellationReason"
            name="cancellationReason"
            customref={register({ required: true })}
            placeholder="Select a reason"
          />
          {errors.cancellationReason && (
            <span className="errorMessage">This field is required</span>
          )}
        </div>
        <div className="CancelSubscriptionPopup__info">
          When you cancel your plan, the cancellation will be effective at the end of the current billing period (which has already been paid). <br />
          After this date, you will not be able to access your dashboard anymore.
        </div>
        <div className="CancelSubscriptionPopup__button">
          <Button
            type="submit"
            color="primary-dark"
            caption="Continue"
            showSpinner={false}
            disabled={false}
          />
        </div>
      </form>
    )
  }

  const renderStep2 = () => {
    return (
      <div className="CancelSubscriptionPopup-form">
        <div className="CancelSubscriptionPopup__header">
          <div className="CancelSubscriptionPopup__header-info-patch"/>
          <InfoBadge className="CancelSubscriptionPopup__header-info-badge" />
          You have {formattedCredits(props.secondsAvailable)} left
        </div>
        <div className="CancelSubscriptionPopup__subheader">
          How to keep them?
        </div>
        <div className="CancelSubscriptionPopup__info">
          Checksub allows you to keep your unused credits for {timeToKeepCredits} as long as you have an active subscription.
          <br />
          Your plan currently allows you to keep your credits.
        </div>
        <div className="CancelSubscriptionPopup__buttons">
          <Button
            type="button"
            color="primary-dark"
            caption="Keep my credits with my current plan"
            onClick={props.closePopup}
          />
          <button
            className="CancelSubscriptionPopup__buttons-link-like-button"
            onClick={onCancel}
          >
            Unsubscribe and lose my {formattedCredits(props.secondsAvailable)} left
          </button>
        </div>
      </div>
    )
  }

  const isFirstStep = step === 1
  const isSecondStep = step === 2

  return (
    <React.Fragment>
      {isFirstStep && renderStep1()}
      {isSecondStep && renderStep2()}
    </React.Fragment>
  )
}

export default CancelSubscriptionForm
