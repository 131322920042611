import {
  PayloadData,
  IBillingAddress,
  CheckoutsBillingAddressActionTypes,
  ICheckoutsBillingAddressActionTypes
} from './types'

export const submitBillingAddress = (payload: PayloadData) => ({
  type: CheckoutsBillingAddressActionTypes.SUBMIT_BILLING_ADDRESS,
  payload,
})

export const fetchBillingAddress = (): ICheckoutsBillingAddressActionTypes => ({
  type: CheckoutsBillingAddressActionTypes.FETCH_BILLING_ADDRESS,
})

export const fetchOrSubmitBillingAddressFailure = (): ICheckoutsBillingAddressActionTypes  => ({
  type: CheckoutsBillingAddressActionTypes.FETCH_OR_SUBMIT_BILLING_ADDRESS_FAILURE,
})

export const fetchOrSubmitBillingAddressSuccess = (billingAddress: IBillingAddress): ICheckoutsBillingAddressActionTypes => ({
  type: CheckoutsBillingAddressActionTypes.FETCH_OR_SUBMIT_BILLING_ADDRESS_SUCCESS,
  billingAddress,
})
