import React, { useState, useEffect } from 'react'
import { Loadable } from '@Root/types'
import indexOf from 'lodash/indexOf'

import MainSettings from './MainSettings'
import DefaultVoiceOptions from './DefaultVoiceOptions'
import Costs from './Costs'
import ConfirmationButton from './ConfirmationButton'

import {
  IVoiceAttributes,
} from '@EditorContainers/Settings/types'

import {
  ISetupTtsPayload,
} from '@SitePages/ProjectVersionSettingsTts/types'

import Return from '@Images/return_from_pv_settings.svg'

import '@checksub_team/react-widgets/dist/css/react-widgets.css'
import '@Root/config/dropdowns.scss'
import './ProjectVersionSettingsFormTts.scss'

const CHARACTERS_PER_SECOND_TTS_CHARGING = 3
const REGEX_BLOCK_SPLITTING_CHARACTERS = /[.!?？！؟。।]/g

interface ProjectVersionSettingsFormTtsProps extends Loadable {
  readonly voices: IVoiceAttributes[]
  readonly hasLoaded: boolean
  readonly dubbingLanguages: string[]
  readonly dubbingLanguagesCodes: string[]
  readonly lastUsedTtsProjectVersionLanguage?: string
  readonly subscriptionAvailableSubtitlingTime: number
  readonly subscriptionIsCurrent: boolean
  setupTts(payload: ISetupTtsPayload): void
}

const DEFAULT_GENDER = 'male'

const ProjectVersionSettingsFormTts: React.FC<ProjectVersionSettingsFormTtsProps> = props => {
  const {
    voices,
    hasLoaded,
    dubbingLanguages,
    dubbingLanguagesCodes,
    subscriptionIsCurrent,
    lastUsedTtsProjectVersionLanguage,
    subscriptionAvailableSubtitlingTime,
  } = props

  const [projectVersionLanguage, setProjectVersionLanguage] = useState('')
  const [shouldUseScript, setShouldUseScript] = useState(true)
  const [scriptText, setScriptText] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [gender, setGender] = useState(DEFAULT_GENDER)
  const [voiceName, setVoiceName] = useState('')
  const [languageCode, setLanguageCode] = useState('')

  const scriptTextEmpty = scriptText.length === 0
  const projectVersionLanguageNotSelected = projectVersionLanguage.length === 0
  const hasVoices = voices.length > 0
  const matches = scriptText.match(REGEX_BLOCK_SPLITTING_CHARACTERS)
  const approximateNumberOfBlocks = matches ? matches.length : 0
  const totalCost = (scriptText.length - approximateNumberOfBlocks + 1) / CHARACTERS_PER_SECOND_TTS_CHARGING // Simplified formula: The number of characters used here might be slightly higher (never lower) than the ones actually charged in the backend
  const insufficientCredits = subscriptionAvailableSubtitlingTime < totalCost
  const shouldHideCosts = !shouldUseScript || scriptTextEmpty || insufficientCredits || !subscriptionIsCurrent

  useEffect(() => {
    if (!hasLoaded) { return }

    if (lastUsedTtsProjectVersionLanguage) {
      setProjectVersionLanguage(lastUsedTtsProjectVersionLanguage)
    }
  }, [hasLoaded])

  useEffect(() => {
    if (!projectVersionLanguage) { return }

    const newLanguageIndex = indexOf(dubbingLanguages, projectVersionLanguage)
    const newLanguageCode = dubbingLanguagesCodes[newLanguageIndex]
    setLanguageCode(newLanguageCode)
  }, [projectVersionLanguage])

  const onSubmit = () => {
    let payload: ISetupTtsPayload = { projectVersionLanguage, voiceName }
    if (shouldUseScript) {
      payload = { ...payload, scriptText }
    }

    props.setupTts(payload)
    setIsSubmitted(true)
  }

  const goToProjectTypeSelection = () => {
    window.location.href = '/project_type_selection'
  }

  const handleShouldUseScript = (newShouldUseScript: boolean) => {
    if (newShouldUseScript) {
      setScriptText('')
    }
    setShouldUseScript(newShouldUseScript)
  }

  const renderMainSettings = () => {
    return (
      <MainSettings
        projectVersionLanguage={projectVersionLanguage}
        dubbingLanguages={dubbingLanguages}
        hasLoaded={hasLoaded}
        changeProjectVersionLanguage={setProjectVersionLanguage}
        setScriptText={setScriptText}
        shouldUseScript={shouldUseScript}
        setShouldUseScript={handleShouldUseScript}
      />
    )
  }

  const renderVoiceSettings = () => {
    return (
      <React.Fragment>
        <div className="ProjectVersionSettingsFormTts__title-voice-settings">
          Voice settings
        </div>
        {hasVoices && (
          <DefaultVoiceOptions
            voices={voices}
            gender={gender}
            voiceName={voiceName}
            disabled={projectVersionLanguageNotSelected}
            setGender={setGender}
            setVoiceName={setVoiceName}
            languageCode={languageCode}
          />
        )}
      </React.Fragment>
    )
  }

  const renderCosts = () => {
    return (
      <Costs
        totalCost={totalCost}
        shouldHideCosts={shouldHideCosts}
      />
    )
  }

  const renderConfirmationButton = () => {
    const {
      showSpinner,
      disableButton,
    } = props

    const disableConfirmationButton =
      disableButton ||
      projectVersionLanguageNotSelected ||
      (scriptTextEmpty && shouldUseScript) ||
      isSubmitted

    return (
      <ConfirmationButton
        showSpinner={showSpinner}
        disableButton={disableConfirmationButton}
        confirm={onSubmit}
      />
    )
  }

  const renderBackButton = () => {
    return (
      <div
        className="ProjectVersionSettingsFormTts__return"
        onClick= {goToProjectTypeSelection}
      >
        <Return />
      </div>
    )
  }

  return (
    <React.Fragment>
      {renderBackButton()}
      <form className="ProjectVersionSettingsFormTts">
        <div className="ProjectVersionSettingsFormTts__left">
          <div className="ProjectVersionSettingsFormTts__left-wrapper">
            <div className="ProjectVersionSettingsFormTts__left-top">
              <div className="ProjectVersionSettingsFormTts__title">
                Fine-tune your TTS project
              </div>
              {renderMainSettings()}
            </div>
            <div className="ProjectVersionSettingsFormTts__left-bottom">
              {renderConfirmationButton()}
              {renderCosts()}
            </div>
          </div>
        </div>
        <div className="ProjectVersionSettingsFormTts__right">
          {renderVoiceSettings()}
        </div>
      </form>
    </React.Fragment>
  )
}

export default ProjectVersionSettingsFormTts
