import {
  IDeleteLabelPayload,
  DeleteLabelActionTypes,
  TDeleteLabelActionTypes,
} from './types'

export const deleteLabel = (payload: IDeleteLabelPayload): TDeleteLabelActionTypes => ({
  type: DeleteLabelActionTypes.DELETE_LABEL,
  payload,
})

export const deleteLabelFailure = (): TDeleteLabelActionTypes => ({
  type: DeleteLabelActionTypes.DELETE_LABEL_FAILURE,
})

export const deleteLabelSuccess = (): TDeleteLabelActionTypes => ({
  type: DeleteLabelActionTypes.DELETE_LABEL_SUCCESS
})
