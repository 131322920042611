import {
  PayloadData,
  UserPasswordEditActionTypes,
  TPasswordEditActionTypes
} from './types'

export const updatePassword = (payload: PayloadData): TPasswordEditActionTypes => ({
  type: UserPasswordEditActionTypes.UPDATE_PASSWORD,
  payload
})

export const updatePasswordSuccess = (): TPasswordEditActionTypes => ({
  type: UserPasswordEditActionTypes.UPDATE_PASSWORD_SUCCESS
})

export const updatePasswordFailure = (): TPasswordEditActionTypes => ({
  type: UserPasswordEditActionTypes.UPDATE_PASSWORD_FAILURE, 
})
