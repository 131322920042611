import { Action } from 'redux'
import { put, takeEvery } from 'redux-saga/effects'
import * as Eff from 'redux-saga/effects' // tslint:disable-line:no-duplicate-imports
import toastr from 'toastr'
import axios from 'axios'

import Logger from '@Utils/Logger'
import history from '@Root/historyInstance'

import {
  confirmationNewFailure,
  confirmationNewSuccess
} from './actions'

import {
  ConfirmationNewFormActionTypes
} from './types'

import * as API from './api'

interface IAction extends Action {
  readonly payload: object
}

const call: any = Eff.call

function* confirmationNewUserSaga(action: IAction) {
  try {
    yield call(API.confirmationNewUser, { payload: action.payload })
    yield put(confirmationNewSuccess())
    yield call(history.replace, '/')
    toastr.success('', 'Confirmation request resent')
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.data.message === 'You already confirmed this email. Please log in.') {
        yield call(history.replace, '/')
        toastr.info('', e.response.data.message)
      }
    } else {
      Logger.error(e)
    }

    yield put(confirmationNewFailure())
  }
}

function* confirmationNewFormSagas() {
  yield takeEvery(ConfirmationNewFormActionTypes.CONFIRMATION_NEW, confirmationNewUserSaga)
}

export default confirmationNewFormSagas
