export const PasswordNewFormActionTypes = {
  PASSWORD_NEW: '@@passwordNewForm/PASSWORD_NEW',
  PASSWORD_NEW_FAILURE: '@@passwordNewForm/PASSWORD_NEW_FAILURE',
  PASSWORD_NEW_SUCCESS: '@@passwordNewForm/PASSWORD_NEW_SUCCESS'
}

export interface IUserPasswordNewState {
  readonly loading: boolean
}

export interface PayloadData {
  readonly payload: {
    readonly utf8: string
    readonly commit: string
    readonly user: {
      readonly email: string
    }
  }
}

export interface IPasswordNewAction {
  readonly type: string
  readonly payload: PayloadData
}

export interface IPasswordNewFailureAction {
  readonly type: string
}

export interface IPasswordNewSuccessAction {
  readonly type: string
}

export type TPasswordNewActionTypes = IPasswordNewAction | IPasswordNewFailureAction | IPasswordNewSuccessAction
