import React, { useEffect } from 'react'
import uniq from 'lodash/uniq'
import DropdownList from '@checksub_team/react-widgets/lib/DropdownList'
import classnames from 'classnames'

import OnOffSwitch from './OnOffSwitch'
import ScriptImport from './ScriptImport'

import './MainSettings.scss'

interface MainSettingsProps {
  readonly projectVersionLanguage: string
  readonly dubbingLanguages: string[]
  readonly hasLoaded: boolean
  readonly shouldUseScript: boolean
  changeProjectVersionLanguage(newProjectVersionLanguage: string): void
  setScriptText(newScriptText: string): void
  setShouldUseScript(newShouldUseScript: boolean): void
}

const MainSettings: React.FC<MainSettingsProps> = props => {
  const {
    projectVersionLanguage,
    dubbingLanguages,
    changeProjectVersionLanguage,
    hasLoaded,
    shouldUseScript,
  } = props

  const TOP_LANGUAGES = [
    'Arabic',
    'Chinese, Mandarin (Simplified, China)',
    'English',
    'French',
    'French (Canada)',
    'German',
    'Hindi',
    'Italian',
    'Japanese',
    'Portuguese',
    'Russian',
    'Spanish'
  ]

  const originalLanguages = dubbingLanguages
  const originalLanguagesWithTopLanguagesFirst = uniq(TOP_LANGUAGES.concat(originalLanguages))
  const originalLanguageGrouping = (language: string) => TOP_LANGUAGES.includes(language)

  const languageGroupHeading = ({ item }: {item: boolean}) => (
    item ? <span>Top Languages</span> : <span>All Languages</span>
  )

  const fillInPlaceholderProjectVersionLanguage = () => {
    const searchProjectVersionLanguage = document.querySelectorAll('.MainSettings__formfield-projectVersionLanguage .rw-input-reset')[0] as HTMLInputElement

    if (searchProjectVersionLanguage) {
      searchProjectVersionLanguage.placeholder = 'Set language...'
    }
  }

  useEffect(() => {
    const inputFieldProjectVersionLanguage = document.querySelectorAll('.MainSettings__formfield-projectVersionLanguage .rw-widget-input.rw-widget-picker.rw-widget-container')[0] as HTMLElement
    if (!!projectVersionLanguage) {
      inputFieldProjectVersionLanguage.style.backgroundColor = '#7318FD'
      inputFieldProjectVersionLanguage.style.border = 'none'
    }
  }, [projectVersionLanguage])

  const hasNoDropdown = !hasLoaded

  const onSearch = () => {
    setTimeout(() => {
      const emptyList = document.getElementsByClassName('rw-list-empty')

      if (emptyList && emptyList.length > 0) {
        emptyList[0].innerHTML = 'Not available'
      }
    }, 0)
  }

  const renderProjectVersionLanguageDropDown = () => {
    return(
      <div className={classnames('ProjectVersionSettingsFormTts__formfield MainSettings__formfield-projectVersionLanguage', {'noDropdown': hasNoDropdown})} onClick={fillInPlaceholderProjectVersionLanguage}>
        <label className="ProjectVersionSettingsFormTts__label" htmlFor="projectVersionLanguage">
          Script language:
        </label>

        <DropdownList
          data={originalLanguagesWithTopLanguagesFirst}
          id="projectVersionLanguage"
          name="projectVersionLanguage"
          value={projectVersionLanguage}
          onChange={changeProjectVersionLanguage}
          filter="startsWith"
          groupComponent={languageGroupHeading}
          groupBy={originalLanguageGrouping}
          placeholder="Select the script language..."
          onSearch={onSearch}
        />
      </div>
    )
  }

  const renderOnOffSwitchForScriptImport = () => {
    return (
      <OnOffSwitch
        label="Import a script"
        checked={shouldUseScript}
        setChecked={props.setShouldUseScript}
      />
    )
  }

  const renderScriptImport = () => {
    return (
      <ScriptImport
        setScriptText={props.setScriptText}
      />
    )
  }

  return (
    <React.Fragment>
      {renderProjectVersionLanguageDropDown()}
      {renderOnOffSwitchForScriptImport()}
      {shouldUseScript && renderScriptImport()}
    </React.Fragment>
  )
}

export default MainSettings
