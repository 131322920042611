import { IAction } from '@Root/types'

import {
  IUserInfo,
  IUserState,
  TUserActions,
  IUserStripeInfo,
  IUserStripe,
  UserActionTypes,
} from './types'

import {
  SurveyActionTypes,
} from '@SitePages/Onboarding/Survey/types'

import {
  ISubscription,
  SubscriptionsActionTypes,
} from '@SiteModules/Subscription/types'

import {
  IGetExportingSubtitleIdsSuccessPayload
} from '@EditorModules/User/types'

export const initialState: IUserState = {
  user: {
    id: '',
    type: 'user',
    attributes: {
      name: '',
      email:  '',
      lastName:  '',
      country: '',
      role: '',
      onboardingState: '',
      numberOfSubtitlesCreated: 0,
      maxFileSize:  null,
      balance: 0,
      confirmedEmail: true,
      isFromOutsideEu: false,
      remainingElementStorageSpace: 0,
      maxElementStorageSpace: 0,
      usedElementStorageSpace: 0,
      onboardingPath: null,
      hasActiveElements: false,
      canGetTrial: false,
      isNewUser: false,
      usingPersonalSubscription: false,
      managedSsoOrganizationId: null,
      relevantAlert: null,
      exportingSubtitleIds: [],
    },
  },
  subscription: {
    id: '',
    type: 'subscription',
    attributes: {
      plan: '',
      maxLanguagesLimit: 0,
      secondsAvailable: 0,
      minutesProvided: 0,
      minutesProvidedByPeriod: 0,
      isCurrent: false,
      isCanceled: false,
      isTrialing: false,
      cancelAtPeriodEnd: false,
      hasCreditLikeCharging: false,
      renewalDate: '',
      allowsVoice: false,
      allowsVoiceCloning: false,
      allowsTranslationAdaptionForDubbing: false,
      allowsLipSyncing: false,
    },
    price: {
      id: '',
      type: 'price',
      attributes: {
        amount: 0,
        vat: 0,
        shouldApplyVat: false,
        currency: 'EUR',
      }
    }
  },
  credits: [],
  loading: false,
  hasLoadedStripeInfoSuccessfully: false,
}

export const userReducer = (
  state: IUserState = initialState,
  action: TUserActions,
): IUserState => {
  switch (action.type) {
    case UserActionTypes.GET_INFO: return loadUserInfo(state)
    case UserActionTypes.GET_INFO_FAILURE: return loadUserInfoFailure(state)
    case UserActionTypes.GET_INFO_SUCCESS: return loadUserInfoSuccess(state, action)

    case UserActionTypes.GET_STRIPE_INFO: return loadUserStripeInfo(state)
    case UserActionTypes.GET_STRIPE_INFO_SUCCESS: return loadUserStripeInfoSuccess(state, action)

    case UserActionTypes.SET_LAST_VIEWED_ALERT: return removeRelevantAlert(state)

    case UserActionTypes.GET_EXPORTING_SUBTITLE_IDS_SUCCESS: return getExportingSubtitleIdsSuccess(state, action)

    case SubscriptionsActionTypes.UPDATE_SUBSCRIPTION_SUCCESS: return updateOrCancelSubscriptionSuccess(state, action)
    case SubscriptionsActionTypes.CANCEL_SUBSCRIPTION_SUCCESS: return updateOrCancelSubscriptionSuccess(state, action)

    case SurveyActionTypes.SUBMIT_SURVEY: return finalizingSurvey(state)
    case SurveyActionTypes.SUBMIT_SURVEY_SUCCESS: return submitSurveySuccess(state)

    default: return state
  }
}

const finalizingSurvey = (state: IUserState): IUserState => {
  return { ...state, loading: true }
}

const submitSurveySuccess = (state: IUserState): IUserState => {
  const attributes = { ...state.user.attributes, onboardingState: 'processed' }
  const updatedUser = { ...state.user, attributes }

  return { ...state, user: updatedUser, loading: false }
}

const loadUserInfo = (state: IUserState): IUserState => {
  return { ...state, loading: true }
}

const loadUserInfoFailure = (state: IUserState): IUserState => {
  return { ...state, loading: false }
}

export function isLoadUserInfoSuccessAction(arg: any): arg is Required<IAction<IUserInfo>> {
  return arg.payload && arg.payload.user && arg.payload.subscription && arg.payload.credits
}

const loadUserInfoSuccess = (state: IUserState, action: TUserActions): IUserState => {
  let { user, subscription, credits } = state
  let updatedUser = user

  if (isLoadUserInfoSuccessAction(action)) {
    user = action.payload.user.data

    const country = user.attributes.country || ''
    const updatedAttributes = { ...user.attributes, country }
    updatedUser = { ...updatedUser, attributes: updatedAttributes, id: user.id }

    if (action.payload.subscription.data) {
      subscription = action.payload.subscription.data
    }

    if (action.payload.credits.data) {
      credits = action.payload.credits.data
    }
  }

  return { loading: false, user: updatedUser, subscription, credits, hasLoadedStripeInfoSuccessfully: state.hasLoadedStripeInfoSuccessfully, stripe: state.stripe }
}

const loadUserStripeInfo = (state: IUserState): IUserState => {
  return { ...state, hasLoadedStripeInfoSuccessfully: false }
}

export function isLoadUserStripeInfoSuccessAction(arg: any): arg is Required<IAction<IUserStripeInfo>> {
  return arg.payload && arg.payload.data && arg.payload.data.type === 'userStripe'
}

const loadUserStripeInfoSuccess = (state: IUserState, action: TUserActions): IUserState => {
  let stripe: IUserStripe | undefined

  if (isLoadUserStripeInfoSuccessAction(action)) {
    stripe = action.payload.data
  }

  return { ...state, hasLoadedStripeInfoSuccessfully: true, stripe }
}

function isUpdateOrCancelSubscriptionSuccessAction(arg: any): arg is Required<IAction<ISubscription>> {
  return arg.payload && arg.payload.type === 'subscription'
}

const updateOrCancelSubscriptionSuccess = (state: IUserState, action: TUserActions): IUserState => {
  let { subscription } = state

  if (isUpdateOrCancelSubscriptionSuccessAction(action)) {
    subscription = action.payload
  }

  return { ...state, subscription }
}

const removeRelevantAlert = (state: IUserState): IUserState => {
  const updatedUser = { ...state.user, attributes: { ...state.user.attributes, relevantAlert: null } }
  return { ...state, user: updatedUser }
}

function isGetExportingSubtitleIdsSuccessAction(arg: any): arg is Required<IAction<IGetExportingSubtitleIdsSuccessPayload>> {
  return arg.payload && arg.payload.data.exportingSubtitleIds
}

const getExportingSubtitleIdsSuccess =  (state: IUserState, action: TUserActions): IUserState => {
  let { exportingSubtitleIds } = state.user.attributes

  if (isGetExportingSubtitleIdsSuccessAction(action)) {
    exportingSubtitleIds = action.payload.data.exportingSubtitleIds
  }

  const updatedUser = { ...state.user, attributes: { ...state.user.attributes, exportingSubtitleIds } }

  return { ...state, user: updatedUser }
}
