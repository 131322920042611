import React from 'react'
import ButtonNewV2 from '@SiteComponents/ButtonNewV2'

import './ConfirmationButton.scss'

interface ConfirmationButtonProps {
  readonly showSpinner: boolean
  readonly disableButton: boolean
  confirm(): void
}

const ConfirmationButton: React.FC<ConfirmationButtonProps> = props => {
  const {
    showSpinner,
    disableButton,
  } = props

  return (
    <div className="ConfirmationButton">
      <ButtonNewV2
        type="button"
        styleType="brand-primary"
        size="large"
        caption="Confirm"
        showSpinner={showSpinner}
        disabled={disableButton}
        onClick={props.confirm}
      />
    </div>
  )
}

export default ConfirmationButton
