import React from 'react'
import ButtonNew from '@SiteComponents/ButtonNew'
import subscriptionPriceCalculatorPerPeriod from '@Utils/SubscriptionPriceCalculatorPerPeriod'
import ReverseCheckmark from '@Images/reverse_checkmark.svg'

import './Offer.scss'

const MONTHS_IN_YEAR = 12

interface OfferProps {
  readonly planName: string
  readonly isMonthlyPeriod: boolean
  readonly isPriceInUsd: boolean
  readonly hasLoadedSuccessfully: boolean
  readonly canGetTrial: boolean
  readonly description: string
  readonly features: (JSX.Element | string)[]
  readonly currentPlan: string
  readonly currentPlanVersion: string
  readonly subscriptionIsCurrent: boolean
  readonly subscriptionIsTrialing: boolean
  readonly cancelAtPeriodEnd: boolean
  readonly renewalDate: string
  handleBuyOrUpdate(newPlanName: string): void
  handleBuy(newPlanName: string): void
  handleCancel(): void
}

const Offer: React.FC<OfferProps> = props => {
  const {
    planName,
    isPriceInUsd,
    isMonthlyPeriod,
    hasLoadedSuccessfully,
    description,
    canGetTrial,
    features,
    currentPlan,
    currentPlanVersion,
    subscriptionIsCurrent,
    subscriptionIsTrialing,
    cancelAtPeriodEnd,
    renewalDate,
  } = props

  const isLegacyPlan = planName === 'legacy plan'
  const shouldShowButton = !(cancelAtPeriodEnd && planName === 'legacy plan')

  const isCurrentPlan = subscriptionIsCurrent && (isLegacyPlan || `${planName}_${isMonthlyPeriod ? 'monthly' : 'yearly'}_${currentPlanVersion}` === currentPlan)
  const canCancel = isCurrentPlan && !cancelAtPeriodEnd
  const canUnlock = isCurrentPlan && !cancelAtPeriodEnd && subscriptionIsTrialing && planName !== 'legacy plan'

  const buttonSubtext = `${cancelAtPeriodEnd ? 'Will be canceled on' : 'Will be renewed on'} ${renewalDate}`

  const higherPlanSelected =
    !currentPlan.includes('enterprise') && (
      currentPlan.includes('junior') && (planName === 'starter' || planName === 'studio') ||
      currentPlan.includes('starter') && planName === 'studio' ||
      currentPlan.includes(planName) && currentPlan.includes('monthly') && !isMonthlyPeriod
    )

  const lowerPlanSelected =
    currentPlan.includes('enterprise') ||
    currentPlan.includes('studio') && (planName === 'starter' || planName === 'junior') ||
    currentPlan.includes('starter') && planName === 'junior' ||
    currentPlan.includes(planName) && currentPlan.includes('yearly') && isMonthlyPeriod

  const samePlanButOtherPlanVersionSelected = currentPlan.includes(`${planName}_${isMonthlyPeriod ? 'monthly' : 'yearly'}`) && !currentPlan.includes(currentPlanVersion)

  const canUpgrade = subscriptionIsCurrent && !cancelAtPeriodEnd && !isCurrentPlan && (higherPlanSelected || samePlanButOtherPlanVersionSelected)
  const canDowngrade = subscriptionIsCurrent && !cancelAtPeriodEnd && !isCurrentPlan && lowerPlanSelected

  const priceSubtext = `Per month ${isMonthlyPeriod ? '' : ' (billed annually)'}`

  const costPerPeriod = subscriptionPriceCalculatorPerPeriod(planName, isMonthlyPeriod, isPriceInUsd)
  const costPerMonth = isMonthlyPeriod ? costPerPeriod : costPerPeriod / MONTHS_IN_YEAR

  const currency = isPriceInUsd ? '$' : '€'
  let caption = canGetTrial ? 'Start your free 14-day trial' : 'Subscribe'
  if (canCancel) {
    caption = subscriptionIsTrialing ? 'Stop my trial' : 'Stop my subscription'
  } else if (canUpgrade) {
    caption = 'Upgrade my plan'
  } else if (canDowngrade) {
    caption = 'Downgrade my plan'
  }

  const handleBuyOrUpdate = () => {
    props.handleBuyOrUpdate(planName)
  }

  const handleBuy = () => {
    props.handleBuy(planName)
  }

  return (
    <div className="Offer">
      <div className="Offer__name">
        {isCurrentPlan && <span className="Offer__name-active">ACTIVE</span>}
        <span>{planName.toUpperCase()}</span>
      </div>
      <div className="Offer__description">{description}</div>
      <hr />
      {!isNaN(costPerPeriod) && (
        <React.Fragment>
          <div className="Offer__price">{`${currency}${costPerMonth}`}</div>
          <div className="Offer__price-description">{priceSubtext}</div>
          <hr />
        </React.Fragment>
      )}
      <div className="Offer__features">
        {features.map((feature, index) => {
          return (
            <div key={index} className="Offer__feature">
              <ReverseCheckmark />
              {feature}
            </div>
          )
        })}
      </div>
      <div className="Offer__button-container">
        {canUnlock && (
          <ButtonNew
            color="primary-small"
            caption="Pay & unlock the full credits now"
            onClick={handleBuy}
            showSpinner={!hasLoadedSuccessfully}
            disabled={!hasLoadedSuccessfully}
          />
        )}
        {shouldShowButton && (
          <ButtonNew
            color={canCancel ? 'danger-small2' : 'primary-small'}
            caption={caption}
            onClick={canCancel ? props.handleCancel : handleBuyOrUpdate}
            showSpinner={!hasLoadedSuccessfully}
            disabled={!hasLoadedSuccessfully}
          />
        )}
        <div className="Offer__button-subtext">
          {isCurrentPlan ? buttonSubtext : null}
        </div>
      </div>
    </div>
  )
}

export default Offer
