import React from 'react'
import Button from '@SiteComponents/Button'
import Popup from '@SiteComponents/Popup'
import AttentionIcon from '@Images/attention.svg'

import './DeleteVocabularyPopup.scss'

interface DeleteVocabularyPopupProps  {
  readonly loading: boolean
  closePopup(): void
  deleteVocabulary(): void
}

const DeleteVocabularyPopup: React.FC<DeleteVocabularyPopupProps> = props => {
  const shouldDisableButton = props.loading

  return(
    <Popup
      className="DeleteVocabularyPopup"
      closePopup={props.closePopup}
    >
      <h2 className="DeleteVocabularyPopup__title">Delete a vocabulary list</h2>
      <p className="DeleteVocabularyPopup__description">
        <AttentionIcon className="DeleteVocabularyPopup__description__icon" />
          Attention, you will delete the vocabulary list. <br />
          It cannot be restored.
      </p>
      <Button
        type="button"
        color="primary-dark"
        caption="Confirm the action"
        showSpinner={props.loading}
        disabled={shouldDisableButton}
        onClick={props.deleteVocabulary}
      />
    </Popup>
  )
}

export default DeleteVocabularyPopup
