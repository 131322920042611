import axios, { AxiosRequestConfig } from 'axios'

export function createProject() {
  const config: AxiosRequestConfig = {
    headers: {
      'X-CSRF-Token': window.Checksub.token
    },
    responseType: 'json'
  }

  return axios.post(`/api/v1/projects/`, null, config)
}
