import {
  ILabel,
  ILabelState,
  ILabelActionTypes,
  LabelActionTypes,
  IFetchLabelsSuccessAction,
  IChangeSelectedLabelsAction,
} from './types'

export const initialState: ILabelState = {
  labels: [],
  selectedLabels: [],
  createLabel: {
    loading: false
  },
  fetchLabels: {
    loading: false
  }
}

export const labelsReducer = (
  state: ILabelState = initialState,
  action: ILabelActionTypes
): ILabelState => {
  switch (action.type) {
    case LabelActionTypes.FETCH_LABELS: return fetchLabels(state)
    case LabelActionTypes.FETCH_LABELS_SUCCESS: return fetchLabelsSuccess(state, action)
    case LabelActionTypes.FETCH_LABELS_FAILURE: return labelsFailure(state)
    case LabelActionTypes.CREATE_LABEL: return createLabel(state)
    case LabelActionTypes.CREATE_LABEL_SUCCESS: return createLabelSuccess(state)
    case LabelActionTypes.CREATE_LABEL_FAILURE: return labelsFailure(state)
    case LabelActionTypes.CHANGE_SELECTED_LABELS: return changeSelectedLabels(state, action)
    case LabelActionTypes.RESET_LABEL_SELECTION: return resetLabelSelection(state)
    case LabelActionTypes.CREATE_PROJECT_VERSION_LABELS_FAILURE: return labelsFailure(state)
    case LabelActionTypes.DELETE_PROJECT_VERSION_LABELS_FAILURE: return labelsFailure(state)
    default: return state
  }
}

const fetchLabels = (state: ILabelState): ILabelState => {
  return { ...state, fetchLabels: { loading: true } }
}

function isIFetchLabelsSuccessAction(arg: any): arg is IFetchLabelsSuccessAction {
  return arg.payload && arg.payload.data != null
}

const fetchLabelsSuccess = (state: ILabelState, action: ILabelActionTypes): ILabelState => {
  let labels: ILabel[] = []

  if (isIFetchLabelsSuccessAction(action)) {
    labels = action.payload.data
  }

  return { ...state, labels, fetchLabels: { loading: false } }
}

const createLabel = (state: ILabelState): ILabelState => {
  return { ...state, createLabel: { loading: true } }
}

const createLabelSuccess = (state: ILabelState): ILabelState => {
  return { ...state, createLabel: { loading: false } }
}

const labelsFailure = (state: ILabelState): ILabelState => {
  return { ...state, createLabel: { loading: false }  }
}

function isIChangeSelectedLabels(arg: any): arg is IChangeSelectedLabelsAction {
  return arg.payload && arg.payload.labelId != null
}

const changeSelectedLabels = (state:  ILabelState, action: ILabelActionTypes):  ILabelState => {
  let selectedLabels: number[] = []

  if (isIChangeSelectedLabels(action)){
    const labelId: number = action.payload.labelId

    selectedLabels = [labelId]
  }

  return { ...state, selectedLabels }
}

const resetLabelSelection = (state:  ILabelState): ILabelState => {
  const selectedLabels: number[] = []

  return { ...state, selectedLabels }
}
