import React from 'react'
import { Link } from 'react-router-dom'

import './DashboardLinks.scss'

const ENTERPRISE_OR_CUSTOM_SUBSCRIPTION_PLAN_NAME_PARTS = ['enterprise', 'custom']

interface DashboardLinksProps {
  readonly subscriptionIsCurrent: boolean
  readonly subscriptionPlan: string
  readonly usingPersonalSubscription: boolean
}

const DashboardLinks: React.FC<DashboardLinksProps> = props => {
  const {
    subscriptionIsCurrent,
    subscriptionPlan,
    usingPersonalSubscription,
  } = props

  const hasEnterpriseOrCustomSubscription = ENTERPRISE_OR_CUSTOM_SUBSCRIPTION_PLAN_NAME_PARTS.includes(subscriptionPlan.split('_')[0])
  const shouldRenderSubscriptionManagement = usingPersonalSubscription && (!hasEnterpriseOrCustomSubscription || !subscriptionIsCurrent)

  return (
    <div className="DashboardLinks">
      <ul className="DashboardLinks__list">
        <li className="DashboardLinks__list-item">
          <Link to="/vocabularies">Custom vocabularies</Link>
        </li>
        {shouldRenderSubscriptionManagement && (
          <li className="DashboardLinks__list-item">
            <Link to="/subscriptions">Manage subscriptions</Link>
          </li>
        )}
      </ul>
    </div>
  )
}

export default DashboardLinks
