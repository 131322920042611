import React from 'react'
import formattedCreditsWithHours from '@Utils/FormattedCreditsWithHours'
import classnames from 'classnames'

import './Costs.scss'

interface CostsProps {
  readonly currentDebitOption: string
  readonly totalCostCredits: number
  readonly totalCostSubscription: number
  readonly shouldDisableDebitDropdown: boolean
  readonly availableCredits: number
  readonly availableSubtitlingTime: number
  readonly subscriptionHasCreditLikeCharging: boolean
  readonly projectVersionDuration: number
  readonly numberOfNewSubtitles: number
  readonly openedForMultipleProjects?: boolean
  readonly shouldHideCosts: boolean
}

const Costs: React.FC<CostsProps> = props => {
  const {
    currentDebitOption,
    totalCostCredits,
    totalCostSubscription,
    shouldDisableDebitDropdown,
    availableCredits,
    availableSubtitlingTime,
    openedForMultipleProjects,
    subscriptionHasCreditLikeCharging,
    projectVersionDuration,
    numberOfNewSubtitles,
    shouldHideCosts,
  } = props

  const formattedTotalCostCredits: string = formattedCreditsWithHours(totalCostCredits)
  const formattedTotalCostSubscription: string = formattedCreditsWithHours(totalCostSubscription)
  const formattedTotalCostSubscriptionAndCredits: string = formattedCreditsWithHours(availableSubtitlingTime + (projectVersionDuration - availableSubtitlingTime) * numberOfNewSubtitles)

  const isDebitedFromSubscription = currentDebitOption === 'Use my subscription'
  const isDebitedFromSubscriptionAndCredits = currentDebitOption === 'Use my subscription and credits in addition'

  const renderCosts = () => {
    let formattedCosts = formattedTotalCostCredits
    if (isDebitedFromSubscription && !subscriptionHasCreditLikeCharging) {
      formattedCosts = formattedTotalCostSubscription
    } else if (isDebitedFromSubscriptionAndCredits && !subscriptionHasCreditLikeCharging) {
      formattedCosts = formattedTotalCostSubscriptionAndCredits
    }

    return (
      <span>You will use {formattedCosts} of your credits.
        <a href="https://support.checksub.com/help/faq/how-credits-work" target="_blank">Understand why.</a>
      </span>
    )
  }

  const renderMissingCredits = () => {
    const formattedMissingCredits: string = formattedCreditsWithHours(totalCostCredits - availableCredits)

    return (
      <span>
        You are {formattedMissingCredits} short of credits to create {openedForMultipleProjects ? 'these projects' : 'this project'}.
      </span>
    )
  }

  return (
    <div>
      <div
        className={classnames(
          'Costs',
          {'Costs__hidden': shouldHideCosts})
        }
      >
        {shouldDisableDebitDropdown ? renderMissingCredits() : renderCosts()}
      </div>
    </div>
  )
}

export default Costs
