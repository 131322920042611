import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { IApplicationState } from '@Site/rootReducer'
import getParamValueFromUrl from '@Utils/GetParamValueFromUrl'
import Header from '@SiteContainers/Header'
import ProjectVersionSettingsForm from '@SiteComponents/ProjectVersionSettingsForm'
import SetThemePopup from '@SiteComponents/SetThemePopup'

import {
  processNotEnoughCredits,
} from '@SitePages/CreditsTopup/actions'

import {
  fetchVocabularies,
} from '@SiteModules/Vocabulary/actions'

import {
  getUserInfo
} from '@SiteModules/User/actions'

import {
  closeThemePopup
} from '@SiteContainers/Themes/actions'

import {
  ICreditsRequired,
} from '@SitePages/CreditsTopup/types'

import {
  getVocabularyIds,
  getVocabularyNames,
} from '@SiteModules/Vocabulary/selectors'

import {
  submitProjectVersionUpdate,
  fetchProjectVersionSettings,
  fetchProjectVersionSettingsMultiple,
} from './actions'

import {
  IProjectVersionSettings,
  IFetchProjectVersionSettingsMultiplePayload,
  PayloadSubmitProjectVersionUpdateBody,
  IFetchProjectVersionSettingsPayload,
} from './types'

import '@checksub_team/react-widgets/lib/scss/react-widgets.scss'
import './ProjectVersionSettings.scss'

interface ProjectVersionSettingsProps {
  readonly loading: boolean
  readonly selectedProjectVersions: string[]
  readonly maxLanguagesLimit: number
  readonly vocabularyIds: number[]
  readonly vocabularyNames: string[]
  readonly projectVersionSettings: IProjectVersionSettings
  readonly isSubscriptionCurrent: boolean
  readonly subscriptionHasCreditLikeCharging: boolean
  readonly lastUsedVocabularyName?: string
  readonly lastUsedSubtitleLanguages?: string[]
  readonly lastUsedTranscriptLanguage?: string
  readonly lastUsedReviewTranscript?: boolean
  readonly lastUsedSeparateAudio?: boolean
  readonly lastUsedAdaptTranslation?: boolean
  readonly isAdmin: boolean
  readonly subscriptionAllowsVoiceAndVoiceCloning: boolean
  readonly subscriptionAllowsTranslationAdaptionForDubbing: boolean
  readonly subscriptionAllowsLipSyncing: boolean
  readonly setThemePopupOpen: boolean
  readonly usingPersonalSubscription: boolean
  getUserInfo(): void
  fetchVocabularies(): void
  processNotEnoughCredits(payload: ICreditsRequired): void
  fetchProjectVersionSettings(payload: IFetchProjectVersionSettingsPayload): void
  submitProjectVersionUpdate(payload: PayloadSubmitProjectVersionUpdateBody): void
  fetchProjectVersionSettingsMultiple(payload: IFetchProjectVersionSettingsMultiplePayload): void
  closeThemePopup(): void
}

interface ProjectVersionSettingsState {
  editingProjectVersionIds: string[]
  hasLoaded: boolean
}

class ProjectVersionSettings extends React.Component<ProjectVersionSettingsProps, ProjectVersionSettingsState> {
  constructor(props: ProjectVersionSettingsProps) {
    super(props)

    this.state = {
      editingProjectVersionIds: [],
      hasLoaded: false
    }
  }

  componentDidMount() {
    const projectVersionIdsFromUrl = getParamValueFromUrl(window.location.href, 'projectVersionIds')
    const editingProjectVersionIds = projectVersionIdsFromUrl ? projectVersionIdsFromUrl.split(',') : this.props.selectedProjectVersions
    this.setState({ editingProjectVersionIds })

    const isForOneProjectVersion = editingProjectVersionIds.length === 1
    const isForMultipleProjectVersions = editingProjectVersionIds.length > 1
    if (isForOneProjectVersion) {
      this.props.fetchProjectVersionSettings({ projectVersionId: editingProjectVersionIds[0] })
    } else if (isForMultipleProjectVersions) {
      this.props.fetchProjectVersionSettingsMultiple({ projectVersionIds: editingProjectVersionIds })
    } else {
      window.location.href = '/'
    }
    this.props.fetchVocabularies()
    this.props.getUserInfo()
  }

  componentDidUpdate(prevProps: ProjectVersionSettingsProps) {
    if (prevProps.loading && !this.props.loading) {
      this.setState({ hasLoaded: true })
    }
  }

  renderSetThemePopup = () => {
    const isForOneProjectVersion = this.state.editingProjectVersionIds.length === 1
    const isNewProject = !this.props.projectVersionSettings.attributes.language

    return(
      <SetThemePopup
        closePopup={this.props.closeThemePopup}
        isForOneProjectVersion={isForOneProjectVersion}
        isNewProject={isNewProject}
        projectVersionId={this.state.editingProjectVersionIds[0]}
      />
    )
  }

  render() {
    const {
      loading,
      projectVersionSettings,
      vocabularyIds,
      vocabularyNames,
      maxLanguagesLimit,
      isSubscriptionCurrent,
      subscriptionHasCreditLikeCharging,
      lastUsedVocabularyName,
      lastUsedSubtitleLanguages,
      lastUsedTranscriptLanguage,
      lastUsedReviewTranscript,
      lastUsedSeparateAudio,
      lastUsedAdaptTranslation,
      isAdmin,
      subscriptionAllowsVoiceAndVoiceCloning,
      subscriptionAllowsTranslationAdaptionForDubbing,
      subscriptionAllowsLipSyncing,
      setThemePopupOpen,
      usingPersonalSubscription,
    } = this.props

    const {
      editingProjectVersionIds,
      hasLoaded,
    } = this.state

    const {
      duration,
      language,
      availableCredits,
      subtitleLanguages,
      languagesForVideos,
      translationLanguages,
      nonTranslatableLanguages,
      availableSubtitlingTime,
      alignmentLanguages,
      translationFormalityOptionLanguages,
      voiceCloningLanguages,
      hasMainAssets,
      hasAudioSeparation,
      subscriptionSubtitlesLength,
    } = projectVersionSettings.attributes

    const isForOneProjectVersion = editingProjectVersionIds.length === 1

    return (
      <div className="ProjectVersionSettings">
        {setThemePopupOpen && this.renderSetThemePopup()}
        <Header />
        <ProjectVersionSettingsForm
          hasLoaded={hasLoaded}
          showSpinner={loading}
          disableButton={loading}
          projectVersionDuration={duration}
          submitProjectVersionUpdate={this.props.submitProjectVersionUpdate}
          editingProjectVersionIds={editingProjectVersionIds}
          vocabularyIds={vocabularyIds}
          vocabularyNames={vocabularyNames}
          availableCredits={availableCredits}
          maxLanguagesLimit={maxLanguagesLimit}
          initialProjectVersionLanguage={language}
          languagesForVideos={languagesForVideos}
          initialSubtitleLanguages={subtitleLanguages}
          translationLanguages={translationLanguages}
          nonTranslatableLanguages={nonTranslatableLanguages}
          alignmentLanguages={alignmentLanguages}
          translationFormalityOptionLanguages={translationFormalityOptionLanguages}
          isSubscriptionCurrent={isSubscriptionCurrent}
          availableSubtitlingTime={availableSubtitlingTime}
          subscriptionSubtitlesLength={subscriptionSubtitlesLength}
          processNotEnoughCredits={this.props.processNotEnoughCredits}
          subscriptionHasCreditLikeCharging={subscriptionHasCreditLikeCharging}
          lastUsedVocabularyName={lastUsedVocabularyName}
          lastUsedSubtitleLanguages={lastUsedSubtitleLanguages}
          lastUsedTranscriptLanguage={lastUsedTranscriptLanguage}
          lastUsedReviewTranscript={lastUsedReviewTranscript}
          lastUsedSeparateAudio={lastUsedSeparateAudio}
          lastUsedAdaptTranslation={lastUsedAdaptTranslation}
          isAdmin={isAdmin}
          subscriptionAllowsVoiceAndVoiceCloning={subscriptionAllowsVoiceAndVoiceCloning}
          hasMainAssets={isForOneProjectVersion ? hasMainAssets : true}
          hasAudioSeparation={isForOneProjectVersion ? hasAudioSeparation : false}
          voiceCloningLanguages={voiceCloningLanguages}
          subscriptionAllowsTranslationAdaptionForDubbing={subscriptionAllowsTranslationAdaptionForDubbing}
          subscriptionAllowsLipSyncing={subscriptionAllowsLipSyncing}
          usingPersonalSubscription={usingPersonalSubscription}
        />
      </div>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { user, projectVersionSettings, vocabularies, projectVersionsList, themes } = state

  return {
    vocabularyIds: getVocabularyIds(vocabularies),
    vocabularyNames: getVocabularyNames(vocabularies),
    loading: projectVersionSettings.loading || vocabularies.fetchVocabularies.loading || user.loading,
    isSubscriptionCurrent: user.subscription.attributes.isCurrent,
    maxLanguagesLimit: user.subscription.attributes.maxLanguagesLimit,
    projectVersionSettings: projectVersionSettings.projectVersionSettings, // TODO: fix that later...
    subscriptionHasCreditLikeCharging: user.subscription.attributes.hasCreditLikeCharging,
    lastUsedVocabularyName: user.user.attributes.lastUsedTranscriptInformation?.vocabularyName,
    lastUsedSubtitleLanguages: user.user.attributes.lastUsedTranscriptInformation?.subtitleLanguages,
    lastUsedTranscriptLanguage: user.user.attributes.lastUsedTranscriptInformation?.language,
    lastUsedReviewTranscript: user.user.attributes.lastUsedReviewTranscript,
    lastUsedSeparateAudio: user.user.attributes.lastUsedDubbingInformation?.lastUsedSeparateAudio,
    lastUsedAdaptTranslation: user.user.attributes.lastUsedDubbingInformation?.lastUsedAdaptTranslation,
    isAdmin: user.user.attributes.role === 'admin',
    subscriptionAllowsVoiceAndVoiceCloning: user.subscription.attributes.allowsVoice && user.subscription.attributes.allowsVoiceCloning,
    subscriptionAllowsTranslationAdaptionForDubbing: user.subscription.attributes.allowsTranslationAdaptionForDubbing,
    subscriptionAllowsLipSyncing: user.subscription.attributes.allowsLipSyncing,
    selectedProjectVersions: projectVersionsList.selectedProjectVersions,
    setThemePopupOpen: themes.openSetThemePopup,
    usingPersonalSubscription: user.user.attributes.usingPersonalSubscription,
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    getUserInfo,
    submitProjectVersionUpdate,
    fetchProjectVersionSettings,
    fetchProjectVersionSettingsMultiple,
    fetchVocabularies,
    processNotEnoughCredits,
    closeThemePopup,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectVersionSettings)
