import axios, { AxiosRequestConfig } from 'axios'
import convertToSnakeCase from '@Utils/ConvertToSnakeCase'

import {
  PayloadData
} from './types'

export function submitBillingAddress(data: PayloadData) {
  const config: AxiosRequestConfig = {
    headers: {
      'X-CSRF-Token': window.Checksub.token
    },
    responseType: 'json'
  }

  return axios.post('/api/v1/checkouts/billing_address', convertToSnakeCase(data), config)
}

export function fetchBillingAddress() {
  return axios.get(`/api/v1/checkouts/billing_address`)
}
