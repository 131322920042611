import {
  ThemesActionTypes,
  TThemesActionTypes,
  ISetTargetForThemePayload,
  ISetThemePayloadBody
} from './types'

export const setTheme = (payload: ISetThemePayloadBody): TThemesActionTypes => ({
  type: ThemesActionTypes.SET_THEME,
  payload
})

export const setTargetForTheme = (payload: ISetTargetForThemePayload): TThemesActionTypes => ({
  type: ThemesActionTypes.SET_TARGET_FOR_THEME,
  payload
})

export const setIsReadyForTheme = (): TThemesActionTypes => ({
  type: ThemesActionTypes.IS_READY_FOR_THEME,
})

export const openSetThemePopup = (): TThemesActionTypes => ({
  type: ThemesActionTypes.OPEN_SET_THEME_POPUP,
})

export const closeThemePopup = (): TThemesActionTypes => ({
  type: ThemesActionTypes.CLOSE_POPUP,
})
