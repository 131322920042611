import React from 'react'
import Popup from '@SiteComponents/Popup'
import Button from '@SiteComponents/Button'
import startCase from 'lodash/startCase'

import './UpdateSubscriptionPopup.scss'

interface UpdateSubscriptionPopupProps {
  readonly fullNewPlanName: string
  readonly pricePerPeriodWithoutVat: number
  readonly priceInUsd: boolean
  readonly taxPercent?: number
  closePopup(): void
  updateSubscription(): void
}

const UpdateSubscriptionPopup: React.FC<UpdateSubscriptionPopupProps> = props => {
  const {
    fullNewPlanName,
    priceInUsd,
    pricePerPeriodWithoutVat,
    taxPercent
  } = props

  const formattedNewPlanName = startCase(fullNewPlanName.split('_').slice(0, 2).join(' '))
  const formattedNewPlanNameAllCapital = (fullNewPlanName.split('_').slice(0, 2).join(' ')).toUpperCase()
  const currency = priceInUsd ? '$' : '€'
  const willPayTax = !!taxPercent && taxPercent > 0
  const total = willPayTax ? pricePerPeriodWithoutVat * (1.00 + taxPercent / 100.00) : pricePerPeriodWithoutVat

  const onUpdate = () => {
    props.updateSubscription()
    props.closePopup()
  }

  return (
    <Popup
      className="UpdateSubscriptionPopup"
      closePopup={props.closePopup}
      isDark
    >
      <div className="UpdateSubscriptionPopup__title">
        Confirm your new plan
      </div>
      <div className="UpdateSubscriptionPopup__body">
        <div className="UpdateSubscriptionPopup__info">
          You are about to change your plan to {formattedNewPlanName} which will take effect immediately.
        </div>
        <div className="UpdateSubscriptionPopup__summary">
          <div>
            {formattedNewPlanNameAllCapital}
          </div>
          <div className="UpdateSubscriptionPopup__cost">
            <div>
              Total: {total.toFixed(2)}{currency}
            </div>
            {willPayTax && (
              <div className="UpdateSubscriptionPopup__vat">
                20% VAT included
              </div>
            )}
          </div>
        </div>
        <div className="UpdateSubscriptionPopup__info">
          The amount charged for the first period(s) might be less, since we will give you a credit for what you have already paid.
        </div>
      </div>
      <Button
        type="button"
        color="primary-dark"
        caption="Change my plan"
        onClick={onUpdate}
      />
    </Popup>
  )
}

export default UpdateSubscriptionPopup
