import axios, { AxiosRequestConfig } from 'axios'
import convertToSnakeCase from '@Utils/ConvertToSnakeCase'

import {
  PayloadSubmitProjectVersionUpdate,
  IFetchProjectVersionSettingsMultiplePayload,
  IFetchProjectVersionSettingsPayload
} from './types'

export function fetchProjectVersionSettings(payload: IFetchProjectVersionSettingsPayload) {
  return axios.get(`/api/v1/project_versions/${payload.projectVersionId}/show_settings`)
}

export function submitProjectVersionUpdate(data: PayloadSubmitProjectVersionUpdate) {
  const config: AxiosRequestConfig = {
    headers: {
      'X-CSRF-Token': window.Checksub.token
    },
    responseType: 'json'
  }

  return axios.put(`/api/v1/project_versions/setup`, convertToSnakeCase(data.payload), config)
}

export function fetchProjectVersionSettingsMultiple(payload: IFetchProjectVersionSettingsMultiplePayload) {
  return axios.get('/api/v1/project_versions/show_settings_multiple', { params: convertToSnakeCase(payload) })
}

export function fetchProjectVersionSettingsDemo() {
  return axios.get('/api/v1/project_versions/show_settings_demo')
}
