import each from 'lodash/each'

export default function sanitizeUrlsString(urlsString: string): string[] {
  const urls: string[] = []
  each(urlsString.split('\n'), url => {
    url = url.trim()
    if (url.length > 0) {
      urls.push(url)
    }
  })

  return urls
}
