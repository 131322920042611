import axios, { AxiosRequestConfig } from 'axios'
import convertToSnakeCase from '@Utils/ConvertToSnakeCase'

import {
  ICreateLabelPayload,
  IProjectVersionLabelsPayload
} from './types'

export function fetchLabels() {
  return axios.get(`/api/v1/labels/`)
}

export function createLabel(payload: ICreateLabelPayload) {
  const config: AxiosRequestConfig = {
    headers: {
      'X-CSRF-Token': window.Checksub.token
    },
    responseType: 'json'
  }

  return axios.post(`/api/v1/labels/`, convertToSnakeCase(payload), config)
}

export function createProjectVersionLabels(payload: IProjectVersionLabelsPayload) {
  const config: AxiosRequestConfig = {
    headers: {
      'X-CSRF-Token': window.Checksub.token
    },
    responseType: 'json'
  }

  return axios.post(`/api/v1/project_version_labels/`, convertToSnakeCase(payload), config)
}

export function deleteProjectVersionLabels(payload: IProjectVersionLabelsPayload) {
  const config: AxiosRequestConfig = {
    headers: {
      'X-CSRF-Token': window.Checksub.token
    }
  }

  return axios.delete(`/api/v1/project_version_labels/`, { ...config, data: convertToSnakeCase(payload) })
}
