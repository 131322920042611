import { put, takeLatest } from 'redux-saga/effects'
import * as Eff from 'redux-saga/effects' // tslint:disable-line:no-duplicate-imports
import toastr from 'toastr'

import Logger from '@Utils/Logger'

import {
  getExternalVideosSuccess,
  getExternalVideosFailure,
  getExternalChannelsSuccess,
  getExternalChannelsFailure
} from './actions'

import {
  ChannelsActionTypes,
  IGetExternalVideosSuccessPayload,
  IGetExternalChannelsSuccessPayload,
  IRemoveExternalChannelPayload
} from './types'

import {
  UserActionTypes,
} from '@SiteModules/User/types'

import {
  ISuccessResponse,
  IAction
} from '@Root/types'

import * as API from './api'

const call: any = Eff.call

function* getExternalVideosSaga(): Generator<any, any, any> {
  try {
    const response: ISuccessResponse<IGetExternalVideosSuccessPayload> = yield call(API.getExternalVideos)

    yield put(getExternalVideosSuccess(response.data))
  } catch (e) {
    Logger.error(e, 'External videos could not be loaded')
    yield put(getExternalVideosFailure())
  }
}

function* getExternalChannelsSaga(): Generator<any, any, any> {
  try {
    const response: ISuccessResponse<IGetExternalChannelsSuccessPayload> = yield call(API.getExternalChannels)

    yield put(getExternalChannelsSuccess(response.data))
  } catch (e) {
    Logger.error(e, 'External channels could not be loaded')
    yield put(getExternalChannelsFailure())
  }
}

function* removeExternalChannelSaga(action: IAction<IRemoveExternalChannelPayload>): Generator<any, any, any> {
  try {
    yield call(API.removeExternalChannel, action.payload)

    toastr.success('', 'External channel has been removed')

    const response: ISuccessResponse<IGetExternalChannelsSuccessPayload> = yield call(API.getExternalChannels)

    yield put(getExternalChannelsSuccess(response.data))
  } catch (e) {
    Logger.error(e, 'External channel could not be reloaded')
    yield put(getExternalChannelsFailure())
  }
}

function* channelsTableSagas() {
  yield takeLatest(UserActionTypes.GET_INFO_SUCCESS, getExternalChannelsSaga)
  yield takeLatest(ChannelsActionTypes.GET_EXTERNAL_VIDEOS, getExternalVideosSaga)
  yield takeLatest(ChannelsActionTypes.REMOVE_EXTERNAL_CHANNEL, removeExternalChannelSaga)
}

export default channelsTableSagas
