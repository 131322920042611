import axios from 'axios'
import getMetaValue from '@Utils/GetMetaValue'
import convertToSnakeCase from '@Utils/ConvertToSnakeCase'

import { IUploadedElement } from '@Utils/ElementUploader'

import {
  ISubmitUrlsPayload,
} from './types'

// For testing Net::Timeout error that's possible to use this request.
// just send the request and wait until it's failed.
// return axios.put('http://example.com:81/')

const apiHeaders = {
  headers: {
    'X-CSRF-Token': getMetaValue('csrf-token'),
    'Content-Type': 'application/json',
  }
}

export function createElement(payload: IUploadedElement) {
  return axios.post(`/api/v1/elements/create_with_project`, convertToSnakeCase(payload), apiHeaders)
}

export function submitUrls(payload: ISubmitUrlsPayload) {
  return axios.post(`/api/v1/elements/youtube_dl_with_project`, convertToSnakeCase(payload), apiHeaders)
}
