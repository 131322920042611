import React from 'react'

interface OnOffSwitchProps {
  readonly checked: boolean
  readonly label: string
  setChecked(newChecked: boolean): void
}

import './OnOffSwitch.scss'

const OnOffSwitch: React.FC<OnOffSwitchProps> = props => {
  const {
    checked,
    label,
  } = props

  const handleChange = (e: any) => {
    props.setChecked(e.target.checked)
  }

  // https://www.w3schools.com/howto/howto_css_switch.asp
  return (
    <div className="OnOffSwitch">
      <label className="OnOffSwitch__switch">
        <input
          type="checkbox"
          onClick={handleChange}
          checked={checked}
        />
        <span
          className="OnOffSwitch__slider OnOffSwitch__slider-round"
        />
      </label>
      <span className="OnOffSwitch__text">
        {label}
      </span>
    </div>
  )
}

export default OnOffSwitch
