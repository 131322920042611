import React, { useState } from 'react'
import Delete from '@Images/delete.svg'

import {
  IElement,
  IDeleteElementPayload,
  IGetAffectedProjectVersionsPayload,
} from './types'

interface AssetProps {
  readonly element: IElement
  getAffectedProjectVersions(payload: IGetAffectedProjectVersionsPayload): void
  deleteElement(payload: IDeleteElementPayload): void
}

const Asset: React.FC<AssetProps> = props => {
  const [showInfoForId, setShowInfoForId] = useState<boolean>(false)
  const [infoForIdTimeout, setInfoForIdTimeout] = useState<ReturnType<typeof setTimeout> | null>(null)
  const [shouldShowActionTools, setShouldShowActionTools] = useState<boolean>(false)

  const { element } = props
  const shouldShowColoredThumbnail = !element.attributes.thumbnailUrl

  const toggleShowInfoForId = () => {
    if (infoForIdTimeout) {
      setShowInfoForId(false)
      clearTimeout(infoForIdTimeout)
      setInfoForIdTimeout(null)
    } else {
      const newInfoForIdTimeout = setTimeout(() => {
        setShowInfoForId(true)
      }, window.Checksub.tooltipDelay)
      setInfoForIdTimeout(newInfoForIdTimeout)
    }
  }

  const showActionTools = () => {
    setShouldShowActionTools(true)
  }

  const hideActionTools = () => {
    setShouldShowActionTools(false)
  }

  const handleMouseEnterLength = () => {
    showActionTools()
    toggleShowInfoForId()
  }

  const handleMouseLeaveLength = () => {
    hideActionTools()
    toggleShowInfoForId()
  }

  // This opens the popup to confirm the deletion. Commented temporarily.
  // const getAffectedProjectVersions = () => {
  //   props.getAffectedProjectVersions({ elementId: element.id })
  // }

  const deleteElement = () => {
    props.deleteElement({ elementId: props.element.id })
  }

  return (
    <tr className="Assets__table-row">
      <td className="Assets__table-cell Assets__thumbnail_medium"  onMouseEnter={showActionTools} onMouseLeave={hideActionTools}>
        {shouldShowColoredThumbnail ? (
          <span className="Assets__thumbnail-placeholder"/>
        ) : (
          <img className="Assets__thumbnail" src={element.attributes.thumbnailUrl} />
        )}
      </td>
      <td className="Assets__table-cell Assets__title"  onMouseEnter={showActionTools} onMouseLeave={hideActionTools}>
        <p>
          {element.attributes.title}
        </p>
      </td>
      <td className="Assets__table-cell Assets__length"  onMouseEnter={handleMouseEnterLength} onMouseLeave={handleMouseLeaveLength}>
        {element.attributes.duration}
        {showInfoForId &&
          <div className="Assets__tooltip Assets__id-tooltip">
            Element id: {element.id.slice(0, 6)}
          </div>
        }
      </td>
      <td className="Assets__table-cell Assets__uploaded"  onMouseEnter={showActionTools} onMouseLeave={hideActionTools}>
        {element.attributes.createdAt}
      </td>
      <td className="Assets__table-cell Assets__type"  onMouseEnter={showActionTools} onMouseLeave={hideActionTools}>
        {element.attributes.kind}
      </td>
      <td className="Assets__table-cell Assets__size"  onMouseEnter={showActionTools} onMouseLeave={hideActionTools}>
        {element.attributes.size}
      </td>
      <td className="Assets__table-cell Assets__actions"  onMouseEnter={showActionTools} onMouseLeave={hideActionTools}>
        {shouldShowActionTools && (
          <Delete onClick={deleteElement}/>
        )}
      </td>
    </tr>
  )
}

export default Asset
