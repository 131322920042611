import React, { useState } from 'react'
import Button from '@SiteComponents/Button'
import Popup from '@SiteComponents/Popup'
import { useForm } from 'react-hook-form'

import {
  ICreateVocabularyPayload
} from '@SiteModules/Vocabulary/types'

import './CreateVocabularyPopup.scss'

interface CreateVocabularyPopupProps  {
  readonly loading: boolean
  closePopup(): void
  createVocabulary(payload: ICreateVocabularyPayload): void
}

type Form = {
  name: string
}

const CreateVocabularyPopup: React.FC<CreateVocabularyPopupProps> = props => {
  const [newName, setNewName] = useState('')
  const { register, handleSubmit, errors } = useForm<Form>()

  const onSubmit = handleSubmit(({ name }) => {
    props.createVocabulary({ name })
  })

  const changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewName(e.target.value)
  }

  const shouldDisableButton = props.loading || !newName.trim()

  return(
    <Popup
      className="CreateVocabularyPopup"
      closePopup={props.closePopup}
      isDark
    >
      <h2 className="CreateVocabularyPopup__title">Create a new vocabulary list</h2>
      <form className="CreateVocabularyPopup__form" onSubmit={onSubmit} >
        <input
          id="name"
          name="name"
          type="text"
          value={newName}
          onChange={changeName}
          placeholder="Name of the list"
          autoComplete="off" 
          ref={register({
            maxLength: 100
          })}
        />
        {
          errors.name &&
          errors.name.type === 'maxLength' &&
          <span  className="errorMessage">The maximum length of the list name is 100 characters.</span>
        }

        <Button
          type="submit"
          color="primary-dark"
          caption="Create vocabulary list"
          showSpinner={props.loading}
          disabled={shouldDisableButton}
        />
      </form>
    </Popup>
  )
}

export default CreateVocabularyPopup
