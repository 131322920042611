import axios, { AxiosRequestConfig } from 'axios'
import convertToSnakeCase from '@Utils/ConvertToSnakeCase'

import {
  ISetThemePayload
} from './types'

export function setTheme(data: ISetThemePayload) {
  const config: AxiosRequestConfig = {
    headers: {
      'X-CSRF-Token': window.Checksub.token
    },
    responseType: 'json'
  }

  return axios.put(`/api/v1/project_versions/set_theme`, convertToSnakeCase(data.payload), config)
}
