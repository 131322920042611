import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { IApplicationState } from '../../rootReducer'
import hasElementOrSomeParentTheClass from '@Utils/HasElementOrSomeParentTheClass'
import map from 'lodash/map'
import Loader from '@SiteComponents/Loader'
import ConnectChannelButton from '@SiteComponents/ConnectChannelButton'
import Header from '@SiteContainers/Header'
import Channel from './Channel'

import './ChannelsManage.scss'

import {
  IExternalChannel,
  IRemoveExternalChannelPayload
} from '@SiteContainers/ChannelsTable/types'

import {
  openActionsDropdown,
  closeDropdowns,
  removeExternalChannel
} from '@SiteContainers/ChannelsTable/actions'

import {
  connectExternalChannel
} from '@SiteModules/User/actions'

import { IConnectExternalChannel } from '@SiteModules/User/types'

interface ChannelsManageProps {
  readonly externalChannels: IExternalChannel[]
  readonly loading: boolean
  readonly actionsDropdownOpen: boolean
  openActionsDropdown(): void
  closeDropdowns(): void
  removeExternalChannel(payload: IRemoveExternalChannelPayload): void
  connectExternalChannel(payload: IConnectExternalChannel): void
}

interface ChannelsManageState {}

class ChannelsManage extends React.Component<ChannelsManageProps, ChannelsManageState> {
  currentChannel: IExternalChannel | undefined = undefined

  handleClickOutside = (e: MouseEvent) => {
    if (!this.props.actionsDropdownOpen) { return }
    if (e.target) {
      const element = e.target as HTMLElement
      if (hasElementOrSomeParentTheClass(element, 'ChannelsManage__dropdown')) {
        return
      }
      this.props.closeDropdowns()
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  handleDropdownClickOnRemove = () => {
    const externalChannelId = this.currentChannel?.id

    if (externalChannelId) {
      this.props.removeExternalChannel({ externalChannelId })
      this.props.closeDropdowns()
    }
  }

  handleDropdownClickOnRefresh = () => {
    const service = this.currentChannel?.service

    if (service) {
      this.props.connectExternalChannel({ service })
    }
  }

  renderActionsDropdown = () => {
    const display = this.props.actionsDropdownOpen ? 'block' : 'none'

    return (
      <ul id="channelsManage-dropdown" className="ChannelsManage__dropdown" style={{ display }}>
        <li
          className="ChannelsManage__dropdown-item"
          onClick={this.handleDropdownClickOnRemove}
        >
          Remove
        </li>
        <li
          className="ChannelsManage__dropdown-item"
          onClick={this.handleDropdownClickOnRefresh}
        >
          Refresh access
        </li>
      </ul>
    )
  }

  handleActionsClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, externalChannel: IExternalChannel) => {
    this.currentChannel = externalChannel

    const dropdown = document.getElementById('channelsManage-dropdown')
    const boundingRect = e.currentTarget.getBoundingClientRect()

    if (dropdown) {
      dropdown.style.top = `${boundingRect.y + boundingRect.width}px`
      dropdown.style.left = `${boundingRect.x}px`
    }

    this.props.openActionsDropdown()
  }

  renderChannels = () => {
    return map(this.props.externalChannels, (value, index) => {
      return (
        <Channel
          key={index}
          externalChannel={value}
          handleActionsClick={this.handleActionsClick}
        />
      )
    })
  }

  render() {
    const shouldRenderLoader = this.props.loading

    return (
      <div className="ChannelsManage">
        <Header />

        <div className="ChannelsManage__connect-channel-button-wrapper">
          <ConnectChannelButton />
        </div>

        <div className="ChannelsManage__title">
          Channels
        </div>
        {shouldRenderLoader ? (
            <Loader color="dark" />
          ) : (
            <div className="ChannelsManage__channel-wrapper">
              {this.renderChannels()}
              {this.renderActionsDropdown()}
            </div>
          )
        }
      </div>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { channelsTable } = state

  return {
    externalChannels: channelsTable.externalChannels,
    loading: channelsTable.loadingChannels,
    actionsDropdownOpen: channelsTable.actionsDropdownOpen
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    openActionsDropdown,
    closeDropdowns,
    removeExternalChannel,
    connectExternalChannel
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ChannelsManage)
