import {
  IPrice,
  PaymentActionTypes,
  IPaymentActionTypes,
  IPaymentSuccessPayload
} from './types'

export const paymentFailure = (message: string): IPaymentActionTypes => ({
  type: PaymentActionTypes.PAYMENT_FAILURE,
  error: message,
})

export const paymentSuccess = (payload: IPaymentSuccessPayload): IPaymentActionTypes => ({
  type: PaymentActionTypes.PAYMENT_SUCCESS,
  payload
})

export const fetchCreditPrice = (creditId: number): IPaymentActionTypes => ({
  type: PaymentActionTypes.FETCH_CREDIT,
  creditId,
})

export const fetchCreditPriceFailure = () => ({
  type: PaymentActionTypes.FETCH_CREDIT_FAILURE,
})

export const fetchCreditPriceSuccess = (payload: IPrice): IPaymentActionTypes => ({
  type: PaymentActionTypes.FETCH_CREDIT_SUCCESS,
  payload,
})
