import {
  IUser,
  IUserAttributes,
} from '@SiteModules/User/types'

export const SignInFormActionTypes = {
  SIGN_IN: '@@signInForm/SIGN_IN',
  SSO_SIGN_IN: '@@signInForm/SSO_SIGN_IN',
  SIGN_IN_FAILURE: '@@signInForm/SIGN_IN_FAILURE',
  SIGN_IN_SUCCESS: '@@signInForm/SIGN_IN_SUCCESS',
}

export interface PayloadData {
  readonly utf8: string
  readonly commit: string
  readonly user: IUserSignInPayload
}

export interface IUserSignInPayload {
  readonly email: string
  readonly password: string
  readonly remember_me: number
}

export interface ISsoSignInPayload {
  readonly emailOrDomain: string
}

export interface IUserSignInState {
  readonly user: IUserAttributes
  readonly errors: string[]
  readonly loading: boolean
}

export interface ISignInAction {
  readonly type: string
  readonly payload: IUserSignInPayload
}

export interface ISsoSignInAction {
  readonly type: string
  readonly payload: ISsoSignInPayload
}

export interface ISignInFailureAction {
  readonly type: string
}

export interface ISignInSuccessAction {
  readonly type: string
  readonly payload: IUser
}

export type TSignInActionTypes = ISignInAction | ISsoSignInAction | ISignInFailureAction | ISignInSuccessAction
