class Analytics {
  static track = (event: string, value: number = 0) => {
    let label = ''

    switch (event) {
      case 'sign_up':
        label = 'Sign up'
        break
      case 'buy_credits':
        label = 'Buy Credits'
        break
      case 'order_project':
        label = 'Order a Project'
        break
    }

    if (typeof window.gtag === 'function') {
      gtag('event', event, { 'event_category': 'checksub', 'event_label': label, 'value': value })
    } else {
      console.log(`Google Analytics Track: ${event}`) // tslint:disable-line no-console
      console.log(`Value: ${value}`) // tslint:disable-line no-console
    }
  }

  static trackGTMSignUpLogin = (event: string, method: string) => {
    window.dataLayer = window.dataLayer || []

    switch (event) {
      case 'sign_up':
        window.dataLayer.push({
          'event': event,
          'signupMethod': method
        })
        break
      case 'login':
        window.dataLayer.push({
          'event': event,
          'loginMethod': method
        })
        break
    }
  }

  static trackGTMPurchase = (paidItem: string, paidItemId: number, amount: number, vat: number, currency: string) => {

    let item = ''
    switch(paidItem) {
      case 'credit':
        item = 'Credits'
        break
      case 'order':
        item = 'Professional Project'
        break
    }

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      'event': 'purchase',
      'transaction_id': paidItemId.toString(),
      'value': amount - vat,
      'currency': currency,
      'tax': vat,
      'items': item
    })
  }
}

export default Analytics
