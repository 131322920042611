import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { IApplicationState } from '@Site/rootReducer'
import ThemesForm from '@SiteComponents/ThemesForm'

import {
  ITheme,
  ISetThemePayloadBody
} from './types'

import {
  setTheme
} from './actions'

import './Themes.scss'

interface ThemesProps {
  readonly themes: ITheme[]
  readonly projectVersionIds: string[]
  readonly subtitleLanguages: string[]
  readonly isReadyForTheme: boolean
  setTheme(payload: ISetThemePayloadBody): void
}

interface ThemesState {}

class Themes extends React.Component<ThemesProps, ThemesState> {
  onSubmit = (theme: string | undefined) => {
    const {
      projectVersionIds,
      subtitleLanguages,
    } = this.props

    this.props.setTheme({
      theme,
      projectVersionIds,
      subtitleLanguages,
    })
  }

  render = () => {
    const {
      themes,
      isReadyForTheme,
    } = this.props

    return (
      <section className="Themes">
        <ThemesForm
          themes={themes}
          isReadyForTheme={isReadyForTheme}
          onSubmit={this.onSubmit}
          isRenderedInEditor={false}
        />
      </section>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { themes } = state
  const {
    projectVersionIds,
    subtitleLanguages,
    isReadyForTheme
  } = themes

  return {
    themes: themes.themes,
    projectVersionIds,
    subtitleLanguages,
    isReadyForTheme
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    setTheme
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Themes)
