import axios, { AxiosRequestConfig } from 'axios'
import convertToSnakeCase from '@Utils/ConvertToSnakeCase'

import {
  IRenameLabelPayload,
} from './types'

export function renameLabel(payload: IRenameLabelPayload) {
  const config: AxiosRequestConfig = {
    headers: {
      'X-CSRF-Token': window.Checksub.token
    },
  }

  const { labelId, caption } = payload

  return axios.put(`/api/v1/labels/${labelId}`,  { ...config, data: convertToSnakeCase({ caption }) })
}
