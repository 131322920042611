import {
  DashboardMenuActionTypes,
  IDashboardMenuState,
  IDashboardMenuActionTypes,
  IChangeTabAction
} from './types'

export const initialState: IDashboardMenuState = {
  currentTab: 'projects'
}

export const dashboardMenuReducer = (
  state: IDashboardMenuState = initialState,
  action: IDashboardMenuActionTypes
): IDashboardMenuState => {
  switch (action.type) {
    case DashboardMenuActionTypes.CHANGE_TAB: return changeTab(state, action)
    default: return state
  }
}

function isIChangeTabAction(arg: any): arg is IChangeTabAction {
  return arg.payload && arg.payload.newTab != null
}

const changeTab = (state: IDashboardMenuState, action: IDashboardMenuActionTypes): IDashboardMenuState => {
  let newTab = state.currentTab

  if (isIChangeTabAction(action)) {
    newTab = action.payload.newTab
  }

  return { ...state, currentTab: newTab }
}
