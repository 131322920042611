import { IProjectVersion } from '@SiteContainers/ProjectVersionsList/types'

export const ProjectActionTypes = {
  CREATE_PROJECT: '@@project/CREATE_PROJECT',
  CREATE_PROJECT_SUCCESS: '@@project/CREATE_PROJECT_SUCCESS',
  CREATE_PROJECT_FAILURE: '@@project/CREATE_PROJECT_FAILURE',
}

export interface ICreateProjectSuccessResponseBody {
  readonly data: IProjectVersion[]
}
