import { createSelector } from 'reselect'
import { IApplicationState } from '@Site/rootReducer'

export const getCreditId = (state: IApplicationState): number => {
  const { creditsTopup } = state
  return parseInt(creditsTopup.credit.id, 10)
}

export const isCreditPresent = createSelector(
  [getCreditId],
  (creditId) => creditId > 0
)

export const isThereAnythingInCart = createSelector(
  [isCreditPresent],
  (creditPresent) => {
    return creditPresent
  }
)

export const getItemToPayFor = createSelector(
  [isCreditPresent],
  (creditPresent) => {
    if (creditPresent) { return 'credit' }

    // TODO: what about that?...
    return ''
  }
)

export const getItemToPayForId = createSelector(
  [getCreditId],
  (creditId) => {
    if (creditId > 0) { return creditId }

    // TODO: what about that?...
    return -1
  }
)
