import React from 'react'
import ButtonNewV2 from '@SiteComponents/ButtonNewV2'
import { IUser, Submittable } from '@Root/types'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import './ConfirmationNewForm.scss'

interface ConfirmationNewFormProps extends Submittable {
}

type FormData = Pick<IUser, 'email'>

const ConfirmationNewForm: React.FC<ConfirmationNewFormProps> = props => {
  const { register, handleSubmit, errors } = useForm<FormData>()
  const onSubmit = handleSubmit(({ email }) => {
    const user = { email }
    props.submit({ user })
  })

  const renderEmail = () => {
    return(
      <div className="ConfirmationNewForm__formfield">
        <label htmlFor="email">Email</label>
        <input
          id="email"
          name="email"
          type="email"
          ref={register({
            required: true,
            maxLength: 100,
            pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,63})+$/ // https://www.w3resource.com/javascript/form/email-validation.php. Changed to \w{1,63} to allow all possible TLD (see http://data.iana.org/TLD/tlds-alpha-by-domain.txt)
          })}
        />
        {
          errors.email &&
          errors.email.type === 'required' &&
          <span className="errorMessage">This field is required</span>
        }
        {
          errors.email &&
          errors.email.type === 'maxLength' &&
          <span  className="errorMessage">This field cannot exceed 100 characters</span>
        }
        {
          errors.email &&
          errors.email.type === 'pattern' &&
          <span className="errorMessage">This must be a valid email address</span>
        }
      </div>
    )
  }

  const renderSubmitButton = () => {
    return(
      <ButtonNewV2
        type="submit"
        size="large"
        styleType="brand-primary"
        showSpinner={props.showSpinner}
        disabled={props.disableButton}
        caption="Resend confirmation instructions"
      />
    )
  }

  const renderLinks = () => {
    return(
      <div className="ConfirmationNewForm__links">
        <Link to="/users/sign_in">Log in</Link>{' '}|{' '}
        <Link to="/users/sign_up">Create a new account</Link><br />
        <Link to="/users/password/new">Forgot your password?</Link>
      </div>
    )
  }

  const renderOutlookAdvice = () => {
    return(
      <div className="ConfirmationNewForm__outlook-advice">
        If you use Outlook and don't receive the email,
        <a href="https://www.makeuseof.com/fix-outlook-wont-receive-emails/"> check your settings</a>
      </div>
    )
  }

  return(
    <form className="ConfirmationNewForm" onSubmit={onSubmit} >
      {renderEmail()}
      {renderSubmitButton()}
      {renderLinks()}
      {renderOutlookAdvice()}
    </form>
  )
}

export default ConfirmationNewForm
