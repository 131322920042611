import {
  IRenameLabelState,
  IRenameLabelAction,
  RenameLabelActionTypes,
  TRenameLabelActionTypes,
} from './types'

import {
  DashboardSidebarActionTypes,
} from '@SiteContainers/DashboardSidebar/types'

export const initialState: IRenameLabelState = {
  errors: [],
  loading: false,
  labelId: -1,
  caption: '',
}

export const renameLabelReducer = (
  state: IRenameLabelState = initialState,
  action: TRenameLabelActionTypes
): IRenameLabelState => {
  switch (action.type) {
    case RenameLabelActionTypes.RENAME_LABEL: return renameLabel(state)
    case RenameLabelActionTypes.RENAME_LABEL_SUCCESS: return renameLabelSuccess(state)
    case RenameLabelActionTypes.RENAME_LABEL_FAILURE: return renameLabelFailure(state)
    case DashboardSidebarActionTypes.OPEN_RENAME_LABEL_POPUP: return openRenameLabelPopup(state, action)
    default: return state
  }
}

const renameLabel = (state: IRenameLabelState): IRenameLabelState => {
  return { ...state, loading: true }
}

const renameLabelSuccess = (state: IRenameLabelState): IRenameLabelState => {
  return { ...state, loading: false }
}

const renameLabelFailure = (state: IRenameLabelState): IRenameLabelState => {
  return { ...state, loading: false }
}

function isIOpenRenameLabelPopup(arg: any): arg is IRenameLabelAction {
  return arg.type === DashboardSidebarActionTypes.OPEN_RENAME_LABEL_POPUP
}

const openRenameLabelPopup = (state: IRenameLabelState, action: TRenameLabelActionTypes): IRenameLabelState => {
  if (!isIOpenRenameLabelPopup(action)) { return state }

  const { labelId, caption } = action.payload!

  return { ...state, labelId, caption }
}
