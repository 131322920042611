import difference from 'lodash/difference'
import map from 'lodash/map'
import each from 'lodash/each'
import { IApplicationState } from '@Site/rootReducer'

import {
  ILabelState
} from './types'

export const getLabelCaptions = (state: ILabelState): string[] => {
  return map(state.labels, label => label.attributes.caption)
}

export const getLabelIds = (state: ILabelState): number[] => {
  return map(state.labels, label => label.attributes.id)
}

export const getLabelsInAllSelectedProjectVersions = (state: IApplicationState): number[] => {
  const { labels } = state.labels
  const { selectedProjectVersions } = state.projectVersionsList
  const labelsInAllSelectedProjectVersions: number[] = []
  each(labels, label => {
    if (difference(selectedProjectVersions, label.attributes.projectVersionIds).length === 0) {
      labelsInAllSelectedProjectVersions.push(label.attributes.id)
    }
  })
  return labelsInAllSelectedProjectVersions
}

export const getLabelsInNoSelectedProjectVersion = (state: IApplicationState): number[] => {
  const { labels } = state.labels
  const { selectedProjectVersions } = state.projectVersionsList
  const labelsInNoSelectedProjectVersion: number[] = []
  each(labels, label => {
    if (difference(selectedProjectVersions, label.attributes.projectVersionIds).length === selectedProjectVersions.length) {
      labelsInNoSelectedProjectVersion.push(label.attributes.id)
    }
  })
  return labelsInNoSelectedProjectVersion
}
