import axios, { AxiosRequestConfig } from 'axios'
import convertToSnakeCase from '@Utils/ConvertToSnakeCase'

import {
  IDeleteProjectVersionPayload
} from './types'

export function deleteProjectVersion(payload: IDeleteProjectVersionPayload) {
  const config: AxiosRequestConfig = {
    headers: {
      'X-CSRF-Token': window.Checksub.token
    },
  }

  return axios.delete(`/api/v1/project_versions/`,  { ...config, data: convertToSnakeCase(payload) })
}
