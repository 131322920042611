export const RenameLabelActionTypes = {
  RENAME_LABEL: '@@renameLabelPopup/RENAME_LABEL',
  RENAME_LABEL_FAILURE: '@@renameLabelPopup/RENAME_LABEL_FAILURE',
  RENAME_LABEL_SUCCESS: '@@renameLabelPopup/RENAME_LABEL_SUCCESS',
}

export interface IRenameLabelState {
  readonly errors: string[]
  readonly loading: boolean
  readonly labelId: number
  readonly caption: string
}

export interface IRenameLabelAction {
  readonly type: string
  readonly payload: IRenameLabelPayload
}

export interface IRenameLabelPayload {
  readonly labelId: number
  readonly caption: string
}

export interface IRenameLabelPopupOpenPayload {
  readonly labelId: number
  readonly caption: string
}

export interface IRenameLabelSuccessAction {
  readonly type: string
}

export interface IRenameLabelFailureAction {
  readonly type: string
}

export type TRenameLabelActionTypes = IRenameLabelAction | IRenameLabelSuccessAction | IRenameLabelFailureAction
