import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { IApplicationState } from '@Site/rootReducer'
import { Redirect } from 'react-router-dom'

import {
  logout,
  getUserInfo,
} from '@SiteModules/User/actions'

import './CheckEmail.scss'

interface CheckEmailProps {
  readonly email: string
  readonly confirmedEmail: boolean
  logout(payload: string): {}
  getUserInfo(): void
}

interface CheckEmailState {
}

class CheckEmail extends React.Component<CheckEmailProps, CheckEmailState> {
  componentDidMount() {
    this.props.getUserInfo()
  }

  goToConfirmationNew = () => {
    this.props.logout('/users/confirmation/new')
  }

  renderHeader = () => {
    return (
      <div className="CheckEmail__header">
        ✅ Now, confirm you email, <br />
        to create your first project
      </div>
    )
  }

  renderText = () => {
    return (
      <React.Fragment>
        <div className="CheckEmail__text">
          We've sent an email to {' '}
          <span>{this.props.email}</span><br />
          Click the link in the email to confirm your address.
        </div>
        <div className="CheckEmail__text">
          🙈 Check your spam folder if you have nothing in your mailbox.
        </div>
      </React.Fragment>
    )
  }

  renderResendLink = () => {
    return (
      <div className="CheckEmail_resendLink" onClick={this.goToConfirmationNew}>
        Click here if you didn't receive the confirmation email.
      </div>
    )
  }

  render() {
    const shouldRenderCheckEmail = !this.props.confirmedEmail
    return (
      <React.Fragment>
        {shouldRenderCheckEmail ? (
          <div className="CheckEmail">
            <div className="CheckEmail__wrapper">
              {this.renderHeader()}
              {this.renderText()}
              {this.renderResendLink()}
            </div>
          </div>
        ) : (
          <Redirect to="/" />
        )}
      </React.Fragment>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { user } = state
  return {
    email: user.user.attributes.email,
    confirmedEmail: user.user.attributes.confirmedEmail,
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    logout,
    getUserInfo,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckEmail)
