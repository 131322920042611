import React, { useState } from 'react'
import capitalize from 'lodash/capitalize'
import Button from '@SiteComponents/Button'
import classnames from 'classnames'

import {
  IExternalVideo
} from './types'

import {
  ISubmitUrlsPayload
} from '@EditorModules/Element/types'

interface ChannelsItemProps {
  readonly externalVideo: IExternalVideo
  readonly loading: boolean
  submitUrls(payload: ISubmitUrlsPayload): void
}

const ChannelsItem: React.FC<ChannelsItemProps> = props => {
  const { externalVideo, loading } = props

  const [showButton, setShowButton] = useState(false)
  const [showInfo, setShowInfo] = useState(false)
  const [infoTimeout, setInfoTimeout] = useState<ReturnType<typeof setTimeout> | null>(null)

  const shouldShowColoredThumbnail = !externalVideo.thumbnailUrl
  const shouldDisableNewProjectButton = loading || !externalVideo.canBeDownloaded || !externalVideo.isReady

  const createNewProject = () => {
    props.submitUrls({ urls: [externalVideo.downloadUrl] })
  }

  const enableShowButton = () => {
    setShowButton(true)
  }

  const disableShowButton = () => {
    setShowButton(false)
    if (infoTimeout) {
      setShowInfo(false)
      clearTimeout(infoTimeout)
      setInfoTimeout(null)
    }
  }


  const toggleShowInfo = () => {
    if (infoTimeout) {
      setShowInfo(false)
      clearTimeout(infoTimeout)
      setInfoTimeout(null)
    } else {
      const newInfoTimeout = setTimeout(() => {
        setShowInfo(true)
      }, window.Checksub.tooltipDelay)
      setInfoTimeout(newInfoTimeout)
    }
  }

  return (
    <tr className="ExternalVideos__table-row" onMouseEnter={enableShowButton} onMouseLeave={disableShowButton}>
      <td className="ExternalVideos__table-cell ExternalVideos__table-cell-projectVersion ExternalVideos__thumbnail_medium">
        {shouldShowColoredThumbnail ? (
          <span className="ExternalVideos__thumbnail-placeholder"/>
        ) : (
          <img className="ExternalVideos__thumbnail" src={externalVideo.thumbnailUrl} />
        )}
      </td>
      <td className="ExternalVideos__table-cell ExternalVideos__title">
        <p>
          {externalVideo.title}
        </p>
      </td>
      <td className="ExternalVideos__table-cell ExternalVideos__length">
        {externalVideo.length}
      </td>
      <td className="ExternalVideos__table-cell ExternalVideos__length">
        {externalVideo.createdAt}
      </td>
      <td className="ExternalVideos__table-cell ExternalVideos__length">
        {capitalize(externalVideo.service)}
      </td>
      <td className="ExternalVideos__table-cell ExternalVideos__length">
        {externalVideo.externalChannelName}
      </td>
      <td className="ExternalVideos__table-cell ExternalVideos__actions">
        <div className={classnames({'ExternalVideos__invisible': !showButton})} onMouseEnter={toggleShowInfo} onMouseLeave={toggleShowInfo}>
          <Button
            color="special"
            onClick={createNewProject}
            caption="New project"
            disabled={shouldDisableNewProjectButton}
            showSpinner={loading}
          />
        </div>

        {showInfo && (!externalVideo.canBeDownloaded || !externalVideo.isReady) && (
          <div className="ExternalVideosList__tooltip">
            {!externalVideo.canBeDownloaded && 'Only PUBLIC and UNLISTED videos can be used'}
            {externalVideo.canBeDownloaded && !externalVideo.isReady && 'This video is still being uploaded/transcoded. Wait a moment then refresh the page'}
          </div>
        )}
      </td>
    </tr>
  )
}

export default ChannelsItem
