import React from 'react'
import classnames from 'classnames'
import PaginationLeft from '@Images/left.svg'
import PaginationRight from '@Images/right.svg'

import './Pagination.scss'

interface PaginationProps {
  readonly totalCount: number
  readonly totalPages: number
  readonly currentPage: number
  readonly itemsPerPage: number
  increasePageCount(): void
  decreasePageCount(): void
}

const Pagination: React.FC<PaginationProps> = props => {
  const { totalCount, currentPage, totalPages, itemsPerPage } = props

  const min = (currentPage - 1) * itemsPerPage + 1
  const max = Math.min(currentPage * itemsPerPage, totalCount)

  const decreasePageCount = () => {
    if (currentPage > 1) {
      props.decreasePageCount()
    }
  }

  const increasePageCount = () => {
    if (currentPage < totalPages) {
      props.increasePageCount()
    }
  }

  return (
    <div className="Pagination">
      <span className="Pagination__counter">
        {`${min}-${max} of ${totalCount} items`}
      </span>
      <span
        className={classnames('Pagination__left', {
          'Pagination__inactive': currentPage === 1,
          'Pagination__active': currentPage !== 1
        })}
        onClick={decreasePageCount}>
        <PaginationLeft />
      </span>
      <span
        className={classnames('Pagination__right', {
          'Pagination__inactive': currentPage === totalPages,
          'Pagination__active': currentPage !== totalPages
        })}
        onClick={increasePageCount}>
        <PaginationRight />
      </span>
    </div>
  )
}

export default Pagination
