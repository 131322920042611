import React from 'react'

import './Balloon.scss'

interface BalloonProps {
}

const Balloon: React.FC<BalloonProps> = props => {
  return (
    <div className="Balloon">
      <ul className="Balloon__list">
        {props.children}
      </ul>
    </div>
  )
}

export default Balloon
