import axios, { AxiosRequestConfig } from 'axios'
import convertToSnakeCase from '@Utils/ConvertToSnakeCase'

import {
  ISurveyData
} from './types'

export function submitSurvey(data: ISurveyData) {
  const config: AxiosRequestConfig = {
    headers: {
      'X-CSRF-Token': window.Checksub.token
    },
    responseType: 'json'
  }

  return axios.put('/api/v1/users/survey', convertToSnakeCase(data), config)
}
