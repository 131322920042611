import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { IApplicationState } from '@Site/rootReducer'
import getParamValueFromUrl from '@Utils/GetParamValueFromUrl'
import Header from '@SiteContainers/Header'
import Wave from '@Images/wave.svg'
import ProcessingInfo from '@Utils/ProcessingInfo'
import classnames from 'classnames'
import './Processing.scss'

const TIME_BETWEEN_CURRENT_PROCESSING_UPDATES = 10000 // 10 sec
const UPPER_PERCENTAGE_LIMIT_FOR_COUTING = 99
const SECONDS_PER_MINUTE = 60
const COUNT_INTERVAL = 0.1 // %, a smaller number means more updates of the % shown
const LOAD_INTERVAL = 0.1  // %, a smaller number makes the progress bar update smoother
const LOAD_PERIOD = 10 // sec. Time until progress bar reaches the maximum

import {
  IFetchProcessingInfoPayload,
  IFetchCurrentProcessingStepPayload,
} from './types'

import {
  fetchProcessingInfo,
  fetchCurrentProcessingStep,
} from './actions'

interface ProcessingProps {
  readonly loading: boolean
  readonly allProcessingSteps: string[]
  readonly currentProcessingStep: string
  readonly projectVersionDuration: number
  readonly projectTitle: string
  readonly projectId: string
  readonly subtitleId: number
  readonly hasVoice: boolean
  fetchProcessingInfo(payload: IFetchProcessingInfoPayload): void
  fetchCurrentProcessingStep(payload: IFetchCurrentProcessingStepPayload): void
}

interface ProcessingState {
  projectVersionId: string
  totalProcessingDuration: number // in sec
  percentageDone: number
  loaderPercentage: number
  currentStepUpperPercentageLimit: number
  intervalIdForUpdateOfCurrentProcessingStep: number
  intervalIdForCounting: number
  intervalIdForLoading: number
  onlyDownloading: boolean
}

class Processing extends React.Component<ProcessingProps, ProcessingState> {
  constructor(props: ProcessingProps) {
    super(props)

    this.state = {
      projectVersionId: '',
      totalProcessingDuration: 0,
      percentageDone: 0,
      currentStepUpperPercentageLimit: 0,
      loaderPercentage: 0,
      intervalIdForUpdateOfCurrentProcessingStep: -1,
      intervalIdForCounting: -1,
      intervalIdForLoading: -1,
      onlyDownloading: false
    }
  }

  componentDidMount() {
    const projectVersionId = getParamValueFromUrl(window.location.href, 'projectVersionId')

    if (projectVersionId) {
      this.setState({ projectVersionId })
      this.props.fetchProcessingInfo({ projectVersionId })
    } else {
      window.location.href = '/'
    }
  }

  componentDidUpdate(previousProps: ProcessingProps) {
    const {
      loading,
      allProcessingSteps,
      currentProcessingStep,
      projectVersionDuration
    } = this.props

    if (!loading) {
      this.forwardIfNecessary()
    }

    if ((previousProps.loading !== loading) && !loading) {
      if (allProcessingSteps.length > 0) {
        const totalProcessingDuration = ProcessingInfo.getTotalProcessingDuration(allProcessingSteps, projectVersionDuration)
        this.setState({ totalProcessingDuration })
        this.prepareCounting(totalProcessingDuration)
      } else {
        this.setState({ onlyDownloading: true })
      }

      this.prepareUpdatingCurrentProcessingStep()
      this.prepareLoading()
    } else if ((previousProps.currentProcessingStep !== currentProcessingStep) && !this.state.onlyDownloading) {
      clearInterval(this.state.intervalIdForCounting)
      this.prepareCounting(this.state.totalProcessingDuration)
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalIdForUpdateOfCurrentProcessingStep)
    clearInterval(this.state.intervalIdForCounting)
    clearInterval(this.state.intervalIdForLoading)
  }

  prepareUpdatingCurrentProcessingStep = () => {
    const newTimerId = window.setInterval(this.updateCurrentProcessingStep, TIME_BETWEEN_CURRENT_PROCESSING_UPDATES)
    this.setState({ intervalIdForUpdateOfCurrentProcessingStep: newTimerId })
  }

  updateCurrentProcessingStep = () => {
    this.props.fetchCurrentProcessingStep({ projectVersionId: this.state.projectVersionId })
  }

  prepareCounting = (totalProcessingDuration: number) => {
    const {
      allProcessingSteps,
      currentProcessingStep,
      projectVersionDuration
    } = this.props

    const currentStepPercentageLimits = ProcessingInfo.getPercentageLimitsForStep(currentProcessingStep, allProcessingSteps, totalProcessingDuration, projectVersionDuration)
    this.setState({ percentageDone: currentStepPercentageLimits[0], currentStepUpperPercentageLimit: currentStepPercentageLimits[1] }, () => {
      const newTimerId = window.setInterval(this.count, totalProcessingDuration / 100 * 1000 * COUNT_INTERVAL) // divided by 100 to calculate processing duration per percent, multiplied 1000 to convert to ms
      this.setState({ intervalIdForCounting: newTimerId })
    })
  }

  count = () => {
    const shouldCount = this.state.percentageDone < this.state.currentStepUpperPercentageLimit && this.state.percentageDone < UPPER_PERCENTAGE_LIMIT_FOR_COUTING

    if (shouldCount) {
      this.setState({ percentageDone: this.state.percentageDone + COUNT_INTERVAL })
    }
  }

  prepareLoading = () => {
    const newTimerId = window.setInterval(this.load, LOAD_PERIOD / 100 * 1000 * LOAD_INTERVAL) // divided by 100 to calculate load period per percent, multiplied 1000 to convert to ms
    this.setState({ intervalIdForLoading: newTimerId })
  }

  load = () => {
    let newLoaderPercentage = this.state.loaderPercentage + LOAD_INTERVAL
    if (newLoaderPercentage > 100) {
      newLoaderPercentage = 0
    }

    this.setState({ loaderPercentage: newLoaderPercentage })
  }

  forwardIfNecessary = () => {
    const { currentProcessingStep, projectId, subtitleId, hasVoice } = this.props

    if (currentProcessingStep === 'processed' && this.state.onlyDownloading) {
      window.location.href = `/setup?projectVersionIds=${this.state.projectVersionId}`
    } else if (currentProcessingStep === 'processed') {
      const urlParams = hasVoice ? '?openVoice=true' : ''

      window.location.href = `/projects/${projectId}/project_versions/${this.state.projectVersionId}/subtitles/${subtitleId}/edit${urlParams}`
    } else if (currentProcessingStep === '') {
      window.location.href = '/'
    }
  }

  renderInfo = () => {
    const { onlyDownloading } = this.state

    return (
      <div className="Processing__info">
        <div className="Processing__info-title">
          {this.props.projectTitle}
        </div>
        <div
          className={classnames(
            'Processing__info-text',
            { 'Processing__info-text-downloading': onlyDownloading }
          )}
        >
          {`We're ${onlyDownloading ? 'downloading your video' : 'generating your project'}. `} <br/>
          You can close this page and come back later.
        </div>
        {!onlyDownloading && this.renderDurationInfo()}
      </div>
    )
  }

  renderDurationInfo = () => {
    const remainingDurationEstimateInSeconds = (100 - this.state.percentageDone ) / 100 * this.state.totalProcessingDuration
    const remainingDurationEstimate = remainingDurationEstimateInSeconds > SECONDS_PER_MINUTE ? `${Math.round(remainingDurationEstimateInSeconds / SECONDS_PER_MINUTE)} minutes` : `${Math.round(remainingDurationEstimateInSeconds)} seconds`

    return (
      <React.Fragment>
        <div className="Processing__info-percentage">
          {Math.round(this.state.percentageDone)}%
        </div>
        <div className="Processing__info-time">
          Estimated time remaining: {remainingDurationEstimate}
        </div>
      </React.Fragment>
    )

  }

  render() {
    return (
      <div className="Processing">
        <div className="Processing__progress" style={{'width' : `${this.state.loaderPercentage}%`}} />
        <div className="Processing__main">
          <Header
            hideCredits
            hideSalesLink
          />
          <div className="Processing__body">
            {this.renderInfo()}
            <Wave className="Processing__wave"/>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { processing } = state

  return {
    loading: processing.loading,
    allProcessingSteps: processing.attributes.allProcessingSteps,
    currentProcessingStep: processing.attributes.currentProcessingStep,
    projectVersionDuration: processing.attributes.projectVersionDuration,
    projectTitle: processing.attributes.projectTitle,
    projectId: processing.attributes.projectId,
    subtitleId: processing.attributes.subtitleId,
    hasVoice: processing.attributes.hasVoice
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    fetchProcessingInfo,
    fetchCurrentProcessingStep,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Processing)
