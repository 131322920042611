import { put, takeEvery } from 'redux-saga/effects'
import * as Eff from 'redux-saga/effects' // tslint:disable-line:no-duplicate-imports
import toastr from 'toastr'

import Logger from '@Utils/Logger'

import {
  IAction,
  ISuccessResponse,
} from '@Root/types'

import {
  cancelSubscriptionFailure,
  cancelSubscriptionSuccess,
  startCheckoutSessionFailure,
  updateSubscriptionFailure,
  updateSubscriptionSuccess,
} from './actions'

import {
  SubscriptionsActionTypes,
  ICancelSubscriptionPayload,
  IStartCheckoutSessionPayload,
  IUpdateSubscriptionPayload,
  IUpdateSubscriptionSuccessResponseBody,
  ICancelSubscriptionSuccessResponseBody,
} from './types'

import * as API from './api'

const call: any = Eff.call

function* cancelSubscriptionSaga(action: IAction<ICancelSubscriptionPayload>): Generator<any, any, any> {
  try {
    const response: ISuccessResponse<ICancelSubscriptionSuccessResponseBody> = yield call(API.cancelSubscription, action.payload)
    const payloadResponse = response.data.data
    yield put(cancelSubscriptionSuccess(payloadResponse))
    toastr.success('', 'Subscription has been canceled.')
  } catch (e) {
    Logger.error(e, 'Can\'t cancel the subscription')
    yield put(cancelSubscriptionFailure())
  }
}

interface IRedirectUri {
  readonly redirect_uri: string
}

function* startCheckoutSessionSaga(action: IAction<IStartCheckoutSessionPayload>): Generator<any, any, any> {
  try {
    const response: ISuccessResponse<IRedirectUri> = yield call(API.startCheckoutSession, action.payload)
    window.location.href = response.data.redirect_uri
  } catch (e) {
    Logger.error(e, 'Can\'t sell the subscription')
    yield put(startCheckoutSessionFailure())
  }
}

function* updateSubscriptionSaga(action: IAction<IUpdateSubscriptionPayload>): Generator<any, any, any> {
  try {
    const response: ISuccessResponse<IUpdateSubscriptionSuccessResponseBody> = yield call(API.updateSubscription, action.payload)
    const payloadResponse = response.data.data
    yield put(updateSubscriptionSuccess(payloadResponse))
    toastr.success('', 'Subscription has been updated.')
  } catch (e) {
    Logger.error(e, 'Can\'t update the subscription')
    yield put(updateSubscriptionFailure())
  }
}

function* subscriptionSagas() {
  yield takeEvery(SubscriptionsActionTypes.CANCEL_SUBSCRIPTION, cancelSubscriptionSaga)
  yield takeEvery(SubscriptionsActionTypes.START_CHECKOUT_SESSION, startCheckoutSessionSaga)
  yield takeEvery(SubscriptionsActionTypes.UPDATE_SUBSCRIPTION, updateSubscriptionSaga)
}

export default subscriptionSagas
