import React from 'react'
import DropdownList from '@checksub_team/react-widgets/lib/DropdownList'
import indexOf from 'lodash/indexOf'

import './LipSyncModel.scss'

interface LipSyncModelProps {
  readonly lipSyncModel: string
  setLipSyncModel(newLipSyncModel: string): void
}

const LIP_SYNC_MODELS = ['Fox', 'Tiger']
const LIP_SYNC_MODELS_WITH_EXPLANATION = ['Fox - Standard quality, lower price', 'Tiger - Premium quality, higher price']

const LipSyncModel: React.FC<LipSyncModelProps> = props => {
  const lipSyncModelIndex = indexOf(LIP_SYNC_MODELS, props.lipSyncModel)
  const lipSyncModelWithExplanation = LIP_SYNC_MODELS_WITH_EXPLANATION[lipSyncModelIndex]

  const changeLipSyncModel = (newLipSyncModelWithExplanation: string) => {
    const newLipSyncModelIndex = indexOf(LIP_SYNC_MODELS_WITH_EXPLANATION, newLipSyncModelWithExplanation)
    props.setLipSyncModel(LIP_SYNC_MODELS[newLipSyncModelIndex])
  }

  return (
    <div className="ProjectVersionSettingsForm__formfield LipSyncModel__formfield">
      <label className="ProjectVersionSettingsForm__label LipSyncModel__label" htmlFor="lipSyncModel">
        Lip-sync model:
      </label>
      <DropdownList
        data={LIP_SYNC_MODELS_WITH_EXPLANATION}
        id="lipSyncModel"
        name="lipSyncModel"
        value={lipSyncModelWithExplanation}
        onChange={changeLipSyncModel}
      />
    </div>
  )
}

export default LipSyncModel
