import React, { useState, useEffect } from 'react'
import map from 'lodash/map'
import find from 'lodash/find'
import DropdownV2 from '@EditorComponents/DropdownV2'
import PlayButtonForVoices from './PlayButtonForVoices'
import WarningIconVoice from '@Images/warning_icon_voice.svg'

import {
  IVoiceAttributes,
} from '@EditorContainers/Settings/types'

const PREMIUM_VOICE_SERVICES = ['ELEVENLABS']

interface VoiceNameProps {
  readonly gender: string
  readonly voiceName: string
  readonly country: string
  readonly languageCode: string
  readonly voices: IVoiceAttributes[]
  setVoiceName(newVoiceName: string): void
}

const VoiceName: React.FC<VoiceNameProps> = props => {
  const {
    voices,
    gender,
    country,
    voiceName,
    languageCode,
  } = props

  const [voicesForGenderAndCountry, setVoicesForGenderAndCountry] = useState<IVoiceAttributes[]>([])
  const [currentVoiceHasWrongLanguage, setCurrentVoiceHasWrongLanguage] = useState(false)
  const [showInfoForVoiceHasWrongLanguage, setShowInfoForVoiceHasWrongLanguage] = useState(false)
  const [infoForVoiceHasWrongLanguageTimeout, setInfoForVoiceHasWrongLanguageTimeout] = useState<ReturnType<typeof setTimeout> | null>(null)
  const voiceNamesForGenderAndCountry = map(voicesForGenderAndCountry, voice => voice.name)
  const hasOnlyOneVoiceName = voiceNamesForGenderAndCountry.length <= 1
  const shouldShowWarningForVoiceHasWrongLanguage = showInfoForVoiceHasWrongLanguage && currentVoiceHasWrongLanguage

  useEffect(() => {
    let newVoicesForGenderAndCountry = voices.filter(voice => voice.gender === gender)
    if (country !== 'All') {
      newVoicesForGenderAndCountry = newVoicesForGenderAndCountry.filter(voice => voice.country === country)
    }

    if (newVoicesForGenderAndCountry.length === 0) { return }
    setVoicesForGenderAndCountry(newVoicesForGenderAndCountry)

    let currentVoiceHasWrongGender = true
    let currentVoiceHasWrongCountry = true

    if (voiceName && voiceName.length > 0) {
      const currentVoice = voices.find(voice => voice.name === voiceName)

      if (currentVoice) {
        currentVoiceHasWrongGender = currentVoice.gender !== gender
        currentVoiceHasWrongCountry = country === 'All' ? false : currentVoice.country !== country
      }
    }

    if (currentVoiceHasWrongGender || currentVoiceHasWrongCountry) {
      const newVoice = find(newVoicesForGenderAndCountry, newVoiceForGenderAndCountry => newVoiceForGenderAndCountry.languages.includes(languageCode)) || newVoicesForGenderAndCountry[0]
      handleChangeVoiceName(newVoice.name)
    }
  }, [gender, country])

  useEffect(() => {
    if (voiceName && voiceName.length > 0 && languageCode.length > 0) {
      const currentVoice = voices.find(voice => voice.name === voiceName) as IVoiceAttributes
      const newCurrentVoiceHasWrongLanguage = !currentVoice.languages.includes(languageCode)
      setCurrentVoiceHasWrongLanguage(newCurrentVoiceHasWrongLanguage)
    } else {
      setCurrentVoiceHasWrongLanguage(false)
    }
  }, [voiceName, languageCode])

  const renderShowInfoForVoiceHasWrongLanguage = () => {
    if (!infoForVoiceHasWrongLanguageTimeout) {
      const newInfoForVoiceHasWrongLanguageTimeout = setTimeout(() => {
        setShowInfoForVoiceHasWrongLanguage(true)
      }, window.Checksub.tooltipDelay)
      setInfoForVoiceHasWrongLanguageTimeout(newInfoForVoiceHasWrongLanguageTimeout)
    }
  }

  const hideShowInfoForVoiceHasWrongLanguage = () => {
    if (infoForVoiceHasWrongLanguageTimeout) {
      setShowInfoForVoiceHasWrongLanguage(false)
      clearTimeout(infoForVoiceHasWrongLanguageTimeout)
      setInfoForVoiceHasWrongLanguageTimeout(null)
    }
  }

  const itemsToShow = map(voicesForGenderAndCountry, (voice, index) => {
    const isPremiumVoice = PREMIUM_VOICE_SERVICES.includes(voice.serviceName)

    return (
      <span key={index}>
        {voice.name}
        {isPremiumVoice && (
          <span className="VoiceSettings__premium-tag">Premium</span>
        )}
      </span>
    )
  })

  const handleChangeVoiceName = (newVoiceName: string) => {
    props.setVoiceName(newVoiceName)
  }

  return (
    <div className="VoiceSettings__value-wrapper">
      <span className="VoiceSettings__value">Voice</span>
      <div className="VoiceSettings__dropdown-and-play-wrapper">
        {currentVoiceHasWrongLanguage && (
          <WarningIconVoice
            className="VoiceSettings__warning-icon-voice"
            onMouseEnter={renderShowInfoForVoiceHasWrongLanguage}
            onMouseLeave={hideShowInfoForVoiceHasWrongLanguage}
          />
        )}
        <PlayButtonForVoices
          voiceName={voiceName}
          voices={voices}
        />
        <DropdownV2
          items={voiceNamesForGenderAndCountry}
          itemsToShow={itemsToShow}
          currentItem={voiceName}
          onChange={handleChangeVoiceName}
          shouldBlockDropdown={hasOnlyOneVoiceName}
          noPortal
        />
        {shouldShowWarningForVoiceHasWrongLanguage && (
          <div className="VoiceSettings__tooltip VoiceSettings__tooltip-voice-language">
            The language of the selected <br/>
            voice seems to be different <br/>
            from the one of the project. <br/>
            Take into account that the <br/>
            quality of the dubbing <br/>
            is uncertain.
          </div>
        )}
      </div>
    </div>
  )
}

export default VoiceName
