import React from 'react'
import Upload from '@Images/upload_2.svg'
import FreeTrialArrow from '@Images/free_trial_arrow.svg'
import UploadText from '@Images/upload_text.svg'

interface UploadAreaProps {
  readonly fileRef: React.RefObject<HTMLInputElement>
  readonly shouldRenderFreeTrialMessage: boolean
  readonly limitToOneFile?: boolean
  openFileManager(): void
  onFileDrop(e: React.DragEvent<HTMLDivElement>): void
  handleDragEnter(e: React.DragEvent<HTMLDivElement>): void
  handleDragLeave(e: React.DragEvent<HTMLDivElement>): void
  handleUpload(filesToUpload: File[]): void
  handleUploadClick(e: React.ChangeEvent<HTMLInputElement>): void
  handleDragOver(e: React.DragEvent<HTMLDivElement>): void
}

const UploadArea: React.FC<UploadAreaProps> = props => {
  const renderFreeTrialMessage = () => {
    return (
      <React.Fragment>
        <div className="Upload__free-trial-message">
          <div className="Upload__free-trial-message-header">
            Free trial
          </div>
          <UploadText/>
        </div>
        <FreeTrialArrow className="Upload__free-trial-arrow"/>
      </React.Fragment>
    )
  }

  return (
    <div
      className="Upload__upload-area"
      onClick={props.openFileManager}
      onDrop={props.onFileDrop}
      onDragOver={props.handleDragOver}
      onDragEnter={props.handleDragEnter}
      onDragLeave={props.handleDragLeave}
    >
      <Upload className="Upload__upload-area-image"/>
      <div className="Upload__upload-area-text">
        Click to upload <br />
        {`or drag-and-drop your file${props.limitToOneFile ? '' : 's' } here`}
      </div>
      <input
        ref={props.fileRef}
        type="file"
        multiple={!props.limitToOneFile}
        className="Upload__upload-area-file"
        onChange={props.handleUploadClick}
        accept=".mov,video/mp4,video/x-m4v,audio/*,video/*,.mkv"
      />
      {props.shouldRenderFreeTrialMessage && renderFreeTrialMessage()}
    </div>
  )
}

export default UploadArea
