import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { IApplicationState } from '@Site/rootReducer'
import map from 'lodash/map'
import classnames from 'classnames'
import ConnectChannelButton from '@SiteComponents/ConnectChannelButton'
import getParamValueFromUrl from '@Utils/GetParamValueFromUrl'
import history from '@Root/historyInstance'

import {
  getExternalVideos
} from '@SiteContainers/ChannelsTable/actions'

import './DashboardMenu.scss'

import {
  IChangeTabPayload
} from './types'

import {
  changeTab
} from './actions'

import {
  IElement
} from '@SiteContainers/AssetsTable/types'

import {
  getElements
} from '@SiteContainers/AssetsTable/actions'

const ASSETS_MENU_ITEM = 'Assets'
const PROJECTS_MENU_ITEM = 'Projects'
const CHANNELS_MENU_ITEM = 'Channels'

interface DashboardMenuProps {
  readonly currentTab: string
  readonly areChannelsLoading: boolean
  readonly areVideosLoading: boolean
  readonly hasLoadedVideosOnce: boolean
  readonly hasExternalChannels: boolean
  readonly hasSubscription: boolean
  readonly elements: IElement[]
  readonly areElementsLoading: boolean
  readonly userHasActiveElements: boolean
  getExternalVideos(): void
  getElements(): void
  changeTab(payload: IChangeTabPayload): void
}

interface DashboardMenuState {
}

class DashboardMenu extends React.Component<DashboardMenuProps, DashboardMenuState> {
  componentDidMount() {
    const shouldShowAssets = getParamValueFromUrl(window.location.href, 'showAssets') === 'true'

    if (shouldShowAssets) {
      this.props.getElements()
      this.props.changeTab({ newTab: 'assets' })
    }
  }

  getMenuItems = () => {
    const menuItems = [PROJECTS_MENU_ITEM]

    if (this.props.hasSubscription && !this.props.areChannelsLoading) {
      menuItems.push(CHANNELS_MENU_ITEM)
    }

    if (this.props.userHasActiveElements) {
      menuItems.push(ASSETS_MENU_ITEM)
    }

    return menuItems
  }

  getTabList = (currentTab: string) => {
    return map(this.getMenuItems(), (value, index) => {
      const isCurrentTab= value.toLowerCase() === currentTab
      const handleChangeTab = () => {
        const newTab = value.toLowerCase()
        const tabChanged = currentTab !== newTab
        if (tabChanged) {
          if (newTab === 'channels' && !this.props.areChannelsLoading && !this.props.hasExternalChannels) {
            history.push('/channels/new')
          } else {
            if (newTab === 'channels' && !this.props.hasLoadedVideosOnce && !this.props.areVideosLoading) {
              this.props.getExternalVideos()
            }

            if (newTab === 'assets' && !this.props.areElementsLoading) {
              this.props.getElements()
            }

            this.props.changeTab({ newTab })
          }
        }
      }

      return (
        <li
          key={index}
          className={classnames(
            { 'DashboardMenu__list-item-current': isCurrentTab }
          )}
          onClick={handleChangeTab}
        >
          {value}
        </li>
      )
    })
  }

  render() {
    const { currentTab } = this.props
    const isCurrentTabChannels = currentTab === 'channels'

    return (
      <div className="DashboardMenu">
        <ul className="DashboardMenu__list">
          {this.getTabList(currentTab)}
        </ul>
        {isCurrentTabChannels && (
          <ConnectChannelButton />
        )}
      </div>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { dashboardMenu, user, channelsTable, assetsTable } = state
  const hasSubscription = user.subscription.attributes.isCurrent

  return {
    currentTab: dashboardMenu.currentTab,
    areChannelsLoading: channelsTable.loadingChannels,
    areVideosLoading: channelsTable.loadingVideos,
    hasLoadedVideosOnce: channelsTable.hasLoadedVideosOnce,
    hasExternalChannels: channelsTable.externalChannels.length > 0,
    elements: assetsTable.elements,
    areElementsLoading: assetsTable.loading,
    userHasActiveElements: user.user.attributes.hasActiveElements,
    hasSubscription
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    changeTab,
    getExternalVideos,
    getElements
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardMenu)
