import React, { useState } from 'react'
import ButtonNewV2 from '@SiteComponents/ButtonNewV2'
import { Link } from 'react-router-dom'
import { IUser, IPasswordable, Submittable } from '@Root/types'
import { useForm } from 'react-hook-form'
// import GoogleButton from '@Images/google_signin_light.png'
import GoogleLogo from '@Images/google-logo.svg'

import './SignInForm.scss'

interface SignInFormProps extends Submittable {
}

type FormData = Pick<IUser, 'email'> & Pick<IPasswordable, 'password'> & {
  readonly remember_me: number // camelize it
}

const SignInForm: React.FC<SignInFormProps> = props => {
  const { register, handleSubmit, errors } = useForm<FormData>()
  const [sso, setSso] = useState(false)

  const onSubmit = handleSubmit(({ email, password, remember_me }) => {
    props.submit(sso ? { emailOrDomain: email } : { email, password, remember_me: +remember_me }, sso)
  })

  const forwardToGoogle = () => {
    window.location.href = '/users/auth/google_oauth2'
  }

  const changeSso = () => {
    setSso(!sso)
  }

  const renderEmail = () => {
    return(
      <div className="SignInForm__formfield">
        <label htmlFor="email">Email</label>
        <input
          id="email"
          name="email"
          type="email"
          placeholder={sso ? 'Write your email address…' : ''}
          ref={register({ required: true, maxLength: 100 })}
        />
        {
          errors.email &&
          errors.email.type === 'required' &&
          <span className="errorMessage">This field is required</span>
        }
        {
          errors.email &&
          errors.email.type === 'maxLength' &&
          <span  className="errorMessage">This field cannot exceed 100 characters</span>
        }
      </div>
    )
  }

  const renderPassword = () => {
    return(
      <div className="SignInForm__formfield">
        <label htmlFor="password">Password</label>
        <input
          id="password"
          name="password"
          type="password"
          ref={register({ required: true, maxLength: 100 })}
        />
        {
          errors.password &&
          errors.password.type === 'required' &&
          <span className="errorMessage">This field is required</span>
        }
        {
          errors.password &&
          errors.password.type === 'maxLength' &&
          <span  className="errorMessage">This field cannot exceed 100 characters</span>
        }
      </div>
    )
  }

  const renderRememberMe = () => {
    return(
      <label className="SignInForm__checkbox" htmlFor="remember_me">
        <input
          id="remember_me"
          name="remember_me"
          type="checkbox"
          ref={register()}
        />
        <span className="SignInForm__checkmark" />
        <span className="SignInForm__checkbox-text" >
          Remember me
        </span>
      </label>
    )
  }

  const renderSubmitButton = () => {
    return(
      <div className="SignInForm__fill-width">
        <ButtonNewV2
          type="submit"
          caption={sso ? 'Continue with SSO' : 'Log in'}
          size="large"
          styleType="brand-primary"
          showSpinner={props.showSpinner}
          disabled={props.disableButton}
        />
      </div>
    )
  }

  const renderLinks = () => {
    return(
      <div className="SignInForm__links">
        <Link to="/users/sign_up">Create a new account</Link><br />
        <Link to="/users/password/new">Forgot your password?</Link><br />
        <Link to="/users/confirmation/new">Didn't receive confirmation instructions?</Link>
      </div>
    )
  }

  const renderDelimiter = () => {
    return(
      <div className="SignInForm__delimiter">
        <hr />
        <span>OR</span>
        <hr />
      </div>
    )
  }

  const renderOauth = () => {
    // TO KEEP IN CASE OF REVIEW
    // return(
    //   <div className="SignInForm__oauth">
    //     <img src={GoogleButton} className="SignInForm__oauth-button SignInForm__oauth-button-google" onClick={forwardToGoogle}/>
    //   </div>
    // )
    return(
      <div className="SignInForm__fill-width">
        <ButtonNewV2
          caption="Sign in with Google"
          size="large"
          styleType="colorful-white"
          icon={<GoogleLogo/>}
          onClick={forwardToGoogle}
        />
      </div>
    )
  }

  const renderHeader = () => {
    return(
      <div className="SignInForm__header">
        Nice to see you again
      </div>
    )
  }

  const renderSso = () => {
    return(
      <div className="SignInForm__fill-width">
        <ButtonNewV2
          caption="Sign in with SSO"
          size="large"
          styleType="neutral-action-default"
          onClick={changeSso}
        />
      </div>
    )
  }

  return(
    <form className="SignInForm" onSubmit={onSubmit} >
      {renderHeader()}
      <div className="SignInForm__sign-in-options">
        {renderOauth()}
        {renderSso()}
      </div>
      {renderDelimiter()}
      {renderEmail()}
      {!sso && renderPassword()}
      {!sso && renderRememberMe()}
      {renderSubmitButton()}
      {renderLinks()}
    </form>
  )
}

export default SignInForm
