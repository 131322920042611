import React from 'react'

import './PaymentOverview.scss'

interface PaymentOverviewProps {
  readonly title: string
  readonly amount: number
  readonly currency: string
  readonly lineItems?: string[]
  readonly description?: string
  readonly shouldApplyVat: boolean
  readonly descriptionItems?: string[]
}

const PaymentOverview: React.FC<PaymentOverviewProps> = props => {
  const { amount, lineItems, descriptionItems, currency } = props
  const formattedAmount = amount.toFixed(2)

  let lineItemsList
  let descriptionItemsList

  if (lineItems) {
    lineItemsList = lineItems.map((lineItem, index) =>
      <li key={index}><b>{lineItem}</b> subtitle</li>
    )
  }

  if (descriptionItems) {
    descriptionItemsList = descriptionItems.map((descriptionItem, index) =>
      <li key={index}>{descriptionItem}</li>
    )
  }

  return (
    <div className="PaymentOverview">
      <div className="PaymentOverview__body">
        <div className="PaymentOverview__body__title">
          {props.title}
        </div>

        <div className="PaymentOverview__body__description">
          {props.description}
        </div>

        <ul className="PaymentOverview__body__lineItemsList">
          {lineItemsList}
          {descriptionItemsList}
        </ul>

        <div className="PaymentOverview__body__total" >
          <span translate="no">Total: {currency}{formattedAmount}</span>
          {props.shouldApplyVat && (
            <div className="PaymentOverview__body__total__vat">
              (20% VAT included)
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default PaymentOverview
