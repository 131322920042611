import { History } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
// import rollbarMiddleware from 'rollbar-redux-middleware'
import createSagaMiddleware from 'redux-saga'
import rootReducer from './rootReducer'
import sagas from './sagas'

// TODO: types
export default function configureStore(history: History): any {
  const middlewares = [routerMiddleware(history)]

  const sagaMiddleware = createSagaMiddleware()

  // TODO: add it
  // if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') {
  //   const rollbar = rollbarMiddleware(window.Rollbar)

  //   middlewares.push(rollbar)
  // }

  if (process.env.NODE_ENV === 'development') {
    const { logger } = require('redux-logger')

    middlewares.push(logger)
  }

  middlewares.push(sagaMiddleware)

  // https://github.com/zalmoxisus/redux-devtools-extension
  const composeEnhancer: typeof compose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const store = createStore(
    rootReducer(history),
    composeEnhancer(applyMiddleware(...middlewares))
  )

  sagaMiddleware.run(sagas)

  return store
}
