import React, { useState, useEffect } from 'react'
import Checkbox from './Checkbox'

interface UploadOptionProps {
  readonly uploadScriptChecked: boolean
  readonly generateShortenedProjectVersion: boolean
  readonly openedForMultipleProjects?: boolean
  setUploadScriptChecked(checked: boolean): void
}

const UploadOption: React.FC<UploadOptionProps> = props => {
  const [showInfoForDisabledUploadOption, setShowInfoForDisabledUploadOption] = useState(false)
  const [infoForDisabledUploadOptionTimeout, setInfoForDisabledUploadOptionTimeout] = useState<ReturnType<typeof setTimeout> | null>(null)

  const {
    uploadScriptChecked,
    openedForMultipleProjects,
    setUploadScriptChecked,
    generateShortenedProjectVersion,
  } = props

  useEffect(() => {
    if (generateShortenedProjectVersion && uploadScriptChecked) {
      setUploadScriptChecked(false)
    }
  }, [generateShortenedProjectVersion])

  const toggleInfoForDisabledUploadOption = () => {
    if (infoForDisabledUploadOptionTimeout) {
      setShowInfoForDisabledUploadOption(false)
      clearTimeout(infoForDisabledUploadOptionTimeout)
      setInfoForDisabledUploadOptionTimeout(null)
    } else {
      const newInfoForDisabledUploadOptionTimeout = setTimeout(() => {
        setShowInfoForDisabledUploadOption(true)
      }, window.Checksub.tooltipDelay)
      setInfoForDisabledUploadOptionTimeout(newInfoForDisabledUploadOptionTimeout)
    }
  }

  const shouldShowDisabledUploadOptionMultiple = openedForMultipleProjects
  const shouldShowInfoForDisabledUploadOption = showInfoForDisabledUploadOption && (shouldShowDisabledUploadOptionMultiple || generateShortenedProjectVersion)

  const infoMultiple = () => {
    return (
      <div className="ProjectVersionSettingsForm__tooltip ProjectVersionSettingsForm__tooltip-upload-multiple">
        This option is available when ordering the subtitles<br />
        separately for each project from the dashboard.
      </div>
    )
  }

  const infoShortenedProjectVersion = () => {
    return (
      <div className="ProjectVersionSettingsForm__tooltip ProjectVersionSettingsForm__tooltip-upload-shortened">
        Upgrade to use this option
      </div>
    )
  }

  return (
    <div
      className="ProjectVersionSettingsForm__upload-option"
      onMouseEnter={toggleInfoForDisabledUploadOption}
      onMouseLeave={toggleInfoForDisabledUploadOption}
    >
      <Checkbox
        checked={uploadScriptChecked}
        setChecked={setUploadScriptChecked}
        disabled={openedForMultipleProjects || generateShortenedProjectVersion}
        label="Import a script or SRT file as transcription"
      />
      {shouldShowInfoForDisabledUploadOption && (
        shouldShowDisabledUploadOptionMultiple ? infoMultiple() : infoShortenedProjectVersion()
      )}
    </div>
  )
}

export default UploadOption
