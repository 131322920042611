import React  from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { IApplicationState } from '@Site/rootReducer'
import SalesTeam from '@Images/sales_team.png'
import classnames from 'classnames'

import './DemoFooter.scss'

interface DemoFooterProps {
  submitted: boolean
}

interface DemoFooterState {
}

class DemoFooter extends React.Component<DemoFooterProps, DemoFooterState> {
  render() {
    return (
      <footer className={classnames(
        'DemoFooter',
        { 'DemoFooter__hidden': this.props.submitted }
      )}>
        <img src={SalesTeam}/>
        <a
          href="https://meetings-eu1.hubspot.com/florian-stegre/show-event"
          className="DemoFooter__book-meeting"
          target="_blank"
        >
          Book a meeting with our Sales Team
        </a>
      </footer>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { demo } = state

  return {
    submitted: demo.submitted
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(DemoFooter)
