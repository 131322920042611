import {
  IVocabulary,
  IVocabularyState,
  IVocabularyActionTypes,
  VocabularyActionTypes,
  IFetchVocabulariesSuccessAction,
} from './types'

export const initialState: IVocabularyState = {
  errors: [],
  vocabularies: [],
  createVocabulary: {
    loading: false
  },
  updateVocabulary: {
    loading: false
  },
  deleteVocabulary: {
    loading: false
  },
  fetchVocabularies: {
    loading: false
  },
}

export const vocabulariesReducer = (
  state: IVocabularyState = initialState,
  action: IVocabularyActionTypes
): IVocabularyState => {
  switch (action.type) {
    case VocabularyActionTypes.FETCH_VOCABULARIES: return fetchVocabularies(state)
    case VocabularyActionTypes.FETCH_VOCABULARIES_SUCCESS: return fetchVocabulariesSuccess(state, action)
    case VocabularyActionTypes.FETCH_VOCABULARIES_FAILURE: return fetchVocabulariesFailure(state)
    case VocabularyActionTypes.CREATE_VOCABULARY: return createVocabulary(state)
    case VocabularyActionTypes.CREATE_VOCABULARY_SUCCESS: return createVocabularySuccess(state)
    case VocabularyActionTypes.CREATE_VOCABULARY_FAILURE: return createVocabularyFailure(state)
    case VocabularyActionTypes.UPDATE_VOCABULARY: return updateVocabulary(state)
    case VocabularyActionTypes.UPDATE_VOCABULARY_SUCCESS: return updateVocabularySuccess(state)
    case VocabularyActionTypes.UPDATE_VOCABULARY_FAILURE: return updateVocabularyFailure(state)
    case VocabularyActionTypes.DELETE_VOCABULARY: return deleteVocabulary(state)
    case VocabularyActionTypes.DELETE_VOCABULARY_SUCCESS: return deleteVocabularySuccess(state)
    case VocabularyActionTypes.DELETE_VOCABULARY_FAILURE: return deleteVocabularyFailure(state)
    default: return state
  }
}

const fetchVocabularies = (state: IVocabularyState): IVocabularyState => {
  return { ...state, fetchVocabularies: { loading: true } }
}

function isIFetchVocabulariesSuccessAction(arg: any): arg is IFetchVocabulariesSuccessAction {
  return arg.payload && arg.payload.data != null
}

const fetchVocabulariesSuccess = (state: IVocabularyState, action: IVocabularyActionTypes): IVocabularyState => {
  let vocabularies: IVocabulary[] = []

  if (isIFetchVocabulariesSuccessAction(action)) {
    vocabularies = action.payload.data
  }

  return { ...state, vocabularies, fetchVocabularies: { loading: false } }
}

const fetchVocabulariesFailure = (state: IVocabularyState): IVocabularyState => {
  return { ...state, fetchVocabularies: { loading: false } }
}

const createVocabulary = (state: IVocabularyState): IVocabularyState => {
  return { ...state, createVocabulary: { loading: true } }
}

const createVocabularySuccess = (state: IVocabularyState): IVocabularyState => {
  return { ...state, createVocabulary: { loading: false } }
}

const createVocabularyFailure = (state: IVocabularyState): IVocabularyState => {
  return { ...state, createVocabulary: { loading: false } }
}

const updateVocabulary = (state: IVocabularyState): IVocabularyState => {
  return { ...state, updateVocabulary: { loading: true } }
}

const updateVocabularySuccess = (state: IVocabularyState): IVocabularyState => {
  return { ...state, updateVocabulary: { loading: false } }
}

const updateVocabularyFailure = (state: IVocabularyState): IVocabularyState => {
  return { ...state, updateVocabulary: { loading: false } }
}

const deleteVocabulary = (state: IVocabularyState): IVocabularyState => {
  return { ...state, deleteVocabulary: { loading: true } }
}

const deleteVocabularySuccess = (state: IVocabularyState): IVocabularyState => {
  return { ...state, deleteVocabulary: { loading: false } }
}

const deleteVocabularyFailure = (state: IVocabularyState): IVocabularyState => {
  return { ...state, deleteVocabulary: { loading: false } }
}
