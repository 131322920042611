import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import * as Eff from 'redux-saga/effects' // tslint:disable-line:no-duplicate-imports
import toastr from 'toastr'

import Logger from '@Utils/Logger'

import {
  fetchProjectVersionSettingsSuccess,
  projectVersionSettingsFailure,
  submitProjectVersionUpdateSuccess,
  submitProjectVersionUpdateFailure,
  fetchProjectVersionSettingsMultipleSuccess,
  fetchProjectVersionSettingsDemoSuccess,
} from './actions'

import {
  ProjectVersionSettingsActionTypes,
  PayloadSubmitProjectVersionUpdateBody,
  IFetchProjectVersionSettingsSuccessResponse,
  IFetchProjectVersionSettingsMultipleSuccessResponse,
  IFetchProjectVersionSettingsDemoSuccessResponse,
} from './types'

import {
  openSetThemePopup,
  setTargetForTheme,
  setIsReadyForTheme
} from '@SiteContainers/Themes/actions'

import * as API from './api'

interface IAction extends Action {
  readonly payload: object
}

const call: any = Eff.call

function* submitProjectVersionUpdateSaga(action: IAction): Generator<any, any, any> {
  const payload = action.payload as PayloadSubmitProjectVersionUpdateBody

  try {
    yield put(openSetThemePopup())
    yield put(setTargetForTheme({
      projectVersionIds: payload.projectVersionIds,
      subtitleLanguages: payload.subtitleLanguages,
      isNewProject: payload.includesTranscript
    }))
    yield call(API.submitProjectVersionUpdate, { payload })
    yield put(submitProjectVersionUpdateSuccess())

    if (payload.includesTranscript) {
      toastr.info('You\'ll get an email once it\'s ready ✉️', 'We\'re processing your project(s)')
    } else {
      toastr.success('', 'Request submitted')
    }

    yield put(setIsReadyForTheme())
  } catch (e) {
    Logger.error(e, 'Request could not be submitted')
    yield put(submitProjectVersionUpdateFailure())
  }
}

function* fetchProjectVersionSettingsSaga(action: IAction): Generator<any, any, any> {
  try {
    const response: IFetchProjectVersionSettingsSuccessResponse = yield call(API.fetchProjectVersionSettings, action.payload)
    const { projectVersionData, otherData, languageData } = response.data
    const payload = { attributes: { ...projectVersionData.attributes, ...otherData.attributes, ...languageData.attributes } }

    yield put(fetchProjectVersionSettingsSuccess(payload))
  } catch (e) {
    Logger.error(e, 'Video data could not be loaded')
    yield put(projectVersionSettingsFailure())
  }
}

function* fetchProjectVersionSettingsMultipleSaga(action: IAction): Generator<any, any, any> {
  try {
    const response: IFetchProjectVersionSettingsMultipleSuccessResponse = yield call(API.fetchProjectVersionSettingsMultiple, action.payload)
    const { projectVersionData, otherData, languageData } = response.data
    const payload = { attributes: { ...projectVersionData.attributes, ...otherData.attributes, ...languageData.attributes } }

    yield put(fetchProjectVersionSettingsMultipleSuccess(payload))
  } catch (e) {
    Logger.error(e, 'Video data could not be loaded')
    yield put(projectVersionSettingsFailure())
  }
}

function* fetchProjectVersionSettingsDemoSaga(action: IAction): Generator<any, any, any> {
  try {
    const response: IFetchProjectVersionSettingsDemoSuccessResponse = yield call(API.fetchProjectVersionSettingsDemo)
    const { languageData } = response.data
    const payload = { attributes: { ...languageData.attributes } }

    yield put(fetchProjectVersionSettingsDemoSuccess(payload))
  } catch (e) {
    Logger.error(e, 'Data could not be loaded')
    yield put(projectVersionSettingsFailure())
  }
}

function* projectVersionSettingsSagas() {
  yield takeLatest(ProjectVersionSettingsActionTypes.SUBMIT_PROJECT_VERSION_UPDATE, submitProjectVersionUpdateSaga)
  yield takeLatest(ProjectVersionSettingsActionTypes.FETCH_PROJECT_VERSION_SETTINGS, fetchProjectVersionSettingsSaga)
  yield takeLatest(ProjectVersionSettingsActionTypes.FETCH_PROJECT_VERSION_SETTINGS_MULTIPLE, fetchProjectVersionSettingsMultipleSaga)
  yield takeLatest(ProjectVersionSettingsActionTypes.FETCH_PROJECT_VERSION_SETTINGS_DEMO, fetchProjectVersionSettingsDemoSaga)
}

export default projectVersionSettingsSagas
