import { put, takeEvery } from 'redux-saga/effects'
import * as Eff from 'redux-saga/effects' // tslint:disable-line:no-duplicate-imports
import toastr from 'toastr'

import Logger from '@Utils/Logger'
import { IAction } from '@Root/types'

import {
  deleteProjectVersionSuccess,
  deleteProjectVersionFailure
} from './actions'

import {
  getUserInfo
} from '@SiteModules/User/actions'

import {
  DeleteProjectVersionActionTypes,
  IDeleteProjectVersionPayload
} from './types'

import {
  closePopup,
} from '@SiteContainers/ProjectVersionsList/actions'

import * as API from './api'

const call: any = Eff.call

function* deleteProjectVersionSaga(action: IAction<IDeleteProjectVersionPayload>) {
  try {
    yield call(API.deleteProjectVersion, action.payload)
    yield put(deleteProjectVersionSuccess())
    yield put(closePopup())
    yield put(getUserInfo())
    toastr.success('', 'Project version(s) deleted')
  } catch (e) {
    Logger.error(e, 'Project version(s) could not be deleted')
    yield put(deleteProjectVersionFailure())
  }
}

function* deleteProjectVersionSagas() {
  yield takeEvery(DeleteProjectVersionActionTypes.DELETE_PROJECT_VERSION, deleteProjectVersionSaga)
}

export default deleteProjectVersionSagas
