import {
  ProjectActionTypes
} from './types'

export const createProject = () => ({
  type: ProjectActionTypes.CREATE_PROJECT,
})

export const createProjectSuccess = () => ({
  type: ProjectActionTypes.CREATE_PROJECT_SUCCESS,
})

export const createProjectFailure = () => ({
  type: ProjectActionTypes.CREATE_PROJECT_FAILURE,
})
