import map from 'lodash/map'
import last from 'lodash/last'

export default function modifyUrls(urls: string[]): (string | undefined)[] {
  const modifiedUrls = map(urls, url => {
    if (url.includes('.wistia.com/media')) {
      const wistiaVideoId = last(url.split('/'))
      return `https://fast.wistia.net/embed/iframe/${wistiaVideoId}`
    } else if (url.includes('youtube.com/shorts')) {
      const youtubeVideoId = last(url.split('/'))
      return `https://youtu.be/${youtubeVideoId}`
    } else if (url.includes('youtube.com') || url.includes('youtu.be')) {
      return simplifiedYoutubUrl(url)
    } else {
      return url
    }
  })

  return modifiedUrls
}

// https://blog.devgenius.io/how-to-extract-the-id-of-a-youtube-or-vimeo-url-with-javascript-ad5e2d1049a
const simplifiedYoutubUrl = (url: string) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  const match = url.match(regExp)
  return match && match[2].length === 11 ? `https://youtu.be/${match[2]}` : undefined
}
