import { TActions } from '@Root/types'

export const PaymentActionTypes = {
  FETCH_CREDIT: '@@payment/FETCH_CREDIT',
  FETCH_CREDIT_SUCCESS: '@@payment/FETCH_CREDIT_SUCCESS',
  FETCH_CREDIT_FAILURE: '@@payment/FETCH_CREDIT_FAILURE',

  UPDATE_STRIPE_TOKEN: '@@payment/UPDATE_STRIPE_TOKEN',
  UPDATE_STRIPE_TOKEN_SUCCESS: '@@payment/UPDATE_STRIPE_TOKEN_SUCCESS',
  UPDATE_STRIPE_TOKEN_FAILURE: '@@payment/UPDATE_STRIPE_TOKEN_FAILURE',

  PAYMENT_FAILURE: '@@payment/PAYMENT_FAILURE',
  PAYMENT_SUCCESS: '@@payment/PAYMENT_SUCCESS',
}

export interface IFetchCreditAction {
  readonly type: string
  readonly creditId: number
}

export interface IPriceData {
  readonly data: IPrice
}

export interface IPrice {
  readonly id: string,
  readonly type: 'price'
  readonly attributes: {
    readonly amount: number
    readonly vat: number
    readonly shouldApplyVat: boolean
    readonly currency: string
  }
}

export interface ICheckoutsPaymentDetails {
  readonly tokenUpdated: boolean
}

export interface IStripeToken {
  readonly token: string
}

export interface IPaymentSuccessPayload {
  readonly paidItem: string
  readonly paidItemId: number
  readonly amount: number
  readonly vat: number
  readonly currency: string
}

export type IPaymentActionTypes = IFetchCreditAction | TActions<{}, IPrice>
