import React from 'react'
import Button from '@SiteComponents/Button'
import { useForm } from 'react-hook-form'

import '@checksub_team/react-widgets/dist/css/react-widgets.css'

import '@Root/config/dropdowns.scss'
import './BillingAddressForm.scss'

interface BillingAddressFormStep1Props {
  readonly name: string
  readonly lastName: string
  readonly company: string
  readonly showSpinner: boolean
  readonly disableButton: boolean
  submit(name: string, lastName: string, company: string): void
}

type FormData = Omit<BillingAddressFormStep1Props, 'submit' | 'showSpinner' | 'disableButton'>

const BillingAddressFormStep1: React.FC<BillingAddressFormStep1Props> = props => {
  const { register, errors, handleSubmit } = useForm<FormData>()

  const onSubmit = handleSubmit(({ name, lastName, company }) => {
    props.submit(name, lastName, company)
  })

  const renderCompany = () => {
    return(
      <div className="BillingAddressForm__formfield">
        <label htmlFor="company">Enterprise (optional)</label>
        <input
          id="company"
          name="company"
          type="text"
          defaultValue={props.company}
          ref={register()}
        />
      </div>
    )
  }

  const renderName = () => {
    return(
      <div className="BillingAddressForm__formfield">
        <label htmlFor="name">First Name</label>
        <input
          id="name"
          name="name"
          type="text"
          defaultValue={props.name}
          ref={register({ required: true, maxLength: 100 })}
        />
        {
          errors.name &&
          errors.name.type === 'required' &&
          <span className="errorMessage">This field is required</span>
        }
        {
          errors.name &&
          errors.name.type === 'maxLength' &&
          <span  className="errorMessage">This field cannot exceed 100 characters</span>
        }
      </div>
    )
  }

  const renderLastName = () => {
    return(
      <div className="BillingAddressForm__formfield">
        <label htmlFor="Name">Last Name</label>
        <input
          id="lastName"
          name="lastName"
          type="text"
          defaultValue={props.lastName}
          ref={register({ required: true, maxLength: 100 })}
        />
        {
          errors.lastName &&
          errors.lastName.type === 'required' &&
          <span className="errorMessage">This field is required</span>
        }
        {
          errors.lastName &&
          errors.lastName.type === 'maxLength' &&
          <span  className="errorMessage">This field cannot exceed 100 characters</span>
        }
      </div>
    )
  }

  const renderSubmitButton = () => {
    return(
      <Button
        type="submit"
        caption={
          <React.Fragment>
            Continue <span className="BillingAddressForm_arrow">→</span>
          </React.Fragment>
        }
        color="primary-dark"
        showSpinner={props.showSpinner}
        disabled={props.disableButton}
      />
    )
  }

  return(
    <form className="BillingAddressForm" onSubmit={onSubmit} >
      <div className="BillingAddressForm__counter">Step 1 of 2</div>
      {renderCompany()}
      {renderName()}
      {renderLastName()}
      {renderSubmitButton()}
    </form>
  )
}

export default BillingAddressFormStep1
