import { put, takeEvery } from 'redux-saga/effects'
import * as Eff from 'redux-saga/effects' // tslint:disable-line:no-duplicate-imports
import toastr from 'toastr'

import Logger from '@Utils/Logger'
import { IAction } from '@Root/types'

import {
  deleteLabelSuccess,
  deleteLabelFailure
} from './actions'

import {
  fetchLabels,
} from '@SiteModules/Label/actions'

import {
  DeleteLabelActionTypes,
  IDeleteLabelPayload
} from './types'

import {
  closePopup,
} from '@SiteContainers/ProjectVersionsList/actions'

import * as API from './api'

const call: any = Eff.call

function* deleteLabelSaga(action: IAction<IDeleteLabelPayload>) {
  try {
    yield call(API.deleteLabel, action.payload)
    yield put(deleteLabelSuccess())
    yield put(closePopup())
    yield put(fetchLabels())
    toastr.success('', 'Label is deleted!')
  } catch (e) {
    Logger.error(e, 'Label could not be deleted!')
    yield put(deleteLabelFailure())
  }
}

function* deleteLabelSagas() {
  yield takeEvery(DeleteLabelActionTypes.DELETE_LABEL, deleteLabelSaga)
}

export default deleteLabelSagas
