import { IUploadedElement } from '@Utils/ElementUploader'

import {
  ISubmitUrlsPayload,
  ElementActionTypes,
  IInitializeUploadPayload,
  ISubmitUrlsSuccessResponseBody,
  ICreateElementSuccessResponseBody,
} from './types'

export const createElement = (payload: IUploadedElement) => ({
  type: ElementActionTypes.CREATE_ELEMENT,
  payload
})

export const createElementFailure = () => ({
  type: ElementActionTypes.CREATE_ELEMENT_FAILURE,
})

export const createElementSuccess = (payload: ICreateElementSuccessResponseBody) => ({
  type: ElementActionTypes.CREATE_ELEMENT_SUCCESS,
  payload
})

export const resetUpload = () => ({
  type: ElementActionTypes.RESET_UPLOAD,
})

export const initializeUpload = (payload: IInitializeUploadPayload) => ({
  type: ElementActionTypes.INITIALIZE_UPLOAD,
  payload
})

export const submitUrls = (payload: ISubmitUrlsPayload) => ({
  type: ElementActionTypes.SUBMIT_URLS,
  payload
})

export const submitUrlsSuccess = (payload: ISubmitUrlsSuccessResponseBody) => ({
  type: ElementActionTypes.SUBMIT_URLS_SUCCESS,
  payload
})

export const submitUrlsFailure = () => ({
  type: ElementActionTypes.SUBMIT_URLS_FAILURE,
})
