import { IAction } from '@Root/types'

import {
  IProjectVersion
} from '@SiteContainers/ProjectVersionsList/types'

export const AssetsActionTypes = {
  INCREASE_PAGE_COUNT: '@@assetsTable/INCREASE_PAGE_COUNT',
  DECREASE_PAGE_COUNT: '@@assetsTable/DECREASE_PAGE_COUNT',
  GET_ELEMENTS: '@@assetsTable/GET_ELEMENTS',
  GET_ELEMENTS_SUCCESS: '@@assetsTable/GET_ELEMENTS_SUCCESS',
  GET_ELEMENTS_FAILURE: '@@assetsTable/GET_ELEMENTS_FAILURE',
  GET_AFFECTED_PROJECT_VERSIONS: '@@assetsTable/GET_AFFECTED_PROJECTS',
  GET_AFFECTED_PROJECT_VERSIONS_SUCCESS: '@@assetsTable/GET_AFFECTED_PROJECT_VERSIONS_SUCCESS',
  GET_AFFECTED_PROJECT_VERSIONS_FAILURE: '@@assetsTable/GET_AFFECTED_PROJECT_VERSIONS_FAILURE',
  CLOSE_DELETE_ELEMENT_POPUP: '@@assetsTable/CLOSE_DELETE_ELEMENT_POPUP',
  DELETE_ELEMENT: '@@assetsTable/DELETE_ELEMENT',
  DELETE_ELEMENT_SUCCESS: '@@assetsTable/DELETE_ELEMENT_SUCCESS',
  DELETE_ELEMENT_FAILURE: '@@assetsTable/DELETE_ELEMENT_FAILURE',
}

export interface IAssetsTableState {
  readonly elements: IElement[]
  readonly affectedProjectVersions: IProjectVersion[]
  readonly elementToDeleteId: string
  readonly loading: boolean
  readonly deleting: boolean
  readonly totalCount: number
  readonly totalPages: number
  readonly currentPage: number
  readonly loadingAffectedProjectVersions: boolean
  readonly openDeleteElementPopup: boolean
}

export interface IElement {
  readonly id: string
  readonly type: 'element'
  readonly attributes: IElementAttributes
}

export interface IElementAttributes {
  readonly thumbnailUrl: string
  readonly title: string
  readonly duration: string
  readonly createdAt: string
  readonly kind: string
  readonly size: string
}

export interface IGetElementsPayload {
  readonly page: number
}

export interface IGetElementsSuccessResponse {
  readonly data: IGetElementsBody
  readonly config: object
  readonly headers: object
  readonly request: object
  readonly status: 200
  readonly statusText: string
}

export interface IGetElementsBody {
  readonly totalCount: number
  readonly totalPages: number
  readonly data: IElement[]
}

export interface IGetElementsSuccessAction {
  type: string,
  payload: IGetElementsSuccessPayload
}

export type IGetElementsSuccessPayload = IGetElementsBody

export interface IGetAffectedProjectVersionsPayload {
  readonly elementId: string
}

export interface IGetAffectedProjectVersionsSuccessResponse {
  readonly data: IGetAffectedProjectVersionsBody
  readonly config: object
  readonly headers: object
  readonly request: object
  readonly status: 200
  readonly statusText: string
}

export interface IGetAffectedProjectVersionsBody {
  data: IProjectVersion[]
}

export interface IGetAffectedProjectVersionsSuccessAction {
  type: string,
  payload: IGetAffectedProjectVersionsSuccessPayload
}

export interface IGetAffectedProjectVersionsSuccessPayload extends IGetAffectedProjectVersionsBody {
  elementId: string
}

export interface IDeleteElementPayload {
  elementId: string
}

export type IAssetsActionTypes = IAction | IGetElementsSuccessAction | IGetAffectedProjectVersionsSuccessAction
