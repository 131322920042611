import { TActionTypes, IAction } from '@Root/types'

export const LabelActionTypes = {
  FETCH_LABELS: '@@label/FETCH_LABEL',
  FETCH_LABELS_FAILURE: '@@label/FETCH_LABELS_FAILURE',
  FETCH_LABELS_SUCCESS: '@@label/FETCH_LABELS_SUCCESS',
  CREATE_LABEL: '@@label/CREATE_LABEL',
  CREATE_LABEL_FAILURE: '@@label/CREATE_LABEL_FAILURE',
  CREATE_LABEL_SUCCESS: '@@label/CREATE_LABEL_SUCCESS',
  CHANGE_SELECTED_LABELS: '@@label/CHANGE_SELECTED_LABELS',
  RESET_LABEL_SELECTION: '@@label/RESET_LABEL_SELECTION',
  DELETE_PROJECT_VERSION_LABELS: '@@label/DELETE_PROJECT_VERSION_LABELS',
  DELETE_PROJECT_VERSION_LABELS_FAILURE: '@@label/DELETE_PROJECT_VERSION_LABELS_FAILURE',
  DELETE_PROJECT_VERSION_LABELS_SUCCESS: '@@label/DELETE_PROJECT_VERSION_LABELS_SUCCESS',
  CREATE_PROJECT_VERSION_LABELS: '@@label/CREATE_PROJECT_VERSION_LABELS',
  CREATE_PROJECT_VERSION_LABELS_FAILURE: '@@label/CREATE_PROJECT_VERSION_LABELS_FAILURE',
  CREATE_PROJECT_VERSION_LABELS_SUCCESS: '@@label/CREATE_PROJECT_VERSION_LABELS_SUCCESS',
}

export interface ILabelState {
  readonly labels: ILabel[]
  readonly selectedLabels: number[]
  readonly createLabel: {
    readonly loading: boolean
  }
  readonly fetchLabels: {
    readonly loading: boolean
  }
}

export interface ILabel {
  readonly id: string
  readonly type: 'label'
  readonly attributes: ILabelAttributes
}

export interface ILabelAttributes {
  readonly id: number
  readonly caption: string
  readonly projectVersionIds: string[]
}

export interface IFetchSsoDataPayload {
  readonly ssoOrganizationId: number
}

export interface IFetchLabelsSuccessResponseBody {
  readonly data: ILabel[]
}

export interface IFetchLabelsSuccessAction extends IAction {
  readonly payload: IFetchLabelsSuccessResponseBody
}

export interface ICreateLabelPayload {
  readonly caption: string
}

export interface IChangeSelectedLabelsAction {
  readonly type: string
  readonly payload: IChangeSelectedLabelsPayload
}

export interface IChangeSelectedLabelsPayload {
  readonly labelId: number
}

export interface IProjectVersionLabelsPayload {
  readonly labelId: number
  readonly selectedProjectVersions: string[]
}

export interface IEditLabelPayload {
  readonly id: string
  readonly labelName: string
}

export type ILabelActionTypes =
  TActionTypes |
  IAction<ICreateLabelPayload> |
  IFetchLabelsSuccessAction |
  IChangeSelectedLabelsAction |
  IAction<IProjectVersionLabelsPayload>
