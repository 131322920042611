import {
  DashboardSidebarActionTypes,
  IDashboardSidebarState,
  IDashboardSidebarActions,
} from './types'

import {
  ProjectVersionActionTypes,
} from '@SiteContainers/ProjectVersionsList/types'

export const initialState: IDashboardSidebarState = {
  openCreateLabelPopup: false,
  openDeleteLabelPopup: false,
  openRenameLabelPopup: false,
}

export const dashboardSidebarReducer = (
  state: IDashboardSidebarState = initialState,
  action: IDashboardSidebarActions
): IDashboardSidebarState => {
  switch (action.type) {
    case ProjectVersionActionTypes.CLOSE_POPUP: return closePopup(state)
    case DashboardSidebarActionTypes.CLOSE_POPUP: return closePopup(state)
    case DashboardSidebarActionTypes.OPEN_DELETE_LABEL_POPUP: return openDeleteLabelPopup(state)
    case DashboardSidebarActionTypes.OPEN_RENAME_LABEL_POPUP: return openRenameLabelPopup(state)
    case DashboardSidebarActionTypes.OPEN_CREATE_LABEL_POPUP: return openCreateLabelPopup(state)
    default: return state
  }
}

const openCreateLabelPopup = (state: IDashboardSidebarState): IDashboardSidebarState => {
  return { ...state, openCreateLabelPopup: true }
}

const openDeleteLabelPopup = (state: IDashboardSidebarState): IDashboardSidebarState => {
  return { ...state, openDeleteLabelPopup: true }
}

const openRenameLabelPopup = (state: IDashboardSidebarState): IDashboardSidebarState => {
  return { ...state, openRenameLabelPopup: true }
}

const closePopup = (state: IDashboardSidebarState): IDashboardSidebarState => {
  return {
    ...state,
    openCreateLabelPopup: false,
    openRenameLabelPopup: false,
    openDeleteLabelPopup: false
  }
}
