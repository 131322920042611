export const DeleteLabelActionTypes = {
  DELETE_LABEL: '@@deleteLabel/DELETE_LABEL',
  DELETE_LABEL_FAILURE: '@@deleteLabel/DELETE_LABEL_FAILURE',
  DELETE_LABEL_SUCCESS: '@@deleteLabel/DELETE_LABEL_SUCCESS',
}

export interface IDeleteLabelState {
  readonly errors: string[]
  readonly loading: boolean
  readonly caption: string
  readonly labelId: number
}

export interface IDeleteLabelAction {
  readonly type: string
  readonly payload: IDeleteLabelPayload
}

export interface IDeleteLabelSuccessAction {
  readonly type: string
}

export interface IDeleteLabelFailureAction {
  readonly type: string
}

export interface IDeleteLabelPayload {
  readonly labelId: number
}

export interface IDeleteLabelPopupOpen {
  readonly type: string
  readonly payload: IDeleteLabelPopupOpenPayload
}

export interface IDeleteLabelPopupOpenPayload {
  readonly labelId: number
  readonly caption: string
}

export type TDeleteLabelActionTypes = IDeleteLabelAction | IDeleteLabelPopupOpen | IDeleteLabelFailureAction | IDeleteLabelSuccessAction
