import {
  IExternalToken,
  ExternalTokenActionTypes,
  IExternalTokenState,
  IExternalTokenActionTypes,
  ICreateOrUpdateExternalTokenSuccessAction,
} from './types'

import {
  ProjectVersionActionTypes,
} from '@SiteContainers/ProjectVersionsList/types'

const initialExternalToken: IExternalToken = {
  id: '',
  type: 'externalToken',
  attributes: {
    externalTokenUrl: ''
  }
}

export const initialState: IExternalTokenState = {
  externalTokenData: initialExternalToken,
  loading: false
}

export const externalTokenReducer = (
  state: IExternalTokenState = initialState,
  action: IExternalTokenActionTypes
): IExternalTokenState => {
  switch (action.type) {
    case ProjectVersionActionTypes.CLOSE_POPUP: return cleanPopupDate(state)
    case ExternalTokenActionTypes.CREATE_OR_UPDATE_EXTERNAL_TOKEN: return createOrUpdateExternalToken(state)
    case ExternalTokenActionTypes.CREATE_OR_UPDATE_EXTERNAL_TOKEN_SUCCESS: return createOrUpdateExternalTokenSuccess(state, action)
    case ExternalTokenActionTypes.CREATE_OR_UPDATE_EXTERNAL_TOKEN_FAILURE: return createOrUpdateExternalTokenFailure(state)
    default: return state
  }
}

const createOrUpdateExternalToken = (state: IExternalTokenState): IExternalTokenState => {
  return { ...state, loading: true }
}

function isIFindOrCreateExternalTokenSuccessAction(arg: any): arg is ICreateOrUpdateExternalTokenSuccessAction {
  return arg.externalTokenData != null
}

const cleanPopupDate = (state: IExternalTokenState): IExternalTokenState => {
  const newState: IExternalTokenState = {
    externalTokenData: initialExternalToken,
    loading: false
  }

  return newState
}

const createOrUpdateExternalTokenSuccess = (state: IExternalTokenState, action: IExternalTokenActionTypes): IExternalTokenState => {
  let externalTokenData = initialExternalToken

  if (isIFindOrCreateExternalTokenSuccessAction(action)) {
    externalTokenData = action.externalTokenData
  }

  return { ...state, externalTokenData, loading: false }
}

const createOrUpdateExternalTokenFailure = (state: IExternalTokenState): IExternalTokenState => {
  return { ...state, loading: false }
}
