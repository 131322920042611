import React, { useState }  from 'react'
import ProfileIcon from '@Images/profile.svg'
import ProfileDropdown from '@SiteComponents/ProfileDropdown'
import useClickOutsideListener from '@Utils/UseClickOutsideListener'
import classnames from 'classnames'

import './ProfileButton.scss'

interface ProfileButtonProps {
  readonly hasExternalChannels: boolean
  readonly subscriptionIsCurrent: boolean
  readonly subscriptionPlan: string
  readonly disabled?: boolean
  readonly usingPersonalSubscription: boolean
  readonly managedSsoOrganizationId: number | null
  createCustomerPortalSession(): void
  logout(payload: string): {}
}

const ProfileButton: React.FC<ProfileButtonProps> = props => {
  const {
    hasExternalChannels,
    subscriptionIsCurrent,
    subscriptionPlan,
    disabled,
    usingPersonalSubscription,
    managedSsoOrganizationId,
    createCustomerPortalSession,
    logout,
  } = props

  const [openProfileDropdown, setOpenProfileDropdown] = useState(false)

  useClickOutsideListener('ProfileButton', setOpenProfileDropdown, openProfileDropdown)

  const toggleShowingProfileDropdown = () => {
    if (props.disabled) { return }

    setOpenProfileDropdown(!openProfileDropdown)
  }

  return(
    <div
      className = {classnames('ProfileButton',
        {'ProfileButton-active': openProfileDropdown},
        {'ProfileButton-disabled': disabled}
      )}
    >
      <ProfileIcon
        className="ProfileButton__icon"
        onClick={toggleShowingProfileDropdown}
      />
      {openProfileDropdown && (
        <ProfileDropdown
          hasExternalChannels={hasExternalChannels}
          subscriptionIsCurrent={subscriptionIsCurrent}
          subscriptionPlan={subscriptionPlan}
          createCustomerPortalSession={createCustomerPortalSession}
          usingPersonalSubscription={usingPersonalSubscription}
          managedSsoOrganizationId={managedSsoOrganizationId}
          logout={logout}
        />
      )}
    </div>
  )
}

export default ProfileButton
