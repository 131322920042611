import {
  ICreateLabelPayload,
  IFetchLabelsSuccessResponseBody,
  LabelActionTypes,
  ILabelActionTypes,
  IChangeSelectedLabelsPayload,
  IProjectVersionLabelsPayload
} from './types'

export const fetchLabels = (): ILabelActionTypes => ({
  type: LabelActionTypes.FETCH_LABELS,
})

export const fetchLabelsFailure = (): ILabelActionTypes => ({
  type: LabelActionTypes.FETCH_LABELS_FAILURE,
})

export const fetchLabelsSuccess = (payload: IFetchLabelsSuccessResponseBody): ILabelActionTypes => ({
  type: LabelActionTypes.FETCH_LABELS_SUCCESS,
  payload,
})

export const createLabel = (payload: ICreateLabelPayload): ILabelActionTypes => ({
  type: LabelActionTypes.CREATE_LABEL,
  payload,
})

export const createLabelFailure = (): ILabelActionTypes => ({
  type: LabelActionTypes.CREATE_LABEL_FAILURE,
})

export const createLabelSuccess = (): ILabelActionTypes => ({
  type: LabelActionTypes.CREATE_LABEL_SUCCESS,
})

export const changeSelectedLabels = (payload: IChangeSelectedLabelsPayload): ILabelActionTypes => ({
  type: LabelActionTypes.CHANGE_SELECTED_LABELS,
  payload
})

export const resetLabelSelection = (): ILabelActionTypes => ({
  type: LabelActionTypes.RESET_LABEL_SELECTION,
})

export const deleteProjectVersionLabels = (payload: IProjectVersionLabelsPayload): ILabelActionTypes => ({
  type: LabelActionTypes.DELETE_PROJECT_VERSION_LABELS,
  payload
})

export const deleteProjectVersionLabelsFailure = (): ILabelActionTypes => ({
  type: LabelActionTypes.DELETE_PROJECT_VERSION_LABELS_FAILURE,
})

export const deleteProjectVersionLabelsSuccess = (): ILabelActionTypes => ({
  type: LabelActionTypes.DELETE_PROJECT_VERSION_LABELS_SUCCESS,
})

export const createProjectVersionLabels = (payload: IProjectVersionLabelsPayload): ILabelActionTypes => ({
  type: LabelActionTypes.CREATE_PROJECT_VERSION_LABELS,
  payload
})

export const createProjectVersionLabelsFailure = (): ILabelActionTypes => ({
  type: LabelActionTypes.CREATE_PROJECT_VERSION_LABELS_FAILURE,
})

export const createProjectVersionLabelsSuccess = (): ILabelActionTypes => ({
  type: LabelActionTypes.CREATE_PROJECT_VERSION_LABELS_SUCCESS,
})
