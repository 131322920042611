export const SignUpFormActionTypes = {
  SIGN_UP: '@@signUpForm/SIGN_UP',
  SIGN_UP_FAILURE: '@@signUpForm/SIGN_UP_FAILURE',
  SIGN_UP_SUCCESS: '@@signUpForm/SIGN_UP_SUCCESS'
}

export interface IUserSignUpState {
  readonly loading: boolean
}

export interface PayloadData {
 readonly payload: PayloadDataBody
}

export interface PayloadDataBody {
  readonly name: string
  readonly email: string
  readonly lastName: string
  readonly password: string
  readonly languages: string[]
  readonly passwordConfirmation: string
}

export interface ISignUpAction {
  readonly type: string
  readonly payload: PayloadData
}

export interface ISignUpFailureAction {
  readonly type: string
}

export interface ISignUpSuccessAction {
  readonly type: string
}

export type TSignUpActionTypes = ISignUpAction | ISignUpFailureAction | ISignUpSuccessAction
