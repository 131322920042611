import React from 'react'

import './PeriodSwitch.scss'

interface PeriodSwitchProps {
  readonly isMonthlyPeriod: boolean
  changePeriod(): void
}

const PeriodSwitch: React.FC<PeriodSwitchProps> = props => {
  const { isMonthlyPeriod } = props

  return (
    <label className="PeriodSwitch">
      <span className=" PeriodSwitch__label PeriodSwitch__label-left-period">Pay monthly</span>
      <input
        type="checkbox"
        id="period"
        name="period"
        onChange={props.changePeriod}
        checked={!isMonthlyPeriod}
      />
      <span className="PeriodSwitch__slider" />
      <span className="PeriodSwitch__label PeriodSwitch__label-right-period">Pay yearly</span>
    </label>
  )
}

export default PeriodSwitch
