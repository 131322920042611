export const CreditsTopupActionTypes = {
  UPDATE_TIME: '@@creditsTopup/UPDATE_TIME',
  NOT_ENOUGH_CREDITS: '@@creditsTopup/NOT_ENOUGH_CREDITS',
  SUBMIT_TOPUP_REQUEST: '@@creditsTopup/SUBMIT_TOPUP_REQUEST',
  SUBMIT_TOPUP_REQUEST_SUCCESS: '@@creditsTopup/SUBMIT_TOPUP_REQUEST_SUCCESS',
  SUBMIT_TOPUP_REQUEST_FAILURE: '@@creditsTopup/SUBMIT_TOPUP_REQUEST_FAILURE',
}

export interface ICreditsTopupState {
  readonly time: number
  readonly errors: string[]
  readonly loading: boolean
  readonly credit: ICredit
}

export interface PayloadData {
  readonly time: number
  readonly type: string
}

export interface ICreditData {
  readonly data: ICredit
}

export interface ICredit {
  readonly id: string
  readonly type: 'creditPaymentInfo',
  readonly attributes: ICreditAttributes
}

export interface ICreditAttributes {
  readonly vat: number
  readonly amount: number
  readonly shouldApplyVat: boolean
  readonly availableTimeInHours: number
  readonly paymentIntentClientSecret: string
}

export interface IUpdateTimeAction {
  readonly type: string
  readonly time: number
}

export interface ISubmitTopupRequestAction {
  readonly type: string
  readonly payload: PayloadData
}

export interface ISubmitTopupRequestFailureAction {
  readonly type: string
}

export interface ISubmitTopupRequestSuccessAction {
  readonly type: string
  readonly payload: ICredit
}

export interface ICreditsRequired {
  readonly requiredCredits: number
}

export type ICreditsTopupActionTypes = IUpdateTimeAction | ISubmitTopupRequestAction | ISubmitTopupRequestFailureAction | ISubmitTopupRequestSuccessAction
