import {
  TImportSrtActions,
  ImportSrtActionTypes,
  IImportSrtState
} from './types'

export const initialState: IImportSrtState = {
  loading: false
}

export const importSrtReducer = (
  state: IImportSrtState = initialState,
  action: TImportSrtActions
): IImportSrtState => {
  switch (action.type) {
    case ImportSrtActionTypes.IMPORT_SRT: return importSrt(state)
    case ImportSrtActionTypes.IMPORT_SRT_SUCCESS: return importSrtSuccess(state)
    case ImportSrtActionTypes.IMPORT_SRT_FAILURE: return importSrtFailure(state)
    default: return state
  }
}

const importSrt = (state: IImportSrtState): IImportSrtState => {
  return { ...state, loading: true }
}

const importSrtSuccess = (state: IImportSrtState): IImportSrtState => {
  return { ...state, loading: false }
}

const importSrtFailure = (state: IImportSrtState): IImportSrtState => {
  return { ...state, loading: false }
}
