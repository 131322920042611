import {
  ISubscription,
  ICancelSubscriptionPayload,
  IStartCheckoutSessionPayload,
  IUpdateSubscriptionPayload,
  SubscriptionsActionTypes,
  TSubscriptionsActionTypes,
} from './types'

export const cancelSubscription = (payload: ICancelSubscriptionPayload): TSubscriptionsActionTypes => ({
  type: SubscriptionsActionTypes.CANCEL_SUBSCRIPTION,
  payload,
})

export const cancelSubscriptionSuccess = (payload: ISubscription): TSubscriptionsActionTypes => ({
  type: SubscriptionsActionTypes.CANCEL_SUBSCRIPTION_SUCCESS,
  payload
})

export const cancelSubscriptionFailure = (): TSubscriptionsActionTypes => ({
  type: SubscriptionsActionTypes.CANCEL_SUBSCRIPTION_FAILURE,
})

export const startCheckoutSession = (payload: IStartCheckoutSessionPayload): TSubscriptionsActionTypes => ({
  type: SubscriptionsActionTypes.START_CHECKOUT_SESSION,
  payload
})

export const startCheckoutSessionFailure = (): TSubscriptionsActionTypes => ({
  type: SubscriptionsActionTypes.START_CHECKOUT_SESSION_FAILURE,
})

export const updateSubscription = (payload: IUpdateSubscriptionPayload): TSubscriptionsActionTypes => ({
  type: SubscriptionsActionTypes.UPDATE_SUBSCRIPTION,
  payload,
})

export const updateSubscriptionSuccess = (payload: ISubscription): TSubscriptionsActionTypes => ({
  type: SubscriptionsActionTypes.UPDATE_SUBSCRIPTION_SUCCESS,
  payload,
})

export const updateSubscriptionFailure = (): TSubscriptionsActionTypes => ({
  type: SubscriptionsActionTypes.UPDATE_SUBSCRIPTION_FAILURE,
})
