import { Action } from 'redux'
import { put, takeEvery } from 'redux-saga/effects'
import * as Eff from 'redux-saga/effects' // tslint:disable-line:no-duplicate-imports
import toastr from 'toastr'

import Logger from '@Utils/Logger'
import history from '@Root/historyInstance'

import {
  passwordNewFailure,
  passwordNewSuccess
} from './actions'

import {
  PasswordNewFormActionTypes
} from './types'

import * as API from './api'

interface IAction extends Action {
  readonly payload: object
}

const call: any = Eff.call

function* passwordNewUserSaga(action: IAction) {
  try {
    yield call(API.passwordNewUser, { payload: action.payload })
    yield put(passwordNewSuccess())
    yield call(history.replace, '/')
    toastr.success('', 'Reset password instructions sent')
  } catch (e) {
    Logger.error(e, 'Reset password instructions could not be sent')
    yield put(passwordNewFailure())
  }
}

function* passwordNewFormSagas() {
  yield takeEvery(PasswordNewFormActionTypes.PASSWORD_NEW, passwordNewUserSaga)
}

export default passwordNewFormSagas
