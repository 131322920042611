import {
  IElement,
  AssetsActionTypes,
  IAssetsTableState,
  IAssetsActionTypes,
  IGetElementsSuccessAction,
  IGetAffectedProjectVersionsSuccessAction,
} from './types'

import {
  IProjectVersion
} from '@SiteContainers/ProjectVersionsList/types'

export const initialState: IAssetsTableState = {
  elements: [],
  affectedProjectVersions: [],
  elementToDeleteId: '',
  loading: false,
  deleting: false,
  totalCount: 0,
  totalPages: 1,
  currentPage: 1,
  loadingAffectedProjectVersions: false,
  openDeleteElementPopup: false,
}

export const assetsTableReducer = (
  state: IAssetsTableState = initialState,
  action: IAssetsActionTypes,
): IAssetsTableState => {
  switch (action.type) {
    case AssetsActionTypes.INCREASE_PAGE_COUNT: return increasePageCount(state)
    case AssetsActionTypes.DECREASE_PAGE_COUNT: return decreasePageCount(state)
    case AssetsActionTypes.GET_ELEMENTS: return getElements(state)
    case AssetsActionTypes.GET_ELEMENTS_SUCCESS: return getElementsSuccess(state, action)
    case AssetsActionTypes.GET_ELEMENTS_FAILURE: return getElementsFailure(state)
    case AssetsActionTypes.GET_AFFECTED_PROJECT_VERSIONS: return getAffectedProjectVersions(state)
    case AssetsActionTypes.GET_AFFECTED_PROJECT_VERSIONS_SUCCESS: return getAffectedProjectVersionsSuccess(state, action)
    case AssetsActionTypes.GET_AFFECTED_PROJECT_VERSIONS_FAILURE: return getAffectedProjectVersionsFailure(state)
    case AssetsActionTypes.CLOSE_DELETE_ELEMENT_POPUP: return closeDeleteElementPopup(state)
    case AssetsActionTypes.DELETE_ELEMENT: return deleteElement(state)
    case AssetsActionTypes.DELETE_ELEMENT_SUCCESS: return deleteElementSuccess(state)
    case AssetsActionTypes.DELETE_ELEMENT_FAILURE: return deleteElementFailure(state)

    default: return state
  }
}

const increasePageCount = (state: IAssetsTableState): IAssetsTableState => {
  return { ...state, currentPage: state.currentPage + 1 }
}

const decreasePageCount = (state: IAssetsTableState): IAssetsTableState => {
  return { ...state, currentPage: state.currentPage - 1 }
}

const getElements = (state: IAssetsTableState): IAssetsTableState => {
  return { ...state, loading: true }
}

function isGetElementsSuccessAction(arg: any): arg is IGetElementsSuccessAction {
  return arg.payload.data!= null && arg.payload.totalPages != null && arg.payload.totalCount
}

const getElementsSuccess = (state: IAssetsTableState, action: IAssetsActionTypes): IAssetsTableState => {
  let elements: IElement[] = []
  let totalCount: number = 0
  let totalPages: number = 1

  if (isGetElementsSuccessAction(action)) {
    elements = action.payload.data
    totalCount = action.payload.totalCount
    totalPages = action.payload.totalPages
  }

  return { ...state, elements, totalCount, totalPages, loading: false }
}

const getElementsFailure = (state: IAssetsTableState): IAssetsTableState => {
  return { ...state, loading: false }
}

const getAffectedProjectVersions = (state: IAssetsTableState): IAssetsTableState => {
  return { ...state, loadingAffectedProjectVersions: true }
}

function isGetAffectedProjectVersionsSuccessAction(arg: any): arg is IGetAffectedProjectVersionsSuccessAction {
  return arg.payload.data!= null && arg.payload.elementId != null
}

const getAffectedProjectVersionsSuccess = (state: IAssetsTableState, action: IAssetsActionTypes): IAssetsTableState => {
  let affectedProjectVersions: IProjectVersion[] = []
  let elementToDeleteId = ''

  if (isGetAffectedProjectVersionsSuccessAction(action)) {
    affectedProjectVersions = action.payload.data
    elementToDeleteId = action.payload.elementId
  }

  return { ...state, affectedProjectVersions, elementToDeleteId, loadingAffectedProjectVersions: false, openDeleteElementPopup: true }
}

const getAffectedProjectVersionsFailure = (state: IAssetsTableState): IAssetsTableState => {
  return { ...state, loadingAffectedProjectVersions: false }
}

const closeDeleteElementPopup = (state: IAssetsTableState): IAssetsTableState => {
  return { ...state, openDeleteElementPopup: false, affectedProjectVersions: [], elementToDeleteId: ''}
}

const deleteElement = (state: IAssetsTableState): IAssetsTableState => {
  return { ...state, deleting: true }
}

const deleteElementSuccess = (state: IAssetsTableState): IAssetsTableState => {
  return { ...state, deleting: false , openDeleteElementPopup: false, affectedProjectVersions: [], elementToDeleteId: ''}
}

const deleteElementFailure = (state: IAssetsTableState): IAssetsTableState => {
  return { ...state, deleting: false , openDeleteElementPopup: false, affectedProjectVersions: [], elementToDeleteId: ''}
}
