import React from 'react'
import Popup from '@EditorComponents/Popup'
import Themes from '@SiteContainers/Themes'
import showConfetti from '@Utils/ShowConfettiAnimation'

import './SetThemePopup.scss'

const SHORT_TIMESPAN = 2000 // ms

interface SetThemePopupProps {
  readonly isForOneProjectVersion: boolean
  readonly isNewProject: boolean
  readonly projectVersionId: string
  closePopup(): void
}

const SetThemePopup: React.FC<SetThemePopupProps> = props => {
  const {
    isForOneProjectVersion,
    isNewProject,
    projectVersionId,
  } = props

  const close = () => {
    props.closePopup()
    showConfetti()

    setTimeout(() => {
      window.location.href = (isForOneProjectVersion && isNewProject) ? `/processing?projectVersionId=${projectVersionId}` : '/'
    }, SHORT_TIMESPAN)
  }

  return(
    <Popup
      className="SetThemePopup"
      closePopup={close}
    >
      <Themes/>
    </Popup>
  )
}

export default SetThemePopup
