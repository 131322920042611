export const CheckoutsBillingAddressActionTypes = {
  FETCH_BILLING_ADDRESS: '@@checkoutsBillingAddress/FETCH_BILLING_ADDRESS',
  SUBMIT_BILLING_ADDRESS: '@@checkoutsBillingAddress/SUBMIT_BILLING_ADDRESS',
  FETCH_OR_SUBMIT_BILLING_ADDRESS_FAILURE: '@@checkoutsBillingAddress/FETCH_OR_SUBMIT_BILLING_ADDRESS_FAILURE',
  FETCH_OR_SUBMIT_BILLING_ADDRESS_SUCCESS: '@@checkoutsBillingAddress/FETCH_OR_SUBMIT_BILLING_ADDRESS_SUCCESS',
}

export interface IBillingAddress {
  readonly id: string
  readonly type: 'billingAddress'
  readonly attributes: IBillingAddressAttributes
}

export interface IBillingAddressAttributes {
  readonly company: string
  readonly name: string
  readonly last_name: string
  readonly address: string
  readonly city: string
  readonly country: string
  readonly zipcode: string
  readonly vat_id: string
  readonly countries: string[]
  readonly eu_countries: string[] // TODO: camelize it
}

export interface ICheckoutsBillingAddressState {
  readonly billingAddress: IBillingAddress
  readonly errors: string[]
  readonly loading: boolean
}

export interface PayloadData {
  readonly user: {
    readonly company: string
    readonly name: string
    readonly last_name: string
    readonly address: string
    readonly city: string
    readonly country: string
    readonly zipcode: string
    readonly vat_id: string
  }
  readonly itemToPayFor: string
  readonly itemToPayForId: number
}

export interface IFetchBillingAddressAction {
  readonly type: string
}

export interface IFetchOrSubmitBillingAddressFailureAction {
  readonly type: string
  readonly error: string
}

export interface IFetchOrSubmitBillingAddressSuccessAction {
  readonly type: string
  readonly billingAddress: IBillingAddress
}

export interface IFetchBillingAddressSuccessResponse {
  readonly data: IFetchBillingAddressBody
  readonly config: object
  readonly headers: object
  readonly request: object
  readonly status: number
  readonly statusText: string
}

interface IFetchBillingAddressBody {
  readonly data: IBillingAddress
}

export type ICheckoutsBillingAddressActionTypes =
  IFetchBillingAddressAction |
  IFetchOrSubmitBillingAddressFailureAction |
  IFetchOrSubmitBillingAddressSuccessAction
