import React from 'react'
import Loader from '@SiteComponents/Loader'
import classnames from 'classnames'

import './ButtonNewV2.scss'

interface ButtonNewV2Props {
  readonly styleType: string
  readonly caption: string
  readonly disabled?: boolean
  readonly showSpinner?: boolean
  readonly size: string
  readonly type?: 'button' | 'submit' | 'reset' | undefined
  readonly icon?: JSX.Element
  readonly iconRight?: boolean
  readonly active?: boolean
  onMouseEnter?(): void
  onMouseLeave?(): void
  onClick?(e?: any): void
}

const ButtonNewV2: React.FC<ButtonNewV2Props> = props => {
  const onClick = (e: any) => {
    if (!props.onClick) { return }

    props.onClick(e)
  }

  const renderCaption = () => {
    return (
      <span>
        {props.caption}
      </span>
    )
  }

  return (
    <button
      type={props.type || 'button'}
      className={classnames(
        'ButtonNewV2',
        `ButtonNewV2-${props.styleType}`,
        `ButtonNewV2-${props.size}`,
        { 'ButtonNewV2-active': props.active },
        { 'spinner': props.showSpinner },
        { 'ButtonNewV2-icon-right': props.iconRight },
      )}
      onClick={onClick}
      disabled={props.disabled}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      {props.showSpinner && (
        <Loader />
      )}
      {!props.showSpinner && !props.iconRight && props.icon}
      {!props.showSpinner && renderCaption()}
      {!props.showSpinner && props.iconRight && props.icon}
    </button>
  )
}

export default ButtonNewV2
