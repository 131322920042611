import {
  ISurveyData,
  SurveyActionTypes,
  TSurveyActionTypes,
} from './types'

export const submitSurvey = (payload: ISurveyData): TSurveyActionTypes => ({
  type: SurveyActionTypes.SUBMIT_SURVEY,
  payload,
})

export const submitSurveySuccess = (): TSurveyActionTypes => ({
  type: SurveyActionTypes.SUBMIT_SURVEY_SUCCESS,
})

export const submitSurveyFailure = (): TSurveyActionTypes => ({
  type: SurveyActionTypes.SUBMIT_SURVEY_FAILURE,
})
