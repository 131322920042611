import React, { useRef, useEffect } from 'react'

import './IndeterminateCheckbox.scss'

interface IndeterminateCheckboxProps {
  readonly value: string
  readonly id: string
  readonly name: string
  onChange(e: React.ChangeEvent<HTMLInputElement>): void
}

const IndeterminateCheckbox: React.FC<IndeterminateCheckboxProps> = props => {
  const checkRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (checkRef.current !== null) {
      checkRef.current.checked = props.value === 'checked'
      checkRef.current.indeterminate = props.value === 'indeterminate'
    }
  }, [status])
  return (
    <React.Fragment>
      <input
        type="checkbox"
        ref={checkRef}
        id={props.id}
        name={props.name}
        onChange={props.onChange}
      />
      <span className="IndeterminateCheckbox__checkmark" />
    </React.Fragment>
  )
}

export default IndeterminateCheckbox
