import {
  ChannelsActionTypes,
  IChannelsTableState,
  IChannelsActionTypes,
  IGetExternalVideosSuccessAction,
  IGetExternalChannelsSuccessAction
} from './types'

export const initialState: IChannelsTableState = {
  externalChannels: [],
  externalVideos: [],
  loadingVideos: false,
  loadingChannels: false,
  totalCount: 0,
  totalPages: 1,
  currentPage: 1,
  videosPerPage: 10,
  actionsDropdownOpen: false,
  hasLoadedVideosOnce: false
}

export const channelsTableReducer = (
  state: IChannelsTableState = initialState,
  action: IChannelsActionTypes,
): IChannelsTableState => {
  switch (action.type) {
    case ChannelsActionTypes.GET_EXTERNAL_VIDEOS: return getExternalVideos(state)
    case ChannelsActionTypes.GET_EXTERNAL_VIDEOS_FAILURE: return getExternalVideosFailure(state)
    case ChannelsActionTypes.GET_EXTERNAL_VIDEOS_SUCCESS: return getExternalVideosSuccess(state, action)
    case ChannelsActionTypes.INCREASE_PAGE_COUNT: return increasePageCount(state)
    case ChannelsActionTypes.DECREASE_PAGE_COUNT: return decreasePageCount(state)
    case ChannelsActionTypes.GET_EXTERNAL_CHANNELS: return getExternalChannels(state)
    case ChannelsActionTypes.GET_EXTERNAL_CHANNELS_FAILURE: return getExternalChannelsFailure(state)
    case ChannelsActionTypes.GET_EXTERNAL_CHANNELS_SUCCESS: return getExternalChannelsSuccess(state, action)
    case ChannelsActionTypes.OPEN_ACTIONS_DROPDOWN: return openActionsDropdown(state)
    case ChannelsActionTypes.CLOSE_DROPDOWNS: return closeDropdown(state)

    default: return state
  }
}

const getExternalVideos = (state: IChannelsTableState) => {
  return { ...state, loadingVideos: true }
}

const getExternalVideosFailure = (state: IChannelsTableState) => {
  return { ...state, loadingVideos: false }
}

function isIGetExternalVideosSuccessAction(arg: any): arg is IGetExternalVideosSuccessAction {
  return arg.payload && arg.payload.videos != null
}

const getExternalVideosSuccess = (state: IChannelsTableState, action: IChannelsActionTypes): IChannelsTableState => {
  let { externalVideos, totalCount, totalPages } = state

  if (isIGetExternalVideosSuccessAction(action)) {
    externalVideos = action.payload.videos

    totalCount = externalVideos.length
    totalPages = Math.floor(totalCount / state.videosPerPage) + 1
  }

  return { ...state, externalVideos, loadingVideos: false, hasLoadedVideosOnce: true, totalCount, totalPages }
}

const increasePageCount = (state: IChannelsTableState): IChannelsTableState => {
  return { ...state, currentPage: state.currentPage + 1 }
}

const decreasePageCount = (state: IChannelsTableState): IChannelsTableState => {
  return { ...state, currentPage: state.currentPage - 1 }
}

const getExternalChannels = (state: IChannelsTableState) => {
  return { ...state, loadingChannels: true }
}

const getExternalChannelsFailure = (state: IChannelsTableState) => {
  return { ...state, loadingChannels: false }
}

function isIGetExternalChannelsSuccessAction(arg: any): arg is IGetExternalChannelsSuccessAction {
  return arg.payload && arg.payload.externalChannels != null
}

const getExternalChannelsSuccess = (state: IChannelsTableState, action: IChannelsActionTypes): IChannelsTableState => {
  let { externalChannels } = state

  if (isIGetExternalChannelsSuccessAction(action)) {
    externalChannels = action.payload.externalChannels
  }

  return { ...state, externalChannels, loadingChannels: false }
}

const openActionsDropdown = (state: IChannelsTableState): IChannelsTableState => {
  return { ...state, actionsDropdownOpen: true }
}

const closeDropdown = (state: IChannelsTableState): IChannelsTableState => {
  return { ...state, actionsDropdownOpen: false }
}
