import React, { useState } from 'react'
import YoutubeChannelIcon from '@Images/youtube_channel_icon.svg'
import VimeoChannelIcon from '@Images/vimeo_channel_icon.svg'
import More from '@Images/more_icon.svg'
import Checkmark from '@Images/checkmark.svg'
import Crossmark from '@Images/crossmark.svg'

import {
  IExternalChannel
} from '@SiteContainers/ChannelsTable/types'

interface ProjectVersionProps {
  readonly externalChannel: IExternalChannel
  handleActionsClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>, externalChannel: IExternalChannel): void
}

const Channel: React.FC<ProjectVersionProps> = props => {
  const [showInfoForStatus, setShowInfoForStatus] = useState<boolean>(false)
  const [infoForStatusTimeout, setInfoForStatusTimeout] = useState<ReturnType<typeof setTimeout> | null>(null)

  const { externalChannel } = props

  const toggleShowStatusInfo = () => {
    if (infoForStatusTimeout) {
      setShowInfoForStatus(false)
      clearTimeout(infoForStatusTimeout)
      setInfoForStatusTimeout(null)
    } else {
      const newInfoForStatusTimeout = setTimeout(() => {
        setShowInfoForStatus(true)
      }, window.Checksub.tooltipDelay)
      setInfoForStatusTimeout(newInfoForStatusTimeout)
    }
  }

  const handleActionsClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    props.handleActionsClick(e, externalChannel)
  }

  const renderChannelStatus = () => {
    return (
      <React.Fragment>
        {externalChannel.valid ? (
          <React.Fragment>
            <Checkmark />
            {showInfoForStatus &&
              <div className="ChannelsManage__tooltip">
                The channel is connected
              </div>
            }
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Crossmark />
            {showInfoForStatus &&
              <div className="ChannelsManage__tooltip">
                The access needs to be refreshed
              </div>
            }
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }

  return (
    <div className="ChannelsManage__channel">
      <div className="ChannelsManage__channel-left">
        <div className="ChannelsManage__channel-thumbnail">
          {externalChannel.thumbnailUrl ? (
            <img src={externalChannel.thumbnailUrl}/>
          ) : (
            <div className="ChannelsManage__channel-thumbnail-placeholder"/>
          )}
          {externalChannel.service === 'youtube' ? (
            <YoutubeChannelIcon />
          ) : (
            <VimeoChannelIcon />
          )}
        </div>
        <div className="ChannelsManage__channel-content">
          <div className="ChannelsManage__channel-name">
            {externalChannel.name}
          </div>
          <div className="ChannelsManage__channel-service">
            {externalChannel.service === 'youtube' ? (
              <span>Youtube channel</span>
            ) : (
              <span>Vimeo channel</span>
            )}
          </div>
        </div>
      </div>
      <div className="ChannelsManage__channel-right">
        <div
          className="ChannelsManage__channel-state"
          onMouseEnter={toggleShowStatusInfo}
          onMouseLeave={toggleShowStatusInfo}
        >
          {renderChannelStatus()}
        </div>
        <div
          className="ChannelsManage__channel-actions"
          onClick={handleActionsClick}
        >
          <More />
        </div>
      </div>
    </div>
  )
}

export default Channel
