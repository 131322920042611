import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { IApplicationState } from '@Site/rootReducer'

import Popup from '@EditorComponents/Popup'
import EditLabelForm from '@SiteComponents/EditLabelForm'

import {
  renameLabel,
} from './actions'

import {
  IRenameLabelPayload,
} from './types'

import './RenameLabelPopup.scss'

interface RenameLabelPopupProps {
  readonly labelId: number
  readonly caption: string
  closePopup(): void
  renameLabel(payload: IRenameLabelPayload): void
}

interface RenameLabelPopupState {}

class RenameLabelPopup extends React.Component<RenameLabelPopupProps, RenameLabelPopupState> {
  render() {
    const {
      labelId,
      caption,
      closePopup,
    } = this.props

    return (
      <Popup
        className="EditLabelPopup"
        closePopup={closePopup}
      >
        <EditLabelForm
          labelId={labelId}
          caption={caption}
          closePopup={closePopup}
          renameLabel={this.props.renameLabel}
        />
      </Popup>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { labelId, caption } = state.renameLabel

  return {
    labelId,
    caption,
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    renameLabel,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(RenameLabelPopup)
