import produce, { Draft }  from 'immer'
import findIndex from 'lodash/findIndex'

import {
  TActionTypes,
  IActionSuccess,
} from '@Root/types'

import {
  ISsoState,
  SsoActionTypes,
  ISsoActionTypes,
  IAddUsersSuccessResponseBody,
  IDeleteMemberSuccessResponseBody,
  IChangeRoleSuccessResponseBody,
  IChangeSubscriptionSuccessResponseBody,
  IFetchSsoDataSuccessResponseBody,
} from './types'


export const initialState: ISsoState = {
  addUsersToSsoPopupOpen: false,
  loading: false,
  ssoUsers: [],
  ssoSubscriptions: []
}

type TAction = TActionTypes | ISsoActionTypes

export const ssoReducer = (
  state: ISsoState = initialState,
  action: TAction,
): ISsoState => {
  return produce(state, draft => {
    switch (action.type) {
      case SsoActionTypes.OPEN_ADD_USERS_TO_SSO_POPUP:
        openAddUsersToSsoPopup(draft)
        break

      case SsoActionTypes.CLOSE_ADD_USERS_TO_SSO_POPUP:
        closeAddUsersToSsoPopup(draft)
        break

      case SsoActionTypes.FETCH_SSO_DATA:
        fetchSsoData(draft)
        break

      case SsoActionTypes.FETCH_SSO_DATA_SUCCESS:
        fetchSsoDataSuccess(draft, action)
        break

      case SsoActionTypes.ADD_USERS_SUCCESS:
        addUsersSuccess(draft, action)
        break

      case SsoActionTypes.DELETE_MEMBER_SUCCESS:
        deleteMemberSuccess(draft, action)
        break

      case SsoActionTypes.CHANGE_ROLE_SUCCESS:
      case SsoActionTypes.CHANGE_SUBSCRIPTION_SUCCESS:
        changeMemberDataSuccess(draft, action)
        break

      default:
        break
    }
  })
}

const openAddUsersToSsoPopup = (draft: Draft<ISsoState>) => {
  draft.addUsersToSsoPopupOpen = true
}

const closeAddUsersToSsoPopup = (draft: Draft<ISsoState>) => {
  draft.addUsersToSsoPopupOpen = false
}

const fetchSsoData = (draft: Draft<ISsoState>) => {
  draft.loading = true
}

function isFetchSsoDataSuccessAction(arg: any): arg is IActionSuccess<IFetchSsoDataSuccessResponseBody> {
  return arg.type === SsoActionTypes.FETCH_SSO_DATA_SUCCESS
}

const fetchSsoDataSuccess = (draft: Draft<ISsoState>, action: TAction) => {
  if (!isFetchSsoDataSuccessAction(action)) { return }

  draft.ssoUsers = action.payload!.data.attributes.ssoUsers.data
  draft.ssoSubscriptions = action.payload!.data.attributes.ssoSubscriptions.data
  draft.loading = false
}

function isAddUsersSuccessAction(arg: any): arg is IActionSuccess<IAddUsersSuccessResponseBody> {
  return arg.type === SsoActionTypes.ADD_USERS_SUCCESS
}

const addUsersSuccess = (draft: Draft<ISsoState>, action: TAction) => {
  if (!isAddUsersSuccessAction(action)) { return }

  draft.ssoUsers = draft.ssoUsers.concat(action.payload!.data)
  draft.addUsersToSsoPopupOpen = false
}

function isDeleteMemberSuccessAction(arg: any): arg is IActionSuccess<IDeleteMemberSuccessResponseBody> {
  return arg.type === SsoActionTypes.DELETE_MEMBER_SUCCESS
}

const deleteMemberSuccess = (draft: Draft<ISsoState>, action: TAction) => {
  if (!isDeleteMemberSuccessAction(action)) { return }

  draft.ssoUsers = action.payload!.data
}

function isChangeMemberDataSuccessAction(arg: any): arg is IActionSuccess<IChangeRoleSuccessResponseBody | IChangeSubscriptionSuccessResponseBody> {
  return (arg.type === SsoActionTypes.CHANGE_ROLE_SUCCESS || arg.type === SsoActionTypes.CHANGE_SUBSCRIPTION_SUCCESS)
}

const changeMemberDataSuccess = (draft: Draft<ISsoState>, action: TAction) => {
  if (!isChangeMemberDataSuccessAction(action)) { return }

  const ssoUsers = draft.ssoUsers
  const updatedSsoUser = action.payload!.data
  const updatedSsoUserIndex = findIndex(ssoUsers, ssoUser => ssoUser.attributes.userId === action.payload!.data.attributes.userId)
  ssoUsers.splice(updatedSsoUserIndex, 1, updatedSsoUser)

  draft.ssoUsers = ssoUsers
}
