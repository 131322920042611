import {
  TProcessingActionTypes,
  ProcessingActionTypes,
  IFetchProcessingInfoPayload,
  IFetchProcessingInfoSuccessResponseBody,
  IFetchCurrentProcessingStepPayload,
  IFetchCurrentProcessingStepSuccessResponseBody
} from './types'

export const fetchProcessingInfo = (payload: IFetchProcessingInfoPayload): TProcessingActionTypes => ({
  type: ProcessingActionTypes.FETCH_PROCESSING_INFO,
  payload
})

export const fetchProcessingInfoSuccess = (payload: IFetchProcessingInfoSuccessResponseBody): TProcessingActionTypes => ({
  type: ProcessingActionTypes.FETCH_PROCESSING_INFO_SUCCESS,
  payload,
})

export const fetchProcessingInfoSuccessForDownloading = (): TProcessingActionTypes => ({
  type: ProcessingActionTypes.FETCH_PROCESSING_INFO_SUCCESS_FOR_DOWNLOADING,
})

export const fetchCurrentProcessingStep = (payload: IFetchCurrentProcessingStepPayload): TProcessingActionTypes => ({
  type: ProcessingActionTypes.FETCH_CURRENT_PROCESSING_STEP,
  payload
})

export const fetchCurrentProcessingStepSuccess = (payload: IFetchCurrentProcessingStepSuccessResponseBody): TProcessingActionTypes => ({
  type: ProcessingActionTypes.FETCH_CURRENT_PROCESSING_STEP_SUCCESS,
  payload,
})
