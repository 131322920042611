import {
  PayloadData,
  ConfirmationNewFormActionTypes,
  TConfirmationNewActionTypes
} from './types'

export const confirmationNew = (payload: PayloadData): TConfirmationNewActionTypes => ({
  type: ConfirmationNewFormActionTypes.CONFIRMATION_NEW,
  payload
})

export const confirmationNewSuccess = (): TConfirmationNewActionTypes => ({
  type: ConfirmationNewFormActionTypes.CONFIRMATION_NEW_SUCCESS,
})

export const confirmationNewFailure = (): TConfirmationNewActionTypes => ({
  type: ConfirmationNewFormActionTypes.CONFIRMATION_NEW_FAILURE,
})
