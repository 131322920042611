import React from 'react'
import { connect } from 'react-redux'
import { Action, Dispatch, bindActionCreators } from 'redux'
import { IApplicationState } from '@Site/rootReducer'
import Loader from '@SiteComponents/Loader'
import ProjectVersion from './ProjectVersion'

import './ProjectVersionsList.scss'

import {
  ISubtitle,
  IProjectVersion,
  IProjectVersionTableRow,
  IDeleteProjectVersionPopup,
  IUpdateProjectVersionsPayload,
  IOpenImportSrtPayload,
  IOpenExportationPayload,
  IOpenRateSubtitlePayload,
  IChangeSelectedProjectVersionsPayload,
} from './types'

import {
  updateProjectVersions,
  openImportSrtPopup,
  openDeleteProjectVersionPopup,
  changeSelectedProjectVersions,
  openExportationPopup,
  openRateSubtitlePopup,
  openExternalTokenPopup,
} from './actions'

import {
  getSubtitleIdsToUpdate,
  getProjectVersionIdsToUpdate,
} from './selectors'

import {
  fetchProjectVersionSettings,
} from '@SitePages/ProjectVersionSettings/actions'

import {
  IFetchProjectVersionSettingsPayload
} from '@SitePages/ProjectVersionSettings/types'

interface ProjectVersionsListProps {
  readonly loading: boolean
  readonly subtitles: ISubtitle[]
  readonly selectedLabels: number[]
  readonly subtitlesToUpdateIds: number[]
  readonly projectVersionsToUpdateIds: string[]
  readonly selectedProjectVersions: string[]
  readonly projectVersions: IProjectVersion[]
  readonly shouldUpdateProjectVersionsAndSubtitles: boolean
  readonly hasCurrentSubscription: boolean
  openCreateLabelPopup(): void
  fetchProjectVersionSettings(payload: IFetchProjectVersionSettingsPayload): void
  updateProjectVersions(payload: IUpdateProjectVersionsPayload): void
  openExternalTokenPopup(subtitleId: number): void
  openDeleteProjectVersionPopup(payload: IDeleteProjectVersionPopup): void
  openImportSrtPopup(payload: IOpenImportSrtPayload): void
  openExportationPopup(payload: IOpenExportationPayload): void
  openRateSubtitlePopup(payload: IOpenRateSubtitlePayload): void
  changeSelectedProjectVersions(payload: IChangeSelectedProjectVersionsPayload): void
}

interface ProjectVersionsListState {
  readonly intervalId: number
}

class ProjectVersionsList extends React.Component<ProjectVersionsListProps, ProjectVersionsListState> {
  state: ProjectVersionsListState = {
    intervalId: -1,
  }

  constructor(props: ProjectVersionsListProps) {
    super(props)
  }

  componentDidMount() {
    this.prepareUpdating()
  }

  componentDidUpdate() {
    this.prepareUpdating()
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId)
  }

  renderLoader() {
    const { loading } = this.props

    if (!loading) { return }

    return(
      <span className="ProjectVersionsList__loader loader" />
    )
  }

  prepareUpdating = () => {
    const { intervalId } = this.state
    const { shouldUpdateProjectVersionsAndSubtitles } = this.props
    const isTimerPresent = intervalId !== -1
    const shouldSetupTimerForFetchingUpdates = shouldUpdateProjectVersionsAndSubtitles && !isTimerPresent
    const shouldRemoveInterval = !shouldUpdateProjectVersionsAndSubtitles && isTimerPresent

    if (shouldSetupTimerForFetchingUpdates) {
      const newTimerId = window.setInterval(this.updateProjectVersions, 60000)
      this.setState({ intervalId: newTimerId })
    } else if (shouldRemoveInterval) {
      clearInterval(this.state.intervalId)
      this.setState({ intervalId: -1 })
    }
  }

  updateProjectVersions = () => {
    const { subtitlesToUpdateIds, projectVersionsToUpdateIds } = this.props
    const payload: IUpdateProjectVersionsPayload = { subtitlesToUpdateIds, projectVersionsToUpdateIds }

    this.props.updateProjectVersions(payload)
  }

  openExternalTokenPopup = (subtitleId: number) => {
    this.props.openExternalTokenPopup(subtitleId)
  }

  openExportationPopup = (payload: IOpenExportationPayload) => {
    this.props.openExportationPopup(payload)
  }

  openRateSubtitlePopup = (payload: IOpenRateSubtitlePayload) => {
    this.props.openRateSubtitlePopup(payload)
  }

  openImportSrtPopup = (payload: IOpenImportSrtPayload) => {
    this.props.openImportSrtPopup(payload)
  }

  openDeleteProjectVersionPopup = (payload: IDeleteProjectVersionPopup) => {
    this.props.openDeleteProjectVersionPopup(payload)
  }

  changeSelectedProjectVersions = (payload: IChangeSelectedProjectVersionsPayload) => {
    this.props.changeSelectedProjectVersions(payload)
  }

  renderProjectVersions() {
    const {
      subtitles,
      projectVersions,
      selectedProjectVersions,
      hasCurrentSubscription,
    } = this.props

    const rows: IProjectVersionTableRow[] = projectVersions.map((projectVersion: IProjectVersion) => {
      return { id: projectVersion.id, subtitles: projectVersion.relationships.subtitles.data, ...projectVersion.attributes }
    })

    return (
      <div className="ProjectVersions">
        <table className="ProjectVersions__table ProjectVersions__table-projectVersions">
          <thead className="ProjectVersions__table-header">
            <tr>
              <th className="ProjectVersions__table-cell" />
              <th className="ProjectVersions__table-cell ProjectVersions__header-title">Title</th>
              <th className="ProjectVersions__table-cell">Length</th>
              <th className="ProjectVersions__table-cell">Status</th>
              <th className="ProjectVersions__table-cell ProjectVersions__header-language">Project language</th>
              <th className="ProjectVersions__table-cell">Language(s)</th>
              <th className="ProjectVersions__table-cell">Created at</th>
              <th className="ProjectVersions__table-cell" />
            </tr>
          </thead>
          <tbody className="ProjectVersions__table-body">
            {rows.map(row => {
              return(
                <ProjectVersion
                  key={row.id}
                  row={row}
                  subtitles={subtitles}
                  selected={selectedProjectVersions.includes(row.id)}
                  openImportSrtPopup={this.openImportSrtPopup}
                  openDeleteProjectVersionPopup={this.openDeleteProjectVersionPopup}
                  openExternalTokenPopup={this.openExternalTokenPopup}
                  openExportationPopup={this.openExportationPopup}
                  openRateSubtitlePopup={this.openRateSubtitlePopup}
                  changeSelectedProjectVersions={this.changeSelectedProjectVersions}
                  hasCurrentSubscription={hasCurrentSubscription}
                  isShortened={row.isShortened}
                />
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }

  render() {
    const { loading } = this.props
    const statusOfProjectVersions = loading ? 'loading' : 'loaded'
    const shouldRenderLoader = loading

    return (
      <div className="ProjectVersionsList">
        {this.renderLoader()}

        <div className={`ProjectVersionsList-${statusOfProjectVersions}`}>
          {shouldRenderLoader ? (
            <Loader color="dark" />
          ) : (
            <React.Fragment>
              {this.renderProjectVersions()}
            </React.Fragment>
          )}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { user, projectVersionsList, labels } = state
  const subtitlesToUpdateIds = getSubtitleIdsToUpdate(projectVersionsList)
  const projectVersionsToUpdateIds = getProjectVersionIdsToUpdate(projectVersionsList)

  return {
    projectVersions: projectVersionsList.projectVersions,
    loading: projectVersionsList.loading,
    subtitles: projectVersionsList.subtitles,
    selectedProjectVersions: projectVersionsList.selectedProjectVersions,
    selectedLabels: labels.selectedLabels,
    shouldUpdateProjectVersionsAndSubtitles: !!subtitlesToUpdateIds.length || !!projectVersionsToUpdateIds.length,
    hasCurrentSubscription: user.subscription.attributes.isCurrent,
    subtitlesToUpdateIds,
    projectVersionsToUpdateIds,
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    updateProjectVersions,
    fetchProjectVersionSettings,
    openImportSrtPopup,
    changeSelectedProjectVersions,
    openDeleteProjectVersionPopup,
    openExportationPopup,
    openRateSubtitlePopup,
    openExternalTokenPopup,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectVersionsList)
