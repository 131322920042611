import { Action } from 'redux'
import { put, takeEvery } from 'redux-saga/effects'
import * as Eff from 'redux-saga/effects' // tslint:disable-line:no-duplicate-imports
import toastr from 'toastr'

import Logger from '@Utils/Logger' 
import history from '@Root/historyInstance'

import {
  updatePasswordFailure,
  updatePasswordSuccess
} from './actions'

import {
  UserPasswordEditActionTypes
} from './types'

import * as API from './api'

interface IAction extends Action {
  readonly payload: object
}

const call: any = Eff.call

function* passwordNewUserSaga(action: IAction) {
  try {
    yield call(API.updatePassword, { payload: action.payload })
    yield put(updatePasswordSuccess())
    yield call(history.replace, '/')
    toastr.success('', 'Password changed')
  } catch (e) {
    Logger.error(e, 'Password could not be changed')
    yield put(updatePasswordFailure())
  }
}

function* passwordNewFormSagas() {
  yield takeEvery(UserPasswordEditActionTypes.UPDATE_PASSWORD, passwordNewUserSaga)
}

export default passwordNewFormSagas
