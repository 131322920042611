import React from 'react'
import formattedCreditsWithHours from '@Utils/FormattedCreditsWithHours'
import classnames from 'classnames'

import './Costs.scss'

interface CostsProps {
  readonly totalCost: number
  readonly shouldHideCosts: boolean
}

const Costs: React.FC<CostsProps> = props => {
  const {
    totalCost,
    shouldHideCosts,
  } = props

  const formattedCosts: string = formattedCreditsWithHours(totalCost)

  const renderCosts = () => {
    return (
      <span>You will use {formattedCosts} of your credits.
        <a href="https://support.checksub.com/help/faq/how-credits-work" target="_blank">Understand why.</a>
      </span>
    )
  }

  return (
    <div>
      <div
        className={classnames(
          'Costs',
          {'Costs__hidden': shouldHideCosts})
        }
      >
        {renderCosts()}
      </div>
    </div>
  )
}

export default Costs
