import {
  IRenameLabelPayload,
  RenameLabelActionTypes,
  TRenameLabelActionTypes,
} from './types'

export const renameLabel = (payload: IRenameLabelPayload): TRenameLabelActionTypes => ({
  type: RenameLabelActionTypes.RENAME_LABEL,
  payload
})

export const renameLabelFailure = (): TRenameLabelActionTypes => ({
  type: RenameLabelActionTypes.RENAME_LABEL_FAILURE,
})

export const renameLabelSuccess = (): TRenameLabelActionTypes => ({
  type: RenameLabelActionTypes.RENAME_LABEL_SUCCESS,
})
