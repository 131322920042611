import { put, takeEvery } from 'redux-saga/effects'
import * as Eff from 'redux-saga/effects' // tslint:disable-line:no-duplicate-imports

import Logger from '@Utils/Logger'

import { ISuccessResponse, IAction } from '@Root/types'

import {
  fetchProcessingInfoSuccess,
  fetchProcessingInfoSuccessForDownloading,
  fetchCurrentProcessingStepSuccess,
} from './actions'

import {
  ProcessingActionTypes,
  IFetchProcessingInfoPayload,
  IFetchCurrentProcessingStepPayload,
  IFetchProcessingInfoSuccessResponseBody,
  IFetchCurrentProcessingStepSuccessResponseBody,
} from './types'

import * as API from './api'

const call: any = Eff.call

const SHORT_TIME_OUT = 2000 // msec

function* fetchProcessingInfoSaga(action: IAction<IFetchProcessingInfoPayload>) {
  try {
    const response: ISuccessResponse<IFetchProcessingInfoSuccessResponseBody> = yield call(API.fetchProcessingInfo, action.payload)
    if (response.data) {
      const payloadResponse = response.data
      yield put(fetchProcessingInfoSuccess(payloadResponse))
    } else {
      yield(put(fetchProcessingInfoSuccessForDownloading()))
    }
  } catch (e) {
    Logger.error(e, 'Processing info could not be loaded')

    setTimeout(() => {
      window.location.href = '/'
    }, SHORT_TIME_OUT)
  }
}

function* fetchCurrentProcessingStepSaga(action: IAction<IFetchCurrentProcessingStepPayload>) {
  try {
    const response: ISuccessResponse<IFetchCurrentProcessingStepSuccessResponseBody> = yield call(API.fetchCurrentProcessingStep, action.payload)
    const payloadResponse = response.data
    yield put(fetchCurrentProcessingStepSuccess(payloadResponse))
  } catch (e) {
    Logger.error(e, 'Processing info could not be loaded')

    setTimeout(() => {
      window.location.href = '/'
    }, SHORT_TIME_OUT)
  }
}

function* processingSagas() {
  yield takeEvery(ProcessingActionTypes.FETCH_PROCESSING_INFO, fetchProcessingInfoSaga)
  yield takeEvery(ProcessingActionTypes.FETCH_CURRENT_PROCESSING_STEP, fetchCurrentProcessingStepSaga)
}

export default processingSagas
