import React from 'react'
import Button from '@SiteComponents/Button'
import Popup from '@SiteComponents/Popup'
import AttentionIcon from '@Images/attention.svg'

import './SaveVocabularyPopup.scss'

interface SaveVocabularyPopupProps  {
  readonly loading: boolean
  readonly shouldDisableSaveButton: boolean
  save(): void
  closePopup(): void
  delayedAction(): void
}

const SaveVocabularyPopup: React.FC<SaveVocabularyPopupProps> = props => {
  const shouldDisableButton = props.loading
  const { shouldDisableSaveButton } = props

  const saveAction = () => {
    props.save()
    props.delayedAction()
  }

  return(
    <Popup
      className="SaveVocabularyPopup"
      closePopup={props.closePopup}
    >
      <p className="SaveVocabularyPopup__description">
        <AttentionIcon className="SaveVocabularyPopup__description__icon" />
          You have unsaved changes to the current vocabulary list.
      </p>
      <Button
        type="button"
        color="primary"
        caption="Save changes and continue"
        showSpinner={props.loading}
        disabled={shouldDisableButton || shouldDisableSaveButton}
        onClick={saveAction}
      />
      <Button
        type="button"
        color="primary"
        caption="Discard changes and continue"
        disabled={shouldDisableButton}
        onClick={props.delayedAction}
      />
    </Popup>
  )
}

export default SaveVocabularyPopup
