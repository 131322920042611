import {
  IDeleteProjectVersionActionTypes,
  DeleteProjectVersionActionTypes,
  IDeleteProjectVersionState
} from './types'

import {
  ProjectVersionActionTypes,
  TProjectVersionsListActionTypes,
  IOpenDeleteProjectVersionPopupAction,
} from '@SiteContainers/ProjectVersionsList/types'

export const initialState: IDeleteProjectVersionState = {
  errors: [],
  loading: false,
  projectVersionIds: [],
}

export const deleteProjectVersionReducer = (
  state: IDeleteProjectVersionState = initialState,
  action: IDeleteProjectVersionActionTypes | TProjectVersionsListActionTypes
): IDeleteProjectVersionState => {
  switch (action.type) {
    case DeleteProjectVersionActionTypes.DELETE_PROJECT_VERSION: return deleteProjectVersion(state)
    case DeleteProjectVersionActionTypes.DELETE_PROJECT_VERSION_SUCCESS: return deleteProjectVersionSuccess(state)
    case DeleteProjectVersionActionTypes.DELETE_PROJECT_VERSION_FAILURE: return deleteProjectVersionFailure(state)
    case ProjectVersionActionTypes.OPEN_DELETE_PROJECT_VERSION_POPUP: return openDeleteProjectVersionPopup(state, action)
    default: return state
  }
}

const deleteProjectVersion = (state: IDeleteProjectVersionState): IDeleteProjectVersionState => {
  return { ...state, loading: true }
}

const deleteProjectVersionSuccess = (state: IDeleteProjectVersionState): IDeleteProjectVersionState => {
  return { ...state, loading: false }
}

const deleteProjectVersionFailure = (state: IDeleteProjectVersionState): IDeleteProjectVersionState => {
  return { ...state, loading: false }
}

function isIOpenDeleteProjectVersionPopup(arg: any): arg is IOpenDeleteProjectVersionPopupAction {
  return arg.payload && arg.payload.projectVersionIds != null
}

const openDeleteProjectVersionPopup = (state: IDeleteProjectVersionState, action: TProjectVersionsListActionTypes): IDeleteProjectVersionState => {
  let projectVersionIds: string[] = []

  if (isIOpenDeleteProjectVersionPopup(action)) {
    projectVersionIds = action.payload.projectVersionIds
  }

  return { ...state, projectVersionIds }
}
