import React from 'react'
import { ConnectedComponent } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

interface PublicRouteProps {
  readonly path: string
  readonly exact: boolean
  readonly restricted: boolean
  readonly component: ConnectedComponent<any, any>
}

const PublicRoute: React.FC<PublicRouteProps> = ({ component: Component, ...rest }) => {
  const { path, exact, restricted } = rest

  const isAuthorized = window.Checksub.cookies.get('authorized')

  const isDemoPage = window.location.pathname.includes('demo')

  // TODO: make a service...
  const onboarding = window.Checksub.cookies.get('onboarding')
  const canRenderPublicPage = !onboarding || onboarding === 'processed'

  return(
    // tslint:disable-next-line:jsx-no-lambda
    <Route path={path} exact={exact} render={(props) => {
      return(
        <React.Fragment>
          {isDemoPage && <Component {...props} /> }
          {!isDemoPage && isAuthorized && restricted && <Redirect to={'/'} />}
          {(!isDemoPage && (!isAuthorized || !restricted) && canRenderPublicPage) && <Component {...props} />}
          {(!isDemoPage && !canRenderPublicPage && restricted) && <Component {...props} />}
        </React.Fragment>
      )
    }}/>
  )
}

export default PublicRoute
