import React, { useState } from 'react'
import Slider from 'react-rangeslider'

import 'react-rangeslider/lib/index.css'

import './HoursSlider.scss'

const HORIZONTAL_LABELS = {
  1: '1',
  5: '5',
  10: '10',
  15: '15',
  20: '20'
}

interface HoursSliderProps {
  readonly total: number
  onChange?(value: number): void
}

const HoursSlider: React.FC<HoursSliderProps> = props => {
  const [time, setTime] = useState(1)

  const onChange = (value: number) => {
    if (value === time) { return }

    if (props.onChange) {
      setTime(value)
      props.onChange(value)
    }
  }

  const renderPrice = () => {
    return(
      <span>{props.total.toFixed(2)}€ (exl. VAT)</span>
    )
  }

  return(
    <div className="HoursSlider">
      <div className="HoursSlider__question">How many hours do you need?</div>

      <Slider className="HoursSlider__slider"
        min={1}
        max={20}
        value={time}
        tooltip={false}
        labels={HORIZONTAL_LABELS}
        onChange={onChange}
      />

      <div className="HoursSlider__summary">
        You will buy {time} automatic hour(s) for {renderPrice()}
      </div>
    </div>
  )
}

export default HoursSlider
