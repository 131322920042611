import React from 'react'
import { connect } from 'react-redux'
import { Prompt } from 'react-router-dom'
import isEqual from 'lodash/isEqual'
import some from 'lodash/some'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { IApplicationState } from '../../rootReducer'
import Header from '@SiteContainers/Header'
import Button from '@SiteComponents/Button'
import PlusBig from '@Images/plus_big.svg'
import TrashBig from '@Images/trash_big.svg'
import SaveVocabularyPopup from '@SiteComponents/SaveVocabularyPopup'
import CreateVocabularyPopup from '@SiteComponents/CreateVocabularyPopup'
import DeleteVocabularyPopup from '@SiteComponents/DeleteVocabularyPopup'

import {
  createVocabulary,
  updateVocabulary,
  deleteVocabulary,
  fetchVocabularies,
} from '@SiteModules/Vocabulary/actions'

import {
  IVocabulary,
  IVocabularyAttributes,
  ICreateVocabularyPayload,
  IUpdateVocabularyPayload,
  IDeleteVocabularyPayload,
} from '@SiteModules/Vocabulary/types'

import './Vocabulary.scss'

interface VocabularyProps {
  readonly loadingUpdate: boolean
  readonly loadingCreate: boolean
  readonly loadingDelete: boolean
  readonly vocabularies: IVocabulary[]
  fetchVocabularies(): void
  createVocabulary(payload: ICreateVocabularyPayload): void
  updateVocabulary(payload: IUpdateVocabularyPayload): void
  deleteVocabulary(payload: IDeleteVocabularyPayload): void
}

interface VocabularyState {
  readonly edited: boolean
  readonly delayedAction: string
  readonly utteranceTooLong: boolean
  readonly saveVocabularyPopupOpen: boolean
  readonly createVocabularyPopupOpen: boolean
  readonly deleteVocabularyPopupOpen: boolean
  readonly nextActiveVocabulary: IVocabulary | null
  readonly initialActiveVocabularyUtterances: string[]
  readonly activeVocabularyAttributes: IVocabularyAttributes
}

const VOCABULARY_NAME_MAX_LENGTH_TO_SHOW = 25
const UTTERANCE_MAX_LENGTH = 100

class Vocabulary extends React.Component<VocabularyProps, VocabularyState> {
  constructor(props: VocabularyProps) {
    super(props)

    this.state = {
      edited: false,
      delayedAction: '',
      utteranceTooLong: false,
      nextActiveVocabulary: null,
      saveVocabularyPopupOpen: false,
      createVocabularyPopupOpen: false,
      deleteVocabularyPopupOpen: false,
      activeVocabularyAttributes: {
        id: -1,
        name: '',
        utterances: []
      },
      initialActiveVocabularyUtterances: [''],
    }
  }

  componentDidMount() {
    this.props.fetchVocabularies()
  }

  openSaveVocabularyPopup = () => {
    this.setState({ saveVocabularyPopupOpen: true })
  }

  openCreateVocabularyPopup = () => {
    this.setState({ createVocabularyPopupOpen: true })
  }

  openDeleteVocabularyPopup = () => {
    this.setState({ deleteVocabularyPopupOpen: true })
  }

  closePopup = () => {
    this.setState({
      deleteVocabularyPopupOpen: false,
      createVocabularyPopupOpen: false,
      saveVocabularyPopupOpen: false,
    })
  }

  handleSave = () => {
    const {id, utterances } = this.state.activeVocabularyAttributes
    const payload = { id, utterances }
    this.props.updateVocabulary(payload)
    this.setState({
      edited: false,
      initialActiveVocabularyUtterances: utterances
    })
  }

  handleCreate = () => {
    if (this.state.edited) {
      this.setState({
        delayedAction: 'createVocabulary'
      })
      this.openSaveVocabularyPopup()
    } else {
      this.openCreateVocabularyPopup()
    }
  }

  createVocabulary = (payload: ICreateVocabularyPayload) => {
    this.props.createVocabulary(payload)
    this.closePopup()
    this.resetActiveVocabulary()
  }

  deleteVocabulary = () => {
    const {id } = this.state.activeVocabularyAttributes
    const payload = { id }
    this.props.deleteVocabulary(payload)
    this.closePopup()
    this.resetActiveVocabulary()
  }

  handleChangeActiveVocabulary = (vocabulary: IVocabulary) => {
    if (this.state.edited) {
      this.setState({
        delayedAction: 'changeActiveVocabulary',
        nextActiveVocabulary: vocabulary
      })
      this.openSaveVocabularyPopup()
    } else {
      this.setState({
        activeVocabularyAttributes: vocabulary.attributes,
        initialActiveVocabularyUtterances: vocabulary.attributes.utterances || [''],
        edited: false
      })
    }
  }

  resetActiveVocabulary = () => {
    this.setState({
      activeVocabularyAttributes: {
        id: -1,
        name: '',
        utterances: []
      },
      edited: false,
      initialActiveVocabularyUtterances: [''],
    })
  }

  changeVocabularyUtterances = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const editedUtterances = e.target.value.split('\n')

    this.setState({utteranceTooLong: some(editedUtterances, utterance => utterance.length > UTTERANCE_MAX_LENGTH)})

    const { initialActiveVocabularyUtterances } = this.state
    const differentFromInitialUtterances = !isEqual(editedUtterances, initialActiveVocabularyUtterances)
    this.setState((state ) => {
      return({
        edited: differentFromInitialUtterances,
        activeVocabularyAttributes: {...state.activeVocabularyAttributes, utterances: editedUtterances}
      })
    })
  }

  renderVocabularyList = () => {
    const { vocabularies } = this.props
    const { activeVocabularyAttributes } = this.state

    const vocabularyList = vocabularies.map((vocabulary: IVocabulary) => {
      const changeActiveVocabulary = () => {
        if (vocabulary.attributes.id !== activeVocabularyAttributes.id) {
          this.handleChangeActiveVocabulary(vocabulary)
        }
      }

      const shortenedVocabularyName = vocabulary.attributes.name.length > VOCABULARY_NAME_MAX_LENGTH_TO_SHOW ?
        `${vocabulary.attributes.name.substring(0,VOCABULARY_NAME_MAX_LENGTH_TO_SHOW)}...` : vocabulary.attributes.name
      const isActive = vocabulary.attributes.id === activeVocabularyAttributes.id

      return(
        <li
          key={vocabulary.id}
          className={`Vocabulary__list-item ${isActive ? 'Vocabulary__list-item-active' : ''}`}
          onClick={changeActiveVocabulary}
        >
          {shortenedVocabularyName}
          <span className="Vocabulary__list-trash" onClick={this.openDeleteVocabularyPopup}>
            {isActive && this.renderTrash()}
          </span>
        </li>
      )
    })

    return(
      <div className="Vocabulary__list-container">
        <ul className="Vocabulary__list">
          {vocabularyList}
        </ul>
        <div className="Vocabulary__list-new" onClick={this.handleCreate}>
          <PlusBig className="Vocabulary__list-new-icon"/> Create new list
        </div>
      </div>
    )
  }

  renderTrash = () => <TrashBig className="Vocabulary__list-trash-icon"/>

  renderForm = () => {
    const { activeVocabularyAttributes, utteranceTooLong } = this.state
    const activeVocabularyUtterancesString = activeVocabularyAttributes.utterances ? activeVocabularyAttributes.utterances.join('\n') : ''

    return(
      <div className="Vocabulary__form-container">
        <form>
          <textarea
            value={activeVocabularyUtterancesString}
            onChange={this.changeVocabularyUtterances}
            placeholder={'Type your words here...\nOne line for one expression or word'}
            className="Vocabulary__form-textarea"
          />
        </form>
        {utteranceTooLong && this.renderWarningTooLongUtterance()}
        {this.renderButton()}
      </div>
    )
  }

  renderButton = () => {
    const shouldDisableButton = !this.state.edited || this.props.loadingUpdate || this.state.utteranceTooLong

    return(
      <Button
        type="button"
        caption="Save"
        color="primary-dark"
        onClick={this.handleSave}
        disabled={shouldDisableButton}
      />
    )
  }

  renderWarningTooLongUtterance = () => {
    return(
      <div className="Vocabulary__form-container-warning">
        Words or expressions must not be longer than 100 characters
      </div>
    )
  }

  renderCreateVocabularyPopup = () => {
    return(
      <CreateVocabularyPopup
        closePopup={this.closePopup}
        loading={this.props.loadingCreate}
        createVocabulary={this.createVocabulary}
      />
    )
  }

  renderDeleteVocabularyPopup = () => {
    return(
      <DeleteVocabularyPopup
        closePopup={this.closePopup}
        loading={this.props.loadingDelete}
        deleteVocabulary={this.deleteVocabulary}
      />
    )
  }

  renderSaveVocabularyPopup = () => {
    const delayedAction = () => {
      this.closePopup()
      if (this.state.delayedAction === 'createVocabulary') {
        this.openCreateVocabularyPopup()
      } else if (this.state.delayedAction === 'changeActiveVocabulary') {
        this.setState({
          activeVocabularyAttributes: this.state.nextActiveVocabulary!.attributes,
          initialActiveVocabularyUtterances: this.state.nextActiveVocabulary!.attributes.utterances,
          edited: false
        })
      }
      this.setState({
        delayedAction: '',
        nextActiveVocabulary: null,
      })
    }

    const closePopup = () => {
      this.setState({
        delayedAction: '',
        nextActiveVocabulary: null,
      })
      this.closePopup()
    }

    return(
      <SaveVocabularyPopup
        closePopup={closePopup}
        loading={this.props.loadingUpdate}
        save={this.handleSave}
        delayedAction={delayedAction}
        shouldDisableSaveButton={this.state.utteranceTooLong}
      />
    )
  }

  render() {
    const {
      saveVocabularyPopupOpen,
      createVocabularyPopupOpen,
      deleteVocabularyPopupOpen,
      activeVocabularyAttributes,
    } = this.state

    const shouldShowForm = activeVocabularyAttributes.id !== -1

    return (
      <div className="Vocabulary">
        <Prompt
          when={this.state.edited}
          message="You have unsaved changes. They will be lost if you leave the page."
        />
        {createVocabularyPopupOpen && this.renderCreateVocabularyPopup()}
        {deleteVocabularyPopupOpen && this.renderDeleteVocabularyPopup()}
        {saveVocabularyPopupOpen && this.renderSaveVocabularyPopup()}
        <Header />
        <div className="Vocabulary__title">
          Your vocabulary lists
        </div>
        <div className="Vocabulary__subtitle">
          Add words to improve the results of your automatic or professional projects
        </div>
        <div className="Vocabulary__list-and-form">
          {this.renderVocabularyList()}
          {shouldShowForm && this.renderForm()}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { vocabularies } = state

  return {
    vocabularies: vocabularies.vocabularies,
    loadingUpdate: vocabularies.updateVocabulary.loading,
    loadingCreate: vocabularies.createVocabulary.loading,
    loadingDelete: vocabularies.deleteVocabulary.loading,
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    createVocabulary,
    updateVocabulary,
    deleteVocabulary,
    fetchVocabularies
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Vocabulary)
