import {
  ChannelsActionTypes,
  IGetExternalVideosSuccessPayload,
  IGetExternalChannelsSuccessPayload,
  IRemoveExternalChannelPayload
} from './types'

export const getExternalVideos = () => ({
  type: ChannelsActionTypes.GET_EXTERNAL_VIDEOS
})

export const getExternalVideosFailure = () => ({
  type: ChannelsActionTypes.GET_EXTERNAL_VIDEOS_FAILURE
})

export const getExternalVideosSuccess = (payload: IGetExternalVideosSuccessPayload) => ({
  type: ChannelsActionTypes.GET_EXTERNAL_VIDEOS_SUCCESS,
  payload
})

export const increasePageCount = () => ({
  type: ChannelsActionTypes.INCREASE_PAGE_COUNT
})

export const decreasePageCount = () => ({
  type: ChannelsActionTypes.DECREASE_PAGE_COUNT
})

export const getExternalChannels = () => ({
  type: ChannelsActionTypes.GET_EXTERNAL_CHANNELS
})

export const getExternalChannelsFailure = () => ({
  type: ChannelsActionTypes.GET_EXTERNAL_CHANNELS_FAILURE
})

export const getExternalChannelsSuccess = (payload: IGetExternalChannelsSuccessPayload) => ({
  type: ChannelsActionTypes.GET_EXTERNAL_CHANNELS_SUCCESS,
  payload
})

export const openActionsDropdown = () => ({
  type: ChannelsActionTypes.OPEN_ACTIONS_DROPDOWN
})

export const closeDropdowns = () => ({
  type: ChannelsActionTypes.CLOSE_DROPDOWNS
})

export const removeExternalChannel = (payload: IRemoveExternalChannelPayload) => ({
  type: ChannelsActionTypes.REMOVE_EXTERNAL_CHANNEL,
  payload
})
