import AWS from 'aws-sdk/global'
import S3 from 'aws-sdk/clients/s3'
import { v4 as uuidv4 } from 'uuid'
import toastr from 'toastr'

import FileNameSanitizer from '@Utils/FileNameSanitizer'

import 'toastr/build/toastr.css'

const AUDIO_EXTENSION = 'mp3'

const TOASTR_OPTIONS = {
  closeButton: true,
  timeOut: 0,
  extendedTimeOut: 0
}

export interface IElementUploaderOptions {
  readonly userId: string
  uploadElementCallback: (params: IUploadedElement) => void
  showProgressCallback: (data: S3.ManagedUpload.Progress) => void
}

export interface IUploadedElement {
  readonly width: number
  readonly height: number
  readonly url: string
  readonly filename: string
  readonly kind: string
}

interface IAWSUploadParams {
  readonly ACL: string
  readonly ContentType: string
  readonly Body: File
  readonly Bucket: string
  readonly Key: string
}

interface IElementMetaData {
  readonly width: number
  readonly height: number
  readonly size: number
  readonly filename: string
  readonly loaded: boolean
}

class ElementUploader {
  S3Client: S3
  userId: string
  filename: string
  sanitizedFileName: string
  element: HTMLVideoElement
  elementMetaData: IElementMetaData
  uploadElementCallback: (params: IUploadedElement) => void
  showProgressCallback: (data: S3.ManagedUpload.Progress) => void

  constructor(
    private readonly file: File,
    options: IElementUploaderOptions,
  ) {
    this.file = file
    this.elementMetaData = { width: 0, height: 0, size: 0, filename: '', loaded: false }
    this.filename = decodeURI(encodeURI(this.file.name))
    // we have to take only the first 50 chars to supress the error for tempfiles:
    // Filename too long @rb_sysopen - /tmp/...
    // the maximum tempfile's name is 170 chars, but we also add a UUID to it.
    this.sanitizedFileName = new FileNameSanitizer(this.filename).call().substring(0,50)
    this.uploadElementCallback = options.uploadElementCallback
    this.showProgressCallback = options.showProgressCallback
    this.element = this._initializeElement()
    this.userId = options.userId

    AWS.config.region = 'eu-west-1'
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: 'eu-west-1:f53d9a4e-3b29-483f-a166-9dff87ca2941'
    })

    // https://github.com/aws/aws-sdk-js/issues/949#issuecomment-204178782
    this.S3Client = new S3({
      region: 'eu-west-1',
      useAccelerateEndpoint: false,
      httpOptions: { timeout: 0 },
    })
  }

  call(): S3.ManagedUpload {
    const params = this._generateAwsParams()
    return this._upload(params)
  }

  _upload(params: IAWSUploadParams): S3.ManagedUpload {
    return this.S3Client.upload(params, (err: Error, data: S3.ManagedUpload.SendData) => {
      if (err) {
        if (err.name === 'RequestTimeTooSkewed') {
          toastr.error('Please adjust the system time of your device. Then try the uploading again.', 'Element cannot be uploaded!', TOASTR_OPTIONS)
        }
        if (!window.Rollbar) { return }
        window.Rollbar.error(err, data)
      } else {
        const fileExtension = this.file.name.split('.').pop()
        const kind = fileExtension === AUDIO_EXTENSION ? 'audio' : 'video'
        if (this.elementMetaData.loaded) {
          this.uploadElementCallback({
            ...this.elementMetaData,
            url: decodeURI(data.Location),
            kind
          })
        } else {
          toastr.error('There is a problem with the format of your file. Your file could be corrupted. Please check it.', 'Upload cannot be completed!', TOASTR_OPTIONS)
        }
        this.element.removeEventListener('loadedmetadata', () => {
          this.elementMetaData = {
            height: this.element.videoHeight,
            width: this.element.videoWidth,
            size: this.file.size,
            filename: this.filename,
            loaded: true
          }
        })
      }
    }).on('httpUploadProgress', (data: S3.ManagedUpload.Progress) => {
      this.showProgressCallback(data)
    })
  }

  _generateAwsParams(): IAWSUploadParams {
    const fileExtension = this.filename.split('.').pop()
    const bucketName = window.EditorSources ? `${window.EditorSources.s3Bucket}/user/${this.userId}` : `${window.Checksub.s3Bucket}/user/${this.userId}`

    // Characters That Might Require Special Handling
    // https://docs.aws.amazon.com/en_us/AmazonS3/latest/dev/UsingMetadata.html
    const key = encodeURI(`${this.sanitizedFileName}_${uuidv4()}.${fileExtension}`)

    return {
      ACL: 'public-read',
      ContentType: this.file.type,
      Body: this.file,
      Bucket: bucketName,
      Key: key
    }
  }

  _initializeElement(): HTMLVideoElement {
    const element = document.createElement('video')
    element.preload = 'metadata'
    element.src = URL.createObjectURL(this.file)

    element.addEventListener('loadedmetadata', () => {
      this.elementMetaData = {
        height: this.element.videoHeight,
        width: this.element.videoWidth,
        size: this.file.size,
        filename: this.filename,
        loaded: true
      }
    })

    return element
  }
}

export default ElementUploader
