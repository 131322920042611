import React from 'react'
import dayjs from 'dayjs'

interface FaqProps {
  durationTrialInDays: number
}

const Faq: React.FC<FaqProps> = props => {
  const now = dayjs()
  const endOfTrial = now.add(props.durationTrialInDays, 'day')

  return (
    <div className="Subscription__faq">
      Your 14-day trial is <span className="Subscription__faq-important">100% free</span> and lasts until <span className="Subscription__faq-important">{endOfTrial.format('MMMM DD, YYYY')}</span>. Cancel any time.
      <br /><br />
      <span className="Subscription__faq-important">How does the credit system work?</span>
      <br />
      Each time you generate a subtitle, translation or dubbing, the duration of the video is deducted from your subscription credits. The exception is dubbing <u>with voice cloning</u>, for which we deduct 2 times the length of the video.
      <br /><br />
      <span className="Subscription__faq-important">Will I see a charge right away?</span>
      <br />
      No. You won't be charged until after your free trial ends on {endOfTrial.format('MMMM DD, YYYY')}.
      <br />
      After your free trial, you'll be charged the price of your plan (monthly or yearly) plus taxes if applicable until you change your plan or cancel your subscription.
      <br /><br />
      <span className="Subscription__faq-important">Can I change or cancel my plan?</span>
      <br />
      Yes, you can switch to a new plan or cancel your subscription at any time.
      <br /><br />
      <span className="Subscription__faq-important">What languages do you suport?</span>
      <br />
      Access the entire list via this <a href="https://www.checksub.com/language-list/">link</a>.
    </div>
  )
}

export default Faq
