import { IUser } from '@SiteModules/User/types'

import {
  IUserSignInPayload,
  ISsoSignInPayload,
  SignInFormActionTypes,
  TSignInActionTypes,
} from './types'

export const signIn = (payload: IUserSignInPayload): TSignInActionTypes => ({
  type: SignInFormActionTypes.SIGN_IN,
  payload,
})

export const ssoSignIn = (payload: ISsoSignInPayload): TSignInActionTypes => ({
  type: SignInFormActionTypes.SSO_SIGN_IN,
  payload,
})

export const signInSuccess = (payload: IUser): TSignInActionTypes => ({
  type: SignInFormActionTypes.SIGN_IN_SUCCESS,
  payload,
})

export const signInFailure = (): TSignInActionTypes => ({
  type: SignInFormActionTypes.SIGN_IN_FAILURE,
})
