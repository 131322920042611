import React, { useState, useEffect } from 'react'

import VoicePlayIcon from '@Images/voice_play_big2.svg'

import {
  IVoiceAttributes,
} from '@EditorContainers/Settings/types'

interface PlayButtonForVoicesProps {
  readonly voices: IVoiceAttributes[]
  readonly voiceName: string
}

const PlayButtonForVoices: React.FC<PlayButtonForVoicesProps> = props => {
  const { voiceName, voices } = props

  const [currentVoiceAudio, setCurrentVoiceAudio] = useState<HTMLAudioElement>()
  const [showInfoForVoiceSample, setShowInfoForVoiceSample] = useState(false)
  const [infoForVoiceSampleTimeout, setInfoForVoiceSampleTimeout] = useState<ReturnType<typeof setTimeout> | null>(null)

  useEffect(() => {
    const currentVoice = voices.find(voice => voice.name === voiceName)

    if (currentVoice) {
      setVoiceAudio(currentVoice.sampleUrl)
    }
  }, [voiceName])

  const setVoiceAudio = (sampleUrl: string) => {
    if (currentVoiceAudio && !currentVoiceAudio.paused) {
      currentVoiceAudio.pause()
    }
    setCurrentVoiceAudio(new Audio(sampleUrl))
  }

  const toggleShowInfoForVoiceSample = () => {
    if (infoForVoiceSampleTimeout) {
      setShowInfoForVoiceSample(false)
      clearTimeout(infoForVoiceSampleTimeout)
      setInfoForVoiceSampleTimeout(null)
    } else {
      const newInfoForVoiceSampleTimeout = setTimeout(() => {
        setShowInfoForVoiceSample(true)
      }, window.Checksub.tooltipDelay)
      setInfoForVoiceSampleTimeout(newInfoForVoiceSampleTimeout)
    }
  }

  const startPlayingSample = () => {
    if (currentVoiceAudio && currentVoiceAudio.paused) {
      currentVoiceAudio.play()
    }
  }

  return (
    <div
      className="VoiceSettings__play-wrapper"
      onMouseEnter={toggleShowInfoForVoiceSample}
      onMouseLeave={toggleShowInfoForVoiceSample}
    >
      <VoicePlayIcon
        className="VoiceSettings__play"
        onClick={startPlayingSample}
      />
      {showInfoForVoiceSample && (
        <div className="VoiceSettings__tooltip VoiceSettings__tooltip-play">
          Play Voice Sample
        </div>
      )}
    </div>
  )
}

export default PlayButtonForVoices
