import axios from 'axios'
import getMetaValue from '@Utils/GetMetaValue'
import convertToSnakeCase from '@Utils/ConvertToSnakeCase'

import {
  ISubmitDemoProjectVersionSettingsPayload
} from './types'

// For testing Net::Timeout error that's possible to use this request.
// just send the request and wait until it's failed.
// return axios.put('http://example.com:81/')

const apiHeaders = {
  headers: {
    'X-CSRF-Token': getMetaValue('csrf-token'),
    'Content-Type': 'application/json',
  }
}

export function submitDemoProjectVersionSettings(payload: ISubmitDemoProjectVersionSettingsPayload) {
  return axios.post(`/api/v1/demo/start_processing`, convertToSnakeCase(payload), apiHeaders)
}
