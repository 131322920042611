import axios, { AxiosRequestConfig } from 'axios'
import convertToSnakeCase from '@Utils/ConvertToSnakeCase'

import {
  IFetchSsoDataPayload,
  IAddUsersPayload,
  IDeleteMemberPayload,
  IChangeRolePayload,
  IChangeSubscriptionPayload,
} from './types'

const config: AxiosRequestConfig = {
  headers: {
    'X-CSRF-Token': window.Checksub.token
  },
  responseType: 'json'
}

export function fetchSsoData(payload: IFetchSsoDataPayload) {
  return axios.get(`/api/v1/sso_organizations/${payload.ssoOrganizationId}`)
}

export function addUsers(payload: IAddUsersPayload) {
  return axios.put(`/api/v1/sso_organizations/${payload.ssoOrganizationId}/add_users`, convertToSnakeCase(payload), config)
}

export function deleteMember(payload: IDeleteMemberPayload) {
  return axios.delete(`/api/v1/sso_organizations/${payload.ssoOrganizationId}/delete_member`, { ...config, data: convertToSnakeCase(payload) })
}

export function changeRole(payload: IChangeRolePayload) {
  return axios.put(`/api/v1/sso_organizations/${payload.ssoOrganizationId}/change_role`, convertToSnakeCase(payload), config)
}

export function changeSubscription(payload: IChangeSubscriptionPayload) {
  return axios.put(`/api/v1/sso_organizations/${payload.ssoOrganizationId}/change_subscription`, convertToSnakeCase(payload), config)
}
