const PRICE: ISubscriptionPrice = {
  'junior': {
    'monthly': {
      'eur': 18,
      'usd': 18
    },
    'yearly': {
      'eur': 144,
      'usd': 144
    }
  },
  'starter': {
    'monthly': {
      'eur': 59,
      'usd': 59
    },
    'yearly': {
      'eur': 576,
      'usd': 576
    }
  },
  'studio': {
    'monthly': {
      'eur': 99,
      'usd': 99
    },
    'yearly': {
      'eur': 1020,
      'usd': 1020
    }
  }
}

interface ISubscriptionPrice {
  readonly junior: ISubscriptionPlanPrice,
  readonly starter: ISubscriptionPlanPrice,
  readonly studio: ISubscriptionPlanPrice,
  readonly [key: string]: ISubscriptionPlanPrice
}

interface ISubscriptionPlanPrice {
  readonly monthly: ISubscriptionPlanPeriodPrice,
  readonly yearly: ISubscriptionPlanPeriodPrice,
  readonly [key: string]: ISubscriptionPlanPeriodPrice
}

interface ISubscriptionPlanPeriodPrice {
  readonly eur: number,
  readonly usd: number,
  readonly [key: string]: number
}

export default function subscriptionPriceCalculatorPerPeriod(selectedPlanName: string, monthlyPeriod: boolean, priceInUsd: boolean): number {
  const period = monthlyPeriod ? 'monthly' : 'yearly'
  const currency = priceInUsd ? 'usd' : 'eur'

  return PRICE[selectedPlanName]?.[period]?.[currency]
}
