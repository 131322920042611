import JSZip from 'jszip'
import axios from 'axios'
import saveAs from 'file-saver'

const ZIP_FILENAME = 'Checksub.zip'

export default function zipAndSaveFiles(urls: (string | undefined)[]): void {
  // Zip files: https://yashodgayashan.medium.com/zip-files-in-react-30fb77fd6a58
  const zip = new JSZip()

  const axiosCalls = urls.map(url => {
    return (
      axios.get(url as string, { responseType: 'blob' })
    )
  })

  Promise.all(axiosCalls).then(responses => {
    responses.forEach(response => {
      const urlParts = response.config!.url!.split('/')
      const filename = urlParts[urlParts.length - 1]

      zip.file(filename, response.data)
    })

    zip.generateAsync({ type: 'blob' }).then(content => {
      saveAs(content, ZIP_FILENAME)
    })
  })
}
