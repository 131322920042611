import { TActionTypes, IActionSuccess } from '@Root/types'

export const SsoActionTypes = {
  ADD_USERS: '@@sso/ADD_USERS',
  ADD_USERS_SUCCESS: '@@sso/ADD_USERS_SUCCESS',
  ADD_USERS_FAILURE: '@@sso/ADD_USERS_FAILURE',
  OPEN_ADD_USERS_TO_SSO_POPUP: '@@sso/OPEN_ADD_USERS_TO_SSO_POPUP',
  CLOSE_ADD_USERS_TO_SSO_POPUP: '@@sso/CLOSE_ADD_USERS_TO_SSO_POPUP',
  FETCH_SSO_DATA: '@@sso/FETCH_SSO_DATA',
  FETCH_SSO_DATA_SUCCESS: '@@sso/FETCH_SSO_DATA_SUCCESS',
  FETCH_SSO_DATA_FAILURE: '@@sso/FETCH_SSO_DATA_FAILURE',
  DELETE_MEMBER: '@@sso/DELETE_MEMBER',
  DELETE_MEMBER_SUCCESS: '@@sso/DELETE_MEMBER_SUCCESS',
  DELETE_MEMBER_FAILURE: '@@sso/DELETE_MEMBER_FAILURE',
  CHANGE_ROLE: '@@sso/CHANGE_ROLE',
  CHANGE_ROLE_SUCCESS: '@@sso/CHANGE_ROLE_SUCCESS',
  CHANGE_ROLE_FAILURE: '@@sso/CHANGE_ROLE_FAILURE',
  CHANGE_SUBSCRIPTION: '@@sso/CHANGE_SUBSCRIPTION',
  CHANGE_SUBSCRIPTION_SUCCESS: '@@sso/CHANGE_SUBSCRIPTION_SUCCESS',
  CHANGE_SUBSCRIPTION_FAILURE: '@@sso/CHANGE_SUBSCRIPTION_FAILURE',
}

export interface ISsoState {
  readonly addUsersToSsoPopupOpen: boolean
  readonly loading: boolean
  readonly ssoUsers: ISsoUser[]
  readonly ssoSubscriptions: ISsoSubscription[]
}

export interface ISsoOrganization {
  readonly id: string
  readonly type: 'ssoOrganization'
  readonly attributes: {
    readonly allowProfilesOutsideOrganization: boolean
    readonly name: string | null
    readonly domains: string[]
    readonly ssoUsers: {
      readonly data: ISsoUser[]
    }
    readonly ssoSubscriptions: {
      readonly data: ISsoSubscription[]
    }
  }
}

export interface ISsoUser {
  readonly id: string
  readonly type: 'ssoUser'
  readonly attributes: {
    readonly userId: number
    readonly firstName: string
    readonly lastName: string
    readonly email: string
    readonly subscriptionId: undefined | number
    readonly isAdmin: boolean
  }
}

export interface ISsoSubscription {
  readonly id: string
  readonly type: 'ssoSubscription'
  readonly attributes: {
    readonly subscriptionId: number
    readonly label: string
  }
}

export interface IFetchSsoDataPayload {
  readonly ssoOrganizationId: number
}

export interface IFetchSsoDataSuccessResponseBody {
  readonly data: ISsoOrganization
}

export interface IAddUsersPayload {
  readonly ssoOrganizationId: number
  readonly emails: string[]
  readonly isAdmin: boolean
}

export interface IAddUsersSuccessResponseBody {
  readonly data: ISsoUser[]
}

export interface IDeleteMemberPayload {
  readonly ssoOrganizationId: number
  readonly userId: number
}

export interface IDeleteMemberSuccessResponseBody {
  readonly data: ISsoUser[]
}

export interface IChangeRolePayload {
  readonly ssoOrganizationId: number
  readonly userId: number
  readonly isAdmin: boolean
}

export interface IChangeRoleSuccessResponseBody {
  readonly data: ISsoUser
}

export interface IChangeSubscriptionPayload {
  readonly ssoOrganizationId: number
  readonly userId: number
  readonly subscriptionId: number
}

export interface IChangeSubscriptionSuccessResponseBody {
  readonly data: ISsoUser
}

export type ISsoActionTypes =
  TActionTypes |
  IActionSuccess<IAddUsersSuccessResponseBody> |
  IActionSuccess<IDeleteMemberSuccessResponseBody> |
  IActionSuccess<IChangeRoleSuccessResponseBody> |
  IActionSuccess<IChangeSubscriptionSuccessResponseBody> |
  IActionSuccess<IFetchSsoDataSuccessResponseBody>
