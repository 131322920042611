import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { IApplicationState } from '../../rootReducer'
import Header from '@SiteContainers/Header'
import Button from '@SiteComponents/Button'
import HoursSlider from '@SiteComponents/HoursSlider'
import formattedCredits from '@Utils/FormattedCredits'
import { ICredit } from '@SiteModules/Credit/types'

import './CreditsTopup.scss'

import {
  PayloadData
} from './types'

import {
  updateTime,
  submitTopupRequest
} from './actions'

interface CreditsTopupProps {
  readonly time: number
  readonly loading: boolean
  readonly proBalance: number
  readonly autoCredits: ICredit[]
  updateTime(time: number): void
  submitTopupRequest(payload: PayloadData): void
}

interface CreditsTopupState {}

class CreditsTopup extends React.Component<CreditsTopupProps, CreditsTopupState> {
  updateTime = (time: number) => {
    this.props.updateTime(time)
  }

  handleTopupRequest = () => {
    const { time } = this.props
    const type = 'essential'

    this.props.submitTopupRequest({ time, type })
  }

  openMailTo = () => {
    location.href = 'mailto:team@checksub.com'
  }

  renderCreditsInfo = () => {
    const { proBalance, autoCredits } = this.props

    const autoBalance = autoCredits.reduce((total, credit) => {
      return total + credit.attributes.secondsAvailable
    }, 0)

    const shouldShowProCredits = proBalance !== 0
    const shouldShowAutoCreditsDetails = autoBalance !== 0

    const autoCreditsDetails = autoCredits.map((credit, index) => {
      const shouldShowExpirationDate = credit.attributes.expirationDate !== ''
      return (
        <li key={index}>
          {formattedCredits(credit.attributes.secondsAvailable)}
          {shouldShowExpirationDate && <span>{` to use before ${credit.attributes.expirationDate}`}</span>}
        </li>
      )
    })

    return (
      <React.Fragment>
        <div className="CreditsTopup__info">
          <div className="CreditsTopup__info-box CreditsTopup__info-box-auto">
            <div className="CreditsTopup__info-header">
              Automatic Credits
            </div>
            <div className="CreditsTopup__info-amount">
              {formattedCredits(autoBalance)}
            </div>
            <div className="CreditsTopup__info-details">
              {shouldShowAutoCreditsDetails ? autoCreditsDetails : 'You currently have no credits available'}
            </div>
          </div>
          {shouldShowProCredits &&
            <div className="CreditsTopup__info-box CreditsTopup__info-box-pro">
              <div className="CreditsTopup__info-header">
                Professional Credits
              </div>
              <div className="CreditsTopup__info-amount">
                €{proBalance}
              </div>
              <div className="CreditsTopup__info-details">
                No expiration date
              </div>
            </div>
          }
        </div>
      </React.Fragment>
    )
  }

  render() {
    const { time, loading } = this.props

    const total = time * window.Checksub.essentialCostForMinute * 60

    return (
      <div className="CreditsTopup">
        <Header />

        {this.renderCreditsInfo()}

        <div className="CreditsTopup__body">
          <div className="CreditsTopup__title">Top-up your account</div>

          <HoursSlider
            total={total}
            onChange={this.updateTime}
          />

          <div className="CreditsTopup__buttonConfirm">
            <Button
              color="primary-dark"
              caption="Confirm"
              onClick={this.handleTopupRequest}
              showSpinner={loading}
              disabled={loading}
            />
          </div>

          <div className="CreditsTopup__question">
            Want to save on the costs per hour? <br />
            <Link to="/subscriptions">Checkout our subscriptions</Link>
          </div>

          <div className="CreditsTopup__question">
            Do you have larger volumes? Do you have any questions?
          </div>

          <div className="CreditsTopup__buttonContact">
            <Button
              caption="Contact us"
              color="primary-dark"
              onClick={this.openMailTo}
            />
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { creditsTopup, user } = state

  return {
    time: creditsTopup.time,
    loading: creditsTopup.loading,
    proBalance: user.user.attributes.balance,
    autoCredits: user.credits
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    updateTime,
    submitTopupRequest
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CreditsTopup)
