import { put, takeLatest } from 'redux-saga/effects'
import * as Eff from 'redux-saga/effects' // tslint:disable-line:no-duplicate-imports
import toastr from 'toastr'

import Logger from '@Utils/Logger'
import { IAction } from '@Root/types'

import {
  renameLabelSuccess,
  renameLabelFailure,
} from './actions'

import {
  fetchLabels,
} from '@SiteModules/Label/actions'

import {
  IRenameLabelPayload,
  RenameLabelActionTypes,
} from './types'

import {
  closePopup,
} from '@SiteContainers/ProjectVersionsList/actions'

import * as API from './api'

const call: any = Eff.call

function* renameLabelSaga(action: IAction<IRenameLabelPayload>) {
  try {
    yield call(API.renameLabel, action.payload)
    yield put(renameLabelSuccess())
    yield put(closePopup())
    yield put(fetchLabels())
    toastr.success('', 'Label is renamed!')
  } catch (e) {
    Logger.error(e, 'Label could not be renamed!')
    yield put(renameLabelFailure())
  }
}

function* renameLabelPopupSagas() {
  yield takeLatest(RenameLabelActionTypes.RENAME_LABEL, renameLabelSaga)
}

export default renameLabelPopupSagas
