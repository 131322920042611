import React, { useState } from 'react'
import ButtonNewV2 from '@SiteComponents/ButtonNewV2'

import './ConfirmationButton.scss'

interface ConfirmationButtonProps {
  readonly showSpinner: boolean
  readonly projectVersionLanguage: string
  readonly disableButton: boolean
  readonly isPaidBySubscription: boolean
  readonly isLanguageLimitReached: boolean
  readonly onlyNewSubtitleLanguages: string[]
  readonly shouldRenderLinkToSubscription: boolean
  readonly shouldDisableDebitDropdown: boolean
  readonly defaultCaption?: string
  readonly shouldShowTooltip?: boolean
  readonly tooltipText?: string
  readonly icon?: JSX.Element
  readonly iconRight?: boolean
  readonly generateShortenedProjectVersion?: boolean
}

const ConfirmationButton: React.FC<ConfirmationButtonProps> = props => {
  const {
    showSpinner,
    disableButton,
    projectVersionLanguage,
    isPaidBySubscription,
    isLanguageLimitReached,
    onlyNewSubtitleLanguages,
    shouldDisableDebitDropdown,
    shouldRenderLinkToSubscription,
    generateShortenedProjectVersion,
    defaultCaption,
    shouldShowTooltip,
    tooltipText,
    iconRight,
    icon,
  } = props

  const [showInfo, setShowInfo] = useState(false)
  const [infoTimeout, setInfoTimeout] = useState<ReturnType<typeof setTimeout> | null>(null)

  const isFormCompleted = projectVersionLanguage && onlyNewSubtitleLanguages.length
  const couldNotCreateWithSubscription = isLanguageLimitReached && isPaidBySubscription

  const shouldDisableButton = disableButton || !isFormCompleted || couldNotCreateWithSubscription || (shouldDisableDebitDropdown && !generateShortenedProjectVersion)

  const buttonCaption = defaultCaption ?? 'Confirm'

  const toggleInfo = () => {
    if (infoTimeout) {
      setShowInfo(false)
      clearTimeout(infoTimeout)
      setInfoTimeout(null)
    } else {
      const newInfoTimeout = setTimeout(() => {
        setShowInfo(true)
      }, window.Checksub.tooltipDelay)
      setInfoTimeout(newInfoTimeout)
    }
  }

  const forwardToSubscription = () => {
    window.location.href = '/subscriptions'
  }

  const shouldShowInfo = shouldShowTooltip && showInfo && !shouldRenderLinkToSubscription

  return (
    <div
      className="ConfirmationButton"
      onMouseEnter={toggleInfo}
      onMouseLeave={toggleInfo}
    >
      {shouldRenderLinkToSubscription ? (
        <ButtonNewV2
          styleType="brand-primary"
          size="large"
          caption="Upgrade my plan"
          showSpinner={showSpinner}
          disabled={showSpinner}
          onClick={forwardToSubscription}
          icon={icon}
          iconRight={iconRight}
        />
      ) : (
        <ButtonNewV2
          type="submit"
          styleType="brand-primary"
          size="large"
          caption={buttonCaption}
          showSpinner={showSpinner}
          disabled={shouldDisableButton}
          icon={icon}
          iconRight={iconRight}
        />
      )}
      {shouldShowInfo && (
        <div className="ProjectVersionSettingsForm__tooltip ConfirmationButton__tooltip">
          {tooltipText}
        </div>
      )}
    </div>
  )
}

export default ConfirmationButton
