import React from 'react'
import CloseIcon from '@Images/close.svg'

import './Popup.scss'

interface PopupProps {
  readonly className?: string
  closePopup?(): void
}

const Popup: React.FC<PopupProps> = props => {
  return (
    <React.Fragment>
      <div className="Overlay" />
      <div className={`Popup ${props.className}`}>
        {props.closePopup && (
          <CloseIcon
            className="Popup__close"
            onClick={props.closePopup}
          />
        )}
        {props.children}
      </div>
    </React.Fragment>
  )
}

export default Popup
