import { Action } from 'redux'
import { put, takeEvery } from 'redux-saga/effects'
import * as Eff from 'redux-saga/effects' // tslint:disable-line:no-duplicate-imports

import Logger from '@Utils/Logger'

import {
  createOrUpdateExternalTokenSuccess,
  createOrUpdateExternalTokenFailure
} from './actions'

import {
  ExternalTokenActionTypes,
  ICreateOrUpdateExternalTokenPayload,
  ICreateOrUpdateExternalTokenSuccessResponse
} from './types'

import * as API from './api'

const call: any = Eff.call

interface IAction extends Action {
  readonly payload: ICreateOrUpdateExternalTokenPayload
}

function* createOrUpdateExternalTokenSaga(action: IAction) {
  try {
    const response: ICreateOrUpdateExternalTokenSuccessResponse = yield call(API.createOrUpdateExternalToken, action.payload)
    yield put(createOrUpdateExternalTokenSuccess(response.data.data))
  } catch (e) {
    Logger.error(e, 'Link to share could not be created')
    yield put(createOrUpdateExternalTokenFailure())
  }
}

function* externalTokenSagas() {
  yield takeEvery(ExternalTokenActionTypes.CREATE_OR_UPDATE_EXTERNAL_TOKEN, createOrUpdateExternalTokenSaga)
}

export default externalTokenSagas
