import uniq from 'lodash/uniq'
import forEach from 'lodash/forEach'

export default function getLanguagesThatDoNotAllowVoiceCloning(projectVersionLanguage: string, subtitleLanguages: string[], voiceCloningLanguages: string[]): string[] {
  const languagesThatDoNotAllowDubbing: string[] = []

  if (!voiceCloningLanguages.includes(projectVersionLanguage)) {
    languagesThatDoNotAllowDubbing.push(projectVersionLanguage)
  }

  forEach(subtitleLanguages, subtitleLanguage => {
    if (!voiceCloningLanguages.includes(subtitleLanguage)) {
      languagesThatDoNotAllowDubbing.push(subtitleLanguage)
    }
  })

  return uniq(languagesThatDoNotAllowDubbing)
}
