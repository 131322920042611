export const ImportSrtActionTypes = {
  IMPORT_SRT: '@@importSrt/IMPORT_SRT',
  IMPORT_SRT_SUCCESS: '@@importSrt/IMPORT_SRT_SUCCESS',
  IMPORT_SRT_FAILURE: '@@importSrt/IMPORT_SRT_FAILURE',
}

export interface IImportSrtState {
  readonly loading: boolean
}

export interface PayloadImportSrt {
  readonly payload: PayloadImportSrtBody
}

export interface PayloadImportSrtBody {
  readonly fileSrt: File | null
}

export interface IImportSrtAction {
  readonly type: string
  readonly payload: PayloadImportSrt
}

export interface IImportSrtFailureAction {
  readonly type: string
}

export interface IImportSrtSuccessAction {
  readonly type: string
}

export interface IImportSrtPayload {
  readonly projectVersionId: string
  readonly subtitleId: number
  readonly fileSrt: File | null
  readonly enableSpeakerDiarization: boolean
}

export type TImportSrtActions = IImportSrtAction | IImportSrtSuccessAction | IImportSrtFailureAction
