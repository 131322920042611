import React, { useState, useCallback } from 'react'

import Balloon from '@SiteComponents/Balloon'

import LabelIcon from '@Images/label.svg'
import MoreIcon from '@Images/more_icon.svg'

import useClickOutsideListener from '@Utils/UseClickOutsideListener'

import {
  IDeleteLabelPopupOpenPayload,
} from '@SiteContainers/DeleteLabelPopup/types'

import {
  IRenameLabelPayload,
} from '@SiteContainers/RenameLabelPopup/types'

interface LabelProps {
  readonly labelId: number
  readonly caption: string
  readonly labelStatus: string
  openCreateLabelPopup(): void
  changeSelectedLabels(id: number): void
  openRenameLabelPopup(payload: IRenameLabelPayload): void
  openDeleteLabelPopup(payload: IDeleteLabelPopupOpenPayload): void
}

const Label: React.FC<LabelProps> = props => {
  const [openBalloon, setOpenBalloon] = useState<boolean>(false)
  const [showMoreIcon, setShowMoreIcon] = useState<boolean>(false)

  const {
    labelId,
    caption,
    labelStatus,
  } = props

  useClickOutsideListener(`LabelsPanel__more-wrapper-${labelId}`, setOpenBalloon, openBalloon)

  const toggleShowingBalloon = (e: React.MouseEvent) => {
    e.stopPropagation()

    setOpenBalloon(!openBalloon)
  }

  const onCreateLabelClick = useCallback(() => {
    setOpenBalloon(false)

    props.openCreateLabelPopup()
  }, [])

  const closeBalloon = useCallback(() => {
    setOpenBalloon(false)
  }, [])

  const onEditClick = () => {
    setOpenBalloon(false)

    props.openRenameLabelPopup({ labelId, caption })
  }

  const onDeleteLabelClick = () => {
    setOpenBalloon(false)

    props.openDeleteLabelPopup({ labelId, caption })
  }

  const onMouseLeave = () => {
    closeBalloon()
    setShowMoreIcon(false)
  }

  const onMouseOver = () => {
    setShowMoreIcon(true)
  }

  const handleLabelSelection = () => {
    props.changeSelectedLabels(labelId)
  }

  return (
    <li
      key={labelId}
      className="LabelsPanel__item"
      onClick={handleLabelSelection}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
    >
      <div className="LabelsPanel__item-content">
        <span className={`LabelsPanel__list-item LabelsPanel__list-item-${labelStatus}`}>
          <span className="LabelsPanel__caption">
            <LabelIcon className="LabelsPanel__arrow" />
            <span className="LabelsPanel__caption-text">{caption}</span>
          </span>
        </span>
        <div
          className={`LabelsPanel__more-wrapper LabelsPanel__more-wrapper-${labelId}`}
          onClick={toggleShowingBalloon}
        >
          {showMoreIcon && <MoreIcon className="LabelsPanel__more-vertical" />}
          {openBalloon && (
            <div className="LabelsPanel__balloon-wrapper">
              <Balloon>
                <li className="LabelsPanel__Balloon__item" onClick={onEditClick}>
                  Edit
                </li>
                <li className="LabelsPanel__Balloon__item" onClick={onDeleteLabelClick}>
                  Delete the label
                </li>
                <li className="LabelsPanel__Balloon__item" onClick={onCreateLabelClick}>
                  Create a new label
                </li>
              </Balloon>
            </div>
          )}
        </div>
      </div>
    </li>
  )
}

export default Label
