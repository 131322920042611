import {
  ICredit,
  PayloadData,
  ICreditsRequired,
  CreditsTopupActionTypes,
  ICreditsTopupActionTypes
} from './types'

export const submitTopupRequest = (payload: PayloadData): ICreditsTopupActionTypes => ({
  type: CreditsTopupActionTypes.SUBMIT_TOPUP_REQUEST,
  payload,
})

export const submitTopupRequestSuccess = (payload: ICredit) => ({
  type: CreditsTopupActionTypes.SUBMIT_TOPUP_REQUEST_SUCCESS,
  payload,
})

export const submitTopupRequestFailure = (): ICreditsTopupActionTypes => ({
  type: CreditsTopupActionTypes.SUBMIT_TOPUP_REQUEST_FAILURE,
})

export const updateTime = (time: number): ICreditsTopupActionTypes => ({
  type: CreditsTopupActionTypes.UPDATE_TIME,
  time,
})

export const processNotEnoughCredits = (payload: ICreditsRequired) => ({
  type: CreditsTopupActionTypes.NOT_ENOUGH_CREDITS,
  payload,
})
