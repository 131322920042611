import { IActionSuccess, IAction, TActionTypes } from '@Root/types'

import {
  IExportationForDownload,
  IGetExportationsForDownloadPayload,
  IRemoveFromExportationsForDownloadPayload,
} from '@EditorModules/Exportation/types'

export const ExportationActionTypes = {
  GET_EXPORTATIONS_FOR_DOWNLOAD: '@@exportation/GET_EXPORTATIONS_FOR_DOWNLOAD',
  GET_EXPORTATIONS_FOR_DOWNLOAD_SUCCESS: '@@exportation/GET_EXPORTATIONS_FOR_DOWNLOAD_SUCCESS',
  REMOVE_FROM_EXPORTATIONS_FOR_DOWNLOAD: '@@exportation/REMOVE_FROM_EXPORTATIONS_FOR_DOWNLOAD',
}

export interface IExportationState {
  readonly exportationsForDownload: IExportationForDownload[]
}

export type TExportationActionTypes =
  TActionTypes |
  IActionSuccess<IGetExportationsForDownloadPayload> |
  IAction<IRemoveFromExportationsForDownloadPayload>
