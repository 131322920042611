export const DeleteProjectVersionActionTypes = {
  DELETE_PROJECT_VERSION: '@@deleteProjectVersion/DELETE_PROJECT_VERSION',
  DELETE_PROJECT_VERSION_FAILURE: '@@deleteProjectVersion/DELETE_PROJECT_VERSION_FAILURE',
  DELETE_PROJECT_VERSION_SUCCESS: '@@deleteProjectVersion/DELETE_PROJECT_VERSION_SUCCESS',
}

export interface IDeleteProjectVersionState {
  readonly errors: string[]
  readonly loading: boolean
  readonly projectVersionIds: string[]
}

export interface IDeleteProjectVersionAction {
  readonly type: string
  readonly payload: IDeleteProjectVersionPayload
}

export interface IDeleteProjectVersionSuccessAction {
  readonly type: string
}

export interface IDeleteProjectVersionFailureAction {
  readonly type: string
}

export interface IDeleteProjectVersionPayload {
  readonly projectVersionIds: string[]
}

export type IDeleteProjectVersionActionTypes = IDeleteProjectVersionAction | IDeleteProjectVersionFailureAction | IDeleteProjectVersionSuccessAction
