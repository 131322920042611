import confetti from 'canvas-confetti'

const PARTICLE_COUNT = 200

export default function showConfetti() {
  runRealisticAnimation()
}

const runRealisticAnimation = () => {
  fire(0.2, { spread: 60, startVelocity: 55, gravity: 3.4 })
  fire(0.35, { spread: 100, scalar: 0.8, startVelocity: 55, gravity: 3.4 })
}

const fire = (particleRatio: number, options: confetti.Options) => {
  const particleCount = Math.floor(PARTICLE_COUNT * particleRatio)

  confetti({ origin: { x: 0.2, y: 0.3 }, particleCount, ...options, })
  confetti({ origin: { x: 0.5, y: 0.5 }, particleCount, ...options })
  confetti({ origin: { x: 0.8, y: 0.9 }, particleCount, ...options })
  confetti({ origin: { x: 0.8, y: 0.3 }, particleCount, ...options })
  confetti({ origin: { x: 0.2, y: 0.9 }, particleCount, ...options })
}
