import React from 'react'
import history from '@Root/historyInstance'

import './ConnectChannelButton.scss'

interface ConnectChannelButtonProps {
}

const ConnectChannelButton: React.FC<ConnectChannelButtonProps> = props => {
  const onClick = () => {
    history.push('/channels/new')
  }

  return (
    <div 
      className="ConnectChannelButton"
      onClick={onClick}
    >
      + Connect a Channel
    </div>
  )
}

export default ConnectChannelButton
