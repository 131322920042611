import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { IApplicationState } from '@Site/rootReducer'
import Popup from '@SiteComponents/Popup'
import range from 'lodash/range'
import Button from '@SiteComponents/Button'
import Star from '@Images/star_rating.svg'
import StarActive from '@Images/star_rating_active.svg'

import {
  rateSubtitle,
} from '@SiteContainers/ProjectVersionsList/actions'

import {
  IRateSubtitlePayload
} from '@SiteContainers/ProjectVersionsList/types'


import './RateSubtitlePopup.scss'

interface RateSubtitleProps {
  readonly subtitleId: string
  closePopup(): void
  rateSubtitle(payload: IRateSubtitlePayload): void
}

interface RateSubtitleState {
  readonly comment: string
  readonly numberOfStars: number
  readonly shouldShowCommentInput: boolean
}

const GOOD_RATING_THRESHOLD = 3

class RateSubtitle extends React.Component<RateSubtitleProps, RateSubtitleState> {
  constructor(props: RateSubtitleProps) {
    super(props)

    this.state = {
      comment: '',
      numberOfStars: 0,
      shouldShowCommentInput: false
    }
  }

  handleClickOnStar = () => {
    const { numberOfStars } = this.state
    const isGoodRating = numberOfStars > GOOD_RATING_THRESHOLD

    if (isGoodRating) {
      const { subtitleId } = this.props

      this.props.rateSubtitle({ subtitleId, numberOfStars, comment: '' })
      this.props.closePopup()
    } else {
      this.setState({ shouldShowCommentInput: true })
    }
  }

  handleCommentInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ comment: e.target.value })
  }

  handleSubmit = () => {
    const { numberOfStars, comment } = this.state
    const { subtitleId } = this.props

    this.props.rateSubtitle({ subtitleId, numberOfStars, comment })
    this.props.closePopup()
  }

  renderStars = () => {
    return (
      range(1, 6).map(rating => {
        const setRating = () => {
          this.setState({ numberOfStars: rating })
        }

        const resetRating = () => {
          this.setState({ numberOfStars: 0 })
        }

        return (
          rating > this.state.numberOfStars ? (
            <Star
              key={rating}
              className="RateSubtitlePopup__star"
              onMouseEnter={setRating}
              onMouseLeave={resetRating}
            />
          ) : (
            <StarActive
              key={rating}
              className="RateSubtitlePopup__star"
              onMouseEnter={setRating}
              onMouseLeave={resetRating}
              onClick={this.handleClickOnStar}
            />
          )
        )
      })
    )
  }

  renderRating = () => {
    return (
      <React.Fragment>
        <div className="RateSubtitlePopup__question">
          What do you think <br />
          of the quality of the subtitles?
        </div>
        {this.renderStars()}
        <div className="RateSubtitlePopup__vote">
          <span>Very bad</span>
          <span>Very good</span>
        </div>
      </React.Fragment>
    )
  }

  renderCommentInput = () => {
    return(
      <React.Fragment>
        <div className="RateSubtitlePopup__question">
          What problem did you encounter?
        </div>
        <textarea
          className="RateSubtitlePopup__comment-input"
          value={this.state.comment}
          onChange={this.handleCommentInput}
          placeholder={'Write your feedback to help us improve \nour service...'}
        />
        <Button
          color="primary-dark"
          caption="Send"
          onClick={this.handleSubmit}
        />
      </React.Fragment>
    )
  }

  render() {
    return (
      <Popup
        className="RateSubtitlePopup"
        closePopup={this.props.closePopup}
        isDark
      >
        {this.state.shouldShowCommentInput ? this.renderCommentInput() : this.renderRating()}
      </Popup>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { projectVersionsList } = state

  return {
    subtitleId: projectVersionsList.subtitleId.toString(),
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    rateSubtitle,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(RateSubtitle)
