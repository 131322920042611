import { Action } from 'redux'
import { put, takeEvery } from 'redux-saga/effects'
import * as Eff from 'redux-saga/effects' // tslint:disable-line:no-duplicate-imports
import axios from 'axios'
import toastr from 'toastr'

import Logger from '@Utils/Logger'
import history from '@Root/historyInstance'

import {
  fetchOrSubmitBillingAddressFailure,
  fetchOrSubmitBillingAddressSuccess
} from './actions'

import {
  CheckoutsBillingAddressActionTypes,
  IFetchBillingAddressSuccessResponse
} from './types'

import * as API from './api'

interface IAction extends Action {
  readonly payload: object
}

const call: any = Eff.call

function* submitBillingAddressSaga(action: IAction): Generator<any, any, any> {
  try {
    const response = yield call(API.submitBillingAddress, action.payload)
    yield put(fetchOrSubmitBillingAddressSuccess(response.data.data))
    const redirectPath = '/checkouts/payment_details'

    yield call(history.push, redirectPath)
  } catch (e) {
    if (axios.isAxiosError(e) && e.response) {
      const errorMessage = e.response.data.message === 'Invalid value for eu_vat.' ? 'Your VAT ID is invalid' : e.response.data.message
      toastr.error(errorMessage, 'Billing address could not be saved')
      if (window.Rollbar) { window.Rollbar.error(e.response.data.message) }
    } else {
      Logger.error(e)
    }

    yield put(fetchOrSubmitBillingAddressFailure())
  }
}

function* fetchBillingAddressSaga(action: Action): Generator<any, any, any> {
  try {
    const response: IFetchBillingAddressSuccessResponse = yield call(API.fetchBillingAddress)
    yield put(fetchOrSubmitBillingAddressSuccess(response.data.data))
  } catch (e) {
    Logger.error(e)
    yield put(fetchOrSubmitBillingAddressFailure())
  }
}

function* checkoutsBillingAddressSagas() {
  yield takeEvery(CheckoutsBillingAddressActionTypes.SUBMIT_BILLING_ADDRESS, submitBillingAddressSaga)
  yield takeEvery(CheckoutsBillingAddressActionTypes.FETCH_BILLING_ADDRESS, fetchBillingAddressSaga)
}

export default checkoutsBillingAddressSagas
