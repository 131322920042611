import React, { useState }  from 'react'
import find from 'lodash/find'
import map from 'lodash/map'
import classnames from 'classnames'
import useClickOutsideListener from '@Utils/UseClickOutsideListener'
import Delete from '@Images/delete.svg'

import {
  ISsoUser,
  ISsoSubscription
} from '@SiteModules/Sso/types'

import './SsoMembers.scss'

interface SsoMembersProps {
  readonly member: ISsoUser
  readonly ssoSubscriptions: ISsoSubscription[]
  deleteMember(userId: number): void
  changeRole(userId: number, isAdmin: boolean): void
  changeSubscription(userId: number, subscriptionId: number): void
}

const ADMIN_ROLE = 'Admin'
const MEMBER_ROLE = 'Member'
const ROLES = [MEMBER_ROLE, ADMIN_ROLE]

const SsoMembers: React.FC<SsoMembersProps> = props => {
  const [roleDropdownOpen, setRoleDropdownOpen] = useState(false)
  const [subscriptionDropdownOpen, setSubscriptionDropdownOpen] = useState(false)

  const {
    member,
    deleteMember,
    changeRole,
    changeSubscription,
    ssoSubscriptions,
  } = props

  const { firstName, lastName, email, isAdmin, subscriptionId, userId } = member.attributes

  const subscription = find(ssoSubscriptions, ssoSubscription => ssoSubscription.attributes.subscriptionId === subscriptionId)
  const subscriptionLabel = subscription ? subscription.attributes.label : 'None'
  const firstNameInitial = firstName && firstName.length > 0 ? firstName[0] : ' '
  const lastNameInitial = lastName && lastName.length > 0 ? lastName[0] : ' '

  const toggleRoleDropdown = () => {
    setRoleDropdownOpen(!roleDropdownOpen)
  }

  const toggleSubscriptionDropdown = () => {
    setSubscriptionDropdownOpen(!subscriptionDropdownOpen)
  }

  useClickOutsideListener(`SsoMember__role-field-${userId}`, toggleRoleDropdown, roleDropdownOpen)
  useClickOutsideListener(`SsoMember__subscription-field-${userId}`, toggleSubscriptionDropdown, subscriptionDropdownOpen)

  const handleDeleteMember = () => {
    deleteMember(userId)
  }

  const roleList = map(ROLES, (role, index) => {
    const isCurrentRole = (isAdmin && role === ADMIN_ROLE) || (!isAdmin && role === MEMBER_ROLE)

    const handleChangeRole = () => {
      if (isCurrentRole) { return }

      changeRole(userId, role === ADMIN_ROLE)
      toggleRoleDropdown()
    }

    return (
      <li
        key={index}
        className={classnames(
          'SsoMember__dropdown-item',
          { 'SsoMember__dropdown-item-current': isCurrentRole }
        )}
        onClick={handleChangeRole}
      >
        {role}
      </li>
    )
  })

  const renderRoleDropdown = () => {
    return (
      <ul className="SsoMember__dropdown SsoMember__role-dropdown">
        {roleList}
      </ul>
    )
  }

  const subscriptionList = map(ssoSubscriptions, (ssoSubscription, index) => {
    const isCurrentSubscription =
      ssoSubscription.attributes.subscriptionId === subscriptionId ||
      (subscriptionLabel === 'None' && ssoSubscription.attributes.label === 'None')

    const handleChangeSubscription = () => {
      if (isCurrentSubscription) { return }

      changeSubscription(userId, ssoSubscription.attributes.subscriptionId)
      toggleSubscriptionDropdown()
    }

    return (
      <li
        key={index}
        className={classnames(
          'SsoMember__dropdown-item',
          { 'SsoMember__dropdown-item-current': isCurrentSubscription }
        )}
        onClick={handleChangeSubscription}
      >
        <span className="SsoMember__subscription-dropdown-item-text">
          {ssoSubscription.attributes.label}
        </span>
      </li>
    )
  })

  const renderSubscriptionDropdown = () => {
    return (
      <ul className="SsoMember__dropdown SsoMember__subscription-dropdown">
        {subscriptionList}
      </ul>
    )
  }

  return (
    <tr className="SsoMember">
      <td className="SsoMember__initials-field">
        <div className="SsoMember__initials">
          {firstNameInitial}{lastNameInitial}
        </div>
      </td>
      <td className="SsoMember__name-email-field">
        <div className="SsoMember__name">
          {firstName} {lastName}
        </div>
        <div className="SsoMember__email">
          {email}
        </div>
      </td>
      <td className={`SsoMember__role-field SsoMember__role-field-${userId}`}>
        <div
          className="SsoMember__role"
          onClick={toggleRoleDropdown}
        >
          {isAdmin ? 'Admin' : 'Member'}
        </div>
        {roleDropdownOpen && renderRoleDropdown()}
      </td>
      <td className={`SsoMember__subscription-field SsoMember__subscription-field-${userId}`}>
        <div
          className="SsoMember__subscription"
          onClick={toggleSubscriptionDropdown}
        >
          {subscriptionLabel}
        </div>
        {subscriptionDropdownOpen && renderSubscriptionDropdown()}
      </td>
      <td className="SsoMember__delete-field">
        <Delete onClick={handleDeleteMember}/>
      </td>
    </tr>
  )
}

export default SsoMembers
