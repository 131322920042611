import { put, takeEvery } from 'redux-saga/effects'
import * as Eff from 'redux-saga/effects' // tslint:disable-line:no-duplicate-imports

import Logger from '@Utils/Logger'

import {
  ISuccessResponse,
  IAction,
} from '@Root/types'

import {
  getExportationsForDownloadSuccess,
} from './actions'

import {
  ExportationActionTypes,
} from './types'

import {
  IGetExportationsForDownloadSuccessPayload,
  IGetExportationsForDownloadPayload
} from '@EditorModules/Exportation/types'

import * as API from './api'

const call: any = Eff.call

function* getExportationsForDownloadSaga(action: IAction<IGetExportationsForDownloadPayload>) {
  try {
    const response: ISuccessResponse<IGetExportationsForDownloadSuccessPayload> = yield call(API.getExportationsForDownload, action.payload)
    const payloadResponse = response.data
    yield put(getExportationsForDownloadSuccess(payloadResponse))
  } catch(error) {
    Logger.error(error)
  }
}

function* exportationSagas() {
  yield takeEvery(ExportationActionTypes.GET_EXPORTATIONS_FOR_DOWNLOAD, getExportationsForDownloadSaga)
}

export default exportationSagas
