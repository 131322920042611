export default function getParamValueFromUrl(url: string, key: string): string | null {
  const urlParts = url.split('?')
  const searchParams = urlParts[1]

  if (!searchParams) { return null }

  let params: string[] = []

  searchParams.split('&').forEach((param) => {
    params = params.concat(param.split('='))
  })

  const index = params.indexOf(key)

  return index !== -1 ? params[index + 1] : null
}
