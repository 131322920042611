import { put, takeEvery } from 'redux-saga/effects'
import * as Eff from 'redux-saga/effects' // tslint:disable-line:no-duplicate-imports
import toastr from 'toastr'

import Logger from '@Utils/Logger'
import { IAction } from '@Root/types'

import {
  closePopup
} from '@SiteContainers/ProjectVersionsList/actions'

import {
  importSrtSuccess,
  importSrtFailure
} from './actions'

import {
  IImportSrtPayload,
  ImportSrtActionTypes,
} from './types'

import * as API from './api'

const call: any = Eff.call

const DELAY_UNTIL_SUBTITLE_STATUS_IS_UPDATED_IN_DATABASE = 2000 // msec

const cleanLocalStorage = (subtitleId: number) => {
  // we're saving last user's changes to localStorage
  // we should remove them to load exactly the imported srt
  const prefix = `subtitle-${subtitleId}`

  window.localStorage.removeItem(`${prefix}-cues`)
  window.localStorage.removeItem(`${prefix}-synchronized`)
  window.localStorage.removeItem(`${prefix}-updated-at`)
}

function* importSrtSaga(action: IAction<IImportSrtPayload>) {
  try {
    const { payload } = action
    yield call(API.importSrt, { payload })
    yield put(importSrtSuccess())
    if (payload) {
      cleanLocalStorage(payload.subtitleId)
    }
    yield put(closePopup())
    toastr.success('', 'SRT file imported')

    setTimeout(() => { window.location.href = '/' }, DELAY_UNTIL_SUBTITLE_STATUS_IS_UPDATED_IN_DATABASE)
  } catch (e) {
    Logger.error(e, 'SRT file could not be imported')
    yield put(importSrtFailure())
  }
}

function* importSrtSagas() {
  // TODO: replace takeEvery with takeLatest
  yield takeEvery(ImportSrtActionTypes.IMPORT_SRT, importSrtSaga)
}

export default importSrtSagas
