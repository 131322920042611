import React from 'react'
import { Link } from 'react-router-dom'

import HomeIcon from '@Images/home.svg'
import LogoutIcon from '@Images/logout.svg'
import Vocabulary from '@Images/vocabulary.svg'
import ChannelsIcon from '@Images/channels.svg'
import SubscriptionIcon from '@Images/subscription.svg'
import Help from '@Images/help.svg'
import Billing from '@Images/billing.svg'
import HumanFigure from '@Images/human_figure.svg'

import './ProfileDropdown.scss'

const ENTERPRISE_OR_CUSTOM_SUBSCRIPTION_PLAN_NAME_PARTS = ['enterprise', 'custom']

interface ProfileDropdownProps {
  readonly hasExternalChannels: boolean
  readonly subscriptionIsCurrent: boolean
  readonly subscriptionPlan: string
  readonly usingPersonalSubscription: boolean
  readonly managedSsoOrganizationId: number | null
  createCustomerPortalSession(): void
  logout(payload: string): {}
}

const ProfileDropdown: React.FC<ProfileDropdownProps> = props => {
  const {
    hasExternalChannels,
    subscriptionIsCurrent,
    subscriptionPlan,
    usingPersonalSubscription,
    managedSsoOrganizationId,
  } = props

  const logout = () => {
    props.logout('/users/sign_in')
  }

  const openHelp = () => {
    window.open('https://support.checksub.com', '_blank')
  }

  const pathForChannels = hasExternalChannels ? '/channels/manage' : '/channels/new'
  const hasEnterpriseOrCustomSubscription = ENTERPRISE_OR_CUSTOM_SUBSCRIPTION_PLAN_NAME_PARTS.includes(subscriptionPlan.split('_')[0])
  const shouldRenderSubscriptionAndBilling = usingPersonalSubscription && (!hasEnterpriseOrCustomSubscription || !subscriptionIsCurrent)
  const shouldRenderChannels = subscriptionIsCurrent

  return (
    <div className="ProfileDropdown">
      <div className="ProfileDropdown__list-wrapper">
        <ul className="ProfileDropdown__list">
          <li className="ProfileDropdown__list__item">
            <Link to="/">
              <HomeIcon className="ProfileDropdown__home-icon ProfileDropdown__icon"/>
              Dashboard
            </Link>
          </li>
          <li className="ProfileDropdown__list__item">
            <Link to="/vocabularies">
              <Vocabulary className="ProfileDropdown__vocabulary-icon ProfileDropdown__icon"/>
              Custom vocabularies
            </Link>
          </li>
          {shouldRenderChannels && (
            <li className="ProfileDropdown__list__item">
              <Link to={pathForChannels}>
                <ChannelsIcon className="ProfileDropdown__channels-icon ProfileDropdown__icon"/>
                Channels
              </Link>
            </li>
          )}
          {shouldRenderSubscriptionAndBilling && (
            <React.Fragment>
              <li className="ProfileDropdown__list__item">
                <Link to="/subscriptions">
                  <SubscriptionIcon className="ProfileDropdown__subscription-icon ProfileDropdown__icon"/>
                  Subscription
                </Link>
              </li>
              <li className="ProfileDropdown__list__item">
                <div className="ProfileDropdown__billing" onClick={props.createCustomerPortalSession}>
                  <Billing className="ProfileDropdown__billing-icon ProfileDropdown__icon"/>
                  Billing
                </div>
              </li>
            </React.Fragment>
          )}
          {managedSsoOrganizationId && (
            <li className="ProfileDropdown__list__item">
              <Link to="/sso/management">
                <HumanFigure className="ProfileDropdown__sso-icon ProfileDropdown__icon"/>
                Workspace settings
              </Link>
            </li>
          )}
          <li className="ProfileDropdown__list__item">
            <div className="ProfileDropdown__help" onClick={openHelp}>
              <Help className="ProfileDropdown__help-icon ProfileDropdown__icon"/>
              Help center
            </div>
          </li>
        </ul>
        <div className="ProfileDropdown__logout" onClick={logout}>
          <LogoutIcon className="ProfileDropdown__logout-icon ProfileDropdown__icon"/>
          Logout
        </div>
      </div>
    </div>
  )
}

export default ProfileDropdown
