import {
  TSignUpActionTypes,
  SignUpFormActionTypes,
  IUserSignUpState
} from './types'

export const initialState: IUserSignUpState = {
  loading: false
}

export const userSignUpReducer = (
  state: IUserSignUpState = initialState,
  action: TSignUpActionTypes
): IUserSignUpState => {
  switch (action.type) {
    case SignUpFormActionTypes.SIGN_UP: return signUp(state)
    case SignUpFormActionTypes.SIGN_UP_SUCCESS: return signUpSuccess(state)
    case SignUpFormActionTypes.SIGN_UP_FAILURE: return signUpFailure(state)
    default: return state
  }
}

const signUp = (state: IUserSignUpState): IUserSignUpState => {
  return { ...state, loading: true }
}

const signUpSuccess = (state: IUserSignUpState): IUserSignUpState => {
  return { ...state, loading: false }
}

const signUpFailure = (state: IUserSignUpState): IUserSignUpState => {
  return { ...state, loading: false }
}
