import { TActionTypes } from '@Root/types'

export const DashboardSidebarActionTypes = {
  CLOSE_POPUP: '@@dashboardSidebar/CLOSE_POPUP',
  OPEN_DELETE_LABEL_POPUP: '@@dashboardSidebar/OPEN_DELETE_LABEL_POPUP',
  OPEN_RENAME_LABEL_POPUP: '@@dashboardSidebar/OPEN_RENAME_LABEL_POPUP',
  OPEN_CREATE_LABEL_POPUP: '@@dashboardSidebar/OPEN_CREATE_LABEL_POPUP',
}

export interface IDashboardSidebarState {
  readonly openCreateLabelPopup: boolean
  readonly openDeleteLabelPopup: boolean
  readonly openRenameLabelPopup: boolean
}

export type IDashboardSidebarActions  = TActionTypes
