import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { IApplicationState } from '@Site/rootReducer'
import Popup from '@SiteComponents/Popup'
import ExternalTokenForm from '@SiteComponents/ExternalTokenForm'

import toastr from 'toastr'
import 'toastr/build/toastr.css'

import {
  createOrUpdateExternalToken
} from './actions'

import {
  IExternalToken,
  ICreateOrUpdateExternalTokenPayload
} from './types'

import './ExternalTokenPopup.scss'

interface ExternalTokenProps {
  readonly loading: boolean
  readonly subtitleId: number
  readonly externalTokenData: IExternalToken
  closePopup(): void
  createOrUpdateExternalToken(payload: ICreateOrUpdateExternalTokenPayload): void
}

interface ExternalTokenState {}

class ExternalToken extends React.Component<ExternalTokenProps, ExternalTokenState> {
  textAreaRef: React.RefObject<HTMLTextAreaElement>

  constructor(props: ExternalTokenProps) {
    super(props)
    this.textAreaRef = React.createRef()
  }

  setValidityOfExternalToken = (validityInDays: number) => {
    this.props.createOrUpdateExternalToken({ subtitleId: this.props.subtitleId, validityInDays })
  }

  copyToClipboard = (e: any) => {
    if (this.textAreaRef.current) {
      this.textAreaRef.current.select()
    }

    document.execCommand('copy')
    e.target.focus()

    toastr.success('Your link for sharing is copied to the clipboard!')
  }

  render() {
    const {
      loading,
      closePopup,
      externalTokenData,
    } = this.props

    return (
      <Popup
        className="ExternalTokenPopup"
        closePopup={closePopup}
        isDark
      >
        <ExternalTokenForm
          loading={loading}
          externalTokenData={externalTokenData}
          textAreaRef={this.textAreaRef}
          copyToClipboard={this.copyToClipboard}
          setValidityOfExternalToken={this.setValidityOfExternalToken}
          renderedFromDashboard
        />
      </Popup>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { externalToken, projectVersionsList } = state

  return {
    loading: externalToken.loading,
    subtitleId: projectVersionsList.subtitleId,
    externalTokenData: externalToken.externalTokenData,
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    createOrUpdateExternalToken,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ExternalToken)
