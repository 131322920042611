import React from 'react'

import './Aside.scss'

// TODO: camelize it
interface AsideProps {
  readonly image: string
  readonly text_part1?: string
  readonly text_highlight?: string
  readonly text_part2?: string
  readonly text_small_part1?: string
  readonly text_small_highlight?: string
  readonly text_small_part2?: string
}

const Aside: React.FC<AsideProps> = props => {
  return (
    <div className="Aside">
      <div className="Aside__image">
        <img src={props.image} />
      </div>
      <div className="Aside__text">
        {props.text_part1}<span>{props.text_highlight}</span>{props.text_part2}
      </div>
      <div className="Aside__text Aside__text_small">
        {props.text_small_part1}<span>{props.text_small_highlight}</span>{props.text_small_part2}
      </div>
    </div>
  )
}

export default Aside
