export const DashboardMenuActionTypes = {
  CHANGE_TAB: '@@dashboardMenu/CHANGE_TAB'
}

export interface IChangeTabPayload {
  readonly newTab: string
}

export interface IDashboardMenuState {
  readonly currentTab: string
}

export interface IChangeTabAction {
  readonly type: string
  readonly payload: IChangeTabPayload
}

export type IDashboardMenuActionTypes = IChangeTabAction
