import axios from 'axios'

import {
  IRemoveExternalChannelPayload
} from './types'

export function getExternalVideos() {
  return axios.get('/api/v1/external_channels/get_all_videos')
}

export function getExternalChannels() {
  return axios.get('/api/v1/external_channels')
}

export function removeExternalChannel(data: IRemoveExternalChannelPayload) {
  return axios.delete(`/api/v1/external_channels/${data.externalChannelId}/delete`)
}
