import {
  DashboardSidebarActionTypes,
} from './types'

import {
  IDeleteLabelPayload,
} from '@SiteContainers/DeleteLabelPopup/types'

import {
  IRenameLabelPopupOpenPayload,
} from '@SiteContainers/RenameLabelPopup/types'

export const closePopupDashboardSidebar = () => ({
  type: DashboardSidebarActionTypes.CLOSE_POPUP
})

export const openCreateLabelPopup = () => ({
  type: DashboardSidebarActionTypes.OPEN_CREATE_LABEL_POPUP
})

export const openDeleteLabelPopup = (payload: IDeleteLabelPayload) => ({
  type: DashboardSidebarActionTypes.OPEN_DELETE_LABEL_POPUP,
  payload,
})

export const openRenameLabelPopup = (payload: IRenameLabelPopupOpenPayload) => ({
  type: DashboardSidebarActionTypes.OPEN_RENAME_LABEL_POPUP,
  payload
})
