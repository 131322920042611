import { TActionTypes, IAction } from '@Root/types'

export const ProcessingActionTypes = {
  FETCH_PROCESSING_INFO: '@@processing/FETCH_PROCESSING_INFO',
  FETCH_PROCESSING_INFO_SUCCESS: '@@processing/FETCH_PROCESSING_INFO_SUCCESS',
  FETCH_PROCESSING_INFO_SUCCESS_FOR_DOWNLOADING: '@@processing/FETCH_PROCESSING_INFO_SUCCESS_FOR_DOWNLOADING',
  FETCH_CURRENT_PROCESSING_STEP: '@@processing/FETCH_CURRENT_PROCESSING_STEP',
  FETCH_CURRENT_PROCESSING_STEP_SUCCESS: '@@processing/FETCH_CURRENT_PROCESSING_STEP_SUCCESS',
}

export interface IProcessingState {
  readonly loading: boolean
  readonly attributes: IProcessingAttributes
}

export interface IProcessingAttributes {
  readonly allProcessingSteps: string[]
  readonly currentProcessingStep: string
  readonly projectVersionDuration: number
  readonly projectTitle: string
  readonly projectId: string
  readonly subtitleId: number
  readonly hasVoice: boolean
}

export interface IFetchProcessingInfoPayload {
  readonly projectVersionId: string
}

export interface IFetchProcessingInfoSuccessResponseBody {
  readonly data: {
    readonly attributes: IProcessingAttributes
  }
}

export interface IFetchCurrentProcessingStepPayload {
  readonly projectVersionId: string
}

export interface IFetchCurrentProcessingStepSuccessResponseBody {
  readonly data: {
    readonly currentProcessingStep: string
  }
}

export type TProcessingActionTypes = TActionTypes |
    IAction<IFetchProcessingInfoSuccessResponseBody> |
    IAction<IFetchCurrentProcessingStepSuccessResponseBody>
