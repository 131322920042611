import axios, { AxiosRequestConfig } from 'axios'
import convertToSnakeCase from '@Utils/ConvertToSnakeCase'

import {
  ICancelSubscriptionPayload,
  IStartCheckoutSessionPayload,
  IUpdateSubscriptionPayload,
} from './types'

export function cancelSubscription(data: ICancelSubscriptionPayload) {
  const config: AxiosRequestConfig = {
    headers: {
      'X-CSRF-Token': window.Checksub.token
    }
  }

  return axios.delete(`/api/v1/subscriptions/cancel`, { ...config, data: convertToSnakeCase(data) })
}

export function startCheckoutSession(payload: IStartCheckoutSessionPayload) {
  const config: AxiosRequestConfig = {
    headers: {
      'X-CSRF-Token': window.Checksub.token
    }
  }

  return axios.post('/api/v1/subscriptions/start_checkout_session', convertToSnakeCase(payload), config)
}

export function updateSubscription(payload: IUpdateSubscriptionPayload) {
  const config: AxiosRequestConfig = {
    headers: {
      'X-CSRF-Token': window.Checksub.token
    }
  }

  return axios.put(`/api/v1/subscriptions/${payload.subscriptionId}`, convertToSnakeCase(payload), config)
}
