import produce, { Draft }  from 'immer'

import {
  IAction,
  TActionTypes
} from '@Root/types'

import {
  IElementState,
  TElementAction,
  ElementActionTypes,
  IInitializeUploadPayload,
  ICreateElementSuccessResponseBody,
} from './types'


export const initialState: IElementState = {
  uploading: false,
  numberOfFilesToUpload: 0,
  alreadyUploaded: 0,
  uploadedProjectVersionIds: []
}

type TAction = TActionTypes | TElementAction

export const elementsReducer = (
  state: IElementState = initialState,
  action: TAction,
): IElementState => {
  return produce(state, draft => {
    switch (action.type) {
      case ElementActionTypes.CREATE_ELEMENT:
        createElement(draft)
        break

      case ElementActionTypes.CREATE_ELEMENT_SUCCESS:
        createElementSuccess(draft, action)
        break

      case ElementActionTypes.CREATE_ELEMENT_FAILURE:
        createElementFailure(draft)
        break

      case ElementActionTypes.RESET_UPLOAD:
        resetUpload(draft)
        break

      case ElementActionTypes.INITIALIZE_UPLOAD:
        initializeUpload(draft, action)
        break

      case ElementActionTypes.SUBMIT_URLS:
        submitUrls(draft)
        break

      case ElementActionTypes.SUBMIT_URLS_SUCCESS:
        submitUrlsSuccess(draft)
        break

      case ElementActionTypes.SUBMIT_URLS_FAILURE:
        submitUrlsFailure(draft)
        break

      default:
        break
    }
  })
}

const createElement = (draft: Draft<IElementState>) => {
  draft.uploading = true
}

function isCreateElementSuccessAction(arg: any): arg is IAction<ICreateElementSuccessResponseBody> {
  return arg.type === ElementActionTypes.CREATE_ELEMENT_SUCCESS
}

const createElementSuccess = (draft: Draft<IElementState>, action: TAction) => {
  if (!isCreateElementSuccessAction(action)) { return }

  draft.uploading = false
  draft.alreadyUploaded = draft.alreadyUploaded + 1
  draft.uploadedProjectVersionIds.push(action.payload.data[0].id)
}

const createElementFailure = (draft: Draft<IElementState>) => {
  draft.uploading = false
}

const resetUpload = (draft: Draft<IElementState>) => {
  draft.uploading = false
  draft.numberOfFilesToUpload = 0
  draft.alreadyUploaded = 0
  draft.uploadedProjectVersionIds = []
}

function isInitializeUploadAction(arg: any): arg is IAction<IInitializeUploadPayload> {
  return arg.type === ElementActionTypes.INITIALIZE_UPLOAD
}

const initializeUpload = (draft: Draft<IElementState>, action: TAction) => {
  if (!isInitializeUploadAction(action)) { return }

  draft.numberOfFilesToUpload = action.payload!.numberOfFilesToUpload
}

const submitUrls = (draft: Draft<IElementState>) => {
  draft.uploading = true
}

const submitUrlsSuccess = (draft: Draft<IElementState>) => {
  draft.uploading = false
}

const submitUrlsFailure = (draft: Draft<IElementState>) => {
  draft.uploading = false
}
