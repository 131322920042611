import { IAction } from '@Root/types'
import { IProjectVersion } from '@SiteContainers/ProjectVersionsList/types'

export const ElementActionTypes = {
  CREATE_ELEMENT: '@@element/CREATE_ELEMENT',
  CREATE_ELEMENT_SUCCESS: '@@element/CREATE_ELEMENT_SUCCESS',
  CREATE_ELEMENT_FAILURE: '@@element/CREATE_ELEMENT_FAILURE',
  RESET_UPLOAD: '@@element/RESET_UPLOAD',
  INITIALIZE_UPLOAD: '@@element/INITIALIZE_UPLOAD',
  SUBMIT_URLS: '@@element/SUBMIT_URLS',
  SUBMIT_URLS_SUCCESS: '@@element/SUBMIT_URLS_SUCCESS',
  SUBMIT_URLS_FAILURE: '@@element/SUBMIT_URLS_FAILURE',
}

export interface IElementState {
  readonly uploading: boolean
  readonly numberOfFilesToUpload: number
  readonly alreadyUploaded: number
  readonly uploadedProjectVersionIds: string[]
}

export interface IInitializeUploadPayload {
  readonly numberOfFilesToUpload: number
}

export interface ICreateElementSuccessResponseBody {
  readonly data: IProjectVersion[]
}

export interface ICreateElementSuccessAction {
  readonly type: string
  readonly payload: ICreateElementSuccessResponseBody
}

export interface ISubmitUrlsPayload {
  readonly urls: string[]
}

export interface ISubmitUrlsSuccessResponseBody {
  readonly data: IProjectVersion[]
}

export interface ISubmitUrlsSuccessAction {
  readonly type: string
  readonly payload: ISubmitUrlsSuccessResponseBody
}

export type TElementAction =
  IAction<IInitializeUploadPayload> | ICreateElementSuccessAction | ISubmitUrlsSuccessAction
