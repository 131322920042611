import { Action } from 'redux'
import { put, takeEvery } from 'redux-saga/effects'
import * as Eff from 'redux-saga/effects' // tslint:disable-line:no-duplicate-imports
import toastr from 'toastr'

// TODO: !!
import 'toastr/build/toastr.css'

import Logger from '@Utils/Logger'

import { ISuccessResponse, IAction } from '@Root/types'

import {
  fetchLabelsFailure,
  fetchLabelsSuccess,
  createLabelFailure,
  createLabelSuccess,
  createProjectVersionLabelsFailure,
  createProjectVersionLabelsSuccess,
  deleteProjectVersionLabelsFailure,
  deleteProjectVersionLabelsSuccess
} from './actions'

import {
  LabelActionTypes,
  IFetchLabelsSuccessResponseBody,
  ICreateLabelPayload,
  IProjectVersionLabelsPayload,
} from './types'

import {
  closePopupDashboardSidebar,
} from '@SiteContainers/DashboardSidebar/actions'

import * as API from './api'

const call: any = Eff.call

function* fetchLabelsSaga(action: Action) {
  try {
    const response: ISuccessResponse<IFetchLabelsSuccessResponseBody> = yield call(API.fetchLabels)
    const payloadResponse = response.data
    yield put(fetchLabelsSuccess(payloadResponse))
  } catch (e) {
    Logger.error(e, 'Labels could not be loaded')
    yield put(fetchLabelsFailure())
  }
}

function* createLabelSaga(action: IAction<ICreateLabelPayload>) {
  try {
    const { payload } = action
    yield call(API.createLabel, payload)
    yield put(createLabelSuccess())
    yield put(closePopupDashboardSidebar())
    toastr.success('', 'Label created')
  } catch (e) {
    Logger.error(e, 'Label could not be created')
    yield put(createLabelFailure())
  }
}

function* createProjectVersionLabelsSaga(action: IAction<IProjectVersionLabelsPayload>) {
  try {
    const { payload } = action
    yield call(API.createProjectVersionLabels, payload)
    yield put(createProjectVersionLabelsSuccess())
  } catch (e) {
    Logger.error(e, 'Labels could not be updated')
    yield put(createProjectVersionLabelsFailure())
  }
}

function* deleteProjectVersionLabelsSaga(action: IAction<IProjectVersionLabelsPayload>) {
  try {
    const { payload } = action
    yield call(API.deleteProjectVersionLabels, payload)
    yield put(deleteProjectVersionLabelsSuccess())
  } catch (e) {
    Logger.error(e, 'Labels could not be updated')
    yield put(deleteProjectVersionLabelsFailure())
  }
}

function* labelSagas() {
  yield takeEvery(LabelActionTypes.FETCH_LABELS, fetchLabelsSaga)
  yield takeEvery(LabelActionTypes.CREATE_LABEL, createLabelSaga)
  yield takeEvery(LabelActionTypes.CREATE_LABEL_SUCCESS, fetchLabelsSaga)
  yield takeEvery(LabelActionTypes.DELETE_PROJECT_VERSION_LABELS, deleteProjectVersionLabelsSaga)
  yield takeEvery(LabelActionTypes.CREATE_PROJECT_VERSION_LABELS, createProjectVersionLabelsSaga)
  yield takeEvery(LabelActionTypes.DELETE_PROJECT_VERSION_LABELS_SUCCESS, fetchLabelsSaga)
  yield takeEvery(LabelActionTypes.CREATE_PROJECT_VERSION_LABELS_SUCCESS, fetchLabelsSaga)
}

export default labelSagas
