import axios, { AxiosRequestConfig } from 'axios'

import {
  IPayload,
} from '@Root/types'

import {
  IImportSrtPayload
} from './types'

export function importSrt(data: IPayload<IImportSrtPayload>) {
  const config: AxiosRequestConfig = {
    headers: {
      'X-CSRF-Token': window.Checksub.token,
      'Content-Type': 'multipart/form-data'
    },
    responseType: 'json'
  }

  const { payload } = data
  const { projectVersionId, subtitleId, fileSrt, enableSpeakerDiarization } = payload

  const formData = new FormData()

  if (fileSrt) {
    formData.append('file_srt', fileSrt)
    formData.append('enable_speaker_diarization', enableSpeakerDiarization.toString())
  }

  return axios.post(`/api/v1/project_versions/${projectVersionId}/subtitles/${subtitleId}/import_srt`, formData, config)
}
