import zipAndSaveFiles from '@Utils/ZipAndSaveFiles'

import {
  IExportationForDownload,
} from '@EditorModules/Exportation/types'

export default function autoDownloadExportation(exportationReadyForDownload: IExportationForDownload): void {
  if (exportationReadyForDownload.urls.length === 1) {
    const a = document.createElement('a')
    a.href = exportationReadyForDownload.urls[0]
    a.download = 'true'
    a.click()
  } else {
    zipAndSaveFiles(exportationReadyForDownload.urls)
  }
}
