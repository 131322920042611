import map from 'lodash/map'
import find from 'lodash/find'
import assign from 'lodash/assign'
import concat from 'lodash/concat'
import differenceBy from 'lodash/differenceBy'

export default function smartMerge(hash1: ArrayLike<any>, hash2: ArrayLike<any>) {
  let isWithUuid = false

  const updatedHash1 = map(hash1, (obj) => {
    if (obj.uuid) {
      isWithUuid = true
      return assign(obj, find(hash2, { uuid: obj.uuid }))
    } else {
      return assign(obj, find(hash2, { id: obj.id }))
    }
  })

  const result = isWithUuid ? differenceBy(hash2, hash1, 'uuid') : differenceBy(hash2, hash1, 'id')

  return concat(updatedHash1, result)
}
