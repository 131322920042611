import React from 'react'
import ButtonNew from '@SiteComponents/ButtonNew'
import ReverseCheckmark from '@Images/reverse_checkmark.svg'

import './Offer.scss'

interface EnterpriseOfferProps {
  readonly planName: string
  readonly isMonthlyPeriod: boolean
  readonly isPriceInUsd: boolean
  readonly hasLoadedSuccessfully: boolean
  readonly description: JSX.Element
  readonly features: (JSX.Element | string)[]
  readonly currentPlan: string
  readonly currentPlanVersion: string
  readonly subscriptionIsCurrent: boolean
  scheduleDemo(): void
}

const EnterpriseOffer: React.FC<EnterpriseOfferProps> = props => {
  const {
    planName,
    isPriceInUsd,
    currentPlan,
    subscriptionIsCurrent,
    isMonthlyPeriod,
    currentPlanVersion,
    hasLoadedSuccessfully,
    description,
    features,
  } = props

  const isCurrentPlan = subscriptionIsCurrent && `${planName}_${isMonthlyPeriod ? 'monthly' : 'yearly'}_${currentPlanVersion}` === currentPlan

  const currency = isPriceInUsd ? '$' : '€'

  return (
    <div className="Offer EnterpriseOffer">
      <div className="Offer__name">
        {isCurrentPlan && <span className="Offer__name-active">ACTIVE</span>}
        <span>{planName.toUpperCase()}</span>
      </div>
      <div className="Offer__description">{description}</div>
      <hr />
      <div className="Offer__price">Custom</div>
      <div className="Offer__price-description">{`From 199${currency}/month (billed annually)`}</div>
      <hr />
      <div className="Offer__features">
        {features.map((feature, index) => {
          return (
            <div key={index} className="Offer__feature">
              <ReverseCheckmark />
              {feature}
            </div>
          )
        })}
      </div>
      <div className="Offer__button-container">
        <ButtonNew
          color={'tertiary-small'}
          caption={'Book a demo'}
          onClick={props.scheduleDemo}
          showSpinner={!hasLoadedSuccessfully}
          disabled={!hasLoadedSuccessfully}
        />
        <div className="Offer__button-subtext">No credit card required</div>
      </div>
    </div>
  )
}

export default EnterpriseOffer
