import React, { useEffect, useState } from 'react'
import classnames from 'classnames'

import VoiceSettings from './VoiceSettings'

import {
  IVoiceAttributes,
} from '@EditorContainers/Settings/types'

import Arrow from '@Images/arrow_pv_settings.svg'

const DELAY_TO_SHOW_OVERFLOW = 200 // ms, The same time as for the opening/closing animations in ProjectVersionSettingsFormTts__options-body

interface DubbingOptionsProps {
  readonly voices: IVoiceAttributes[]
  readonly voiceName: string
  readonly gender: string
  readonly disabled: boolean
  readonly languageCode: string
  setVoiceName(newVoiceName: string): void
  setGender(newGender: string): void
}

const DubbingOptions: React.FC<DubbingOptionsProps> = props => {
  const {
    disabled,
    voices,
    voiceName,
    gender,
    languageCode,
  } = props

  const [bodyOpen, setBodyOpen] = useState(false)
  const [bodyOpenDelayed, setBodyOpenDelayed] = useState(false)

  useEffect(() => {
    if (!disabled) {
      showBody()
    }
  }, [])

  useEffect(() => {
    if (disabled && bodyOpen) {
      toggleBody()
    } else if (!disabled) {
      showBody()
    }
  }, [disabled])

  const toggleBody = () => {
    // https://stackoverflow.com/questions/13938460/css-transition-auto-height-not-working
    const bodyDiv = document.getElementById('ProjectVersionSettingsFormTts__options-body-voice')
    if (!bodyDiv) { return }

    bodyDiv.clientHeight ? closeBody(bodyDiv) : openBody(bodyDiv)
  }

  const showBody = () => {
    const bodyDiv = document.getElementById('ProjectVersionSettingsFormTts__options-body-voice')
    if (!bodyDiv) { return }

    openBody(bodyDiv)
  }

  const openBody = (bodyDiv: HTMLElement) => {
    const wrapper = document.querySelector('.ProjectVersionSettingsFormTts__options-body-inner-wrapper-voice')
    if (wrapper) {
      bodyDiv.style.height = wrapper.clientHeight + 'px'
      setBodyOpen(true)
      setTimeout(() => {
        setBodyOpenDelayed(true)
      }, DELAY_TO_SHOW_OVERFLOW)
    }
  }

  const closeBody = (bodyDiv: HTMLElement) => {
    bodyDiv.style.height = '0'
    setBodyOpen(false)
    setBodyOpenDelayed(false)
  }

  const handleClick = () => {
    if (disabled) { return }

    toggleBody()
  }

  const renderBody = () => {
    return (
      <div
          className={classnames(
            'ProjectVersionSettingsFormTts__options-body',
            {'ProjectVersionSettingsFormTts__options-body-open': bodyOpenDelayed})
          }
          id="ProjectVersionSettingsFormTts__options-body-voice"
        >
        <div className="ProjectVersionSettingsFormTts__options-body-inner-wrapper-voice">
          <VoiceSettings
            voices={voices}
            voiceName={voiceName}
            gender={gender}
            setVoiceName={props.setVoiceName}
            setGender={props.setGender}
            languageCode={languageCode}
          />
        </div>
      </div>
    )
  }

  return (
    <div
      className={classnames(
        'ProjectVersionSettingsFormTts__options',
        {'ProjectVersionSettingsFormTts__options-disabled': disabled})
      }
    >
      <div
        className={classnames(
          'ProjectVersionSettingsFormTts__options-header',
          {'ProjectVersionSettingsFormTts__options-header-disabled': disabled})
        }
        onClick={handleClick}
      >
        <span>
          Default voice
        </span>
        <Arrow
          className={classnames(
            'ProjectVersionSettingsFormTts__options-arrow',
            {'ProjectVersionSettingsFormTts__options-arrow-open': bodyOpen},
            {'ProjectVersionSettingsFormTts__options-arrow-disabled': disabled})
          }
        />
      </div>
      {renderBody()}
    </div>
  )
}

export default DubbingOptions
