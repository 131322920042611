import React from 'react'
import ReactPlayer from 'react-player'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import range from 'lodash/range'
import classnames from 'classnames'
import { IApplicationState } from '@Site/rootReducer'
import Button from '@SiteComponents/Button'
import SurveyForm from '@SiteComponents/SurveyForm'
import Ball from '@Images/progress_ball.svg'
import BallActive from '@Images/progress_ball_active.svg'

import {
  submitSurvey,
} from './actions'

import {
  ISurveyData,
} from './types'

import './Survey.scss'

interface SurveyProps {
  readonly loading: boolean
  submitSurvey(payload: ISurveyData): void
}

interface SurveyState {
  readonly step: number
}

class Survey extends React.Component<SurveyProps, SurveyState> {
  constructor(props: SurveyProps) {
    super(props)

    this.state = {
      step: 0
    }
  }

  submit = (payload: ISurveyData) => {
    this.props.submitSurvey(payload)
  }

  increaseStep = () => {
    this.setState((state) => ({
      step: state.step + 1
    }))
  }

  renderStep0 = () => {
    return (
      <div className="Survey__wrapper">
        <div className="Survey__header">
          Welcome to Checksub
        </div>
        <div className="Survey__text">
          Create impactful videos 💥 <br />
          thanks to captivating subtitles.
        </div>
        <div className="Survey__text">
          See how it works.
        </div>
        <Button
          type="button"
          caption="Get Started"
          color="primary-dark"
          onClick={this.increaseStep}
        />
      </div>
    )
  }

  renderStep1 = () => {
    return (
      <div className="Survey__wrapper">
        <div className="Survey__header">
          Upload your video
        </div>
        <div className="Survey__text">
          Once your video is ready, <br />
          upload it to Checksub to turn it into impactful content 💥.
        </div>
        <div className="Survey__video-container">
          <ReactPlayer
            url="https://checksub-website-assets.s3.eu-west-2.amazonaws.com/onboarding_assets/Upload+demo+video.mp4"
            playing
            loop
          />
        </div>
        <Button
          type="button"
          caption="Got it"
          color="primary-dark"
          onClick={this.increaseStep}
        />
      </div>
    )
  }

  renderStep2 = () => {
    return (
      <div className="Survey__wrapper">
        <div className="Survey__header">
          Generate the subtitles
        </div>
        <div className="Survey__text">
          Get automatic subtitles like magic ✨.
        </div>
        <div className="Survey__video-container">
          <ReactPlayer
            url="https://checksub-website-assets.s3.eu-west-2.amazonaws.com/onboarding_assets/Subtitle+creation+demo.mp4"
            playing
            loop
          />
        </div>
        <Button
          type="button"
          caption="Next"
          color="primary-dark"
          onClick={this.increaseStep}
        />
      </div>
    )
  }

  renderStep3 = () => {
    return (
      <div className="Survey__wrapper">
        <div className="Survey__header">
          Edit and style your subtitles
        </div>
        <div className="Survey__text">
          Edit them for a perfect result.
        </div>
        <div className="Survey__video-container">
          <ReactPlayer
            url="https://checksub-website-assets.s3.eu-west-2.amazonaws.com/onboarding_assets/edit+and+style+a+video.mp4"
            playing
            loop
          />
        </div>
        <Button
          type="button"
          caption="Next"
          color="primary-dark"
          onClick={this.increaseStep}
        />
      </div>
    )
  }

  renderStep4 = () => {
    return (
      <div className="Survey__wrapper">
        <div className="Survey__header">
          Share it with the world
        </div>
        <div className="Survey__text">
          Export your video to get your message out. <br />
          If you want a translation, confirm the transcription first.
        </div>
        <div className="Survey__video-container">
          <ReactPlayer
            url="https://checksub-website-assets.s3.eu-west-2.amazonaws.com/onboarding_assets/export+your+video+project+with+subtitles.mp4"
            playing
            loop
          />
        </div>
        <Button
          type="button"
          caption="Got it"
          color="primary-dark"
          onClick={this.increaseStep}
        />
      </div>
    )
  }

  renderStep5 = () => {
    const { loading } = this.props

    return (
      <div className="Survey__wrapper">
        <div className="Survey__header">
          Let's get to know you
        </div>
        <SurveyForm
          submit={this.submit}
          showSpinner={loading}
          disableButton={loading}
        />
      </div>
    )
  }

  renderProgressIndicator = () => {
    const { step } = this.state
    const { loading } = this.props

    return (
      <div className="Survey__progress">
        {range(1, 6).map(item => {
          const goBackToPreviousStep = () => {
            if (loading) { return }

            this.setState({ step: item })
          }
          return (
            item <= step ? (
              <BallActive key={item} onClick={goBackToPreviousStep} className={
                classnames('Survey__progress-ball', { 'Survey__progress-ball-active': item !== step && !loading })
              }/>
            ) : (
              <Ball key={item} className="Survey__progress-ball"/>
            )
          )
        })}
      </div>
    )
  }

  render() {
    // const { step } = this.state

    // temporarily commented, maybe we'll need it in the future
    // return (
    //   <div className="Survey">
    //     {step === 0 && this.renderStep0()}
    //     {step === 1 && this.renderStep1()}
    //     {step === 2 && this.renderStep2()}
    //     {step === 3 && this.renderStep3()}
    //     {step === 4 && this.renderStep4()}
    //     {step === 5 && this.renderStep5()}
    //     {step > 0 && this.renderProgressIndicator()}
    //   </div>
    // )

    return (
      <div className="Survey">
        {this.renderStep5()}
      </div>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { user } = state

  return {
    loading: user.loading,
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    submitSurvey
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Survey)
