import {
  IImportSrtPayload,
  ImportSrtActionTypes,
} from './types'

export const importSrt = (payload: IImportSrtPayload) => ({
  type: ImportSrtActionTypes.IMPORT_SRT,
  payload,
})

export const importSrtSuccess = () => ({
  type: ImportSrtActionTypes.IMPORT_SRT_SUCCESS,
})

export const importSrtFailure = () => ({
  type: ImportSrtActionTypes.IMPORT_SRT_FAILURE,
})
