import { Action } from 'redux'
import { put, takeEvery } from 'redux-saga/effects'
import * as Eff from 'redux-saga/effects' // tslint:disable-line:no-duplicate-imports
import { getUserInfo } from '@SiteModules/User/actions'
import toastr from 'toastr'
import axios from 'axios'

import Logger from '@Utils/Logger'
import history from '@Root/historyInstance'

import {
  signUpFailure,
  signUpSuccess
} from './actions'

import {
  SignUpFormActionTypes,
  PayloadDataBody
} from './types'

import * as API from './api'

interface IAction extends Action {
  readonly payload: PayloadDataBody
}

const call: any = Eff.call

function* signUpUserSaga(action: IAction): Generator<any, any, any> {
  try {
    const domain = action.payload.email.split('@')[1]
    const checkFakeEmailResult = yield axios.get(`https://open.kickbox.com/v1/disposable/${domain}`)

    if (checkFakeEmailResult.data.disposable === false) {
      yield call(API.signUpUser, { payload: action.payload })
      yield put(signUpSuccess())

      window.Checksub.token = window.Checksub.cookies.get('token')
      yield put(getUserInfo())
      yield call(history.replace, '/onboarding')
    } else {
      toastr.error('Email domain is not permitted', 'Error')
      setTimeout(() => history.go(0), 3000)
    }
  } catch (e) {
    Logger.error(e)
    yield put(signUpFailure())
    setTimeout(() => history.go(0), 3000)
  }
}

function* signUpFormSagas() {
  yield takeEvery(SignUpFormActionTypes.SIGN_UP, signUpUserSaga)
}

export default signUpFormSagas
