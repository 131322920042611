export const ConfirmationNewFormActionTypes = {
  CONFIRMATION_NEW: '@@confirmationNewForm/CONFIRMATION_NEW',
  CONFIRMATION_NEW_FAILURE: '@@confirmationNewForm/CONFIRMATION_NEW_FAILURE',
  CONFIRMATION_NEW_SUCCESS: '@@confirmationNewForm/CONFIRMATION_NEW_SUCCESS'
}

export interface IUserConfirmationNewState {
  readonly errors: string[]
  readonly loading: boolean
}

export interface PayloadData {
  readonly payload: {
    readonly utf8: string
    readonly commit: string
    readonly user: {
      readonly email: string
    }
  }
}

export interface IConfirmationNewAction {
  readonly type: string
  readonly payload: PayloadData
}

export interface IConfirmationNewFailureAction {
  readonly type: string
}

export interface IConfirmationNewSuccessAction {
  readonly type: string
}

export type TConfirmationNewActionTypes = IConfirmationNewAction | IConfirmationNewFailureAction | IConfirmationNewSuccessAction
