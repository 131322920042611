import axios, { AxiosRequestConfig } from 'axios'
import convertToSnakeCase from '@Utils/ConvertToSnakeCase'

import {
  PayloadData,
  IUserSignInPayload,
  ISsoSignInPayload
} from './types'

export function signInUser(userPayload: IUserSignInPayload) {
  const config: AxiosRequestConfig = {
    headers: {
      'X-CSRF-Token': window.Checksub.token,
      'X-Requested-With': 'XMLHttpRequest',
    },
    responseType: 'json',
  }

  const payload: PayloadData = {
    utf8: '✓',
    commit: 'Log in',
    user: userPayload
  }

  return axios.post('/users/sign_in', convertToSnakeCase(payload), config)
}

export function ssoSignIn(payload: ISsoSignInPayload) {
  return axios.post('/api/v1/sso/auth', convertToSnakeCase(payload))
}
