import axios, { AxiosRequestConfig } from 'axios'

import {
  IDeleteLabelPayload
} from './types'

export function deleteLabel(payload: IDeleteLabelPayload) {
  const config: AxiosRequestConfig = {
    headers: {
      'X-CSRF-Token': window.Checksub.token
    },
  }

  const { labelId } = payload

  return axios.delete(`/api/v1/labels/${labelId}`,  { ...config })
}
