import { Action } from 'redux'
import { put, takeEvery } from 'redux-saga/effects'
import * as Eff from 'redux-saga/effects' // tslint:disable-line:no-duplicate-imports
import toastr from 'toastr'
import Analytics from '@Utils/Analytics'

import Logger from '@Utils/Logger'
import history from '@Root/historyInstance'

import {
  submitTopupRequestFailure,
  submitTopupRequestSuccess,
} from './actions'

import {
  ICredit,
  ICreditData,
  ICreditsRequired,
  CreditsTopupActionTypes,
} from './types'

import {
  ISuccessResponse
} from '@Root/types'

import * as API from './api'

interface IAction<T> extends Action {
  readonly payload: T
}

const call: any = Eff.call

function* submitTopupRequestSaga(action: IAction<object>) {
  try {
    const response: ISuccessResponse<ICreditData> = yield call(API.submitTopupRequest, action.payload)
    const payload: ICredit = response.data.data
    yield put(submitTopupRequestSuccess(payload))
    Analytics.track('buy_credits', payload.attributes.amount)
    yield call(history.push, '/checkouts/billing_address')
  } catch (e) {
    Logger.error(e, 'Top-up of credits failed')
    yield put(submitTopupRequestFailure())
  }
}

function* notEnoughCreditsSaga(action: IAction<ICreditsRequired>) {
  try {
    yield call(history.push, '/credits/topup')
    const { requiredCredits } = action.payload
    const requiredMinutes = Math.floor(requiredCredits / 60)
    const requiredSeconds = requiredCredits % 60

    toastr.info(
      `You are ${requiredMinutes} minutes ${requiredSeconds} seconds short of credit to validate this project.`,
      'You need to top-up your account to create those subtitles.'
    )
  } catch (e) {
    Logger.error(e, 'Redirect is failed.')
  }
}

function* creditsTopupSagas() {
  yield takeEvery(CreditsTopupActionTypes.SUBMIT_TOPUP_REQUEST, submitTopupRequestSaga)
  yield takeEvery(CreditsTopupActionTypes.NOT_ENOUGH_CREDITS, notEnoughCreditsSaga)
}

export default creditsTopupSagas
