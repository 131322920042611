import {
  AssetsActionTypes,
  IDeleteElementPayload,
  IGetElementsSuccessPayload,
  IGetAffectedProjectVersionsPayload,
  IGetAffectedProjectVersionsSuccessPayload,
} from './types'

export const increasePageCount = () => ({
  type: AssetsActionTypes.INCREASE_PAGE_COUNT
})

export const decreasePageCount = () => ({
  type: AssetsActionTypes.DECREASE_PAGE_COUNT
})

export const getElements = () => ({
  type: AssetsActionTypes.GET_ELEMENTS
})

export const getElementsSuccess = (payload: IGetElementsSuccessPayload) => ({
  type: AssetsActionTypes.GET_ELEMENTS_SUCCESS,
  payload
})

export const getElementsFailure = () => ({
  type: AssetsActionTypes.GET_ELEMENTS_FAILURE
})

export const getAffectedProjectVersions = (payload: IGetAffectedProjectVersionsPayload) => ({
  type: AssetsActionTypes.GET_AFFECTED_PROJECT_VERSIONS,
  payload
})

export const getAffectedProjectVersionsSuccess = (payload: IGetAffectedProjectVersionsSuccessPayload) => ({
  type: AssetsActionTypes.GET_AFFECTED_PROJECT_VERSIONS_SUCCESS,
  payload
})

export const getAffectedProjectVersionsFailure = () => ({
  type: AssetsActionTypes.GET_AFFECTED_PROJECT_VERSIONS_FAILURE,
})

export const closeDeleteElementPopup = () => ({
  type: AssetsActionTypes.CLOSE_DELETE_ELEMENT_POPUP,
})

export const deleteElement = (payload: IDeleteElementPayload) => ({
  type: AssetsActionTypes.DELETE_ELEMENT,
  payload
})

export const deleteElementSuccess = () => ({
  type: AssetsActionTypes.DELETE_ELEMENT_SUCCESS,
})

export const deleteElementFailure = () => ({
  type: AssetsActionTypes.DELETE_ELEMENT_FAILURE,
})
