import React from 'react'
import Popup from '@SiteComponents/Popup'
import CancelSubscriptionForm from './CancelSubscriptionForm'
import CancelSubscriptionFormTrial from './CancelSubscriptionFormTrial'

import {
  ICancelSubscriptionPayload,
} from '@SiteModules/Subscription/types'

import './CancelSubscriptionPopup.scss'

interface CancelSubscriptionPopupProps {
  closePopup(): void
  cancelSubscription(payload: ICancelSubscriptionPayload): void
  readonly secondsAvailable: number
  readonly plan: string
  readonly subscriptionIsTrialing: boolean
}

const CancelSubscriptionPopup: React.FC<CancelSubscriptionPopupProps> = props => {
  return (
    <Popup
      className="CancelSubscriptionPopup"
      closePopup={props.closePopup}
      isDark
    >
      {props.subscriptionIsTrialing ? (
        <CancelSubscriptionFormTrial
          cancelSubscription={props.cancelSubscription}
          closePopup={props.closePopup}
        />
      ) : (
        <CancelSubscriptionForm
          cancelSubscription={props.cancelSubscription}
          closePopup={props.closePopup}
          secondsAvailable={props.secondsAvailable}
          plan={props.plan}
        />
      )}
    </Popup>
  )
}

export default CancelSubscriptionPopup
