import {
  IDeleteLabelState,
  IDeleteLabelPopupOpen,
  DeleteLabelActionTypes,
  TDeleteLabelActionTypes,
} from './types'

import {
  DashboardSidebarActionTypes,
} from '@SiteContainers/DashboardSidebar/types'

export const initialState: IDeleteLabelState = {
  errors: [],
  loading: false,
  labelId: -1,
  caption: '',
}

export const deleteLabelReducer = (
  state: IDeleteLabelState = initialState,
  action: TDeleteLabelActionTypes
): IDeleteLabelState => {
  switch (action.type) {
    case DeleteLabelActionTypes.DELETE_LABEL: return deleteLabel(state)
    case DeleteLabelActionTypes.DELETE_LABEL_SUCCESS: return deleteLabelSuccess(state)
    case DeleteLabelActionTypes.DELETE_LABEL_FAILURE: return deleteLabelFailure(state)
    case DashboardSidebarActionTypes.OPEN_DELETE_LABEL_POPUP: return openDeleteLabelPopup(state, action)
    default: return state
  }
}

const deleteLabel = (state: IDeleteLabelState): IDeleteLabelState => {
  return { ...state, loading: true }
}

const deleteLabelSuccess = (state: IDeleteLabelState): IDeleteLabelState => {
  return { ...state, loading: false }
}

const deleteLabelFailure = (state: IDeleteLabelState): IDeleteLabelState => {
  return { ...state, loading: false }
}

function isIOpenDeleteLabelPopup(arg: any): arg is IDeleteLabelPopupOpen {
  return arg.type === DashboardSidebarActionTypes.OPEN_DELETE_LABEL_POPUP
}

const openDeleteLabelPopup = (state: IDeleteLabelState, action: TDeleteLabelActionTypes): IDeleteLabelState => {
  if (!isIOpenDeleteLabelPopup(action)) { return state }

  const { labelId, caption } = action.payload

  return { ...state, labelId, caption }
}
