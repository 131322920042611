import axios, { AxiosRequestConfig } from 'axios'
import convertToSnakeCase from '@Utils/ConvertToSnakeCase'

import {
  PayloadData
} from './types'

export function signUpUser(data: PayloadData) {
  const config: AxiosRequestConfig = {
    headers: {
      'X-CSRF-Token': window.Checksub.token,
      'X-Requested-With': 'XMLHttpRequest',
    },
    responseType: 'json'
  }

  return axios.post('/users', convertToSnakeCase(data.payload), config)
}
