import axios, { AxiosRequestConfig } from 'axios'
import convertToSnakeCase from '@Utils/ConvertToSnakeCase'

import {
  IRateSubtitlePayload,
  IUpdateProjectVersionsApiCallPayload,
  IFetchProjectVersionsApiCallPayload,
} from './types'

export function fetchProjectVersions(payload: IFetchProjectVersionsApiCallPayload) {
  return axios.get('/api/v1/project_versions', { params: convertToSnakeCase(payload) })
}

export function updateProjectVersions(payload: IUpdateProjectVersionsApiCallPayload) {
  const config: AxiosRequestConfig = {
    headers: {
      'X-CSRF-Token': window.Checksub.token
    },
    responseType: 'json'
  }
  return axios.post('/api/v1/project_versions/updates', convertToSnakeCase(payload), config)
}

export function rateSubtitle(payload: IRateSubtitlePayload) {
  const config: AxiosRequestConfig = {
    headers: {
      'X-CSRF-Token': window.Checksub.token
    },
    responseType: 'json'
  }

  return axios.post(`/api/v1/ratings`, convertToSnakeCase(payload), config)
}
