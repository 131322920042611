import React from 'react'
import InfoBadge from '@Images/info_badge.svg'
import Button from '@SiteComponents/Button'

import {
  ICancelSubscriptionPayload,
} from '@SiteModules/Subscription/types'

import '@Root/config/dropdowns.scss'
import './CancelSubscriptionPopup.scss'

interface CancelSubscriptionFormTrialProps {
  closePopup(): void
  cancelSubscription(payload: ICancelSubscriptionPayload): void
}

const CancelSubscriptionFormTrial: React.FC<CancelSubscriptionFormTrialProps> = props => {
  const onCancel = () => {
    props.cancelSubscription({})
    props.closePopup()
  }

  return (
    <div className="CancelSubscriptionPopup-form">
      <div className="CancelSubscriptionPopup__header">
        <div className="CancelSubscriptionPopup__header-info-patch"/>
        <InfoBadge className="CancelSubscriptionPopup__header-info-badge" />
        Cancellation of your subscription trial
      </div>
      <div className="CancelSubscriptionPopup__info">
        When you cancel your trial, the cancellation will be effective immediately, and you will loose all your credits. <br />
        After this action, you will not be able to access your dashboard anymore.
      </div>
      <div className="CancelSubscriptionPopup__buttons">
        <Button
          type="button"
          color="primary-dark"
          caption="Keep my credits and continue my trial"
          onClick={props.closePopup}
        />
        <button
          className="CancelSubscriptionPopup__buttons-link-like-button"
          onClick={onCancel}
        >
          Stop my free trial
        </button>
      </div>
    </div>
  )
}

export default CancelSubscriptionFormTrial
