import {
  ICreateVocabularyPayload,
  IUpdateVocabularyPayload,
  IDeleteVocabularyPayload,
  IFetchVocabulariesSuccessResponseBody,
  VocabularyActionTypes,
  IVocabularyActionTypes,
} from './types'

export const fetchVocabularies = (): IVocabularyActionTypes => ({
  type: VocabularyActionTypes.FETCH_VOCABULARIES,
})

export const fetchVocabulariesFailure = (): IVocabularyActionTypes => ({
  type: VocabularyActionTypes.FETCH_VOCABULARIES_FAILURE,
})

export const fetchVocabulariesSuccess = (payload: IFetchVocabulariesSuccessResponseBody): IVocabularyActionTypes => ({
  type: VocabularyActionTypes.FETCH_VOCABULARIES_SUCCESS,
  payload,
})

export const createVocabulary = (payload: ICreateVocabularyPayload): IVocabularyActionTypes => ({
  type: VocabularyActionTypes.CREATE_VOCABULARY,
  payload,
})

export const createVocabularyFailure = (): IVocabularyActionTypes => ({
  type: VocabularyActionTypes.CREATE_VOCABULARY_FAILURE,
})

export const createVocabularySuccess = (): IVocabularyActionTypes => ({
  type: VocabularyActionTypes.CREATE_VOCABULARY_SUCCESS,
})

export const updateVocabulary = (payload: IUpdateVocabularyPayload): IVocabularyActionTypes => ({
  type: VocabularyActionTypes.UPDATE_VOCABULARY,
  payload,
})

export const updateVocabularyFailure = (): IVocabularyActionTypes => ({
  type: VocabularyActionTypes.UPDATE_VOCABULARY_FAILURE,
})

export const updateVocabularySuccess = (): IVocabularyActionTypes => ({
  type: VocabularyActionTypes.UPDATE_VOCABULARY_SUCCESS,
})

export const deleteVocabulary = (payload: IDeleteVocabularyPayload): IVocabularyActionTypes => ({
  type: VocabularyActionTypes.DELETE_VOCABULARY,
  payload,
})

export const deleteVocabularyFailure = (): IVocabularyActionTypes => ({
  type: VocabularyActionTypes.DELETE_VOCABULARY_FAILURE,
})

export const deleteVocabularySuccess = (): IVocabularyActionTypes => ({
  type: VocabularyActionTypes.DELETE_VOCABULARY_SUCCESS,
})
