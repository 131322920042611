import {
  TConfirmationNewActionTypes,
  ConfirmationNewFormActionTypes,
  IUserConfirmationNewState
} from './types'

export const initialState: IUserConfirmationNewState = {
  errors: [],
  loading: false
}

export const userConfirmationNewReducer = (
  state: IUserConfirmationNewState = initialState,
  action: TConfirmationNewActionTypes
): IUserConfirmationNewState => {
  switch (action.type) {
    case ConfirmationNewFormActionTypes.CONFIRMATION_NEW: return confirmationNew(state)
    case ConfirmationNewFormActionTypes.CONFIRMATION_NEW_SUCCESS: return confirmationNewSuccess(state)
    case ConfirmationNewFormActionTypes.CONFIRMATION_NEW_FAILURE: return confirmationNewFailure(state)
    default: return state
  }
}

const confirmationNew = (state: IUserConfirmationNewState): IUserConfirmationNewState => {
  return { ...state, loading: true }
}

const confirmationNewSuccess = (state: IUserConfirmationNewState): IUserConfirmationNewState => {
  return { ...state, loading: false }
}

const confirmationNewFailure = (state: IUserConfirmationNewState): IUserConfirmationNewState => {
  return { ...state, loading: false }
}
