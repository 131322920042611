import {
  ExportationActionTypes,
} from './types'

import {
  IGetExportationsForDownloadPayload,
  IGetExportationsForDownloadSuccessPayload,
  IRemoveFromExportationsForDownloadPayload,
} from '@EditorModules/Exportation/types'

export const getExportationsForDownload = (payload: IGetExportationsForDownloadPayload) => {
  return {
    type: ExportationActionTypes.GET_EXPORTATIONS_FOR_DOWNLOAD,
    payload
  }
}

export const getExportationsForDownloadSuccess = (payload: IGetExportationsForDownloadSuccessPayload) => {
  return {
    type: ExportationActionTypes.GET_EXPORTATIONS_FOR_DOWNLOAD_SUCCESS,
    payload
  }
}

export const removeFromExportationsForDownload = (payload: IRemoveFromExportationsForDownloadPayload) => {
  return {
    type: ExportationActionTypes.REMOVE_FROM_EXPORTATIONS_FOR_DOWNLOAD,
    payload
  }
}
