import {
  DemoActionTypes,
  IDemoActionTypes,
  ISaveUrlInStatePayload,
  ISubmitDemoProjectVersionSettingsPayload
} from './types'

export const saveUrlInState = (payload: ISaveUrlInStatePayload): IDemoActionTypes => ({
  type: DemoActionTypes.SAVE_URL_IN_STATE,
  payload
})

export const submitDemoProjectVersionSettings = (payload: ISubmitDemoProjectVersionSettingsPayload): IDemoActionTypes => ({
  type: DemoActionTypes.SUBMIT_DEMO_PROJECT_VERSION_SETTINGS,
  payload
})

export const setSubmitted = (): IDemoActionTypes => ({
  type: DemoActionTypes.SET_SUBMITTED,
})
