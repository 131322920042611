import {
  TPasswordEditActionTypes,
  UserPasswordEditActionTypes,
  IUserPasswordEditState
} from './types'

export const initialState: IUserPasswordEditState = {
  loading: false
}

export const userPasswordEditReducer = (
  state: IUserPasswordEditState = initialState,
  action: TPasswordEditActionTypes
): IUserPasswordEditState => {
  switch (action.type) {
    case UserPasswordEditActionTypes.UPDATE_PASSWORD: return passwordEdit(state)
    case UserPasswordEditActionTypes.UPDATE_PASSWORD_SUCCESS: return passwordEditSuccess(state)
    case UserPasswordEditActionTypes.UPDATE_PASSWORD_FAILURE: return passwordEditFailure(state)
    default: return state
  }
}

const passwordEdit = (state: IUserPasswordEditState): IUserPasswordEditState => {
  return { ...state, loading: true }
}

const passwordEditSuccess = (state: IUserPasswordEditState): IUserPasswordEditState => {
  return { ...state, loading: false }
}

const passwordEditFailure = (state: IUserPasswordEditState): IUserPasswordEditState => {
  return { ...state, loading: false }
}
