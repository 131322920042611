import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { IApplicationState } from '@Site/rootReducer'
import Popup from '@SiteComponents/Popup'
import CreateLabelForm from '@SiteComponents/CreateLabelForm'

import {
  createLabel
} from '@SiteModules/Label/actions'

import {
  ICreateLabelPayload
} from '@SiteModules/Label/types'

import './CreateLabelPopup.scss'

interface CreateLabelPopupProps {
  readonly loading: boolean
  closePopup(): void
  createLabel(payload: ICreateLabelPayload): void
}

interface CreateLabelPopupState {
}

class CreateLabelPopup extends React.Component<CreateLabelPopupProps, CreateLabelPopupState> {
  render() {
    const { closePopup, loading } = this.props

    return (
      <Popup
        className="CreateLabelPopup"
        closePopup={closePopup}
        isDark
      >
        <div className="CreateLabelPopup__title">Create new label</div>
        <CreateLabelForm
          showSpinner={loading}
          disableButton={loading}
          submit={this.props.createLabel}
        />
      </Popup>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  return {
    loading: state.labels.createLabel.loading,
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    createLabel,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateLabelPopup)
