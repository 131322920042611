// https://www.compart.com/en/unicode/category/Pd
const DASHES = /[\u002D\u2011-\u2015\ufe58\uff0d]/g

// https://docs.aws.amazon.com/AmazonS3/latest/dev/UsingMetadata.html
const BLACKLIST_CHARACTERS = /[\s().|«»^><!?*@$&:\\=+\/;{}`~#\[\]\'\%]+/g
// const BLACKLIST_ASCII_CHARACTERS = /[\x00-\x31\x7F\x80-\xFF]/g

// allow only latin letters, numbers, small hyphens (minus) and low line (underscore)
const SMALL_HYPHEN_MINUS = '-'
const BLACKLIST_UNICODE_CHARACTERS = /[^\u002D\u0030-\u0039\u0041-\u005A\u005F\u0061-\u007A]+/g

class FileNameSanitizer {
  constructor(
    private readonly fileName: string
  ) {
    this.fileName = fileName
  }

  call(): string {
    // AWS S3 doesn't work with white spaces, so we convert it to the "_" chars
    return this.fileName
      .split('.')
      .slice(0, -1)
      .join('.')
      .replace(BLACKLIST_CHARACTERS, '')
      .replace(DASHES, SMALL_HYPHEN_MINUS)
      .normalize('NFD') // https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
      .replace(/[\u0300-\u036f]/g, '')
      .replace(BLACKLIST_UNICODE_CHARACTERS, '_')
  }
}

export default FileNameSanitizer
