import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { Redirect } from 'react-router-dom'
import Aside from '@SiteComponents/Aside'
import Header from '@SiteContainers/Header'
import { IApplicationState } from '@Site/rootReducer'
import BillingAddressFormStep1 from './BillingAddressFormStep1'
import BillingAddressFormStep2 from './BillingAddressFormStep2'
import VideoParametersImage from '@Images/video_parameters.png'

import {
  submitBillingAddress,
  fetchBillingAddress,
} from './actions'

import {
  PayloadData,
  IBillingAddress,
} from './types'

import {
  getItemToPayFor,
  getItemToPayForId,
  isThereAnythingInCart,
} from './selectors'

import './CheckoutsBillingAddress.scss'

interface CheckoutsBillingAddressProps {
  readonly loading: boolean
  readonly itemToPayFor: string
  readonly itemToPayForId: number
  readonly billingAddress: IBillingAddress
  readonly isThereAnythingToPayFor: boolean
  fetchBillingAddress(): void
  submitBillingAddress(payload: PayloadData): void
}

interface CheckoutsBillingAddressState {
  readonly name: string
  readonly lastName: string
  readonly company: string
  readonly isFirstBillingAddressStep: boolean
}

class CheckoutsBillingAddress extends React.Component<CheckoutsBillingAddressProps, CheckoutsBillingAddressState> {
  constructor(props: CheckoutsBillingAddressProps) {
    super(props)

    this.state = {
      name: '',
      lastName: '',
      company: '',
      isFirstBillingAddressStep: true,
    }
  }

  componentDidMount() {
    this.props.fetchBillingAddress()
  }

  componentDidUpdate(prevProps: CheckoutsBillingAddressProps) {
    if (prevProps.billingAddress.attributes !== this.props.billingAddress.attributes) {
      this.setState({
        name: this.props.billingAddress.attributes.name,
        lastName: this.props.billingAddress.attributes.last_name,
        company: this.props.billingAddress.attributes.company,
      })
    }
  }

  submitFirstStep = (name: string, lastName: string, company: string): void => {
    this.setState({
      name,
      lastName,
      company,
      isFirstBillingAddressStep: false
    })
  }

  submit = (address: string, city: string, country: string, zipcode: string, vatId: string): void => {
    const { company, name, lastName } = this.state
    const user = { company, name, last_name: lastName, address, city, country, zipcode, vat_id: vatId }
    const { itemToPayFor, itemToPayForId } = this.props

    this.props.submitBillingAddress({ user, itemToPayFor, itemToPayForId })
  }

  render() {
    const {
      loading,
      billingAddress,
      isThereAnythingToPayFor,
    } = this.props

    const {
      countries,
      address,
      city,
      country,
      zipcode,
      vat_id,
      eu_countries,
    } = billingAddress.attributes

    const {
      company,
      name,
      lastName
    } = this.state

    const canBuy = isThereAnythingToPayFor

    return (
      <React.Fragment>
        {canBuy ? (
          <div className="CheckoutsBillingAddress">
            <Header />

            <div className="CheckoutsBillingAddress_body">
              <div className="CheckoutsBillingAddress_body__aside">
                <Aside
                  image={VideoParametersImage}
                  text_part1="Please fill in "
                  text_highlight="your billing address"
                />
              </div>

              {this.state.isFirstBillingAddressStep ? (
                <BillingAddressFormStep1
                  company={company}
                  name={name}
                  lastName={lastName}
                  showSpinner={loading}
                  disableButton={loading}
                  submit={this.submitFirstStep}
                />
              ) : (
                <BillingAddressFormStep2
                  company={company}
                  countries={countries}
                  address={address}
                  city={city}
                  country={country}
                  zipcode={zipcode}
                  euCountries={eu_countries}
                  vatId={vat_id}
                  showSpinner={loading}
                  disableButton={loading}
                  submit={this.submit}
                />
              )}
            </div>
          </div>
        ) : (
          <Redirect to="/" />
        )}
      </React.Fragment>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { checkoutsBillingAddress } = state

  return {
    itemToPayFor: getItemToPayFor(state),
    itemToPayForId: getItemToPayForId(state),
    isThereAnythingToPayFor: isThereAnythingInCart(state),
    billingAddress: checkoutsBillingAddress.billingAddress,
    loading: checkoutsBillingAddress.loading,
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    submitBillingAddress,
    fetchBillingAddress,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutsBillingAddress)
