import axios from 'axios'

import {
  IFetchProcessingInfoPayload,
  IFetchCurrentProcessingStepPayload,
} from './types'

export function fetchProcessingInfo(payload: IFetchProcessingInfoPayload) {
  return axios.get(`/api/v1/project_versions/${payload.projectVersionId}/show_processing_info`)
}

export function fetchCurrentProcessingStep(payload: IFetchCurrentProcessingStepPayload) {
  return axios.get(`/api/v1/project_versions/${payload.projectVersionId}/show_current_processing_step`)
}
