import React from 'react'
import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'
import { IApplicationState } from '@Site/rootReducer'
import Popup from '@SiteComponents/Popup'
import ExportationButtons from '@SiteComponents/ExportationButtons'
import { ISubtitle } from '@SiteContainers/ProjectVersionsList/types'

import {
  getCurrentProcessedSubtitles,
  getAnyCurrentProcessedSubtitleHasSpeakers,
} from '@SiteContainers/ProjectVersionsList/selectors'

import './ExportationPopup.scss'

interface ExportationProps {
  readonly subtitles: ISubtitle[]
  readonly anyExportingSubtitleHasSpeakers: boolean
  closePopup(): void
}

interface ExportationState {
}

class Exportation extends React.Component<ExportationProps, ExportationState> {
  render() {
    const {
      closePopup,
      subtitles,
      anyExportingSubtitleHasSpeakers
    } = this.props

    return (
      <Popup
        className="ExportationPopup"
        closePopup={closePopup}
        isDark
      >
        <ExportationButtons
          subtitles={subtitles}
          anyExportingSubtitleHasSpeakers={anyExportingSubtitleHasSpeakers}
        />
      </Popup>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { projectVersionsList } = state

  return {
    subtitles: getCurrentProcessedSubtitles(projectVersionsList),
    anyExportingSubtitleHasSpeakers: getAnyCurrentProcessedSubtitleHasSpeakers(projectVersionsList)
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Exportation)
