import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { IApplicationState } from '@Site/rootReducer'
import Popup from '@SiteComponents/Popup'
import AddUsersToSsoForm from '@SiteComponents/AddUsersToSsoForm'
import Home from '@Images/home_sso.svg'

import {
  addUsers,
  closeAddUsersToSsoPopup,
} from '@SiteModules/Sso/actions'

import {
  IAddUsersPayload
} from '@SiteModules/Sso/types'

import './AddUsersToSsoPopup.scss'

interface AddUsersToSsoPopupProps {
  readonly ssoOrganizationId: number | null
  closeAddUsersToSsoPopup(): void
  addUsers(payload: IAddUsersPayload): void
}

interface AddUsersToSsoPopupState {
}

class AddUsersToSsoPopup extends React.Component<AddUsersToSsoPopupProps, AddUsersToSsoPopupState> {
  addUsers = (emails: string[], isAdmin: boolean) => {
    const { ssoOrganizationId } = this.props
    if (!ssoOrganizationId) { return }

    const payload = { emails, isAdmin, ssoOrganizationId }

    this.props.addUsers(payload)
  }

  render() {
    return (
      <Popup
        className="AddUserstoSsoPopup"
        closePopup={this.props.closeAddUsersToSsoPopup}
        isDark
      >
        <div className="AddUsersToSsoPopup__title">
          <Home/>
          Add to your workspace {/* correct would be 'SSO organization' */}
        </div>
        <AddUsersToSsoForm
          addUsers={this.addUsers}
        />
      </Popup>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { user } = state

  return {
    ssoOrganizationId: user.user.attributes.managedSsoOrganizationId
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    addUsers,
    closeAddUsersToSsoPopup,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AddUsersToSsoPopup)
