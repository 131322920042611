import { IAction, IActionSuccess } from '@Root/types'

import {
  ISubscription
} from '@SiteModules/Subscription/types'

import {
  ICredit
} from '@SiteModules/Credit/types'

import {
  IPrice,
} from '@SitePages/CheckoutsPaymentDetails/types'

import {
  IGetExportingSubtitleIdsSuccessPayload
} from '@EditorModules/User/types'

export const UserActionTypes = {
  LOGOUT: '@@user/LOGOUT',
  LOGOUT_FAILURE: '@@user/LOGOUT_FAILURE',
  LOGOUT_SUCCESS: '@@user/LOGOUT_SUCCESS',

  GET_INFO: '@@user/GET_INFO',
  GET_INFO_FAILURE: '@@user/GET_INFO_FAILURE',
  GET_INFO_SUCCESS: '@@user/GET_INFO_SUCCESS',

  GET_STRIPE_INFO: '@@user/GET_STRIPE_INFO',
  GET_STRIPE_INFO_FAILURE: '@@user/GET_STRIPE_INFO_FAILURE',
  GET_STRIPE_INFO_SUCCESS: '@@user/GET_STRIPE_INFO_SUCCESS',

  CONNECT_EXTERNAL_CHANNEL: '@@user/CONNECT_EXTERNAL_CHANNEL',

  CREATE_CUSTOMER_PORTAL_SESSION: '@@user/CREATE_CUSTOMER_PORTAL_SESSION',
  CREATE_CUSTOMER_PORTAL_SESSION_FAILURE: '@@user/CREATE_CUSTOMER_PORTAL_SESSION_FAILURE',

  OPEN_SSO_ADMIN_PORTAL: '@@user/OPEN_SSO_ADMIN_PORTAL',

  SET_LAST_VIEWED_ALERT: '@@user/SET_LAST_VIEWED_ALERT',

  GET_EXPORTING_SUBTITLE_IDS: '@@user/GET_EXPORTING_SUBTITLE_IDS',
  GET_EXPORTING_SUBTITLE_IDS_SUCCESS: '@@user/GET_EXPORTING_SUBTITLE_IDS_SUCCESS',
}

export interface IConnectExternalChannel {
  readonly service: string
}

export interface IOpenSsoAdminPortal {
  readonly ssoOrganizationId: number
}

export interface ISetLastViewedAlert {
  readonly alertId: number
}

export interface IUserState {
  readonly user: IUser
  readonly loading: boolean
  readonly hasLoadedStripeInfoSuccessfully: boolean
  readonly subscription: ISubscription
  readonly credits: ICredit[]
  readonly stripe?: IUserStripe
}

export interface IUser {
  readonly id: string
  readonly type: 'user'
  readonly attributes: IUserAttributes
}

export interface IUserAttributes {
  readonly name: string
  readonly email: string
  readonly country: string
  readonly lastName: string
  readonly role: string
  readonly onboardingState: string
  readonly numberOfSubtitlesCreated: number
  readonly maxFileSize: number | null
  readonly balance: number
  readonly confirmedEmail: boolean
  readonly isFromOutsideEu: boolean
  readonly remainingElementStorageSpace: number
  readonly usedElementStorageSpace: number
  readonly maxElementStorageSpace: number
  readonly onboardingPath: string | null
  readonly hasActiveElements: boolean
  readonly canGetTrial: boolean
  readonly isNewUser: boolean
  readonly usingPersonalSubscription: boolean
  readonly lastUsedTranscriptInformation?: ITranscriptInformation
  readonly lastUsedDubbingInformation?: IDubbingInformation
  readonly lastUsedReviewTranscript?: boolean
  readonly lastUsedTtsProjectVersionLanguage?: string
  readonly managedSsoOrganizationId: number | null
  readonly relevantAlert: IAlert | null
  readonly exportingSubtitleIds: number[]
}

export interface IAlert {
  readonly id: number
  readonly message: string
}

export interface ITranscriptInformation {
  readonly language?: string
  readonly subtitleLanguages?: string[]
  readonly vocabularyName?: string
}

export interface IDubbingInformation {
  readonly lastUsedSeparateAudio?: boolean
  readonly lastUsedAdaptTranslation?: boolean
}

export interface IUserStripe {
  readonly id: string
  readonly type: 'userStripe'
  readonly attributes: IUserStripeAttributes
}

export interface IUserStripeAttributes {
  readonly taxPercent: number
  readonly customerCurrency?: string
}

export interface IUserStripeInfo {
  readonly data: IUserStripe
}

export interface IUserInfo {
  readonly user: {
    readonly data: IUser,
  }
  readonly subscription: {
    readonly data: ISubscription,
  },
  readonly credits: {
    readonly data: ICredit[]
  }
  readonly stripe?: IUserStripeInfo
}

export type TUserActions = IAction<IUserInfo> | IAction<ISubscription> | IActionSuccess<IPrice> | IAction<IUserStripeInfo> | IAction<IGetExportingSubtitleIdsSuccessPayload>
