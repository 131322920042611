import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { IApplicationState } from '@Site/rootReducer'
import LabelsPanel from '@SiteComponents/LabelsPanel'
import DashboardLinks from '@SiteComponents/DashboardLinks'

import './DashboardSidebar.scss'

import {
  ILabelState,
  IChangeSelectedLabelsPayload
} from '@SiteModules/Label/types'

import {
  getLabelIds,
  getLabelCaptions,
} from '@SiteModules/Label/selectors'

import {
  fetchLabels,
  resetLabelSelection,
  changeSelectedLabels,
} from '@SiteModules/Label/actions'

import {
  IDeleteLabelPayload,
} from '@SiteContainers/DeleteLabelPopup/types'

import {
  IRenameLabelPopupOpenPayload,
} from '@SiteContainers/RenameLabelPopup/types'

import {
  openDeleteLabelPopup,
  openRenameLabelPopup
} from './actions'

interface DashboardSidebarProps {
  readonly labelState: ILabelState
  readonly selectedLabels: number[]
  readonly subscriptionIsCurrent: boolean
  readonly subscriptionPlan: string
  readonly usingPersonalSubscription: boolean
  fetchLabels(): void
  resetLabelSelection(): void
  openCreateLabelPopup(): void
  openRenameLabelPopup(payload: IRenameLabelPopupOpenPayload): void
  openDeleteLabelPopup(payload: IDeleteLabelPayload): void
  changeSelectedLabels(payload: IChangeSelectedLabelsPayload): void
}

interface DashboardSidebarState {
}

class DashboardSidebar extends React.Component<DashboardSidebarProps, DashboardSidebarState> {
  componentDidMount() {
    this.props.fetchLabels()
  }

  changeSelectedLabels = (labelId: number) => {
    const payload = { labelId }
    this.props.changeSelectedLabels(payload)
  }

  render() {
    const {
      labelState,
      selectedLabels,
      subscriptionIsCurrent,
      subscriptionPlan,
      usingPersonalSubscription,
    } = this.props

    return (
      <div className="DashboardSidebar">
        <React.Fragment>
          <div className="DashboardSidebar__upperPart">
            <LabelsPanel
              selectedLabels={selectedLabels}
              labelIds={getLabelIds(labelState)}
              labelCaptions={getLabelCaptions(labelState)}
              changeSelectedLabels={this.changeSelectedLabels}
              resetLabelSelection={this.props.resetLabelSelection}
              openDeleteLabelPopup={this.props.openDeleteLabelPopup}
              openCreateLabelPopup={this.props.openCreateLabelPopup}
              openRenameLabelPopup={this.props.openRenameLabelPopup}
            />
          </div>
          <DashboardLinks
            subscriptionIsCurrent={subscriptionIsCurrent}
            subscriptionPlan={subscriptionPlan}
            usingPersonalSubscription={usingPersonalSubscription}
          />
        </React.Fragment>
      </div>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { labels, user } = state

  return {
    labelState: labels,
    labels: labels.labels,
    selectedLabels: labels.selectedLabels,
    subscriptionIsCurrent: user.subscription.attributes.isCurrent,
    subscriptionPlan: user.subscription.attributes.plan,
    usingPersonalSubscription: user.user.attributes.usingPersonalSubscription,
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    fetchLabels,
    resetLabelSelection,
    changeSelectedLabels,
    openDeleteLabelPopup,
    openRenameLabelPopup,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardSidebar)
