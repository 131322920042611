import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import Cookies from 'universal-cookie'
import toastr from 'toastr'

import ChecksubApp from '@Site/ChecksubApp'
import history from '@Root/historyInstance'
import configureStore from '@Site/configureStore'

import 'toastr/build/toastr.css'

import './site.scss'

const store = configureStore(history)

const rootEl = document.getElementById('root') as HTMLDivElement
const csrfTokenEl = document.querySelector('meta[name="csrf-token"]') as HTMLMetaElement
const token = csrfTokenEl.content

const cookies = new Cookies()

toastr.options.closeButton = true

interface IChecksub {
  token: string
  readonly email?: string
  readonly s3Bucket: string
  readonly stripePublishableKey: string
  readonly essentialCostForMinute: number
  readonly cookies: Cookies
  readonly tooltipDelay: number
}

declare global {
  interface Window {
    Checksub: IChecksub
    gtag: typeof gtag
    dataLayer: any
    readonly Rollbar: any
  }
}

window.Checksub = {
  ...window.Checksub,
  token,
  cookies,
  tooltipDelay: 500,
}

ReactDOM.render(
  <Provider store={store}>
    <ChecksubApp />
  </Provider>,
  rootEl
)
