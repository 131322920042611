import {
  PayloadData,
  SignUpFormActionTypes,
  TSignUpActionTypes
} from './types'

export const signUp = (payload: PayloadData): TSignUpActionTypes => ({
  type: SignUpFormActionTypes.SIGN_UP,
  payload
})

export const signUpSuccess = (): TSignUpActionTypes => ({
  type: SignUpFormActionTypes.SIGN_UP_SUCCESS,
})

export const signUpFailure = (): TSignUpActionTypes => ({
  type: SignUpFormActionTypes.SIGN_UP_FAILURE,
})
