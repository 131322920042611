import axios, { AxiosRequestConfig } from 'axios'
import convertToSnakeCase from '@Utils/ConvertToSnakeCase'

import {
  IGetElementsPayload,
  IDeleteElementPayload,
  IGetAffectedProjectVersionsPayload
} from './types'

export function getElements(payload: IGetElementsPayload) {
  return axios.get('/api/v1/elements', { params: convertToSnakeCase(payload) })
}

export function getAffectedProjectVersions(payload: IGetAffectedProjectVersionsPayload) {
  return axios.get(`/api/v1/elements/${payload.elementId}/show_project_versions`)
}

export function deleteElement(payload: IDeleteElementPayload) {
  const config: AxiosRequestConfig = {
    headers: {
      'X-CSRF-Token': window.Checksub.token
    }
  }

  return axios.delete(`/api/v1/elements/${payload.elementId}`, config)
}
