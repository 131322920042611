export const UserPasswordEditActionTypes = {
  UPDATE_PASSWORD: '@@usersPasswordEdit/UPDATE_PASSWORD',
  UPDATE_PASSWORD_FAILURE: '@@usersPasswordEdit/UPDATE_PASSWORD_FAILURE',
  UPDATE_PASSWORD_SUCCESS: '@@usersPasswordEdit/UPDATE_PASSWORD_SUCCESS'
}

export interface IUserPasswordEditState {
  readonly loading: boolean
}

export interface PayloadData {
  readonly payload: {
    readonly utf8: string
    readonly commit: string
    readonly user: {
      readonly password: string
      readonly password_confirmation: string
    }
  }
}

export interface IPasswordEditAction {
  readonly type: string
  readonly payload: PayloadData
}

export interface IPasswordEditFailureAction {
  readonly type: string
}

export interface IPasswordEditSuccessAction {
  readonly type: string
}

export type TPasswordEditActionTypes = IPasswordEditAction | IPasswordEditFailureAction | IPasswordEditSuccessAction
