import React, { useEffect } from 'react'
import DropdownList from '@checksub_team/react-widgets/lib/DropdownList'

import './Formality.scss'

interface FormalityProps {
  formality?: string
  changeFormality(newFormality: string | undefined): void
}

const FORMALITY_OPTIONS = ['Auto', 'Formal', 'Informal']
const DEFAULT_FORMALITY = 'Auto'

const Formality: React.FC<FormalityProps> = props => {
  useEffect(() => {
    props.changeFormality(DEFAULT_FORMALITY)

    return () => {
      props.changeFormality(undefined)
    }
  }, [])

  return (
    <div className="ProjectVersionSettingsForm__formfield Formality__formfield">
      <label className="ProjectVersionSettingsForm__label" htmlFor="formality">
        Tone:
      </label>

      <DropdownList
        data={FORMALITY_OPTIONS}
        id="formality"
        name="formality"
        value={props.formality}
        onChange={props.changeFormality}
      />
    </div>
  )
}

export default Formality
