import React from 'react'
import ButtonNewV2 from '@SiteComponents/ButtonNewV2'
import { Submittable } from '@Root/types'
import DropdownList from '@checksub_team/react-widgets/lib/DropdownList'
import { useForm } from 'react-hook-form'

import '@Root/config/dropdowns.scss'
import './SurveyForm.scss'

interface SurveyFormProps extends Submittable {
}

type FormData = {
  role: string
  videoQuantityPerMonth: string
  organizationSize: string
}

const ROLES = [
  'Learning Manager',
  'Social Media Manager',
  'Content Manager',
  'Marketing Project Manager',
  'Video Editor',
  'Content Creator',
  'Influencer',
  'CEO/Founder',
  'Student',
  'Subtitler',
  'Other'
]

const VIDEO_QUANTITY_PER_MONTH = [
  '1 (or less)',
  '2 to 10',
  '11 or more'
]

const ORGANIZATION_SIZES = [
  'one (myself)',
  '1-10',
  '11-50',
  '51-200',
  '201-500',
  '501-1000',
  '1001-5000',
  '5001-10,000'
]

const SELF_EMPLOYED = 'Self-employed'

const SurveyForm: React.FC<SurveyFormProps> = props => {
  const { register, handleSubmit, errors } = useForm<FormData>()

  const onSubmit = handleSubmit(({ role, videoQuantityPerMonth, organizationSize }) => {
    if (organizationSize === ORGANIZATION_SIZES[0]) {
      organizationSize = SELF_EMPLOYED
    }
    props.submit({ role, videoQuantityPerMonth, organizationSize })
  })

  const renderRole = () => {
    return(
      <span className="SurveyForm__formfield SurveyForm__formfield-role">
        <DropdownList
          id="role"
          name="role"
          customref={register({ required: true })}
          data={ROLES}
          translate="no"
        />

        {errors.role && <span className="errorMessage">&#x26A0; This field is required</span>}
      </span>
    )
  }

  const renderVideoQuantityPerMonth = () => {
    return(
      <span className="SurveyForm__formfield SurveyForm__formfield-videoQuantityPerMonth">
        <DropdownList
          id="videoQuantityPerMonth"
          name="videoQuantityPerMonth"
          customref={register({ required: true })}
          data={VIDEO_QUANTITY_PER_MONTH}
          translate="no"
        />

        {errors.videoQuantityPerMonth && <span className="errorMessage">&#x26A0; This field is required</span>}
      </span>
    )
  }

  const renderOrganizationSize = () => {
    return(
      <div className="SurveyForm__formfield SurveyForm__formfield-organizationSize">
        <DropdownList
          id="organizationSize"
          name="organizationSize"
          customref={register({ required: true })}
          data={ORGANIZATION_SIZES}
          translate="no"
        />

        {errors.organizationSize && <span className="errorMessage">&#x26A0; This field is required</span>}
      </div>
    )
  }

  const renderSubmitButton = () => {
    return(
      <ButtonNewV2
        type="submit"
        size="large"
        styleType="brand-primary"
        caption="Let's start"
        disabled={props.disableButton}
        showSpinner={props.showSpinner}
      />
    )
  }

  return(
    <div className="SurveyForm" >
      <form onSubmit={onSubmit} >
          <span className="SurveyForm__text">I'm a</span>
          {renderRole()}
          <span className="SurveyForm__text">and I work in a company of</span>
          {renderOrganizationSize()}
          <span className="SurveyForm__text">
            person(s) 💼 <br />
            And every month I create about
          </span>
          {renderVideoQuantityPerMonth()}
          <span className="SurveyForm__text">videos.</span>
          {renderSubmitButton()}
      </form>
    </div>
  )
}

export default SurveyForm
