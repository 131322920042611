import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { IApplicationState } from '@Site/rootReducer'
import OnboardingSidebar from '@SiteComponents/OnboardingSidebar'
import SignUpFormStep1 from './SignUpFormStep1'
import SignUpFormStep2 from './SignUpFormStep2'

import {
  PayloadData
} from './types'

import {
  signUp
} from './actions'

import './UsersSignUp.scss'

interface UsersSignUpProps {
  readonly loading: boolean
  signUp(payload: PayloadData): void
}

interface UsersSignUpState {
  readonly email: string
  readonly password: string
  readonly isFirstOnboardingStep: boolean
}

class UsersSignUp extends React.Component<UsersSignUpProps, UsersSignUpState> {
  constructor(props: UsersSignUpProps) {
    super(props)
    this.state = {
      email: '',
      password: '',
      isFirstOnboardingStep: true,
    }
  }

  signUpFirstStep = (email: string, password: string): void => {
    this.setState({
        email,
        password,
        isFirstOnboardingStep: false
      })
  }

  render() {
    const { loading } = this.props
    const { isFirstOnboardingStep, email, password } = this.state

    return (
      <div className="UsersSignUp">
        <OnboardingSidebar
          type="sign-up"
          caption1={<span>Now, I can translate my videos in minutes,<br />the result is impressive.</span>}
          caption2={<span>Karine M, Video Content Manager</span>}
        />
        <div className="UsersSignUp__form-wrapper">
          {isFirstOnboardingStep ? (
            <SignUpFormStep1
              submit={this.signUpFirstStep}
              showSpinner={loading}
              disableButton={loading}
            />
          ) : (
            <SignUpFormStep2
              submit={this.props.signUp}
              showSpinner={loading}
              disableButton={loading}
              email={email}
              password={password}
            />
          )}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { userSignUp } = state

  return {
    loading: userSignUp.loading
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    signUp
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersSignUp)
