import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { IApplicationState } from '@Site/rootReducer'
import PasswordNewForm from '@SiteComponents/PasswordNewForm'
import OnboardingSidebar from '@SiteComponents/OnboardingSidebar'

import {
  PayloadData,
} from './types'

import {
  passwordNew,
} from './actions'

import './UsersPasswordNew.scss'

interface UsersPasswordNewProps {
  readonly loading: boolean
  passwordNew(payload: PayloadData): void
}

interface UsersPasswordNewState {
}

class UsersPasswordNew extends React.Component<UsersPasswordNewProps, UsersPasswordNewState> {
  render() {
    const { loading } = this.props

    return (
      <div className="UsersPasswordNew">
        <OnboardingSidebar
          type="sign-in"
          caption1={<span>Forgot your password?<br />We will help you to reset it.</span>}
          caption2={<span />}
        />
        <div className="UsersPasswordNew__form-wrapper">
          <PasswordNewForm
            submit={this.props.passwordNew}
            showSpinner={loading}
            disableButton={loading}
          />
        </div>
      </div>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  const { userPasswordNew } = state

  return {
    loading: userPasswordNew.loading,
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    passwordNew,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersPasswordNew)
