import { put, takeEvery } from 'redux-saga/effects'
import * as Eff from 'redux-saga/effects' // tslint:disable-line:no-duplicate-imports
import toastr from 'toastr'
import 'toastr/build/toastr.css'

import Logger from '@Utils/Logger'

import { ISuccessResponse, IAction } from '@Root/types'

import {
  fetchSsoDataSuccess,
  fetchSsoDataFailure,
  addUsersSuccess,
  addUsersFailure,
  deleteMemberSuccess,
  deleteMemberFailure,
  changeRoleSuccess,
  changeRoleFailure,
  changeSubscriptionSuccess,
  changeSubscriptionFailure,
} from './actions'

import {
  SsoActionTypes,
  IFetchSsoDataPayload,
  IFetchSsoDataSuccessResponseBody,
  IAddUsersPayload,
  IAddUsersSuccessResponseBody,
  IDeleteMemberPayload,
  IDeleteMemberSuccessResponseBody,
  IChangeRolePayload,
  IChangeRoleSuccessResponseBody,
  IChangeSubscriptionPayload,
  IChangeSubscriptionSuccessResponseBody,
} from './types'

import * as API from './api'

const call: any = Eff.call

function* fetchSsoDataSaga(action: IAction<IFetchSsoDataPayload>) {
  try {
    const { payload } = action
    const response: ISuccessResponse<IFetchSsoDataSuccessResponseBody> = yield call(API.fetchSsoData, payload)
    const payloadResponse = response.data
    yield put(fetchSsoDataSuccess(payloadResponse))
  } catch (e) {
    Logger.error(e, 'SSO organization data could not be loaded')
    yield put(fetchSsoDataFailure())
  }
}

function* addUsersSaga(action: IAction<IAddUsersPayload>) {
  try {
    const { payload } = action
    const response: ISuccessResponse<IAddUsersSuccessResponseBody> = yield call(API.addUsers, payload)
    const payloadResponse = response.data
    yield put(addUsersSuccess(payloadResponse))
    if (payloadResponse.data.length === 0) {
      toastr.success('No users added', 'Users already existing.')
    } else {
      toastr.success('', 'Users added')
    }
  } catch (e) {
    Logger.error(e, 'Users cannot be added')
    yield put(addUsersFailure())
  }
}

function* deleteMemberSaga(action: IAction<IDeleteMemberPayload>) {
  try {
    const { payload } = action
    const response: ISuccessResponse<IDeleteMemberSuccessResponseBody> = yield call(API.deleteMember, payload)
    const payloadResponse = response.data
    yield put(deleteMemberSuccess(payloadResponse))
    toastr.success('', 'Member deleted')
  } catch (e) {
    Logger.error(e, 'Member cannot be deleted')
    yield put(deleteMemberFailure())
  }
}

function* changeRoleSaga(action: IAction<IChangeRolePayload>) {
  try {
    const { payload } = action
    const response: ISuccessResponse<IChangeRoleSuccessResponseBody> = yield call(API.changeRole, payload)
    const payloadResponse = response.data
    yield put(changeRoleSuccess(payloadResponse))
    toastr.success('', 'Role changed')
  } catch (e) {
    Logger.error(e, 'Role cannot be changed')
    yield put(changeRoleFailure())
  }
}

function* changeSubscriptionSaga(action: IAction<IChangeSubscriptionPayload>) {
  try {
    const { payload } = action
    const response: ISuccessResponse<IChangeSubscriptionSuccessResponseBody> = yield call(API.changeSubscription, payload)
    const payloadResponse = response.data
    yield put(changeSubscriptionSuccess(payloadResponse))
    toastr.success('', 'Subscription changed')
  } catch (e) {
    Logger.error(e, 'Subscription cannot be changed')
    yield put(changeSubscriptionFailure())
  }
}

function* ssoSagas() {
  yield takeEvery(SsoActionTypes.FETCH_SSO_DATA, fetchSsoDataSaga)
  yield takeEvery(SsoActionTypes.ADD_USERS, addUsersSaga)
  yield takeEvery(SsoActionTypes.DELETE_MEMBER, deleteMemberSaga)
  yield takeEvery(SsoActionTypes.CHANGE_ROLE, changeRoleSaga)
  yield takeEvery(SsoActionTypes.CHANGE_SUBSCRIPTION, changeSubscriptionSaga)
}

export default ssoSagas
