import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, Action } from 'redux'
import { IApplicationState } from '@Site/rootReducer'
import Popup from '@SiteComponents/Popup'
import Button from '@SiteComponents/Button'

import {
  deleteProjectVersion
} from './actions'

import {
  IDeleteProjectVersionPayload
} from './types'

import AttentionIcon from '@Images/attention.svg'

import './DeleteProjectVersionPopup.scss'

interface DeleteProjectVersionProps {
  readonly loading: boolean
  readonly projectVersionIds: string[]
  closePopup(): void
  deleteProjectVersion(payload: IDeleteProjectVersionPayload): void
}

interface DeleteProjectVersionState {
  readonly deleteConfirmation: string
}

class DeleteProjectVersion extends React.Component<DeleteProjectVersionProps, DeleteProjectVersionState> {
  state = {
    deleteConfirmation: ''
  }

  changeDeleteCofirmation = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      deleteConfirmation: e.target.value
    })
  }

  handleConfirm = () => {
    const canDelete = this.state.deleteConfirmation === 'delete' || this.state.deleteConfirmation === 'DELETE'

    if (canDelete) {
      this.props.deleteProjectVersion({ projectVersionIds: this.props.projectVersionIds })
    }
  }

  render() {
    const { closePopup, loading } = this.props
    const { deleteConfirmation } = this.state
    const canDelete = this.state.deleteConfirmation === 'delete' || this.state.deleteConfirmation === 'DELETE'
    const shouldDisableButton = !canDelete || loading

    return (
      <Popup
        className="DeleteProjectVersionPopup"
        closePopup={closePopup}
        isDark
      >
        <div className="DeleteProjectVersionPopup__title">Delete the project(s)</div>
        <p className="DeleteProjectVersionPopup__description">
          <AttentionIcon className="DeleteProjectVersionPopup__description__icon" />
          Warning, you will delete the project(s)<br />
          and all the subtitles related to them.
        </p>
        <p className="DeleteProjectVersionPopup__description">
          Type “delete” below to validate this action.
        </p>

        <input className="DeleteProjectVersionPopup__confirmation"
          placeholder="delete"
          value={deleteConfirmation}
          onChange={this.changeDeleteCofirmation}
        />

        <Button
          color="primary-dark"
          caption="Confirm the action"
          onClick={this.handleConfirm}
          showSpinner={loading}
          disabled={shouldDisableButton}
        />
      </Popup>
    )
  }
}

function mapStateToProps(state: IApplicationState) {
  return {
    loading: state.deleteProjectVersion.loading,
    projectVersionIds: state.deleteProjectVersion.projectVersionIds,
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return bindActionCreators({
    deleteProjectVersion,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteProjectVersion)
