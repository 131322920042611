import {
  TPasswordNewActionTypes,
  PasswordNewFormActionTypes,
  IUserPasswordNewState
} from './types'

export const initialState: IUserPasswordNewState = {
  loading: false
}

export const userPasswordNewReducer = (
  state: IUserPasswordNewState = initialState,
  action: TPasswordNewActionTypes
): IUserPasswordNewState => {
  switch (action.type) {
    case PasswordNewFormActionTypes.PASSWORD_NEW: return passwordNew(state)
    case PasswordNewFormActionTypes.PASSWORD_NEW_SUCCESS: return passwordNewSuccess(state)
    case PasswordNewFormActionTypes.PASSWORD_NEW_FAILURE: return passwordNewFailure(state)
    default: return state
  }
}

const passwordNew = (state: IUserPasswordNewState): IUserPasswordNewState => {
  return { ...state, loading: true }
}

const passwordNewSuccess = (state: IUserPasswordNewState): IUserPasswordNewState => {
  return { ...state, loading: false }
}

const passwordNewFailure = (state: IUserPasswordNewState): IUserPasswordNewState => {
  return { ...state, loading: false }
}
